import React from 'react';
import PropTypes from 'prop-types';

import { Col, Form } from 'react-bootstrap';
import FormikCheckboxGroup from '@intus-ui/components/forms/FormikReactBootstrap/FormikCheckboxGroup';

const CustomCheckboxes = ({
  customItems,
  mainCheckboxes,
  sectionCheckboxes,
  values,
  filterSectionBoxes,
  getDisplayName,
  toggleMain,
}) => {
  const rows = [];
  let row = [];
  customItems.forEach((item, index) => {
    if ((index + 1) % 4 === 0) {
      if (index !== 0) {
        row.push(item);
        rows.push(row);
      }
      row = [];
    } else {
      row.push(item);
    }
  });
  if (row.length) rows.push(row); // any leftovers

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {customItems.length ? (
        rows.map((r, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Form.Row className="m-0 w-100 h-75" key={`custom-checkboxes-row-${index}`}>
            {r.map((id, i) => (
              <Col
                className={`p-3 h-100 w-100 ${i !== 0 ? 'border-left' : ''}`}
                key={`custom-checkboxes-col-${id}`}
              >
                <FormikCheckboxGroup
                  main={mainCheckboxes[id]}
                  setMain={() => toggleMain(id)}
                  mainName={sectionCheckboxes[id].dashName}
                  values={values}
                  name={id}
                  sectionCheckboxes={filterSectionBoxes(sectionCheckboxes[id])}
                  checkboxItemDisplayName={getDisplayName}
                />
              </Col>
            ))}
          </Form.Row>
        ))
      ) : (
        <div className="p-3">Create a Custom Dashboard to see items here</div>
      )}
    </>
  );
};

CustomCheckboxes.propTypes = {
  customItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  mainCheckboxes: PropTypes.instanceOf(Object).isRequired,
  sectionCheckboxes: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  filterSectionBoxes: PropTypes.func.isRequired,
  getDisplayName: PropTypes.func.isRequired,
  toggleMain: PropTypes.func.isRequired,
};

export default CustomCheckboxes;
