export const defaultOverlayStyle = {
  zIndex: 1000,
  position: 'fixed',
  margin: 0,
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(98, 98, 117, 0.66)',
};

export const defaultContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '1px solid #ccc',
  background: '#fff',
  borderRadius: '10px',
  height: 'fit-content',
  padding: '10px 15px',
  maxHeight: '90%',
  gap: 5,
};
