import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from '@global-state';
import '@intus-ui/styles/Login.scss';
import '@intus-ui/styles/WelcomeFlow.scss';
import { getTeams, getFacilities, getHomePageBox, getJobTypes } from '@global-state/redux';
import { useFeatureFlag } from '@util';
import ChooseTeamDayCenter from 'Welcome/ChooseTeamDayCenter';
import { SpinnerOrError } from '@intus-ui';
import WelcomeBegin from './WelcomeBegin';
import TopicSelection from './TopicSelection';
import MonthlyDailyPreferences from './MonthlyDailyPreferences';
import HomePageIntroStep2 from './HomePageIntroStep2';
import ChooseCategories from './ChooseCategories';
import ChooseJobTypes from './ChooseJobTypes';

// If a user's profile is incomplete
const userSettingsError = (userSettings) => {
  const history = useHistory();

  return (
    <SpinnerOrError
      error={
        userSettings.profile
          ? undefined
          : 'Please click the Start Over button below to set up your Intus Care login:'
      }
      onClick={() => {
        history.push('/');
      }}
      buttonText="Start Over"
    />
  );
};

const Welcome = () => {
  const dispatch = useDispatch();
  const { featureIsActive } = useFeatureFlag('new-welcome-and-home');
  const page = useSelector((state) => state.welcome.page);
  const userSettings = useSelector((state) => state.userSettings);
  const orgId = useSelector((state) => state.welcome.orgId);
  const pages = featureIsActive
    ? [
        <WelcomeBegin />,
        <ChooseJobTypes />,
        <ChooseTeamDayCenter />,
        <MonthlyDailyPreferences />,
        <TopicSelection />,
      ]
    : [
        <WelcomeBegin />,
        <ChooseJobTypes />,
        <ChooseTeamDayCenter />,
        <HomePageIntroStep2 />,
        <ChooseCategories />,
      ];
  useEffect(() => {
    if (orgId) {
      dispatch(getTeams(orgId));
      dispatch(getFacilities(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    dispatch(getHomePageBox());
    dispatch(getJobTypes());
  }, []);
  return userSettings.profile ? pages[page] : userSettingsError(userSettings);
};

export default Welcome;
