/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';

const FormikCheckboxGroup = ({
  main,
  setMain,
  mainName,
  values,
  name,
  sectionCheckboxes,
  checkboxItemDisplayName,
}) => {
  if (values[name] === undefined) return <CenteredSpinner className="w-100 h-100" />;

  return (
    <div className="w-100 h-100 d-flex flex-column">
      <h5 className="pb-2">
        <label>
          <Field
            type="checkbox"
            checked={main}
            onClick={() => {
              values[name].length = 0;
              if (!main) {
                values[name] = sectionCheckboxes.map((item) => checkboxItemDisplayName(item));
              }
              setMain();
            }}
            name={`${name}-main`}
            value={`${name}-main`}
          />
          {` ${mainName}`}
        </label>
      </h5>
      <div
        role="group"
        aria-labelledby="checkbox-group"
        className="w-100 overflow-auto d-flex flex-grow-1 flex-column p-2"
      >
        {sectionCheckboxes.map((item) => {
          const displayName = checkboxItemDisplayName(item);
          const index = values[name].indexOf(displayName);
          return (
            <label key={displayName}>
              <Field
                type="checkbox"
                checked={index >= 0}
                onClick={() =>
                  index >= 0 ? values[name].splice(index, 1) : values[name].concat(displayName)
                }
                name={name}
                value={displayName}
              />
              {` ${displayName}`}
            </label>
          );
        })}
      </div>
    </div>
  );
};

FormikCheckboxGroup.propTypes = {
  main: PropTypes.bool.isRequired,
  setMain: PropTypes.func.isRequired,
  mainName: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sectionCheckboxes: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkboxItemDisplayName: PropTypes.func,
};

FormikCheckboxGroup.defaultProps = {
  checkboxItemDisplayName: (name) => name,
};

export default FormikCheckboxGroup;
