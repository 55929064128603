import Icon from '@intus-ui/components/Icon';
import Text from '@intus-ui/components/Text';
import { input } from '@intus-ui/styles/SecondaryColors';
import { MenuItem, Select, Checkbox, Input } from '@mui/material';
import { difference, includes, intersection } from 'lodash';
import { useState } from 'react';
import * as React from 'react';

import FormControl from '@mui/material/FormControl';

/**
 * @typedef {{
 *  label: React.ReactNode,
 *  items: string[],
 *  selectedItems: string[],
 *  allowSelectAll?: boolean,
 *  onChange: (selectedItems: string[]) => void,
 *  renderItem?: (item: string) => React.ReactNode,
 *  } & Omit<import('@mui/material').SelectProps, 'label' | 'onChange' | 'value' | 'multiple' | 'renderValue'>
 * } MultiselectProps
 */

function isCategory(item, categories) {
  if (
    includes(
      categories.map((item) => item.category),
      item
    )
  ) {
    return true;
  }
  return false;
}
/**
 *
 * @param {MultiselectProps} props
 */
export const PillMultiselect = ({
  items,
  selectedItems,
  allowSelectAll,
  onChange,
  categoriesWithSubcategories,
}) => {
  const areAllSelected = selectedItems.length === items.length;

  const [isOpen, setIsOpen] = useState(false);

  // Sort the items when the dropdown is closed.
  // We want the checked items to show up on top but we don't want to sort them as the user clicks them.
  function onCloseDropdown() {
    setIsOpen(false);
  }

  const selectAllText = areAllSelected ? 'Unselect All' : 'Select All';

  const onChangeCatgory = (category) => {
    const { subcategories } = categoriesWithSubcategories.find(
      (item) => item.category === category
    );
    if (subcategories) {
      if (intersection(selectedItems, subcategories).length) {
        onChange(difference(selectedItems, subcategories));
      } else {
        onChange([...selectedItems, ...subcategories]);
      }
    } else {
      if (selectedItems.includes(category)) {
        onChange(selectedItems.filter((item) => item !== category));
      } else {
        onChange([...selectedItems, category]);
      }
    }
  };

  const handleChange = (event, _child) => {
    const {
      target: { value, checked },
    } = event;
    if (typeof value === 'string') {
      if (isCategory(value, categoriesWithSubcategories)) {
        const { subcategories } = categoriesWithSubcategories.find((item) => {
          return item.category === value;
        });
        if (!checked) {
          if (subcategories) onChange(difference(selectedItems, subcategories));
          else onChange(selectedItems.filter((item) => item !== value));
        } else {
          if (subcategories) onChange([...selectedItems, ...subcategories]);
          else onChange([...selectedItems, value]);
        }
      } else {
        if (!checked) {
          onChange(selectedItems.filter((item) => item !== value));
        } else {
          onChange([...selectedItems, value]);
        }
      }
    } else {
      // This is super hacky but I cannot find another way to determine what specific item was clicked.
      const clickedAll = (value.includes('Select All') || value.includes('Unselect All')) ?? false;

      if (clickedAll && areAllSelected) {
        onChange([]);
      } else if (clickedAll && !areAllSelected) {
        onChange(items);
      } else {
        onChange(value);
      }
    }
  };

  let inputDisplayText = selectedItems.join(', ');

  if (areAllSelected && allowSelectAll) {
    inputDisplayText = 'All';
  }

  const flattenItems = (categoriesWithSubcategories) => {
    const flattenedItems = [];
    categoriesWithSubcategories.forEach((item) => {
      flattenedItems.push(
        <div>
          <MenuItem
            key={item.category}
            value={item.category}
            disableRipple
            // Focus the item so we don't have 2 items colored at once
            onMouseEnter={(event) => event.target.focus()}
            style={{
              height: 31,
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 10,
            }}
            onClick={(event) => {
              onChangeCatgory(item.category);
              // Reset the focus to the <li> element under the mouse once React re-renders.
              // This prevents 2 items from being colored at once when clicking.
              setTimeout(() => {
                let elementUnderMouse = document.elementFromPoint(event.clientX, event.clientY);

                while (elementUnderMouse != null && elementUnderMouse.nodeName !== 'LI') {
                  elementUnderMouse = elementUnderMouse.parentElement;
                }

                if (elementUnderMouse != null) {
                  elementUnderMouse.focus();
                }
              }, 1);
            }}
          >
            <Checkbox
              disableRipple
              checked={
                item.subcategories
                  ? intersection(selectedItems, item.subcategories).length ===
                    item.subcategories.length
                  : selectedItems.includes(item.category) || areAllSelected
              }
              value={item.category}
              indeterminate={
                intersection(selectedItems, item.subcategories)?.length <
                  item.subcategories?.length &&
                intersection(selectedItems, item.subcategories)?.length > 0
              }
            />
            <Text>{item.category}</Text>
          </MenuItem>
        </div>
      );
      if (item.subcategories) {
        flattenedItems.push(
          ...item.subcategories.map((type) => {
            return (
              <MenuItem
                key={type}
                value={type}
                disableRipple
                onChange={(event) => handleChange(event)} // Focus the item so we don't have 2 items colored at once
                onMouseEnter={(event) => event.target.focus()}
                onClick={(event) => {
                  // Reset the focus to the <li> element under the mouse once React re-renders.
                  // This prevents 2 items from being colored at once when clicking.
                  setTimeout(() => {
                    let elementUnderMouse = document.elementFromPoint(event.clientX, event.clientY);

                    while (elementUnderMouse != null && elementUnderMouse.nodeName !== 'LI') {
                      elementUnderMouse = elementUnderMouse.parentElement;
                    }

                    if (elementUnderMouse != null) {
                      elementUnderMouse.focus();
                    }
                  }, 1);
                }}
                style={{
                  height: 31,
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 20,
                  paddingRight: 10,
                }}
              >
                <Checkbox
                  disableRipple
                  value={type}
                  checked={selectedItems.includes(type) || areAllSelected}
                />
                <Text>{type}</Text>
              </MenuItem>
            );
          })
        );
      }
    });
    return flattenedItems;
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: '70px' }}>
        <Select
          aria-label={`${inputDisplayText}`}
          IconComponent={(iconProps) => (
            <Icon
              name={isOpen ? 'caret-up' : 'caret-down'}
              color={input.active}
              {...iconProps}
              style={{ ...iconProps.style, top: 'unset', transform: `scale(0.7)` }}
            />
          )}
          multiple
          input={
            <Input
              style={{
                height: '24px',
                minWidth: '60px',
                maxWidth: '180px',
                borderRadius: '20px',
                border: '2px solid #E4ECFF',
                padding: '0px 0px 0px 10px',
                color: '#2E62E7',
              }}
              disableUnderline
            />
          }
          value={selectedItems}
          onOpen={() => setIsOpen(true)}
          onClose={() => onCloseDropdown()}
          onChange={handleChange}
          // displayEmpty is needed to show the bold label text inside when nothing is selected.
          displayEmpty
          renderValue={() => (
            // We need an inline style here as the text overflow with ... is not working with the Text component.
            <span
              style={{
                justifySelf: 'center',
                alignSelf: 'center',
                textAlign: 'left',
                padding: 0,
              }}
            >
              <Text
                wrapper="span"
                style={{ width: '100%', display: 'inline' }}
                type="caption"
                color="link"
                ellipsis
              >
                {inputDisplayText}
              </Text>
            </span>
          )}
          sx={{
            ...styles.input,
          }}
          MenuProps={{
            // Turn off the fading of the dropdown menu when it is opened or closed.
            // This causes weird issues where the dropdown moves around when it's closed then fades away.
            transitionDuration: 0,
            // Do not focus the last item by default when the dropdown opens.
            variant: 'menu',
            // Try and get the dropdown menu to show below the input field if it fits.
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: { ...styles.menu },
          }}
        >
          {allowSelectAll && (
            <MenuItem
              key={selectAllText}
              value={selectAllText}
              style={{
                height: 31,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 10,
              }}
              disableRipple
              // Focus the item so we don't have 2 items colored at once
              onMouseEnter={(event) => event.target.focus()}
              onClick={(event) => {
                // Reset the focus to the <li> element under the mouse once React re-renders.
                // This prevents 2 items from being colored at once when clicking.
                setTimeout(() => {
                  let elementUnderMouse = document.elementFromPoint(event.clientX, event.clientY);

                  while (elementUnderMouse != null && elementUnderMouse.nodeName !== 'LI') {
                    elementUnderMouse = elementUnderMouse.parentElement;
                  }

                  if (elementUnderMouse != null) {
                    elementUnderMouse.focus();
                  }
                }, 1);
              }}
            >
              <Checkbox
                checked={
                  selectAllText === 'Select All' || selectAllText === 'Unselect All'
                    ? areAllSelected
                    : selectedItems.includes(selectAllText)
                }
              />
              <Text>{selectAllText}</Text>
            </MenuItem>
          )}
          {categoriesWithSubcategories && flattenItems(categoriesWithSubcategories)}
          {!categoriesWithSubcategories &&
            items.map((item) => (
              <MenuItem
                key={item}
                value={item}
                disableRipple
                style={{
                  height: 31,
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  paddingRight: 10,
                }} // Focus the item so we don't have 2 items colored at once
                onMouseEnter={(event) => event.target.focus()}
                onClick={(event) => {
                  // Reset the focus to the <li> element under the mouse once React re-renders.
                  // This prevents 2 items from being colored at once when clicking.
                  setTimeout(() => {
                    let elementUnderMouse = document.elementFromPoint(event.clientX, event.clientY);

                    while (elementUnderMouse != null && elementUnderMouse.nodeName !== 'LI') {
                      elementUnderMouse = elementUnderMouse.parentElement;
                    }

                    if (elementUnderMouse != null) {
                      elementUnderMouse.focus();
                    }
                  }, 1);
                }}
              >
                <Checkbox
                  disableRipple
                  checked={
                    item === 'Select All' || item === 'Unselect All'
                      ? areAllSelected
                      : selectedItems.includes(item)
                  }
                />
                <Text>{item}</Text>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

const minWidth = 135;
const maxWidth = '350px';

const styles = {
  input: {
    minWidth,
    maxWidth,
    backgroundColor: input.default,
  },
  menu: {
    '& .MuiPopover-paper': {
      backgroundColor: input.default,
      padding: 0,
      maxWidth,
      minHeight: 'fit-content',
      '& .MuiList-root': {
        minWidth: 145,
        maxHeight: '260px',
      },
    },
  },
};
