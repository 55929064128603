import { getDashboardCompare } from '@api/dashboard';
import { useLazyQuery } from '@api/useQuery';
import { Card, Icon, SpinnerOrError, Text, useParticipantFilters } from '@intus-ui';
import { Button as MuiButton, Tab, Tabs } from '@mui/material';
import { getStringReplacement } from '@util/stringReplacements';
import { useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const CustomDashboardCompareView = ({
  dashboard: _dashboard,
  setIsCompareOpen,
  saveDashboardIfNotSaved,
}) => {
  const timeFilter = useSelector((state) => state.filters.customDashTimeFilter);

  const { teams, facilities } = useParticipantFilters();

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const {
    data,
    loading,
    error,
    runQuery: runCompareQuery,
  } = useLazyQuery(async () => {
    // If the dashboard isn't saved yet, save it before running the compare query.
    const myDashboard = await saveDashboardIfNotSaved();

    return getDashboardCompare(myDashboard.id, timeFilter.startDate, timeFilter.endDate);
  });

  // Re-run the compare query when the time filter changes.
  useEffect(() => {
    runCompareQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter.startDate, timeFilter.endDate]);

  // Returns an object like:
  //
  // [{ id: 123, title: "Total Participants", columns: { All: 12, "Team 1": 15, "Team 2": 7 } }]
  const teamRows = useMemo(() => {
    if (!data) return [];

    const metricRows = [];

    for (const metricData of data) {
      const row = { id: metricData.id, title: metricData.title, columns: {} };
      metricRows.push(row);
      for (const teamData of metricData.breakdown.teams) {
        row.columns[teamData.column] = teamData.value;
      }
    }

    return metricRows;
  }, [data]);

  // Returns an object like:
  //
  // [{ id: 123, title: "Total Participants", columns: { All: 12, "Facility 1": 15, "Facility 2": 7 } }]
  const facilityRows = useMemo(() => {
    if (!data) return [];

    const metricRows = [];

    for (const metricData of data) {
      const row = { id: metricData.id, title: metricData.title, columns: {} };
      metricRows.push(row);
      for (const facilityData of metricData.breakdown.facilities) {
        row.columns[facilityData.column] = facilityData.value;
      }
    }

    return metricRows;
  }, [data]);

  if (error) {
    return <SpinnerOrError error="An error occurred loading the dashboard comparison" />;
  }

  if (loading || !data) {
    return <SpinnerOrError />;
  }

  return (
    <div style={styles.container}>
      <Card
        fluid
        style={styles.card}
        contentStyle={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}
      >
        <div style={{ width: '100%', flex: '0 0 auto', display: 'flex' }}>
          <div style={{ flex: '1 1 100%', display: 'flex', justifyContent: 'center' }}>
            <Tabs value={tabIndex} onChange={handleChange} aria-label="team/facility tabs">
              <Tab
                disableRipple
                label={getStringReplacement('Team')}
                {...a11yProps(0)}
                sx={styles.tab}
              />
              <Tab
                disableRipple
                label={getStringReplacement('Center')}
                {...a11yProps(1)}
                sx={styles.tab}
              />
            </Tabs>
          </div>
          <div style={{ flex: '0 0 auto' }}>
            <MuiButton
              aria-label="Close Breakdown View"
              style={{ minWidth: 32 }}
              onClick={() => setIsCompareOpen(false)}
            >
              <Icon name="remove" />
            </MuiButton>
          </div>
        </div>
        <CompareTabPanel value={tabIndex} index={0} style={{ flex: '1 1 100%' }}>
          <CompareTable teamsOrFacilities={['All', ...teams]} rows={teamRows} />
        </CompareTabPanel>
        <CompareTabPanel value={tabIndex} index={1} style={{ flex: '1 1 100%' }}>
          <CompareTable teamsOrFacilities={['All', ...facilities]} rows={facilityRows} />
        </CompareTabPanel>
      </Card>
    </div>
  );
};

const CompareTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`compare-tabpanel-${index}`}
      aria-labelledby={`compare-tab-${index}`}
      {...other}
      style={{ minWidth: '100%', ...other.style }}
    >
      {value === index && children}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `compare-tab-${index}`,
    'aria-controls': `compare-tabpanel-${index}`,
  };
}

const CompareTable = ({ teamsOrFacilities, rows }) => {
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <Table borderless striped>
        <thead>
          <tr>
            <th style={styles.cell}>
              <Text>
                <b>Metric</b>
              </Text>
            </th>
            {teamsOrFacilities.map((teamOrFacility) => (
              <th key={teamOrFacility.id} style={{ ...styles.cell, textAlign: 'right' }}>
                <Text>
                  <b>{teamOrFacility}</b>
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td style={{ ...styles.cell, width: 300, minWidth: 200 }}>
                <Text>{row.title}</Text>
              </td>
              {teamsOrFacilities.map((teamOrFacility) => (
                <td key={teamOrFacility} style={{ ...styles.cell, textAlign: 'right' }}>
                  <Text>{row.columns[teamOrFacility] ?? ''}</Text>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 5,
  },
  card: {
    backgroundColor: 'white',
    flex: '1 1 100%',
  },
  tab: {
    '&:focus': { outline: 'none ' },
    // Use the default material-ui focus styling but only for tabbing.
    '&:focus-visible': {
      outline: ['1px dotted', '5px auto -webkit-focus-ring-color'],
    },
  },
  cell: {
    padding: '15px 20px 15px 10px',
  },
};
