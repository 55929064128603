const MeetingsApi = {
  tagTypes: ['Meeting'],
  endpoints: (builder) => ({
    getMeetings: builder.query({
      query: () => 'meetings',
    }),

    addMeeting: builder.mutation({
      query: (data) => ({
        url: 'meetings',
        method: 'POST',
        body: data,
      }),
    }),

    updateMeeting: builder.mutation({
      query: (data) => ({
        url: `meetings/${data.id}`,
        method: 'PUT',
        body: data,
      }),
    }),

  }),
};

export default MeetingsApi;
