import { Multiselect } from '@intus-ui/components/Multiselect/Multiselect';
import { useParticipantFilters } from '@intus-ui/components/filters/ParticipantFiltersContext';
import { useMemo } from 'react';

/**
 * Displays a list of extra patient filters that can be defined in the OrganizationSettings.
 *
 * If the org doesn't have any extra filters this renders nothing.
 */
export const PatientGroupFilterList = ({ ...multiselectProps }) => {
  const { groupFilters } = useParticipantFilters();

  // If we have no filters do nothing here.
  if (groupFilters == null || groupFilters.length === 0) {
    return null;
  }

  return (
    <>
      {groupFilters.map((filter) => {
        return <PatientGroupDropdown {...multiselectProps} key={filter.type} filter={filter} />;
      })}
    </>
  );
};

/**
 * @param {{ filter: ReturnType<typeof useParticipantFilters>["groupFilters"][number] }} props
 */
const PatientGroupDropdown = ({ filter, ...multiselectProps }) => {
  const { selectedFilters, setGroupFilter } = useParticipantFilters();

  const { type } = filter;

  const selectedGroupNames = selectedFilters.groups[type]?.map((g) => g.name);

  const allGroupNames = useMemo(() => {
    return filter.groups.map((g) => g.name);
  }, [filter.groups]);

  const onChange = (newGroupNames) => {
    const groups = filter.groups.filter((g) => newGroupNames.includes(g.name));
    setGroupFilter(
      type,
      groups.map((g) => g.id)
    );
  };

  return (
    <Multiselect
      id={type}
      label={filter.name}
      allowSelectAll
      items={allGroupNames}
      // selectedGroupNames being null means all groups are selected
      // empty array means the user unchecked all the groups.
      selectedItems={selectedGroupNames || allGroupNames}
      onChange={(value) => onChange(value)}
      {...multiselectProps}
    />
  );
};
