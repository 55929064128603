import { getWithoutEncoding } from '@api/apiMethods';

export type IMedicationClass = {
  gpi: string;
  gpiType: string;
  groupName: string;
};

export function getAllMedicationClasses() {
  return getWithoutEncoding<IMedicationClass[]>('polypharmacy/medication-classes');
}
