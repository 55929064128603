import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Popover } from 'react-bootstrap';
import Draggable from 'react-draggable';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ScoreBreakDown from '../ScoreBreakDown';
import IntusLabelsPropType from '../IntusLabelsPropType';
import AcuityHeader from '../ProfileAcuityHeader';

import 'Participants/RiskTrendline/style.scss';

const RiskTrendLinePopup = forwardRef(
  ({ point, type, acuityLevelChanges, acuityLevels, handleClick }, ref) => (
    <>
      {type === 'acuity' && (
        <Draggable>
          <Popover
            id="popover-basic"
            ref={ref}
            className="box-shadow-on-gray box-shadow-on-hover"
            style={{
              border: '2px solid #b3c6f4',
              borderRadius: '15px',
              borderWidth: '5px',
            }}
          >
            <Popover.Content className="mt-1">
              <AcuityHeader
                acuityLevel={acuityLevels[point.data.index]}
                riskGroup={acuityLevels[point.data.index].riskGroup}
              >
                <button
                  type="button"
                  title="Close Popup"
                  className="position-absolute outline-none border-0 bg-transparent text-dark hover-light-blue d-flex justify-content-center align-items-center rounded"
                  style={{ top: '5px', right: '1px', padding: '2px' }}
                  onClick={() => {
                    handleClick(false);
                  }}
                >
                  <CloseRoundedIcon fontSize="small" />
                </button>
              </AcuityHeader>
              <ScoreBreakDown
                acuityLevelChanges={acuityLevelChanges}
                acuityLevels={acuityLevels[point.data.index]}
                selected={acuityLevels.length - 1 - point.data.index}
              />
            </Popover.Content>
          </Popover>
        </Draggable>
      )}
      {type === 'undefined' && null}
    </>
  )
);

RiskTrendLinePopup.propTypes = {
  type: PropTypes.string.isRequired,
  point: PropTypes.instanceOf(Object).isRequired,
  acuityLevelChanges: PropTypes.arrayOf(IntusLabelsPropType).isRequired,
  acuityLevels: PropTypes.instanceOf(Array).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default RiskTrendLinePopup;
