import React, { useContext } from 'react';
import { Card, Text } from '@intus-ui';
import ActionCardActions from './ActionCardActions';

import { MeetingContext } from '../context/MeetingContext';

import './ActionItemCard.css';

export default function ActionItemCard({
  index,
  description,
  complete,
  note,
  onEditClick,
  onCompleteClick,
  editing,
  clearForm,
  createdAt,
  completedAt,
}) {
  const { meeting } = useContext(MeetingContext);

  return (
    // <Quad className="card">
    <Card
      className="flex-row"
      clickable={false}
      style={
        editing ? { padding: 5, outline: '5px solid rgba(77, 126, 248, 0.5)' } : { padding: 5 }
      }
      width="auto"
    >
      <Card style={{ width: '15%', border: '0px', padding: 10 }} flat>
        {true && (
          <>
            <Text type="title">Actions</Text>
            <ActionCardActions
              complete={complete}
              onEditClick={() => onEditClick(index)}
              onCompleteClick={() => onCompleteClick(index)}
              inMeeting={meeting}
              editing={editing}
              clearForm={clearForm}
            />
          </>
        )}
      </Card>
      <Card style={{ width: '35%', border: '0px', padding: 10 }} flat bgColor="grey">
        <Text type="title">Description</Text>
        <Text>{description}</Text>
      </Card>
      <Card style={{ width: '35%', border: '0px', padding: 10 }} flat bgColor="grey">
        <Text type="title">Notes</Text>
        <Text>{note}</Text>
      </Card>
      <Card style={{ width: '15%', border: '0px', padding: 10 }} flat bgColor="grey">
        <Text type="subtitle">Created at:</Text>
        <Text>{createdAt.split('T')[0]}</Text>
        <Text type="subtitle">Completed at:</Text>
        <Text>{completedAt ? completedAt.split('T')[0] : '--'}</Text>
      </Card>
    </Card>
  );
}
