import { Icon, Text } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { Bullet } from '@intus-ui/icons';
import { formatDate } from '@util/dateFunctions';
import { useState } from 'react';

export function createFormat(filters) {
  const format = [
    {
      field: 'admitDate',
      name: 'Date',
      flex: 1.8,
      align: 'start',
      render: (row) => {
        const admit = row.item;
        if (!admit) return null;
        const formattedAdmitDate = formatDate(admit.admitDate, 'MM/DD/YYYY');
        const formattedDischargeDate = formatDate(admit.dischargeDate, 'MM/DD/YYYY');
        let dateRange = admit.admitDate;
        if (admit.dischargeDate) dateRange = `${formattedAdmitDate} - Present`;
        if (admit.dischargeDate && admit.dischargeDate !== admit.admitDate)
          dateRange = `${formattedAdmitDate} - ${formattedDischargeDate}`;
        return dateRange;
      },
      addOns: [{ type: 'sort', element: <SortIcon key="admitDate" id="admitDate" /> }],
    },
    {
      field: 'type',
      name: 'Type',
      flex: 2.3,
      align: 'start',
      addOns: [{ type: 'filter', element: filters?.typeFilter }],
    },
    {
      field: 'admitFacility',
      name: 'Admission Location',
      flex: 1.5,
      align: 'start',
    },
    {
      field: 'dischargeDisposition',
      name: 'Discharge Disposition',
      flex: 1.7,
      align: 'start',
    },
    {
      field: 'lengthOfStay',
      name: 'Length of Stay',
      flex: 1.2,
      align: 'start',
    },
    {
      field: 'diagnoses',
      name: 'Diagnoses',
      flex: 4,
      align: 'start',
      render: (row) => {
        const admit = row.item;
        if (!admit) return null;
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text type="subtitle">Principal Diagnoses</Text>
              <DiagnosesList diagnoses={admit.principalDiagnoses} type="principal" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text type="subtitle">Other Diagnoses</Text>
              <DiagnosesList diagnoses={admit.otherDiagnoses} type="other" />
            </div>
          </div>
        );
      },
    },
  ];
  return format;
}

const DiagnosesList = ({ diagnoses, type }) => {
  const [listExpanded, setListExpanded] = useState(false);
  if (!diagnoses || !diagnoses?.length) return '-';
  let limit = 1;
  if (type === 'other') limit = 3;
  const shownDiagnoses = diagnoses.slice(0, limit);
  const hiddenDiagnoses = diagnoses.slice(limit);
  return (
    <>
      {shownDiagnoses.map((dx) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Bullet />
          <Text>{dx}</Text>
        </div>
      ))}
      {hiddenDiagnoses.length > 0 && (
        <>
          {listExpanded &&
            hiddenDiagnoses.map((dx) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Bullet />
                <Text>{dx}</Text>
              </div>
            ))}
          <Text
            type="caption"
            color="caption"
            style={{ cursor: 'pointer' }}
            onClick={() => setListExpanded(!listExpanded)}
          >
            <Icon name={!listExpanded ? 'caret-down' : 'caret-up'} color="caption" size="small" />
            {!listExpanded ? 'Expand All' : 'Collapse All'}
          </Text>
        </>
      )}
    </>
  );
};
