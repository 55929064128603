import { Input, Text } from '@intus-ui';

import { filterRowSchemas } from '../../filterRowConfig';
import DeleteFilterRowButton from './DeleteFilterRowButton';

const FilterInputSelectWithOptions = ({
  filterOptions,
  type,
  searchTerm,
  handleChangeSearchTerm,
  isSingleFilterRow,
  isSecond,
  operator,
  operators,
  handleChangeOperator,
  handleChangeFilterRowLogic,
  isFirst,
  displayRange,
  filter,
  parentIndex,
  handleDeleteFilter,
  filterLogicOption,
  pastMonth,
  handleChangedPastMonths,
}) => {
  const componentSchema = filterRowSchemas()[type]?.components?.FilterInputSelectWithOptions;
  const filterLogicOptions = componentSchema.logicOptions;

  const showPastMonth = ['completedInThePast', 'notCompletedInThePast'].includes(
    filterLogicOption?.value
  );

  return (
    <div style={styles.secondFilterRowWrapper}>
      <div id="secondFilterRow" style={styles.secondFilterRow}>
        {!isSingleFilterRow && (
          <div id="secondFilterRowSpacer" style={styles.filterRowSpacer}>
            {isSecond && (
              <Input
                id="operator"
                name="operator"
                type="select"
                rightIcon="caret-down"
                options={operators.reverse()}
                value={operator}
                onChange={handleChangeOperator}
                style={{ width: 90 }}
              />
            )}
            {!isFirst && !isSecond && (
              <div style={{ paddingLeft: 5 }}>
                <Text type="body">{operator}</Text>
              </div>
            )}
          </div>
        )}
        <Input
          fluid
          id={filterOptions[type]?.options}
          style={{
            minWidth: 300,
            cursor: 'pointer',
          }}
          name={filterOptions[type]?.options}
          type="select"
          options={filterOptions[type]?.options}
          value={searchTerm}
          placeholder={componentSchema?.placeholder}
          onChange={handleChangeSearchTerm}
          rightIcon="caret-down"
        />

        {filterLogicOptions && (
          <Input
            fluid
            id="filter-input-select-options"
            style={{
              width: 250,
              cursor: 'pointer',
            }}
            name="filter-input-select-options"
            type="select"
            options={filterLogicOptions}
            value={filterLogicOption?.label}
            placeholder={componentSchema?.logicOptionsPlaceholder}
            onChange={handleChangeFilterRowLogic}
            rightIcon="caret-down"
          />
        )}
        {!displayRange && filter.groups[parentIndex].conditions.length > 1 && (
          <DeleteFilterRowButton handleDeleteFilter={handleDeleteFilter} />
        )}
      </div>

      {showPastMonth && (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {!isSingleFilterRow && <div id="secondFilterRowSpacer" style={styles.filterRowSpacer} />}
          <input
            min="0"
            type="number"
            className="hover-input-light-blue"
            value={pastMonth}
            onChange={handleChangedPastMonths}
            style={{
              height: 35,
              width: 60,
              paddingLeft: 15,
              backgroundColor: 'white',
              border: '1px solid #9D9D9D',
              borderRadius: 5,
            }}
          />
          <Text>months</Text>
        </div>
      )}
    </div>
  );
};

const styles = {
  secondFilterRowWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  secondFilterRow: {
    display: 'flex',
    gap: 10,
  },
  filterRowSpacer: {
    width: 100,
  },
};
export default FilterInputSelectWithOptions;
