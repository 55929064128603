import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const SaveDashModal = ({ show, hide }) => (
  <Modal show={show} onHide={hide} centered className="w-100">
    <Modal.Header closeButton>
      <Modal.Title className="text-primary pb-1">Save Dashboard</Modal.Title>
    </Modal.Header>
    <Modal.Body className="d-flex text-dark flex-column justify-content-center align-items-center mx-4 pl-5 py-1 pb-5">
      <h5>
        Please <b className="text-secondary">cancel</b> or <b className="text-secondary">save</b>{' '}
        your changes on the current dashboard before navigating to a different dashboard.
      </h5>
    </Modal.Body>
  </Modal>
);

SaveDashModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func,
};

SaveDashModal.defaultProps = {
  hide: () => {},
};

export default SaveDashModal;
