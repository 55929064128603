import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@intus-ui/styles/FilteredList.scss';
import '@intus-ui/styles/Utilities.scss';
import { shadesOfBlue } from '@intus-ui/styles/SecondaryColors';

// Icon
const HoverBg = ({ children, hoverColor, fillColor, padding }) => {
  const [isHover, setIsHover] = useState(false);

  const expandedIcon = (
    <div
      className={`rounded-sm d-flex flex-row align-items-center justify-content-center text-center ${padding}`}
      style={{ backgroundColor: isHover ? hoverColor : fillColor }}
      onFocus={() => setIsHover(true)}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {children}
    </div>
  );

  return expandedIcon;
};

HoverBg.propTypes = {
  children: PropTypes.node.isRequired,
  fillColor: PropTypes.string,
  hoverColor: PropTypes.string,
  padding: PropTypes.string,
};

HoverBg.defaultProps = {
  hoverColor: shadesOfBlue.hoverBlue32,
  fillColor: shadesOfBlue.hoverBlue16,
  padding: 'p-2',
};

export default HoverBg;
