export const columnConfig = {
  default: {
    columns: [
      'admitDate',
      'type',
      'admitFacility',
      'dischargeDisposition',
      'lengthOfStay',
      'diagnoses',
    ],
    breakpoints: [
      {
        minWidth: 700,
        columns: [
          'admitDate',
          'type',
          'admitFacility',
          'dischargeDisposition',
          'lengthOfStay',
          'diagnoses',
        ],
      },
      {
        minWidth: 500,
        columns: ['admitDate', 'type', 'diagnoses'],
      },
    ],
  },
};
