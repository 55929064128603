import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { editCohort, createCohort, postPatientListAPI, getCohort } from '@api';
import { USER_ACCESS_ADMIN, USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import { useLazyQuery } from '@api/useQuery';
import { getStringReplacement } from '@util/stringReplacements';
import { useGetSessionUser } from '@util/session';
import { useForm, Controller } from '@util/forms';
import { useFieldArray } from 'react-hook-form';

import {
  ParticipantList,
  Button,
  Text,
  Icon,
  Input,
  Checkbox,
  SpinnerOrError,
  Divider,
} from '@intus-ui';
import UnsavedChangesModal from 'Initiatives/Trials/TrialsCreateOrEditView/UnsavedChangesModal';
import FilterSummary from 'AdvancedCohort/SearchCreate/ResultView/FilterSummary';
import CohortSearchWrapper from '../CohortSearchWrapper';
import { format } from '../FixedCohorts/customFormat';
import {
  renderTitleElement,
  renderSharedCheckbox,
  isCreatingCohortFromInitiative,
  returnToInitiativeFromCohortCreation,
} from '../FixedCohorts/helpers';

const CreateRollingCohort = ({ cohortToEdit }) => {
  const history = useHistory();
  const { organizationId, id: userId, access } = useGetSessionUser();

  const existingCohort = history?.location?.state;
  const canCreateShared = access === USER_ACCESS_ADMIN || access === USER_ACCESS_HEALTH_SYS_ADMIN;
  // A user can only convert a shared cohort to personal if they created the cohort.
  const isSharedCheckboxEnabled =
    canCreateShared && (cohortToEdit == null || cohortToEdit.createdByCurrentUser === true);
  // const isSharedCheckboxEnabled = false;
  // Load the list of patients to go in the pinned participant list.
  const [searchState, setSearchState] = useState(() => {
    return {
      view: 'SEARCH_CREATE',
      searchCriteria: existingCohort?.searchCriteria || cohortToEdit?.searchCriteria,
      filter: existingCohort?.searchCriteria || cohortToEdit?.searchCriteria,
      data: {
        count: existingCohort?.data?.count || cohortToEdit?.count,
        patientIds: existingCohort?.data?.patientIds || cohortToEdit?.patientIds,
      },
    };
  });

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      shared: false,
      searchCriteria: existingCohort?.searchCriteria || [],
      searchState: existingCohort?.searchState ?? { view: 'SEARCH_CREATE' },
      ...cohortToEdit,
      ...existingCohort,
    },
  });

  // VALIDATION: MAKE SURE THERE IS AT LEAST ONE PPT SELECTED IN ORDER TO MAKE A COHORT
  const { replace: setSearchCriteria } = useFieldArray({
    name: 'searchCriteria',
    control,
    rules: {
      required: 'Please select one or more filters',
    },
  });

  useEffect(() => {
    const subscription = watch((cohortToSave) => {
      history.replace({
        state: {
          ...cohortToSave,
          ...searchState,
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, searchState]);

  const [view, setView] = useState('list'); // 2 view options: list or search

  const [showCohortDescription, setShowCohortDescription] = useState(
    getValues('description')?.length > 0
  );
  const isSearchView = view === 'search';

  const [isSaved, setIsSaved] = useState(false);
  // API CALLS TO CREATE + EDIT COHORTS
  const {
    error: createCohortError,
    runQuery,
    loading: loadingCreateCohort,
  } = useLazyQuery(
    (cohortToSave) => {
      if (cohortToEdit)
        return editCohort(cohortToSave.id, { ...cohortToSave, userId, organizationId });
      return createCohort({ ...cohortToSave, userId, organizationId });
    },
    {
      onSuccess: async (cohortToSave) => {
        const res = await getCohort(cohortToSave.id);
        const newCohort = await res.json();
        const cohortToSaveBody = { ...newCohort[0], isNew: true };
        setIsSaved(true);

        // Wait one event loop tick to allow React to re-render.
        // We need the UnsavedChangesModal component to update with the new state.
        await new Promise((resolve) => {
          setTimeout(resolve, 0);
        });
        if (!cohortToEdit) {
          cohortToSaveBody.highlightCohortId = cohortToSave.id;
          cohortToSaveBody.scrollIntoView = true;
        }

        // Check if the user came from creating a new initiative or editing an existing one
        if (isCreatingCohortFromInitiative()) {
          returnToInitiativeFromCohortCreation(history, cohortToSave);
          return;
        }

        if (cohortToEdit) {
          history.goBack();
        } else {
          const url = cohortToSave.shared ? '/cohorts/shared' : '/cohorts/personal';
          history.replace(url, cohortToSaveBody);
        }
      },
    }
  );

  const handleCreateRollingCohort = async (cohort) => {
    const payload = {
      id: cohort.id,
      name: cohort.name,
      description: cohort.description,
      shared: cohort.shared,
      fixed: false,
      searchCriteria: searchState.searchCriteria,
      userId,
      organizationId,
    };
    runQuery(payload);
  };

  const {
    data: participants,
    loading,
    error,
    runQuery: runPptQuery,
  } = useLazyQuery((patientIds) => {
    return postPatientListAPI({ patientList: patientIds });
  });

  useEffect(() => {
    if (searchState.filter) {
      setSearchCriteria(searchState.searchCriteria);
      runPptQuery(searchState.data.patientIds);
    } else {
      setSearchCriteria([]);
      runPptQuery([]);
    }
  }, [searchState]);

  // If no patients match the search, change the message.
  const emptyListMessage =
    searchState?.data?.patientIds?.length === 0
      ? 'No participants match the filter criteria.'
      : undefined;

  return (
    <form onSubmit={handleSubmit(handleCreateRollingCohort)} style={styles.container}>
      <UnsavedChangesModal
        when={!isSaved && (loadingCreateCohort || isDirty)}
        message="Are you sure you want to leave this page? If you leave, your progress will not be saved."
        shouldAllowNavigation={(location) => {
          return (
            location.pathname?.startsWith('/cohorts/rolling/create') ||
            location.pathname?.startsWith('/patient/') ||
            location.pathname?.endsWith('/edit')
          );
        }}
      />
      <div style={styles.header}>
        <div style={styles.subheader}>
          <Button secondary onClick={() => history.go(-1)}>
            <Icon name="caret-left" />
            Back
          </Button>
          {/* if there's a cohort to edit change header */}
          <Text type="headline" color="navy" style={{ fontSize: '18px', fontWeight: '700' }}>
            {cohortToEdit != null ? `Edit Rolling Cohort` : `Create Rolling Cohort`}
          </Text>
        </div>
        {createCohortError && (
          <Text color="error" type="caption">
            One error occurred.
          </Text>
        )}
        <Button disabled={!isValid || loadingCreateCohort} type="submit">
          {cohortToEdit != null ? `Save Cohort` : `Create Cohort`}
        </Button>
      </div>
      <div style={styles.body}>
        <div style={styles.section}>
          <div
            style={{
              ...styles.filterResultWrapper,
              border: isSearchView ? 'solid 2px #2E62E7' : '',
            }}
          >
            {isSearchView && (
              <div style={styles.filtersView}>
                <CohortSearchWrapper
                  setView={setView}
                  setSearchState={setSearchState}
                  searchState={searchState}
                  onApplyFilterLabel="Show Results"
                />
              </div>
            )}
            {view === 'list' && (
              <div style={styles.listView}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    paddingLeft: '15px',
                    padding: 15,
                    gap: 23,
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text type="title">{searchState.filter ? 'Selected Filter' : 'Filters'}</Text>
                    <Button
                      onClick={() => setView('search')}
                      badge={searchState.filter && searchState.filter?.groups?.length}
                      secondary
                      badgeBgColor="#052D8F"
                    >
                      Filters
                    </Button>
                  </div>

                  {searchState.filter ? (
                    <FilterSummary
                      filter={searchState.filter}
                      setSearchState={setSearchState}
                      searchState={searchState}
                      isEditMode={searchState.isEditMode}
                      existingCohort={searchState.existingCohort}
                    />
                  ) : (
                    <Text color="caption">
                      Start to create your rolling cohort by adding filters. {getStringReplacement('Participant', {plural: true})} who match
                      the filter criteria will show below in the {getStringReplacement('Participant', {lower: true})} list.
                    </Text>
                  )}
                </div>

                <div style={styles.listView.dividerResultSection}>
                  <Divider color="disabled" />
                  {renderTitleElement(participants, 'Results')}
                </div>

                {participants && (
                  <ParticipantList
                    participantList={participants}
                    searchable={false}
                    customFormat={format()}
                    defaultSort={{ field: 'acuity', direction: 'desc' }}
                    onClickItem={(ppt) => history.push(`/patient/${ppt.id}/current-acuity`)}
                    emptyListMessage={emptyListMessage}
                  />
                )}

                {(loading || error) && <SpinnerOrError />}
              </div>
            )}
          </div>
          <div style={styles.cohortFormBox}>
            <div style={styles.cohortForm}>
              <Text type="headline" color="body" style={{ fontSize: '18px', fontWeight: '700' }}>
                Cohort Details
              </Text>
              <div>
                <Text type="caption" color="body">
                  Cohort Name
                </Text>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: 'Cohort name is required' }}
                  render={({ field }) => {
                    return (
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        style={{ width: 325 }}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        error={!!createCohortError}
                      />
                    );
                  }}
                />

                {createCohortError && (
                  <Text type="caption" color="error">
                    This name has been used. Please try another one.
                  </Text>
                )}
              </div>
              {showCohortDescription === false && (
                <div
                  role="none"
                  onClick={() => setShowCohortDescription(true)}
                  style={{
                    display: 'flex',
                    gap: '7.5px',
                    alignItems: 'center',
                    padding: '10px 0px 10px 0px',
                    cursor: 'pointer',
                  }}
                >
                  <Icon name="add" color="link" style={{ height: '17px', width: '17px' }} />
                  <Text type="subtitle" color="link">
                    Cohort Description
                  </Text>
                </div>
              )}
              {showCohortDescription === true && (
                <>
                  <Text type="caption" color="body">
                    Cohort Description
                  </Text>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Input
                          id="description"
                          name="description"
                          type="textarea"
                          style={{ width: 325, height: '90px' }}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        />
                      );
                    }}
                  />
                </>
              )}

              <Controller
                name="shared"
                control={control}
                render={({ field }) => {
                  return renderSharedCheckbox(
                    <div
                      anchorId="sharedCheckbox"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                      }}
                    >
                      <Checkbox
                        onChange={field.onChange}
                        aria-labelledby="ongoing-text"
                        checked={field.value}
                        disabled={isSharedCheckboxEnabled === false}
                        style={{ cursor: isSharedCheckboxEnabled ? 'pointer' : 'default' }}
                      />
                      <Text color={isSharedCheckboxEnabled ? 'body' : 'caption'}>
                        Share this Cohort within my organization.
                      </Text>
                    </div>,
                    isSharedCheckboxEnabled,
                    cohortToEdit && (!cohortToEdit.createdByCurrentUser || !canCreateShared)
                      ? 'Only the person who created this cohort can change the shared setting of this Cohort.'
                      : 'Only Admin users can create shared Cohorts.'
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateRollingCohort;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'start',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    justifyContent: 'space-between',
    padding: '0px 30px 0px 30px',
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    gap: 10,
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    padding: '15px 30px 20px 30px',
    gap: '20px',
    overflow: 'auto',
  },
  section: {
    display: 'flex',
    border: '1px solid #9D9D9D',
    borderRadius: '10px',
    background: '#FFFFFF',
    //  padding: '20px 0px 0px 0px',
    flex: 1,
    overflow: 'auto',
    position: 'relative',
    gap: 15,
  },
  filterResultWrapper: {
    display: 'flex',
    flex: 1,
    borderRadius: 10,
    overflow: 'auto',
    width: '100%',
  },
  filtersView: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    padding: '15px 0px 0px 0px',
  },
  listView: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    width: '100%',
    dividerResultSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      padding: '0px 15px 0px 15px',
    },
  },
  cohortFormBox: {
    display: 'flex',
    width: '360px',
    padding: '20px 15px 20px 0px',
  },
  cohortForm: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: 'solid 1px #C8C8C8',
    padding: '0px 10px 0px 20px',
  },
};
