import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  MAX_INDICATORS,
  selectDashIndicatorsAll,
} from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';
import draggableTypes from '@util/draggableTypes';
import DraggableQuadAndDropTarget from './DraggableQuadAndDropTarget';
import DragContext from './DragContext';

const CustomDashboardContent = () => {
  const rows = [];
  let row = [];
  const { currentDrag, editMode } = useContext(DragContext);
  const activeItems = useSelector(selectDashIndicatorsAll);

  let reactiveItems = activeItems;
  if (
    currentDrag?.type === draggableTypes.DASH_LIST_ITEM &&
    reactiveItems.length < MAX_INDICATORS &&
    editMode
  ) {
    reactiveItems = [...reactiveItems, { displayTitle: undefined, data: undefined }];
  }

  reactiveItems.forEach((item, index) => {
    const newItem = { item };
    newItem.index = index;
    if ((index + 1) % 4 === 0) {
      if (index !== 0) {
        row.push(newItem);
        rows.push(row);
      }
      row = [];
    } else {
      row.push(newItem);
    }
  });
  if ((editMode || row.length) && rows.length < 8) rows.push(row); // any leftovers

  if (!reactiveItems.length) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        Add items to the dashboard from the menu on the left.
      </div>
    );
  }
  return (
    <>
      {rows.map((r, i) => {
        const children = (
          <>
            {r.map((item) => (
              <Col lg={3} key={item.index} className="d-flex justify-content-center h-100">
                <DraggableQuadAndDropTarget item={item.item} dropTargetIndex={item.index} />
              </Col>
            ))}
          </>
        );
        return (
          // eslint-disable-next-line react/no-array-index-key
          <RowGenerator key={`custom-dash-generated-row-${i}`}>{children}</RowGenerator>
        );
      })}
    </>
  );
};

export default CustomDashboardContent;

export const RowGenerator = ({ children }) => (
  <Row className="m-0 w-100" style={{ height: '50%' }}>
    {children}
  </Row>
);

RowGenerator.propTypes = {
  children: PropTypes.node,
};

RowGenerator.defaultProps = {
  children: null,
};
