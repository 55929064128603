import React, { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  deleteDashboard,
  selectDashNameById,
} from 'Dashboard/normalizedDashRedux/customDashboardSlice';
import currentDashPropType from 'Dashboard/dashDataPropTypes/currentDashPropType';

import { ConfirmationModal } from '@intus-ui';
import DragContext from './DragContext';

const DeleteDashModal = ({ show, hide, currDash, dashboardDeleted }) => {
  const dashName = useSelector(selectDashNameById(currDash.id));
  const { toggleEditMode } = useContext(DragContext);

  const removeDashboard = () => {
    toggleEditMode(false);
    hide();
    dashboardDeleted(currDash);
  };

  return (
    <ConfirmationModal
      open={show}
      onClose={hide}
      title="Delete Dashboard"
      description={`Are you sure you want to delete the Custom Dashboard "${dashName}"?`}
      confirmText="Delete Dashboard"
      onClickConfirm={() => removeDashboard()}
    />
  );
};

DeleteDashModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  currDash: currentDashPropType.isRequired,
  dashboardDeleted: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch) => ({
  dashboardDeleted: (dashInfo) => dispatch(deleteDashboard(dashInfo)),
});

const mapState = (state) => ({
  currDash: state.dashboard.currentDash,
});

export default connect(mapState, mapDispatch)(DeleteDashModal);
