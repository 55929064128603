import { environment } from '../constants';

const features = [
  {
    name: 'cohorts-in-custom-dash',
    description: 'Cohorts in custom dash',
    environment: environment.none,
  },
  {
    name: 'meeting-feature',
    description: 'meeting-feature',
    environment: environment.none,
  },
  {
    name: 'new-risk-trendline',
    description: 'New risk trendline',
    environment: environment.production,
  },
  {
    name: 'new-welcome-and-home',
    description: 'Enables the new welcome flow, settings and home page',
    environment: environment.none,
  },
  {
    name: 'rca-button',
    description: 'RCA for ICS',
    environment: environment.checkFlag,
  },
  {
    name: 'intus-care-org-changer',
    description: 'intus-care-org-changer',
    environment: environment.checkFlag,
  },
  {
    name: 'ai-chat',
    description: 'AI Chat',
    environment: environment.checkFlag,
  },
  {
    name: 'falls-analytics-tab',
    description: 'Falls analytics tab',
    environment: environment.staging,
  },
  {
    name: 'warning-signals',
    description: 'Warning Signals Prototype',
    environment: environment.checkFlag,
  },
  {
    name: 'medications',
    description: 'Medications Features Flag',
    environment: environment.checkFlag,
  },
  {
    name: 'initiatives',
    description: 'Initiatives MVP',
    environment: environment.production,
  },
  {
    name: 'can-set-connection-info',
    description: 'Interface for providing EHR connection information',
    environment: environment.checkFlag,
  },
  {
    name: 'ehr-benchmark',
    description: 'EHR Benchmark',
    environment: environment.checkFlag,
  },
  {
    name: 'custom-dashboard-v2',
    description: 'Custom Dashboard V2',
    environment: environment.checkFlag,
  },
  {
    name: 'admits',
    description: 'Admits',
    environment: environment.checkFlag,
  },
  {
    name: 'polypharmacy',
    description: 'Polypharmacy',
    environment: environment.checkFlag,
  },
];

export default features;
