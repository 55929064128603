import React from 'react';
import PropTypes from 'prop-types';
import '@intus-ui/styles/FilteredList.scss';
import '@intus-ui/styles/Utilities.scss';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { shadesOfBlue } from '@intus-ui/styles/SecondaryColors';
import HoverBg from './HoverBg';

// Icon
const Incidents = ({ lineColor, toggleState }) => {
  const eyeOpenIcon = <VisibilityRoundedIcon className={lineColor} />;
  const eyeClosedIcon = <VisibilityOffRoundedIcon className={lineColor} />;

  const expandedIcon = (
    <>
      {toggleState ? eyeOpenIcon : eyeClosedIcon}
      {toggleState ? (
        <p style={{ color: lineColor }} className="text-icon font-weight-bold pl-1">
          Incidents Shown
        </p>
      ) : (
        <p style={{ color: lineColor }} className="text-icon font-weight-bold pl-1">
          Incidents Hidden
        </p>
      )}
    </>
  );

  return <HoverBg>{expandedIcon}</HoverBg>;
};

Incidents.propTypes = {
  lineColor: PropTypes.string,
  toggleState: PropTypes.bool.isRequired,
};

Incidents.defaultProps = {
  lineColor: shadesOfBlue.intusNavy,
};

export default Incidents;
