import useEventListener from '@intus-ui/components/useEventListener';
import { Tooltip } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

/**
 * Helper hook to display a React tooltip over the hovered point in a chart.js graph.
 *
 * This hook handles rendering the tooltip and positioning it correctly on the chart.
 *
 * Usage:
 *
 * ```jsx
 *
 *  const MyComponent = () => {
 *
 *  const { tooltipComponent, tooltipOptions } = useReactChartTooltip((dataIndex) => {
 *    return <div>{myData[dataIndex].value}</div>;
 *  });
 *
 * return (
 *  <div>
 *    {tooltipComponent}
 *    <Bar
 *      data={...}
 *      options={{
 *        tooltips: tooltipOptions,
 *      }}
 *     />
 *   );
 * }
 * ```
 */
export function useReactChartTooltipV2(renderTooltipContents, isHorizontalBar = false) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [dataIndex, setDataIndex] = useState(0);

  const [chartElement, setChartElement] = useState(null);
  const [virtualElement, setVirtualElement] = useState({
    getBoundingClientRect: () => {
      return new DOMRect(0, 0, 0, 0);
    },
  });
  const [scrollParent, setScrollParent] = useState(null);

  const hideTooltip = useCallback(() => {
    setIsTooltipVisible(false);
  }, []);

  useEventListener(
    'mouseleave',
    (event) => {
      // Ignore mouse leave events if the mouse is moving over the tooltip.
      let { relatedTarget } = event;
      while (relatedTarget != null) {
        if (relatedTarget.classList?.contains('MuiTooltip-popper')) {
          return;
        }
        relatedTarget = relatedTarget.parentElement;
      }

      hideTooltip();
    },
    chartElement
  );
  useEventListener('scroll', hideTooltip, scrollParent);

  // See https://mui.com/material-ui/react-tooltip/#virtual-element
  const tooltipComponent = (
    <Tooltip
      title={renderTooltipContents(dataIndex)}
      arrow
      open={isTooltipVisible}
      onOpen={() => {
        // no op
      }}
      onClose={() => {
        setIsTooltipVisible(false);
      }}
      placement={isHorizontalBar ? 'right' : 'top'}
      PopperProps={{
        anchorEl: virtualElement,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '10px',
          },
        },
      }}
    >
      {/* This invisible div does nothing but material-ui blows up without it. */}
      <div style={{ display: 'none' }} />
    </Tooltip>
  );

  /**
   * @type {(chart: Chart, tooltipModel: Chart.ChartTooltipModel) => void}}
   */
  const setTooltipModel = useCallback(
    (chart, tooltipModel) => {
      // When we first hover the chart, add a mouseleave event to hide the tooltip.
      // We do this as the chart.js tooltip event does not always fire when scrolling or moving the mouse quickly
      // and we end up with 2 tooltips displayed.
      if (!chartElement) {
        setChartElement(chart.canvas);

        const chartScrollParent = getScrollParent(chart.canvas);
        setScrollParent(chartScrollParent);
      }

      // Hide the tooltip if we're hovering an area outside the points.
      if (
        tooltipModel.opacity === 0 ||
        tooltipModel.dataPoints == null ||
        tooltipModel.dataPoints.length === 0
      ) {
        setIsTooltipVisible(false);
        return;
      }

      // There will be multiple dataPoints if there are overlapping points in the graph.
      // We assume we want to show a single tooltip for each point on the x-axis, so we just use the x-axis index
      setDataIndex(tooltipModel.dataPoints[0].index);
      setIsTooltipVisible(true);

      // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#external-custom-tooltips
      // The x and y are relative to the entire window.
      const position = chart.canvas.getBoundingClientRect();

      // See https://mui.com/material-ui/react-tooltip/#virtual-element
      setVirtualElement({
        getBoundingClientRect: () => {
          const rect = DOMRect.fromRect(position);
          // Make a 1px by 1px box at the exact pixel the tooltip is pointing to.
          // This allows popper to position correctly and be centered relative to that pixel.
          rect.x += tooltipModel.caretX;
          rect.width = 1;
          rect.y += tooltipModel.caretY;
          rect.height = 1;

          return rect;
        },
        contextElement: chart.canvas,
      });
    },
    [chartElement]
  );

  const tooltipOptions = useMemo(() => getChartOptions(setTooltipModel), [setTooltipModel]);

  return {
    tooltipComponent,
    tooltipOptions,
    chartElement,
  };
}

function getChartOptions(setTooltipModel) {
  // We're on an old version of chart.js, here's the docs on configuring tooltips.
  // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html

  /** @type {Chart.ChartTooltipOptions} */
  const tooltipOptions = {
    mode: 'point',
    intersect: false,
    // Disable the on-canvas tooltip
    enabled: false,

    custom(tooltipModel) {
      // eslint-disable-next-line no-underscore-dangle
      setTooltipModel(this._chart, tooltipModel);
    },
  };

  return tooltipOptions;
}

function getScrollParent(node) {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentNode);
}
