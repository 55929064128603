import { Icon } from '@intus-ui';

const DeleteFilterRowButton = ({ handleDeleteFilter }) => (
  <div style={styles.deleteFilterRowButton}>
    <Icon
      name="Remove"
      color="#9D9D9D"
      height="13px"
      width="13px"
      hoverColor="#052d8f"
      onClick={handleDeleteFilter}
    />
  </div>
);

const styles = {
  deleteFilterRowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export default DeleteFilterRowButton;
