import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, FormControl, Button } from 'react-bootstrap';

import List from '@intus-ui/components/List';
import FilteredListHeader from '@intus-ui/components/FilteredListHeader';

import '@intus-ui/styles/FilteredList.scss';
import '@intus-ui/styles/Utilities.scss';

const ParticipantList = ({
  items,
  format,
  title,
  toolbar,
  search,
  sideList,
  filters,
  fieldFilters,
  defaultSorting,
  emptyText,
  // favoriteList,
  // favorite,
  onReset,
  onClick,
}) => {
  // Manages the sorting state.
  const [sorting, setSorting] = useState(defaultSorting);

  // Manages the searching state.
  const [searchValue, setSearchValue] = useState('');

  // Does the actual sorting and searching.
  const sort = (sortItems) => {
    let filteredBySearch = sortItems;
    if (searchValue) {
      filteredBySearch = sortItems.filter((item) =>
        `${item[search.valueField]}`.toUpperCase().includes(searchValue.toUpperCase())
      );
    }
    if (sorting.value === undefined || !format) {
      return filteredBySearch;
    }
    if (sorting.value === undefined || !format) {
      return filteredBySearch;
    }
    return filteredBySearch.sort((a, b) => {
      let comparison = 0;
      const colFormat = format.find((f) => f.valueField === sorting.valueField);
      if (colFormat) {
        if (colFormat.sorting === 'lexographical') {
          comparison = a[sorting.valueField].localeCompare(b[sorting.valueField], undefined, {
            sensitivity: 'accent',
          });
        } else if (colFormat.sorting === 'numerical') {
          comparison = a[sorting.valueField] - b[sorting.valueField];
        } else if (colFormat.sorting === 'date') {
          comparison = a[sorting.valueField] - b[sorting.valueField];
        }
      }
      return sorting.value ? comparison : -comparison;
    });
  };
  const updateFilter = (clickedFormat) => {
    let nextValue = true;
    if (sorting.valueField === clickedFormat.valueField) {
      // Goes to the next state.
      if (sorting.value === true) {
        nextValue = false;
      } else if (sorting.value === false) {
        nextValue = undefined;
      }
    }
    setSorting({
      valueField: clickedFormat.valueField,
      value: nextValue,
    });
  };
  return (
    <div className="box-shadow-on-gray h-100 m-0 bg-white align-self-center overflow-hidden rounded-lg">
      <Container className="sticky bg-white py-3" fluid>
        <Row
          className={`px-1 pt-0 ${
            sideList
              ? 'm-0'
              : 'mx-3 pb-2 d-flex flex-row justify-content-between align-items-center'
          }`}
        >
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h5 className="text-secondary font-weight-bold">{title}</h5>
          </div>
          {search ? (
            <div>
              <FormControl
                className="rounded-xlg"
                // style={{ width: '15rem' }}
                placeholder={`Search by ${search.valueField}`}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          ) : (
            <Button
              className="btn-link bg-transparent border-0 p-2 m-0"
              text="Clear"
              onClick={onReset}
            >
              Clear List
            </Button>
          )}
        </Row>
        <Row>
          <div className="align-self-center ml-3 pl-4">{toolbar}</div>
        </Row>
        <Row
          className={`mt-1 text-muted border-bottom align-items-center ${
            filters !== undefined ? 'justify-content-between pb-2 mx-2 px-3' : 'pb-1 mx-4 px-2'
          }`}
        >
          {format.map((formatItem, i) => {
            const filterIndex = fieldFilters.indexOf(formatItem.heading);
            return (
              <FilteredListHeader
                format={formatItem}
                sorting={sorting}
                fieldFilters={fieldFilters}
                filter={filterIndex >= 0 ? filters[filterIndex] : null}
                onClick={updateFilter}
                // eslint-disable-next-line react/no-array-index-key
                key={`${formatItem.heading}-${i}`}
              />
            );
          })}
          {filters !== undefined && fieldFilters.length === 0 ? filters : null}
        </Row>
      </Container>
      <div style={{ maxHeight: '40vh' }} className="w-100 overflow-scroll pt-1 pb-5 rounded-lg">
        <List
          className={`${sideList ? 'mx-0 mt-2 mb-3 rounded-lg-bottom' : 'mx-4 my-2 rounded-lg'}`}
          containerClass="pb-5"
          items={sort(items)}
          format={format}
          emptyText={emptyText}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

ParticipantList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  format: PropTypes.arrayOf(
    PropTypes.shape({
      valueField: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])
        .isRequired,
      nodeField: PropTypes.node.isRequired,
      lg: PropTypes.number,
      md: PropTypes.number.isRequired,
      sm: PropTypes.number,
      xs: PropTypes.number,
      heading: PropTypes.string.isRequired,
      additionalHeadingNode: PropTypes.node,
      sorting: PropTypes.oneOf(['lexographical', 'numerical', 'none', 'date']),
    }).isRequired
  ).isRequired,
  title: PropTypes.node,
  toolbar: PropTypes.node,
  search: PropTypes.shape({
    valueField: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  defaultSorting: PropTypes.shape({
    valueField: PropTypes.string,
    value: PropTypes.node,
  }),
  emptyText: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.object),
  sideList: PropTypes.bool,
  fieldFilters: PropTypes.arrayOf(PropTypes.string),
  // favoriteList: PropTypes.arrayOf(PropTypes.number),
  // favorite: PropTypes.bool,
  onReset: PropTypes.func,
  onClick: PropTypes.func,
};

ParticipantList.defaultProps = {
  items: [],
  title: undefined,
  toolbar: undefined,
  search: undefined,
  defaultSorting: {},
  emptyText: '',
  filters: [],
  sideList: false,
  fieldFilters: [],
  // favoriteList: undefined,
  // favorite: undefined,
  onReset: () => {},
  onClick: () => {},
};

const mapStateToProps = (state) => ({
  subscriptions: state.userSettings.subscriptions,
});

export default connect(mapStateToProps)(ParticipantList);
