export const CENSUS_DASH_ID = -1;
export const RISKACUITY_DASH_ID = -2;
export const FALLS_DASH_ID = -3;
export const HOSPITALIZATIONS_DASH_ID = -4;
export const FINANCIAL_DASH_ID = -5;
export const PERFORMANCE_BENCHMARK_DASH_ID = -6;

export const premiumDashboards = [
  {
    id: CENSUS_DASH_ID,
    name: 'Census',
    route: '/dashboard/census',
  },
  {
    id: RISKACUITY_DASH_ID,
    name: 'Risk Acuity',
    route: '/dashboard/riskacuity',
  },
  {
    id: FALLS_DASH_ID,
    name: 'Falls',
    route: '/dashboard/falls',
  },
  {
    id: HOSPITALIZATIONS_DASH_ID,
    name: 'Hospitalizations',
    route: '/dashboard/hospitalizations',
  },
  {
    id: FINANCIAL_DASH_ID,
    name: 'Financial',
    route: '/dashboard/financial',
  },
  {
    id: PERFORMANCE_BENCHMARK_DASH_ID,
    name: 'Performance Benchmark',
    featureFlag: 'ehr-benchmark',
    route: '/dashboard/performancebenchmark',
  },
];
