import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const TooltipComponent = ({
  anchorId,
  content,
  children,
  style,
  position = 'top',
  divStyles = {},
}) => {
  /**
   * @param anchorId This anchors the tooltip to a specific element. The anchorId of any child component should be the same as the tooltip.
   * @param content Content of the tooltip. This can be any JSX element or string.
   * @param position Positions the tooltip around the anchoring element.
   * @param children the JSX element that the tooltip is anchored to
   */
  return (
    <>
      <div id={anchorId} style={{ cursor: 'default', width: 'fit-content', ...divStyles }}>
        {children}
      </div>
      <Tooltip
        positionStrategy="fixed"
        anchorId={anchorId}
        content={content}
        place={position}
        style={{ ...styles.tooltip, ...style }}
        delayShow="0"
      />
    </>
  );
};

export default TooltipComponent;

const styles = {
  tooltip: {
    color: '#222',
    background: '#ffffff',
    maxWidth: '250px',
    borderRadius: '5px',
    padding: '15px',
    zIndex: '100000',
    filter: 'drop-shadow(2px 2px 16px rgba(157, 157, 157, 0.22))',
    transition: 'none',
    opacity: '100%',
  },
};
