import React, { useEffect } from 'react';
import '@intus-ui/styles/Utilities.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getModalContent } from '@global-state/redux/homepageSlice';
import FinAnalytics from 'Homes/HomePage/ModalContent/FinAnalytics';
import { useParticipantFilters } from '@intus-ui';

export const FinAnalyticsDashboard = () => {
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.filters.startDate);
  const endDate = useSelector((state) => state.filters.endDate);

  const { selectedFilters } = useParticipantFilters();

  useEffect(() => {
    dispatch(getModalContent('financial', startDate, endDate, selectedFilters));
  }, [startDate, endDate, selectedFilters]);

  return <FinAnalytics isDashboard />;
};
