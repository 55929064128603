import React, { useEffect } from 'react';
import '@intus-ui/styles/Utilities.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getModalContent } from '@global-state/redux/homepageSlice';
import HospitalizationAnalytics from 'Homes/HomePage/ModalContent/HospitalizationAnalytics';
import { useParticipantFilters } from '@intus-ui';

export const HospitalizationAnalyticsDashboard = () => {
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.filters.startDate);
  const endDate = useSelector((state) => state.filters.endDate);

  const { selectedFilters } = useParticipantFilters();

  useEffect(() => {
    dispatch(getModalContent('hospitalizations', startDate, endDate, selectedFilters));
  }, [startDate, endDate, selectedFilters]);

  return <HospitalizationAnalytics isDashboard />;
};
