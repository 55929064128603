import Checkbox from '@intus-ui/components/Checkbox';
import Icon from '@intus-ui/components/Icon';
import Text from '@intus-ui/components/Text';
import { input, textColors } from '@intus-ui/styles/SecondaryColors';
import { MenuItem, Select } from '@mui/material';
import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

/**
 * @typedef {{
 *  label: React.ReactNode,
 *  items: string[],
 *  selectedItems: string[],
 *  allowSelectAll?: boolean,
 *  onChange: (selectedItems: string[]) => void,
 *  renderItem?: (item: string) => React.ReactNode,
 *  } & Omit<import('@mui/material').SelectProps, 'label' | 'onChange' | 'value' | 'multiple' | 'renderValue'>
 * } MultiselectProps
 */

/**
 * A component that renders a dropdown with checkboxes to select multiple items.
 *
 * See storybook for usage examples.
 *
 * @param {MultiselectProps} props
 */
export const Multiselect = ({
  label,
  items,
  selectedItems,
  allowSelectAll,
  onChange,
  MenuProps,
  renderItem,
  ...props
}) => {
  const areAllSelected = selectedItems.length === items.length;

  const [isOpen, setIsOpen] = useState(false);

  function getSortedItems() {
    const selectedItemSet = new Set(selectedItems);
    // Sort selected items on top, and then sort alphabetically.
    const newSortedItems = orderBy(items, [
      (item) => !selectedItemSet.has(item),
      (item) => item.toLowerCase(),
    ]);
    return newSortedItems;
  }

  const [sortedItems, setSortedItems] = useState(() => getSortedItems());

  // If the items change we need to re-sort them.
  useEffect(() => {
    setSortedItems(getSortedItems());
  }, [items]);

  const handleChange = (event, child) => {
    const {
      target: { value },
    } = event;

    // This is super hacky but I cannot find another way to determine what specific item was clicked.
    const clickedAll =
      (child.key?.includes('Select All') || child.key?.includes('Unselect All')) ?? false;

    if (clickedAll && areAllSelected) {
      onChange([]);
    } else if (clickedAll && !areAllSelected) {
      onChange(items);
    } else {
      onChange(value);
    }
  };

  let inputDisplayText = selectedItems.join(', ');

  if (areAllSelected && allowSelectAll) {
    inputDisplayText = 'All';
  }

  // Sort the items when the dropdown is closed.
  // We want the checked items to show up on top but we don't want to sort them as the user clicks them.
  function onCloseDropdown() {
    setSortedItems(getSortedItems());

    setIsOpen(false);
  }

  const itemsWithAll = useMemo(() => {
    const selectAllText = areAllSelected ? 'Unselect All' : 'Select All';
    return allowSelectAll ? [selectAllText, ...sortedItems] : sortedItems;
  }, [allowSelectAll, sortedItems, areAllSelected]);

  return (
    <Select
      aria-label={`${label} ${inputDisplayText}`}
      IconComponent={(iconProps) => (
        <Icon
          name={isOpen ? 'caret-up' : 'caret-down'}
          color={isOpen ? input.active : textColors.caption}
          {...iconProps}
          style={{ ...iconProps.style, top: 'unset', transform: `scale(1.2)` }}
        />
      )}
      multiple
      value={selectedItems}
      onOpen={() => setIsOpen(true)}
      onClose={() => onCloseDropdown()}
      onChange={handleChange}
      // displayEmpty is needed to show the bold label text inside when nothing is selected.
      displayEmpty
      renderValue={() => (
        // We need an inline style here as the text overflow with ... is not working with the Text component.
        <span style={{ fontFamily: 'Inter', fontSize: 15 }}>
          {label && <Text type="subtitle">{label}:</Text>}
          {inputDisplayText}
        </span>
      )}
      {...props}
      sx={{
        ...styles.input,
        ...props.sx,
      }}
      MenuProps={{
        // Turn off the fading of the dropdown menu when it is opened or closed.
        // This causes weird issues where the dropdown moves around when it's closed then fades away.
        transitionDuration: 0,
        // Do not focus the last item by default when the dropdown opens.
        variant: 'menu',
        // Try and get the dropdown menu to show below the input field if it fits.
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        ...MenuProps,
        sx: { ...styles.menu, ...MenuProps?.sx },
      }}
    >
      {itemsWithAll.map((name) => (
        <MenuItem
          key={name}
          value={name}
          disableRipple
          // Focus the item so we don't have 2 items colored at once
          onMouseEnter={(event) => event.target.focus()}
          onClick={(event) => {
            // Reset the focus to the <li> element under the mouse once React re-renders.
            // This prevents 2 items from being colored at once when clicking.
            setTimeout(() => {
              let elementUnderMouse = document.elementFromPoint(event.clientX, event.clientY);

              while (elementUnderMouse != null && elementUnderMouse.nodeName !== 'LI') {
                elementUnderMouse = elementUnderMouse.parentElement;
              }

              if (elementUnderMouse != null) {
                elementUnderMouse.focus();
              }
            }, 1);
          }}
        >
          <Checkbox
            checked={
              name === 'Select All' || name === 'Unselect All'
                ? areAllSelected
                : selectedItems.includes(name)
            }
            onChange={() => {
              // Do nothing, material-ui will handle the change event.
            }}
          />
          {renderItem && renderItem(name)}
          {!renderItem && <Text type="body">{name}</Text>}
        </MenuItem>
      ))}
    </Select>
  );
};

const minWidth = 135;
const maxWidth = 250;

const styles = {
  input: {
    minWidth,
    maxWidth,
    backgroundColor: input.default,
  },
  menu: {
    '& .MuiMenu-paper': {
      backgroundColor: input.default,
      maxWidth,
      maxHeight: 260,
      '& .MuiList-root': {
        minWidth: 145,
      },
    },
  },
};
