import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSessionUser } from '@util/session';
import * as userTracking from '@util/userTracking';

import { setHamburgerOpen } from '@global-state/redux/dashboardSlice';
import { getUserInfo } from '@global-state/redux/userSettingsSlice';
import { setIsHealthSysAdmin, setTimeFilter } from '@global-state/redux/filtersSlice';
import { initializeCustomDashboard } from 'Dashboard/normalizedDashRedux/customDashboardSlice';
import { fetchFlatIndicators } from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';

import {
  Icon,
  Button,
  PopupButton,
  TeamFacilityFilter,
  ParticipantFiltersContext,
  Text,
  TooltipComponent,
} from '@intus-ui';
import LoadingScreen from '@intus-ui/components/LoadingScreen';

import DateRangeDropdown from '@intus-ui/components/DateRangeDropdown/index';
import { CensusAnalyticsDashboard } from 'Dashboard/DashboardTypes/CensusAnalyticsDashboard';
import { RiskAcuityAnalyticsDashboard } from 'Dashboard/DashboardTypes/RiskAcuityAnalyticsDashboard';
import { FallsAnalyticsDashboard } from 'Dashboard/DashboardTypes/FallsAnalyticsDashboard';
import { FinAnalyticsDashboard } from 'Dashboard/DashboardTypes/FinAnalyticsDashboard';
import { HospitalizationAnalyticsDashboard } from 'Dashboard/DashboardTypes/HospitalizationAnalyticsDashboard';
import DashboardModal from 'Dashboard/DashboardModal';
import SpreadsheetModal from 'Dashboard/spreadsheet/SpreadsheetModal';
import CustomDashboard from 'Dashboard/CustomDash/CustomDashboard';
import DashboardHamburgerMenu from 'Dashboard/DashboardNavigation/DashboardHamburgerMenu';
import {
  CENSUS_DASH_ID,
  RISKACUITY_DASH_ID,
  FALLS_DASH_ID,
  FINANCIAL_DASH_ID,
  HOSPITALIZATIONS_DASH_ID,
  PERFORMANCE_BENCHMARK_DASH_ID,
  premiumDashboards,
} from 'Dashboard/premiumDashNames';

import { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import { newDate, formatDate, startOfDate } from '@util/dateFunctions';
import { PerformanceBenchmarkDashboard } from 'Dashboard/DashboardTypes/PerformanceBenchmark/PerformanceBenchmarkDashboard';

const premiumDashboardIds = premiumDashboards.map((dash) => dash.id);
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const startDate = props.timeFilter.startDate || startOfDate(newDate(), 'month');
    const endDate = props.timeFilter.endDate || newDate();

    this.state = {
      editMode: false,
      leaderboard: false,
      timeFilterVal: `${formatDate(startDate, 'MM/DD/YY')} - ${formatDate(endDate, 'MM/DD/YY')}`,
    };
  }

  componentDidMount() {
    const {
      doInitializeCustomDashboard,
      location,
      customDashItemsFlat,
      doFetchFlatIndicators,
      doGetUserLocalData,
      doSetIsHealthSysAdmin,
      doSetTimeFilter,
      timeFilter,
    } = this.props;

    const { access } = getSessionUser();
    if (location.state) {
      this.setState({ editMode: location.state.editMode });
    }

    doGetUserLocalData(true);
    const isHealthSysAdmin = access === USER_ACCESS_HEALTH_SYS_ADMIN;
    doSetIsHealthSysAdmin(isHealthSysAdmin);
    doInitializeCustomDashboard();
    if (!customDashItemsFlat) doFetchFlatIndicators();

    if (timeFilter == null || timeFilter.startDate == null) {
      const startDate = startOfDate(newDate(), 'month');
      const endDate = newDate();
      this.setState({
        timeFilterVal: `${formatDate(startDate, 'MM/DD/YY')} - ${formatDate(endDate, 'MM/DD/YY')}`,
      });
      doSetTimeFilter({
        startDate,
        endDate,
      });
    }
  }

  render() {
    const {
      currentDashboard,
      customDashboard,
      indicatorStatus,
      dashboardStatus,
      timeFilter,
      isHealthSysAdmin,
      doSetHamburgerOpen,
      hamburgerOpen,
      leaderboardType,
      doSetTimeFilter,
    } = this.props;
    const { timeFilterVal, editMode, leaderboard } = this.state;

    const isCurrentDashPremium = premiumDashboardIds.includes(currentDashboard);

    const isCurrentDashShared = customDashboard?.entities[currentDashboard]?.orgDash;

    const shouldShowTimeFilter =
      !isCurrentDashPremium || currentDashboard === PERFORMANCE_BENCHMARK_DASH_ID;

    const currCustomDashLabel = customDashboard?.entities[currentDashboard]?.name;
    const dashboardName = isCurrentDashPremium
      ? premiumDashboards.find((d) => d.id === currentDashboard)?.name
      : currCustomDashLabel;

    // render method to be passed in as a prop to download popup button
    const renderSpreadsheetModal = () => {
      return <SpreadsheetModal />;
    };
    // const renderCompareModal = () => { return(<TeamLeaderboard/>) }

    const toggleEditMode = (val) => {
      this.setState((state) => ({
        editMode: val !== undefined ? val : !state.editMode,
      }));
    };

    const toggleLeaderboard = () => {
      this.setState((state) => ({
        leaderboard: !state.leaderboard,
      }));
    };

    const setTimeFilterVal = (val) => {
      userTracking.logEvent('Changed Dashboard Time Filter', {
        id: currentDashboard,
        name: dashboardName,
        timeFilter: val.label,
        startDate: val.start,
        endDate: val.end,
      });

      this.setState(() => ({
        timeFilterVal: val.label,
      }));
      doSetTimeFilter({
        startDate: val.start,
        endDate: val.end,
      });
    };

    const activeLen = customDashboard?.entities[currentDashboard]?.indicators.length;

    const { selectedFilters, teams, facilities } = this.context;
    // Filters taken from redux state
    const filters = {
      patientFilters: selectedFilters,
      timeFilter,
    };

    return (
      <div className="bg-light h-100 overflow-hidden d-flex justify-content-center align-items-center flex-column position-relative">
        <DashboardModal />
        {[indicatorStatus, dashboardStatus].includes('loading') && <LoadingScreen />}
        <div className="d-flex w-100 justify-content-between align-items-center container-side-padding-sm mt-3">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <DashboardHamburgerMenu
              hamburgerOpen={hamburgerOpen}
              onOpen={() => doSetHamburgerOpen(true)}
              onClose={() => doSetHamburgerOpen(false)}
              toggleEditMode={toggleEditMode}
            />
            <div className="d-flex flex-row ">
              <Text type="title" color="navy">
                {dashboardName}
              </Text>
              {isCurrentDashPremium && (
                <>
                  <div title="Premium Dashboard">
                    <Icon name="chart" size="medium" className="ml-2 text-muted" />
                  </div>
                  {PERFORMANCE_BENCHMARK_DASH_ID === currentDashboard && (
                    <div style={{ marginLeft: 10 }}>
                      <TooltipComponent
                        anchorId="performance-benchmark-disclaimer-tooltip"
                        style={{
                          maxWidth: 400,
                        }}
                        content={
                          <Text type="body">
                            Due to potential delays in data entry, benchmarks from the current
                            quarter are subject to change and will be updated with newest data as it
                            becomes available.
                          </Text>
                        }
                      >
                        <Icon name="info" color="link" />
                      </TooltipComponent>
                    </div>
                  )}
                </>
              )}

              {!isCurrentDashPremium && !isCurrentDashShared && (
                <div title="Custom Dashboard">
                  <Icon name="dashboard" size="medium" className="ml-2 text-muted" />
                </div>
              )}
              {!isCurrentDashPremium && isCurrentDashShared && (
                <div title="Custom Dashboard">
                  <Icon name="shared" size="medium" className="ml-2 text-muted" />
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-1 align-items-center" style={{ gap: 15, zIndex: 10 }}>
            {/* Do not show team + facility filters on the financial dash or benchmark dash */}
            {(![FINANCIAL_DASH_ID].includes(currentDashboard) || isHealthSysAdmin) && (
              <TeamFacilityFilter
                disabled={(leaderboard && leaderboardType !== 'months') || editMode}
              />
            )}
            {shouldShowTimeFilter && (
              <DateRangeDropdown
                range={[1, 3, 6, 12]}
                value={timeFilterVal}
                dateRangeDirection="left"
                onChange={(val) => {
                  if (!val.isRange) setTimeFilterVal(val);
                  return null;
                }}
                onApplyRange={(val) => {
                  setTimeFilterVal(val);
                }}
              />
            )}
            <div style={{ display: 'flex', gap: 7.5 }}>
              {!!activeLen && (teams.length !== 0 || facilities.length !== 0) && (
                <Button secondary onClick={toggleLeaderboard}>
                  <Icon name="benchmark" />
                  Compare
                </Button>
              )}
              {!isCurrentDashPremium && (
                <PopupButton
                  secondary
                  popupSecondary
                  popupTitle="Download as a Spreadsheet"
                  popupBody={renderSpreadsheetModal}
                >
                  <Icon name="download" />
                  Download
                </PopupButton>
              )}
            </div>
          </div>
        </div>
        <div className="flex-grow-1 d-flex overflow-hidden w-100">
          <div className="w-100 h-100 overflow-hidden">
            {currentDashboard === CENSUS_DASH_ID && <CensusAnalyticsDashboard />}
            {currentDashboard === RISKACUITY_DASH_ID && <RiskAcuityAnalyticsDashboard />}
            {currentDashboard === FALLS_DASH_ID && <FallsAnalyticsDashboard />}
            {currentDashboard === HOSPITALIZATIONS_DASH_ID && <HospitalizationAnalyticsDashboard />}
            {currentDashboard === FINANCIAL_DASH_ID && <FinAnalyticsDashboard />}
            {currentDashboard === PERFORMANCE_BENCHMARK_DASH_ID && (
              <PerformanceBenchmarkDashboard />
            )}
            {!premiumDashboardIds.includes(currentDashboard) && (
              <CustomDashboard
                editMode={editMode}
                toggleEditMode={toggleEditMode}
                leaderboard={leaderboard}
                toggleLeaderboard={toggleLeaderboard}
                filters={filters}
                hamburgerOpen={hamburgerOpen}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.contextType = ParticipantFiltersContext;

Dashboard.propTypes = {
  currentDashboard: PropTypes.number.isRequired,
  customDashboard: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number),
    entities: PropTypes.instanceOf(Object),
  }),
  doInitializeCustomDashboard: PropTypes.func.isRequired,
  doFetchFlatIndicators: PropTypes.func.isRequired,
  doSetIsHealthSysAdmin: PropTypes.func.isRequired,
  doSetHamburgerOpen: PropTypes.func.isRequired,
  customDashItemsFlat: PropTypes.instanceOf(Object),
  timeFilter: PropTypes.instanceOf(Object).isRequired,
  isHealthSysAdmin: PropTypes.bool.isRequired,
  indicatorStatus: PropTypes.string.isRequired,
  dashboardStatus: PropTypes.string.isRequired,
  doGetUserLocalData: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object),
  leaderboardType: PropTypes.string,
};

Dashboard.defaultProps = {
  customDashboard: undefined,
  customDashItemsFlat: undefined,
  location: undefined,
  leaderboardType: 'loading',
};

const mapState = (state) => ({
  currentDashboard: state.dashboard.currentDash.id,
  customDashboard: state.customDashboard,
  customDashItemsFlat: state.customDashIndicators.customDashItemsFlat,
  indicatorStatus: state.customDashIndicators.status,
  dashboardStatus: state.customDashboard.status,
  timeFilter: state.filters.timeFilter,
  isHealthSysAdmin: state.filters.isHealthSysAdmin,
  userSettings: state.userSettings,
  hamburgerOpen: state.dashboard.hamburgerOpen,
  leaderboardType: state.leaderboard.leaderboardType,
});

const mapDispatch = (dispatch) => ({
  doInitializeCustomDashboard: () => dispatch(initializeCustomDashboard()),
  doSetTimeFilter: (timeFilter) => dispatch(setTimeFilter(timeFilter)),
  doFetchFlatIndicators: () => dispatch(fetchFlatIndicators()),
  doGetUserLocalData: (value) => dispatch(getUserInfo(value)),
  doSetHamburgerOpen: (value) => dispatch(setHamburgerOpen(value)),
  doSetIsHealthSysAdmin: (value) => dispatch(setIsHealthSysAdmin(value)),
});

export default connect(mapState, mapDispatch)(Dashboard);
