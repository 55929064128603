// in the following file, I will make a functional react component that will be used to render the multiselect component.

import React from 'react';
import { Multiselect } from '@intus-ui/components/Multiselect/Multiselect';
import features from '@config/featureFlags/features';
import { getSessionUser, clearSession } from '@util/session';
import { changeMyFlags } from '@api';

const ChangeMyFlags = () => {
  const [selectedFlags, setSelectedFlags] = React.useState(getSessionUser().flags);

  const handleChange = (selectedFlags) => {
    setSelectedFlags(selectedFlags);
  };

  const featureItems = features.map((feature) => feature.name);

  const containerStyle = {
    display: 'flex',
  };

  const columnStyle = {
    flex: 1,
    padding: '16px', // Adjust as needed
    border: '1px solid #ddd', // Optional: Add borders for separation
  };
  return (
    <div>
      <div className="mx-4" style={containerStyle}>
        <div className="col s12 m6" style={columnStyle}>
          <Multiselect
            label="Select your feature flags"
            items={featureItems}
            selectedItems={selectedFlags}
            allowSelectAll
            onChange={(selectedItems) => handleChange(selectedItems)}
          />
        </div>
        <div className="col s12 m6" style={columnStyle}>
          Your selected flags are:
          {selectedFlags.map((flag) => {
            return <div>{flag}</div>;
          })}
        </div>
      </div>
      <div className="mx-4" style={containerStyle}>
        <div className="col s12 m6" style={columnStyle}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setSelectedFlags(getSessionUser().flags);
            }}
          >
            Reset Selection
          </button>
        </div>
        <div className="col s12 m6" style={columnStyle}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={async () => {
              await changeMyFlags({ flags: selectedFlags });
              // eslint-disable-next-line no-alert
              prompt(
                'Flags updated. Click ok or cancel to get logged out. Log back in to see the changes.'
              );
              clearSession('/home');
            }}
          >
            update flags
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeMyFlags;
