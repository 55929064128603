import { pluralize, toSentenceCase } from '@util/utilFunctions';

import { Text } from '@intus-ui';
import { getStringReplacement } from '@util/stringReplacements';
import { getLabel } from '../../searchTermLabels';
import { daysToDisplayTitle } from '../../SearchCreate/CreateFilter/helpers';
import { filterRowSchemas } from '../../SearchCreate/CreateFilter/filterRowConfig';

const FilterRowSummary = (props) => {
  const { condition } = props;

  const type = condition?.type;
  const renderPill = (conditionType) => {
    switch (conditionType) {
      case 'diagnosis':
      case 'diagnosisCCSR':
      case 'team':
      case 'facility':
      case 'livingSituation':
      case 'gender':
      case 'age':
      case 'acuityScoreGroup':
      case 'medicationCount':
      case 'medicationName':
      case 'enrollmentDuration':
      case 'document':
        return <TypeSearchTermPill {...props} />;
      case 'hospitalizations':
      case 'incidents':
        return <HospitalizationIncidentPill {...props} />;
      default:
        return null;
    }
  };

  return renderPill(type);
};

const styles = {
  text: {
    marginRight: 5,
  },
};

const TypeSearchTermPill = ({ isNestedFirst, isNestedLast, condition, operator }) => {
  let filterOption = '';
  let label = '';
  let searchTerm = condition?.searchTerm;
  const type = condition?.type;
  const logic = condition?.logic;
  const logicIsEqual = logic === '=';

  if (type === 'diagnosis' || type === 'diagnosisCCSR') {
    const isCCSR = type === 'diagnosisCCSR';
    filterOption = `${isCCSR ? 'CCSR' : 'Intus Labels'} - Diagnoses`;
    label = logicIsEqual ? 'include' : 'does not include';
  }

  if (type === 'team') {
    filterOption = getStringReplacement('Team');
    label = logicIsEqual ? 'is' : 'is not';
  }

  if (type === 'facility') {
    filterOption = getStringReplacement('Center');
    label = logicIsEqual ? 'is' : 'is not';
  }

  if (type === 'livingSituation') {
    filterOption = 'Living situation';
    label = logicIsEqual ? 'is' : 'is not';
    searchTerm = toSentenceCase(searchTerm);
  }

  if (type === 'gender') {
    filterOption = 'Gender';
    label = 'is';
  }
  if (type === 'acuityScoreGroup') {
    filterOption = 'Acuity Score Group';
    label = 'is';
  }

  if (type === 'race') {
    filterOption = 'Race';
    label = 'is';
  }

  if (type === 'age') {
    filterOption = 'Age';
    if (condition?.frequency?.gte >= 0 && condition?.frequency?.lte >= 0) {
      label = 'is between';
      const lte = condition?.frequency?.lte;
      const gte = condition?.frequency?.gte;
      const unit = condition?.frequency?.unit || 'years';
      searchTerm = `${gte} and ${lte} ${unit} old`;
    } else {
      const range = condition?.frequency?.lte ? 'at most' : 'at least';
      label = `is ${range}`;
      const value = condition?.frequency?.lte || condition?.frequency?.gte;
      const unit =
        condition?.frequency?.unit ||
        pluralize('year', condition?.frequency?.lte || condition?.frequency?.gte);

      searchTerm = `${value} ${unit} old`;
    }
  }

  if (type === 'medicationCount') {
    filterOption = 'Active Medication Count';
    if (condition?.frequency?.gte >= 0 && condition?.frequency?.lte >= 0) {
      label = 'is between';
      const lte = condition?.frequency?.lte;
      const gte = condition?.frequency?.gte;
      searchTerm = `${gte} and ${lte} medications`;
    } else {
      const range = condition?.frequency?.lte ? 'at most' : 'at least';
      label = `is ${range}`;
      const value = condition?.frequency?.lte || condition?.frequency?.gte;
      const unit = pluralize('medication', condition?.frequency?.lte || condition?.frequency?.gte);

      searchTerm = `${value} ${unit}`;
    }
  }

  if (type === 'medicationName') {
    filterOption = 'Active Medication Names';
    label = 'equals';
    if (condition?.logic === 'contains') label = 'include';
    if (condition?.logic === 'notContains') label = 'does not include';
    if (condition?.logic === 'startsWith') label = 'starts with';
  }

  if (type === 'enrollmentDuration') {
    filterOption = 'Enrollment duration';
    if (condition?.frequency?.gte >= 0 && condition?.frequency?.lte >= 0) {
      label = 'is between';
      const lte = condition?.frequency?.lte;
      const gte = condition?.frequency?.gte;
      const unit = condition?.frequency?.unit || 'years';
      searchTerm = `${gte} and ${lte} ${unit}`;
    } else {
      const range = condition?.frequency?.lte ? 'at most' : 'at least';

      label = `is ${range}`;
      const value = condition?.frequency?.lte || condition?.frequency?.gte;
      const unit =
        condition?.frequency?.unit ||
        pluralize('year', condition?.frequency?.lte || condition?.frequency?.gte);
      searchTerm = `${value} ${unit}`;
    }
  }

  if (type === 'document') {
    filterOption = `${condition?.searchTerm} documentation`;
    const { logicOptions } = filterRowSchemas().document.components.FilterInputSelectWithOptions;
    const conditionLogic = logicOptions.find((option) => option.value === condition.logic)?.label;
    label = `was ${conditionLogic}`;
    searchTerm = '';
    if (condition?.dateRange) {
      label = `was ${conditionLogic.replace(/\.{3}/g, '')}`;
      searchTerm = `${condition?.dateRange.months} months`;
    }
  }

  return (
    <>
      {isNestedFirst && (
        <>
          <Text type="subtitle" color="navy" style={styles.text}>
            {filterOption}
          </Text>

          <Text color="body" type="body" style={styles.text}>
            {label}
          </Text>
        </>
      )}
      <Text color="navy" type="subtitle" style={styles.text}>
        {searchTerm}
      </Text>
      {!isNestedLast && (
        <Text color="body" type="body" style={styles.text}>
          {operator}
        </Text>
      )}
    </>
  );
};

const HospitalizationIncidentPill = ({ isNestedLast, condition, operator }) => {
  let filterOption = '';
  let label = '';
  let selectionLabel = 'is';

  if (condition?.type === 'incidents') {
    filterOption = getLabel(
      'incident',
      condition?.searchTerm,
      `${pluralize(condition?.searchTerm, 1)} count`
    );
  }

  if (condition?.type === 'hospitalizations') {
    filterOption = getLabel('hospitalization', condition?.searchTerm, 'Inpatient hospitalizations');
  }

  if (condition?.frequency?.gte >= 0 && condition?.frequency?.lte >= 0) {
    const lte = condition?.frequency?.lte;
    const gte = condition?.frequency?.gte;
    label = `between ${gte} and ${lte}`;
  } else {
    const range = condition?.frequency?.lte ? 'at most' : 'at least';
    const value = condition?.frequency?.lte || condition?.frequency?.gte;
    label = `${range} ${value}`;
    selectionLabel = 'is';
  }

  return (
    <>
      <Text type="subtitle" color="navy" style={styles.text}>
        {filterOption}
      </Text>
      <Text color="body" type="body" style={styles.text}>
        {selectionLabel}
      </Text>

      <Text color="navy" type="subtitle" style={styles.text}>
        {label}
      </Text>
      <Text color="navy" type="subtitle" style={styles.text}>
        {pluralize('time', condition?.frequency?.lte || condition?.frequency?.gte)}
      </Text>
      <Text color="body" type="body" style={styles.text}>
        in the
      </Text>
      <Text color="navy" type="subtitle" style={styles.text}>
        {(
          daysToDisplayTitle[condition?.dateRange?.days] ||
          `past ${condition?.dateRange?.days} days`
        ).toLowerCase()}
      </Text>
      {!isNestedLast && (
        <Text color="body" type="body" style={styles.text}>
          {operator}
        </Text>
      )}
    </>
  );
};

export default FilterRowSummary;
