import { Card, Text } from '@intus-ui';
import colors from 'Polypharmacy/colors';
import { find } from 'lodash';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ title, data }) => {
  const adjustedLabels = data.labels.map((label) => {
    if (label.length > 14) {
      return `${label.slice(0, 14)}...`;
    }
    return label;
  });

  const chartData = {
    labels: adjustedLabels,
    datasets: [
      {
        data: data.datasets.map((dataset) => dataset.data[0]),
        backgroundColor: data.labels.map((label, index) => {
          // Get the color for the label if it exists, otherwise get the next color in the array
          const colorArray = Object.entries(colors);
          return getColorForLabel(label, index, colorArray);
        }),
      },
    ],
  };
  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: 'right',
    },
    layout: {
      padding: {
        top: 5,
      },
    },
  };

  return (
    <Card dropshadow style={{ width: '550px' }}>
      <Text wrapper="div" type="subtitle">
        {title}
      </Text>
      <div style={{ width: '500px', height: '330px' }}>
        <Pie data={chartData} options={chartOptions} />
      </div>
    </Card>
  );
};

const getOrderedColor = (index, colorArray) => colorArray[index % colorArray.length][1].code;

const getColorForLabel = (label, index, colorArray) =>
  find(colors, (color) => color.labels.some((l) => l.toLowerCase() === label.toLowerCase()))
    ?.code || getOrderedColor(index, colorArray);

export default PieChart;
