import { useState } from 'react';
import { Icon, Text, Modal, Tag } from '@intus-ui';
import { ExpandableNotes } from 'Participants/acuity/Incidents';

import { MedicationsList } from '@intus-ui/components/index';
import { columnConfigNoLastOrdered } from '@intus-ui/components/MedicationsList/list/columnConfigs';
import { useRiskTrendlineContext } from '../Context';
import { getBadge } from '../badges';

const EventCard = ({ event }) => {
  const { selectedPoint, setSelectedPoint, hoveredPoint, setHoveredPoint } =
    useRiskTrendlineContext();

  const onClick = () => {
    setSelectedPoint(event);
  };

  const onMouseOver = () => {
    setHoveredPoint(event);
  };

  const onMouseLeave = () => {
    setHoveredPoint();
  };

  const order = cardOrder(event);
  let stateClassName = 'acuity-sidebar-item';
  if (selectedPoint && selectedPoint.date === event.date) stateClassName += ' focused';
  else if (hoveredPoint && hoveredPoint.date === event.date) stateClassName += ' hovered';

  return (
    <div
      className={stateClassName}
      style={{ ...styles.acuityCard, order }}
      role="button"
      onKeyDown={() => null}
      onFocus={() => null}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      tabIndex={0}
    >
      <CardContent item={event} />
    </div>
  );
};

export const CardContent = ({ item }) => {
  const {
    event,
    eventType,
    events,
    title,
    description,
    type,
    label,
    date,
    time,
    endDate,
    principalDX,
    placeOfStay,
    dischargeStatus,
    location,
    subType,
    harmType,
    notes,
  } = item;
  const badgeToShow = getBadge(label);
  const [medicationModalIsOpen, setMedicationModalIsOpen] = useState(false);

  if (eventType === 'warningSignal') {
    return (
      <div style={{ width: '100%' }}>
        <div style={styles.cardTitle}>
          <Text type="subtitle">{item.patient.name}</Text>
          <Tag type="intusMade" color={item.tag.color}>
            {item.tag.text}
          </Tag>
        </div>
        <div style={styles.description}>
          <Text>has</Text>
          <Text type="subtitle">&nbsp;{item.state.current}&nbsp;</Text>
          <Text>and {item.state.change}</Text>
          <Text type="subtitle">&nbsp;{item.state.changeValue}</Text>
        </div>
        <div style={styles.footer} role="none">
          <div onClick={() => {}} role="none">
            <Text type="subtitle" color="link">
              See Details
            </Text>
            <Icon size="small" name="arrow-right" color="link" style={{ fontWeight: 'bold' }} />
          </div>
        </div>
      </div>
    );
  }

  if (eventType === 'acuityChange') {
    return (
      <div style={styles.list}>
        <div style={styles.cardTitle}>
          <Text type="subtitle">{title}</Text>
        </div>
        <div style={styles.description}>
          <Icon name="bullet" />
          <Text>{description}</Text>
        </div>
      </div>
    );
  }

  if (eventType === 'change') {
    // MEDICATION CHANGES
    if (type === 'Medication Changes') {
      let cardTitle = 'Changed';
      if (events.added?.length > 0) cardTitle = 'New';
      else if (events.removed?.length > 0) cardTitle = 'Removed';

      return (
        <div style={styles.list}>
          {/* MEDICATIONS CHANGES MODAL */}
          <Modal
            header={{
              title: 'Medications',
              centered: true,
            }}
            type="large"
            open={medicationModalIsOpen}
            onClose={() => setMedicationModalIsOpen(false)}
          >
            {events?.added?.length > 0 && (
              <MedicationsList
                data={events.added}
                titleRowElement={
                  <Text type="title" color="navy">
                    New Medications as of {item?.date}
                  </Text>
                }
                customColumnConfig={columnConfigNoLastOrdered}
                searchable
              />
            )}
            {events?.removed?.length > 0 && (
              <MedicationsList
                data={events.removed}
                titleRowElement={
                  <Text type="title" color="navy">
                    Discontinued Medications as of {item?.date}
                  </Text>
                }
                customColumnConfig={columnConfigNoLastOrdered}
              />
            )}
            {events?.changed?.length > 0 && (
              <MedicationsList
                data={events.changed}
                titleRowElement={
                  <Text type="title" color="navy">
                    Changed Medication Orders as of {item?.date}
                  </Text>
                }
                customColumnConfig={columnConfigNoLastOrdered}
              />
            )}
          </Modal>
          <div style={styles.cardTitle}>
            <Text type="subtitle">{`${cardTitle} Medications`}</Text>
            {badgeToShow && <img src={badgeToShow.src} alt="event-badge" />}
          </div>
          {cardTitle !== 'New' && events.added?.length > 0 && (
            <Text type="subtitle">New Medications</Text>
          )}
          {events.added?.sort().map((medication) => (
            <div
              style={{ ...styles.listChanges }}
              className="text-break border-light medication-change medication-added"
              key={`medication-added-${medication.name}`}
            >
              <div style={styles.description}>
                <Icon name="bullet" />
                <Text>{medication.name}</Text>
              </div>
            </div>
          ))}
          {cardTitle !== 'Removed' && events.removed?.length > 0 && (
            <Text type="subtitle">Discontinued Medications</Text>
          )}
          {events.removed?.sort().map((medication) => (
            <div
              style={styles.listChanges}
              className="text-break border-light medication-change"
              key={`medication-removed-${medication.name}`}
            >
              <div style={styles.description}>
                <Icon name="bullet" />
                <Text>{medication.name}</Text>
              </div>
            </div>
          ))}
          {cardTitle !== 'Changed' && events.changed?.length > 0 && (
            <Text type="subtitle">Changed Medication Orders</Text>
          )}
          {events.changed?.sort().map((medication) => (
            <div
              style={styles.listChanges}
              className="text-break border-light medication-change"
              key={`medication-changed-${medication.name}`}
            >
              <div style={styles.description}>
                <Icon name="bullet" />
                <Text>{medication.name}</Text>
              </div>
            </div>
          ))}
          <div style={styles.footer} onClick={() => setMedicationModalIsOpen(true)} role="none">
            <Text type="subtitle" color="link" onClick={() => setMedicationModalIsOpen(true)}>
              See Details
            </Text>
            <Icon
              size="small"
              name="arrow-right"
              color="link"
              style={{ fontWeight: 'bold' }}
              onClick={() => setMedicationModalIsOpen(true)}
            />
          </div>
        </div>
      );
    }

    // CONDITION CHANGES
    return (
      <div style={{ width: '100%' }}>
        <div style={styles.cardTitle}>
          <Text type="subtitle">{type}</Text>
          {badgeToShow && <img src={badgeToShow.src} alt="event-badge" />}
        </div>

        <div style={{ width: '100%' }}>
          {event?.added.sort().map((condition) => (
            <div
              style={styles.listChanges}
              className="text-break border-light condition-change condition-added"
              key={`condition-added-${condition}`}
            >
              <Icon name="bullet" />
              <div style={styles.description}>
                <Text>{condition}</Text>
              </div>
            </div>
          ))}

          {event?.removed.sort().map((condition) => (
            <div
              style={styles.listChanges}
              className="text-break border-light condition-change"
              key={`condition-removed-${condition}`}
            >
              <Icon name="bullet" />
              <div style={styles.description}>
                <Text>
                  <del>{condition}</del>
                </Text>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (eventType === 'incident') {
    return (
      <div style={styles.list}>
        <div style={styles.cardTitle}>
          <Text type="subtitle">{type}</Text>
          {badgeToShow && <img src={badgeToShow.src} alt="event-badge" />}
        </div>
        <div style={styles.listItem}>
          <Icon name="calendar" style={styles.listIcon} />
          <Text style={styles.listText}>
            {date}
            {time && `, ${time}`}
          </Text>
        </div>
        {location && (
          <div style={styles.listItem}>
            <Icon name="location" style={styles.listIcon} />
            <Text style={styles.listText}>{location}</Text>
          </div>
        )}
        {subType && (
          <div style={styles.listItem}>
            <Icon name="note" style={styles.listIcon} />
            <Text style={styles.listText}>{subType}</Text>
          </div>
        )}
        {harmType && type !== 'Burn' && (
          <div style={styles.listItem}>
            <Icon name="harm-type" style={styles.listIcon} />
            <Text style={styles.listText}>{harmType}</Text>
          </div>
        )}
        {type === 'Burn' && (
          <div style={styles.listItem}>
            <Icon name="burn-degree" style={styles.listIcon} />
            <Text style={styles.listText}>{harmType ?? '-'}</Text>
          </div>
        )}
        {notes && (
          <div>
            <div style={styles.listItem}>
              <Icon name="note" style={styles.listIcon} />
              <Text style={styles.listText}>Notes: </Text>
            </div>
            <div>
              <ExpandableNotes content={notes} />
            </div>
          </div>
        )}
      </div>
    );
  }

  if (eventType === 'admit') {
    return (
      <div style={styles.list}>
        <div style={styles.cardTitle}>
          <Text type="subtitle">{type}</Text>
          {badgeToShow && <img src={badgeToShow.src} alt="event-badge" />}
        </div>
        <div style={styles.listItem}>
          <Icon name="calendar" style={styles.listIcon} />
          <Text style={styles.listText}>
            {date}
            {endDate && ` - ${endDate}`}
          </Text>
        </div>
        <div style={styles.listItem}>
          <Icon name="diagnosis" style={styles.listIcon} />
          <Text style={styles.listText}>{principalDX}</Text>
        </div>
        {placeOfStay && (
          <div style={styles.listItem}>
            <Icon name="admit" style={styles.listIcon} />
            <Text style={styles.listText}>{placeOfStay}</Text>
          </div>
        )}
        <div style={styles.listItem}>
          <Icon name="discharge" style={styles.listIcon} />
          <Text style={styles.listText}>{dischargeStatus}</Text>
        </div>
      </div>
    );
  }

  return <>{JSON.stringify(event)}</>;
};

const cardOrder = (item) => {
  const { eventType, label } = item;
  switch (eventType) {
    case 'incident':
      return 10;
    case 'admit':
      if (label === 'ER') return 20;
      if (label === 'INP') return 21;
      return 29;
    case 'change':
      if (label === 'Dx') return 30;
      if (label === 'Rx') return 31;
      return 39;
    case 'acuityChange':
      return 40;
    default:
      return 1;
  }
};

const styles = {
  acuityCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    cursor: 'pointer',
    padding: '10px 15px',
    borderRadius: 5,
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    flex: 1,
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'start',
  },
  listIcon: {
    color: '#97999b',
    width: 22,
    height: 22,
  },
  listText: {
    marginLeft: 10,
    lineHeight: '19.5px',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  listChanges: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
  },
  bulletText: {
    lineHeight: '19.5px',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  description: {
    display: 'flex',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
  },
};

export default EventCard;
