import React from 'react';

import SearchInput from '@intus-ui/components/SearchInput';
import Text from '@intus-ui/components/Text';

import { omit } from 'lodash';
import { renderAddOns } from './helpers';
import { useListContext } from './Context';

// Wrapper component for search bar and top right elements
const TopElements = ({ searchable, topRightElements, handleSearch, searchTerm }) => {
  return (
    <div
      style={
        searchable ? styles.topHeaderSection : { ...styles.topHeaderSection, justifyContent: 'end' }
      }
    >
      {/* Slots for a search bar on the left, and other react elements on the right */}
      {searchable && (
        <SearchInput
          placeholder="Search by name"
          handleSearch={handleSearch}
          searchTerm={searchTerm}
        />
      )}
      {topRightElements && <div style={styles.topRightElements}>{topRightElements}</div>}
    </div>
  );
};

/**
 *
 * @param {* boolean, shows the search bar, defaults to true} searchable
 * @param {* format is required to place and style the header items} formatActive
 * @param {* topRightElements is a jsx element that slots into the top section of the header} topRightElements
 */
const ListHeader = ({
  searchable,
  columns,
  format,
  topRightElements,
  titleRowElement,
  showColumnHeaders,
  extraRenders,
  isStripedList,
}) => {
  const { searchTerm, setSearchTerm } = useListContext();
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Only show the top row of the header if the list is searchable or has topRightElements
  const showTopElements = searchable || topRightElements;
  return (
    <div style={styles.container}>
      {titleRowElement}
      {showTopElements && (
        <TopElements
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          topRightElements={topRightElements}
          searchable={searchable}
        />
      )}
      {showColumnHeaders && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '5px',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          {extraRenders?.beforeHeader && (
            <div style={styles.extraRenders}>{extraRenders?.beforeHeader}</div>
          )}
          <div
            style={{
              ...styles.bottomHeaderSection,
              padding: isStripedList ? '0px 0px 8px 16px' : '0px 16px 8px 16px',
            }}
          >
            {columns?.map((column) => {
              const item = format.find((formatItem) => formatItem?.field === column);
              if (item == null) {
                throw new Error(
                  `No column found in the format matching the field = ${column}. Please add this to the format.`
                );
              }
              return (
                <div
                  className="listItem-header"
                  key={JSON.stringify(omit(item, 'addOns'))}
                  style={{
                    ...styles.headerItem,
                    flex: `${item.flex}`,
                    justifyContent: `${item.align}`,
                    paddingRight: `${item.paddingRight}`,
                    ...item.customStyles,
                  }}
                >
                  <Text
                    type="body"
                    color="caption"
                    style={{
                      cursor: 'default',
                      overflow: 'hidden',
                      whiteSpace: 'normal',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.name}
                  </Text>
                  {renderAddOns(item)}
                </div>
              );
            })}
          </div>
          {extraRenders?.afterHeader && (
            <div style={styles.extraRenders}>{extraRenders?.afterHeader}</div>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '15px',
  },
  topHeaderSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: '30px',
    gap: '20px',
  },
  bottomHeaderSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: '30px',
    gap: '20px',
    borderBottom: 'solid 1px rgba(200, 200, 200, 1)',
    padding: '0px 16px 8px 16px',
    width: '100%',
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  viewOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    flex: 1,
  },
  extraRenders: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    paddingRight: '15px',
  },
  topRightElements: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
};
export default ListHeader;
