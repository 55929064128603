import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useEventListener from './useEventListener';
import '@intus-ui/styles/HamburgerMenu.scss';

const HamburgerMenu = ({ isOpen, onClose, children, className }) => {
  const hamburgerMenu = useRef();

  // this will collapse the hamburger menu if a click outside it is detected
  const handleOutsideClick = (e) => {
    if (!hamburgerMenu?.current?.contains(e.target)) onClose();
  };
  useEventListener('mousedown', handleOutsideClick);

  return (
    <div
      ref={hamburgerMenu}
      style={{
        top: 0,
        left: 0,
        zIndex: 5,
        backgroundColor: '#194bc3',
      }}
      className={`h-100 d-flex flex-column justify-content-center align-items-stretch smoother position-absolute open-hamburger-menu ${className}`}
    >
      <div>
        <button
          type="button"
          onClick={onClose}
          className="m-0 p-3 bg-transparent border-0 text-white smoother outline-none hover-primary rounded d-flex justify-content-center align-items-center"
          disabled={!isOpen}
        >
          <CloseRoundedIcon fontSize="small" />
        </button>
      </div>
      {children}
    </div>
  );
};

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

HamburgerMenu.defaultProps = {
  children: null,
  className: '',
};

export default HamburgerMenu;
