/**
 * columnConfigs must have an object with a 'columns' field
 * participant list must have a 'default' key
 */
export const defaultColumnConfig = {
  default: {
    columns: ['name', 'statusNode', 'access', 'password', 'settings'],
    breakpoints: [
      { minWidth: 635, columns: ['name', 'statusNode', 'access', 'password', 'settings'] },
      { minWidth: 470, columns: ['name', 'password', 'settings'] },
    ],
  },
};

export const usingSSOColumnConfig = {
  default: {
    columns: ['name', 'statusNode', 'access', 'settings'],
    breakpoints: [
      { minWidth: 635, columns: ['name', 'statusNode', 'access', 'settings'] },
      { minWidth: 470, columns: ['name', 'settings'] },
    ],
  },
};
