import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '@global-state';
import { SpinnerOrError } from '@intus-ui';
import { updateUserAPI } from '@api/api';
import { setProfile } from '@global-state/redux/userSettingsSlice';
import { setWelcomeFlowModal } from '@global-state/redux/welcomeSlice';
import { setSessionUser } from '@util/session';
import ModalButtons from './ModalButtons';
import WelcomeBox from './WelcomeBox';

const allTopics = [
  { name: 'Hospitalizations', settingsId: 'hospitalizations' },
  { name: 'Falls', settingsId: 'falls' },
  { name: 'Living Situation', settingsId: 'livingSituation' },
  { name: 'Diagnosis', settingsId: 'diagnoses' },
];

const defaultSelection = ['falls', 'hospitalizations'];

const Option = ({ name, settingsId, setSelected, selected }) => {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false); // for checkboxes

  useEffect(() => {
    if (checked === true) {
      setSelected([...selected, settingsId]);
      // Removing selected item from the array if it becomes unchecked
    } else if (checked === false && selected.includes(`${settingsId}`)) {
      const updatedSelected = selected.filter((item) => item !== `${settingsId}`);
      setSelected(updatedSelected);
    }
  }, [checked]);

  // Disabling unchecked boxes if max has been reached
  useEffect(() => {
    if (selected.length >= 2 && checked === false) {
      setDisabled(true);
    } else if (selected.length < 2) {
      setDisabled(false);
    }
  }, [selected]);

  return (
    <label htmlFor={settingsId}>
      <div
        style={{
          width: '20vw',
          height: '90px',
          backgroundColor: `${disabled ? '#f6f7fa' : '#ffffff'}`,
        }}
        id={`topicSelect-${name}`}
        className="d-flex cursor-pointer flex-row align-items-center justify-content-start form-check-label py-1 box-shadow-on-gray rounded-xl border-0 text-left m-2"
      >
        <input
          type="checkbox"
          name="homeBoxes"
          id={settingsId}
          value={name}
          style={{ width: '35px', height: '90px' }}
          className="mx-4"
          checked={checked}
          onChange={() => setChecked(!checked)}
          disabled={disabled}
        />
        <h4>{name}</h4>
      </div>
    </label>
  );
};

const TopicOptions = () => {
  const [disabled, setIsDisabled] = useState(true); // for modal buttons
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.userSettings.profile);
  const history = useHistory();

  if (!profile) {
    return <SpinnerOrError error="Could not load the page. Please refresh and try again. " />;
  }
  const { setting } = profile;

  // Disabling next button if nothing has been selected
  useEffect(() => {
    if (selected.length === 2) {
      setIsDisabled(false);
    } else if (selected.length < 2) {
      setIsDisabled(true);
    }
  }, [selected]);

  // Updating user settings and navigating to home page
  const updateSettings = (selection) => {
    const updatedSetting = { ...setting, homeBoxes: selection, welcomeFlowCompleted: true };
    const updatedProfile = { ...profile, settings: updatedSetting };
    // This must be an async function so that the user info is updated before the home page renders
    updateUserAPI(updatedProfile, updatedProfile.id).then(async (result) => {
      if (result.ok) {
        const json = await result.json();
        setSessionUser(json);
        dispatch(setProfile(json));
        dispatch(setWelcomeFlowModal(true));
        history.push('/home');
      }
    });
  };

  const handleSubmit = (selectedBoxes) => {
    if (!selectedBoxes.length) {
      updateSettings(defaultSelection); // setting homeBoxes to default selection
    } else {
      updateSettings(selectedBoxes);
    }
  };

  return (
    <form>
      <Row className="d-flex justify-content-center mb-4">
        {allTopics.map((topic) => (
          <Option
            name={topic.name}
            settingsId={topic.settingsId}
            selected={selected}
            setSelected={setSelected}
            key={topic.name}
          />
        ))}
        <ModalButtons
          onClick={() => handleSubmit(selected)}
          disabled={disabled}
          padding="pl-4 pt-3"
        />
      </Row>
    </form>
  );
};

const TopicSelection = () => {
  return (
    <WelcomeBox minWidth="w-60" minHeight="h-60">
      <div className="d-flex flex-column align-items-center justify-content-between pt-4 w-100">
        <h2 className="text-secondary font-weight-bold p-3">Topic Selection</h2>
        <h4 className="w-70 p-3">
          Please select 2 topics that are most important for you and your workflow.
        </h4>
        <TopicOptions />
      </div>
    </WelcomeBox>
  );
};

export default TopicSelection;
