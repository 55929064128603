import useEventListener from '@intus-ui/components/useEventListener';
import { uniqueId } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';

/**
 * Helper hook to display a React tooltip over the hovered point in a chart.js graph.
 *
 * This hook handles rendering the tooltip and positioning it correctly on the chart.
 *
 * Usage:
 *
 * ```jsx
 *
 *  const MyComponent = () => {
 *
 *  const { tooltipComponent, tooltipOptions } = useReactChartTooltip((dataIndex) => {
 *    return <div>{myData[dataIndex].value}</div>;
 *  });
 *
 * return (
 *  <div>
 *    {tooltipComponent}
 *    <Bar
 *      data={...}
 *      options={{
 *        tooltips: tooltipOptions,
 *      }}
 *     />
 *   );
 * }
 * ```
 */
export function useReactChartTooltip(renderTooltipContents) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [dataIndex, setDataIndex] = useState(0);

  const [chartElement, setChartElement] = useState(null);
  const [scrollParent, setScrollParent] = useState(null);

  const hideTooltip = useCallback(() => {
    setIsTooltipVisible(false);
  }, []);

  useEventListener('mouseleave', hideTooltip, chartElement);
  useEventListener('scroll', hideTooltip, scrollParent);

  const tooltipComponent = (
    <ReactChartTooltip
      dataIndex={dataIndex}
      renderTooltipContents={renderTooltipContents}
      isVisible={isTooltipVisible}
      tooltipPosition={tooltipPosition}
    />
  );

  /**
   * @type {(chart: Chart, tooltipModel: Chart.ChartTooltipModel) => void}}
   */
  const setTooltipModel = useCallback((chart, tooltipModel) => {
    // When we first hover the chart, add a mouseleave event to hide the tooltip.
    // We do this as the chart.js tooltip event does not always fire when scrolling or moving the mouse quickly
    // and we end up with 2 tooltips displayed.
    if (!chartElement) {
      setChartElement(chart.canvas);

      const chartScrollParent = getScrollParent(chart.canvas);
      setScrollParent(chartScrollParent);
    }

    // Hide the tooltip if we're hovering an area outside the points.
    if (
      tooltipModel.opacity === 0 ||
      tooltipModel.dataPoints == null ||
      tooltipModel.dataPoints.length === 0
    ) {
      setIsTooltipVisible(false);
      return;
    }

    // There will be multiple dataPoints if there are overlapping points in the graph.
    // We assume we want to show a single tooltip for each point on the x-axis, so we just use the x-axis index
    setDataIndex(tooltipModel.dataPoints[0].index);
    setIsTooltipVisible(true);

    // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#external-custom-tooltips
    // The x and y are relative to the entire window.
    const position = chart.canvas.getBoundingClientRect();

    const x = position.left + window.scrollX + tooltipModel.caretX;
    let y = position.top + window.scrollY + tooltipModel.caretY;

    // Not entirely sure why this is happening but the navbar pushes the tooltip down by its height.
    // Move it back up.
    const navBar = document.getElementsByClassName('navbar').item(0);
    if (navBar != null) {
      y -= navBar.clientHeight;
    }

    setTooltipPosition({ x, y });
  }, []);

  const tooltipOptions = useMemo(() => getChartOptions(setTooltipModel), [setTooltipModel]);

  return {
    tooltipComponent,
    tooltipOptions,
  };
}

function getChartOptions(setTooltipModel) {
  // We're on an old version of chart.js, here's the docs on configuring tooltips.
  // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html

  /** @type {Chart.ChartTooltipOptions} */
  const tooltipOptions = {
    mode: 'point',
    intersect: false,
    // Disable the on-canvas tooltip
    enabled: false,

    custom(tooltipModel) {
      // eslint-disable-next-line no-underscore-dangle
      setTooltipModel(this._chart, tooltipModel);
    },
  };

  return tooltipOptions;
}

const ReactChartTooltip = ({ dataIndex, renderTooltipContents, isVisible, tooltipPosition }) => {
  const anchorId = uniqueId('tooltip-anchor-');

  return (
    <>
      <div
        id={anchorId}
        style={{
          display: isVisible ? 'block' : 'none',
          position: 'absolute',
          left: tooltipPosition.x,
          top: tooltipPosition.y,
        }}
      />
      <Tooltip
        anchorId={anchorId}
        style={tooltipStyles.tooltipContainer}
        content={renderTooltipContents(dataIndex)}
        isOpen={isVisible}
      />
    </>
  );
};

const tooltipStyles = {
  tooltipContainer: {
    color: '#222',
    background: '#ffffff',
    borderRadius: '5px',
    padding: '15px',
    zIndex: '100000',
    filter: 'drop-shadow(2px 2px 16px rgba(157, 157, 157, 0.22))',
    transition: 'none',
    opacity: '100%',
  },
};

function getScrollParent(node) {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentNode);
}
