import InfoIcon from '@mui/icons-material/InfoOutlined';
import { ssoRedirect } from '@api/login/ssoRedirect';
import { useLazyQuery } from '@api/useQuery';
import BoxOnBlue from '@intus-ui/components/BoxOnBlue';
import SimpleTooltip from '@intus-ui/components/SimpleTooltip';
import { useFeatureFlag } from '@util';
import { getLogger } from '@util/logger';
import onSuccessfulLogin from 'Login/onSuccessfulLogin';
import { isEmpty } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SpinnerOrError } from '@intus-ui';

const logger = getLogger('SSORedirect');

/**
 * Phase 2 of the single-sign on login flow.
 * The user is redirected here by Microsoft and then we hit our API to see if the user logged in to Microsoft successfully and make
 * sure their email matches.
 */
export const SSORedirect: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);

  const { featureIsActive: newWelcomeAndHomeIsActive } = useFeatureFlag('new-welcome-and-home');

  const code = queryParams.get('code');
  const clientInfo = queryParams.get('client_info');

  const ssoUsername = window.localStorage.getItem('ssoUsername');

  const hasCode = !isEmpty(code) && code != null;
  const hasSSOUsername = !isEmpty(ssoUsername) && ssoUsername != null;
  const hasClientInfo = !isEmpty(clientInfo) && clientInfo != null;

  const { error, statusCode, runQuery } = useLazyQuery(ssoRedirect, {
    onSuccess: (result) => {
      onSuccessfulLogin({
        dispatch,
        history,
        queryParams,
        result,
        newWelcomeAndHomeIsActive,
        mustConfigureTwoFactorAuthentication: false,
      });
      window.localStorage.removeItem('ssoUsername');
    },
    onError: (error) => {
      logger.error(error);
    },
  });

  useEffect(() => {
    if (hasCode && hasSSOUsername && hasClientInfo) {
      runQuery(ssoUsername, code, clientInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let errorMessage: string | null = null;

    if (statusCode === 401) {
      errorMessage = error?.message ?? 'Incorrect username or password';
    } else if (statusCode === 429) {
      errorMessage =
        'Too many failed attempts. Please contact your IT administrator to reset your password';
    } else if (statusCode != null && statusCode >= 400) {
      errorMessage = 'An error occurred, please try again';
    }

    if (errorMessage != null) {
      logger.error(errorMessage);
      history.replace(`/?errormessage=${encodeURIComponent(errorMessage)}`);
    }
  }, [error, history, statusCode]);

  if (!hasCode || !hasSSOUsername) {
    return <div>Error</div>;
  }

  return (
    <BoxOnBlue logo>
      {queryParams.has('redirectReason') && (
        <div className="w-100 justify-content-center py-3">
          <h4 className="text-dark text-align-center">
            Please sign in to{' '}
            {queryParams.has('redirectReason') &&
            queryParams.get('redirectReason') === 'sessionExpired'
              ? 'view the requested url:'
              : 'resume session'}
          </h4>
          <br />
          {queryParams.has('redirectReason') && queryParams.get('redirectReason') && (
            <p className="text-secondary text-align-center mb-2">{`${
              window.location.origin
            }${queryParams.get('redirectTo')}`}</p>
          )}
        </div>
      )}

      <SpinnerOrError spinnerStyle={{ height: '70px', width: '70px' }} />

      <div className="d-flex flex-row w-100 justify-content-center pt-4">
        <div className="mr-2">Not a customer?</div>
        <a href="https://intuscare.com/demo">Request Demo.</a>
      </div>
      <div className="d-flex justify-content-center align-self-center w-100 pt-1">
        Need an Account/Forgot Password?
        <SimpleTooltip content="Please contact your IT administrator for account creation or reset.">
          <InfoIcon className="info-icon cursor-pointer m-0" />
        </SimpleTooltip>
      </div>
    </BoxOnBlue>
  );
};
