import PropTypes from 'prop-types';

const customDashItemPropType = PropTypes.shape({
  title: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({
      incidentType: PropTypes.string,
      indicator: PropTypes.string,
    }),
  ]),
});

export default customDashItemPropType;
