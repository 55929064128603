import { getPatientMedicationsWithFilters } from '@api/patients/getPatientMedications';
import {
  IPatientFirstFillStatus,
  IPatientRisingStarsData,
  IRisingStarMeasureData,
  allPatientFirstFillStatus,
  firstFillStatusToDescription,
  getRisingStars,
} from '@api/polypharmacy/getRisingStars';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';
import { useLazyQuery } from '@api/useQuery';
import { Divider, Icon, List, Modal, SearchInput, SpinnerOrError, Text } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { IListColumnConfig, IListFormat } from '@intus-ui/components/List/types/IListFormat';
import { textColors } from '@intus-ui/styles/SecondaryColors';
import { ButtonBase, MenuItem, Select } from '@mui/material';
import { getStringReplacement } from '@util/stringReplacements';
import MedicationsModalList from 'Polypharmacy/MedicationsModalList';
import {
  AdherenceStatusSelect,
  IAllAdherenceStatuses,
} from 'Polypharmacy/components/AdherenceStatusSelect';
import { NextFillDueColumn } from 'Polypharmacy/components/NextFillDueColumn';
import { PatientFirstFillStatus } from 'Polypharmacy/components/PatientFirstFillStatus';
import { StarsMeasureDropdown } from 'Polypharmacy/components/StarsMeasureDropdown';
import {
  IAllStarsMeasures,
  allStarsMeasures,
  starsMeasuresPossibleYears,
} from 'Polypharmacy/types/STARSMeasures';
import { produce } from 'immer';
import { isEmpty, last } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

type AdherenceRisingStarsProps = {
  filters: IPolypharmacyFilters;
};

type PossibleMeasures = 'all' | IAllStarsMeasures;

type PossiblePatientStatus = 'all' | IPatientFirstFillStatus['status'];

const columnConfig: IListColumnConfig<FlatPatient> = {
  columns: [
    'patientStatus',
    'name',
    'fillCount',
    'percentageDaysCovered',
    'fillDueDate',
    'seeDetails',
  ],
};

type FlatPatient = IRisingStarMeasureData & {
  id: number;
  name: string;
  patientStatus: string | null;
  seeDetails?: undefined;
};

function getFormat(
  onClickSeeDetails: (patientId: number) => void,
  onChangeStatus: () => void
): IListFormat<FlatPatient>[] {
  return [
    {
      field: 'patientStatus',
      name: 'Status',
      flex: '0 0 100px',
      addOns: [{ type: 'sort', element: <SortIcon id="patientStatus" key="patientStatus" /> }],
      render: (row) => {
        return <PatientFirstFillStatus patient={row.item} onChangeStatus={onChangeStatus} />;
      },
    },
    {
      field: 'name',
      name: `${getStringReplacement('Participant')} Name`,
      flex: '1 1 300px',
      addOns: [{ type: 'sort', element: <SortIcon id="name" key="name" /> }],
    },
    {
      field: 'fillCount',
      name: '# of Fills',
      flex: '0 0 120px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon id="fillCount" key="fillCount" /> }],
    },
    {
      field: 'percentageDaysCovered',
      name: 'PDC',
      flex: '0 0 120px',
      align: 'end',
      addOns: [
        {
          type: 'sort',
          element: <SortIcon id="percentageDaysCovered" key="percentageDaysCovered" />,
        },
      ],
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;

        return (
          <span
            style={{ color: patient.percentageDaysCovered < 0.8 ? textColors.error : undefined }}
          >
            {parseFloat((patient.percentageDaysCovered * 100).toFixed(0))}%
          </span>
        );
      },
    },
    {
      field: 'fillDueDate',
      name: 'Next fill due',
      flex: '0 0 190px',
      addOns: [{ type: 'sort', element: <SortIcon id="fillDueDate" key="fillDueDate" /> }],
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            <NextFillDueColumn fillDueDate={patient.fillDueDate} />
          </div>
        );
      },
    },
    {
      field: 'seeDetails',
      name: '',
      flex: '0 0 150px',
      customStyles: {
        width: '100%',
        height: '100%',
        justifyContent: 'end',
        alignItems: 'end',
      },
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        return (
          <ButtonBase
            type="button"
            sx={{
              '&:hover': {
                span: {
                  color: '#052d8f !important',
                },
                svg: {
                  color: '#052d8f !important',
                },
              },
            }}
            onClick={() => {
              onClickSeeDetails(patient.id);
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '3px',
                justifyContent: 'center',
                color: '#2E62E7',
              }}
            >
              <Text color="link" type="subtitle">
                See Details
              </Text>
              <Icon name="ArrowRight" size="medium" />
            </div>
          </ButtonBase>
        );
      },
    },
  ];
}

export const AdherenceRisingStars: FC<AdherenceRisingStarsProps> = ({ filters }) => {
  const [selectedMeasure, setSelectedMeasure] = useState<PossibleMeasures>(allStarsMeasures[0]);
  const [adherentStatus, setAdherentStatus] = useState<IAllAdherenceStatuses>('All');
  const [patientStatusFilter, setPatientStatusFilter] = useState<PossiblePatientStatus>('all');
  const [search, setSearch] = useState('');

  // Hacky way to re-run the query in the background when a patient status is changed.
  // This prevents the list from disappearing and re-appearing when a patient status is changed.
  //
  // If the filters are changed we do want to show the loading spinner.
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);

  const { data, loading, error, runQuery } = useLazyQuery(() => getRisingStars(filters));

  const totalPatients = useMemo(() => {
    return (
      data?.patientAdherence.filter((p) =>
        p.risingStarsData.some((r) => r.measure === selectedMeasure)
      ).length ?? 0
    );
  }, [data, selectedMeasure]);

  useEffect(() => {
    setShowLoadingSpinner(true);
    runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filteredPatientAdherence = useMemo(() => {
    if (data == null) return [];

    return produce(data.patientAdherence, (draft) => {
      const filteredPatients: IPatientRisingStarsData[] = [];
      for (const patient of draft) {
        if (!isEmpty(search)) {
          if (!patient.name.toLowerCase().includes(search.toLowerCase())) continue;
        }

        let filteredMeasures = patient.risingStarsData;
        if (selectedMeasure !== 'all') {
          filteredMeasures = patient.risingStarsData.filter((m) => m.measure === selectedMeasure);
        }

        if (adherentStatus === 'Adherent (≥80%)') {
          filteredMeasures = filteredMeasures.filter((m) => m.percentageDaysCovered >= 0.8);
        } else if (adherentStatus === 'Not adherent (<80%)') {
          filteredMeasures = filteredMeasures.filter((m) => m.percentageDaysCovered < 0.8);
        }

        if (patientStatusFilter !== 'all') {
          filteredMeasures = filteredMeasures.filter(
            (m) => m.status?.status === patientStatusFilter
          );
        }

        if (filteredMeasures.length > 0) {
          filteredPatients.push({
            ...patient,
            risingStarsData: filteredMeasures,
          });
        }
      }
      return filteredPatients;
    });
  }, [adherentStatus, data, selectedMeasure, patientStatusFilter, search]);

  if (error) return <SpinnerOrError error="An error occurred loading the rising stars" />;

  if (showLoadingSpinner && (loading || !data)) return <SpinnerOrError />;

  return (
    <div style={{ padding: 10 }}>
      <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
        <Text type="title">
          {filteredPatientAdherence != null ? totalPatients : '~'} patients on first fill of
        </Text>
        <StarsMeasureDropdown
          allowMipsMeasure
          allowAll={false}
          value={selectedMeasure}
          onChange={(measure) => setSelectedMeasure(measure)}
        />
      </div>
      <div style={{ padding: '10px 0px 15px 0px' }}>
        <Text type="caption" color="caption">
          Disclaimer: We provide insights into individual patient and population-level adherence to
          medications. The calculations are based on similar criteria but should not be considered
          an official representation of STARs Ratings. Exclusions that do not factor into
          calculations include: Hospice Enrollment, Dialysis Coverage Dates
        </Text>
        <Divider style={{ marginTop: 15 }} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <SearchInput
            placeholder="Search by name"
            closeIcon
            onClose={() => setSearch('')}
            handleSearch={(event: any) => setSearch(event.target.value)}
            searchTerm={search}
            containerStyle={{
              width: 350,
            }}
          />
        </div>
        <div style={{ display: 'flex', gap: 15 }}>
          {/* Patient status filter */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text type="caption-bold">Patient Status</Text>
            <Select
              label="Patient Status"
              value={patientStatusFilter}
              onChange={(e) => {
                setPatientStatusFilter(e.target.value.toString() as PossiblePatientStatus);
              }}
              sx={{ width: 160 }}
            >
              <MenuItem value="all" key="all">
                All
              </MenuItem>
              {allPatientFirstFillStatus.map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    {firstFillStatusToDescription[status]}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          {/* Adherence or Not Adherent patient selector */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text type="caption-bold">Adherence Status</Text>
            <AdherenceStatusSelect
              adherentStatus={adherentStatus}
              setAdherentStatus={setAdherentStatus}
            />
          </div>
        </div>
      </div>

      <div>
        <RisingStarsTable
          selectedMeasure={selectedMeasure}
          measureData={filteredPatientAdherence}
          onChangePatientStatus={() => {
            setShowLoadingSpinner(false);
            runQuery();
          }}
        />
      </div>
    </div>
  );
};

type RisingStarsTableProps = {
  selectedMeasure: PossibleMeasures;
  measureData: IPatientRisingStarsData[];
  onChangePatientStatus: () => void;
};

const RisingStarsTable: FC<RisingStarsTableProps> = ({
  selectedMeasure,
  measureData,
  onChangePatientStatus,
}) => {
  const currentYear = new Date().getFullYear();
  const measureYear = starsMeasuresPossibleYears.includes(currentYear)
    ? currentYear
    : last(starsMeasuresPossibleYears)!;

  const [medicationsModalOpen, setMedicationsModalOpen] = useState(false);

  const {
    data: medicationsData,
    loading: medicationsDataLoading,
    error: medicationsDataError,
    runQuery: runGetMedicationsData,
  } = useLazyQuery((patientId) =>
    getPatientMedicationsWithFilters({
      patientId,
      starsMeasure: selectedMeasure,
      starsMeasureYear: measureYear,
      active: false,
      isRisingStars: true,
    })
  );

  const onClickSeeDetails = useCallback(
    (patientId: number) => {
      runGetMedicationsData(patientId);
      setMedicationsModalOpen(true);
    },
    [runGetMedicationsData]
  );

  const singleMeasureListFormat = useMemo(() => {
    return getFormat(onClickSeeDetails, onChangePatientStatus);
  }, [onClickSeeDetails, onChangePatientStatus]);

  const flattenedPatients = useMemo(() => {
    const flatPatients: FlatPatient[] = [];

    for (const patient of measureData) {
      flatPatients.push({
        ...patient.risingStarsData[0],
        id: patient.id,
        name: patient.name,
        patientStatus: patient.risingStarsData[0].status?.status ?? null,
      });
    }
    return flatPatients;
  }, [measureData]);

  return (
    <div>
      <List
        data={flattenedPatients}
        format={singleMeasureListFormat}
        columnConfig={columnConfig}
        defaultSort={{ field: 'name', direction: 'asc' }}
        listStyle="striped"
      />
      <Modal
        open={medicationsModalOpen}
        onClose={() => setMedicationsModalOpen(false)}
        type="large"
        style={{ overflow: 'hidden' }}
        modalStyles={{ height: '90%' }}
        header={{ title: 'Medications', centered: true }}
      >
        <MedicationsModalList
          medicationsData={medicationsData}
          loading={medicationsDataLoading}
          error={medicationsDataError}
        />
      </Modal>
    </div>
  );
};
