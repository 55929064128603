import React from 'react';
import PropTypes from 'prop-types';
import customDashItemPropType from 'Dashboard/customDashItemPropType';
import QuadDropTarget from './QuadDropTarget';
import DraggableQuad from './DraggableQuad';

import '@intus-ui/styles/Dashboard.scss';

const DraggableQuadAndDropTarget = ({ item, dropTargetIndex }) => {
  let draggableContent = null;
  if (item && item.displayTitle) {
    draggableContent = <DraggableQuad item={item} />;
  }

  return (
    <div className="height-90 w-100 rounded-xlg position-relative">
      <QuadDropTarget dropTargetIndex={dropTargetIndex} />
      {draggableContent}
    </div>
  );
};

DraggableQuadAndDropTarget.propTypes = {
  item: customDashItemPropType.isRequired,
  dropTargetIndex: PropTypes.number.isRequired,
};

export default DraggableQuadAndDropTarget;
