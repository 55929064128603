import React, { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { toUTC, newDate, formatDate } from '@util/dateFunctions';
import { getSessionUser } from '@util/session';

import { getTrackingEvent } from '@api/api';
import {
  createDashboard,
  saveDashboard,
  selectAllOrgDashboards,
  selectOrgDashNames,
} from 'Dashboard/normalizedDashRedux/customDashboardSlice';
import { setCurrentDash } from '@global-state/redux/dashboardSlice';
import { USER_ACCESS_ADMIN } from 'Settings/userSettingsPropType';
import { ORG_DASH } from 'Dashboard/dashDataPropTypes/currentDashPropType';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Icon } from '@intus-ui';
import { useHistory } from 'react-router-dom';
import DashMenuContext from './DashMenuContext';
import ButtonToForm from './ButtonToForm';
import ExpandableTitle from './ExpandableTitle';

const OrgDashboards = ({
  expanded,
  doCreateOrgDash,
  doSetCurrentDash,
  currentDash,
  // updateDashboard,
  toggleEditMode,
  // sectionCheckboxes,
}) => {
  const { searchVal, closeMenu } = useContext(DashMenuContext);
  const allDashboards = useSelector(selectAllOrgDashboards);
  const dashboardsFiltered = (allDashboards || []).filter(({ name }) =>
    name?.toLowerCase().includes(searchVal.toLowerCase())
  );

  const dashNames = useSelector(selectOrgDashNames);
  const schema = Yup.object().shape({
    input: Yup.mixed()
      .default('')
      .notOneOf([...dashNames], 'No duplicate names allowed'),
  });

  // const editDashName = (id, newDashName) => {
  //   updateDashboard({ id, changes: { name: newDashName }, orgDash: true });
  // };

  const isAdmin = getSessionUser().access === USER_ACCESS_ADMIN;

  const history = useHistory();
  // Function that takes a dashName as input and returns the associated indicators
  // const getDashIndicators = (name) => {
  //   const dash = Object.values(sectionCheckboxes).find((obj) => obj.dashName === name);
  //   const indicatorList = dash.indicators.map((obj) => obj.displayTitle);
  //   return indicatorList;
  // };

  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-start text-white overflow-hidden">
      <ExpandableTitle
        title="Shared Dashboards"
        menuType="org"
        icon={<Icon name="shared" className="mx-2" />}
        listLength={dashboardsFiltered.length}
      />
      {expanded && (
        <>
          {isAdmin && (
            <div className="row-12 w-100">
              <div className="col-12">
                <ButtonToForm
                  buttonTitle="New Shared Dashboard"
                  buttonIcon={<AddRoundedIcon />}
                  validationSchema={schema}
                  className="text-white pl-4"
                  onSubmit={(name) => {
                    doCreateOrgDash({ name });
                    getTrackingEvent({
                      time: formatDate(toUTC(newDate())),
                      page: 'Org Dashboard in HamburgerMenu',
                      action: `New Org Dashboard ${name} created`,
                    });
                    closeMenu();
                    toggleEditMode(true);
                  }}
                />
              </div>
            </div>
          )}
          <div className="d-flex flex-grow-1 w-100 overflow-hidden mb-2">
            <div className="col-12">
              {dashboardsFiltered.map(({ id, name }) => (
                // Removed the ability for admin to edit dashboards from hamburger menu.
                // Comment back in if there is a need to restore this functionality.

                // if (isAdmin) {
                //   return (
                //     <ButtonToForm
                //       key={id}
                //       buttonTitle={name}
                //       buttonIcon={<EditRoundedIcon />}
                //       iconTitle="Edit Dashboard Name"
                //       onSubmit={(newDashName) => editDashName(id, newDashName)}
                //       buttonOnClick={() => {
                //         doSetCurrentDash({ id, type: ORG_DASH });
                //         getTrackingEvent({
                //           time: toUTC(newDate()), page: 'Org Dashboard in HamburgerMenu', action: `${name} Dashboard loaded`, dashIndicators: getDashIndicators(name)
                //         });
                //         // dispatch dashboard loading?
                //         closeMenu();
                //       }}
                //       validationSchema={schema}
                //       className={`${currentDash === id ? 'font-weight-bold bg-white text-secondary dash-menu-option-focus' : 'bg-navy text-white dash-menu-option'}`}
                //     />
                //   );
                // }
                <button
                  key={id}
                  className={`m-0 w-100 text-left pl-4 py-2 border-0 outline-none dash-menu-option ${
                    currentDash === id
                      ? 'font-weight-bold bg-white text-secondary dash-menu-option-focus'
                      : 'text-white'
                  }`}
                  type="button"
                  onClick={() => {
                    doSetCurrentDash({ id, type: ORG_DASH });
                    history.push('/dashboard');
                    closeMenu();
                  }}
                >
                  {name}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

OrgDashboards.propTypes = {
  expanded: PropTypes.bool.isRequired,
  doCreateOrgDash: PropTypes.func.isRequired,
  doSetCurrentDash: PropTypes.func.isRequired,
  currentDash: PropTypes.number.isRequired,
  // updateDashboard: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  // sectionCheckboxes: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapState = (state) => ({
  expanded: state.dashboard.dashMenuExpanded.org,
  currentDash: state.dashboard.currentDash.id,
  sectionCheckboxes: state.spreadsheetModal.sectionCheckboxes,
});

const mapDispatch = (dispatch) => ({
  doCreateOrgDash: (body) => dispatch(createDashboard(body, true)), // bool indicates org dash
  doSetCurrentDash: (dashInfo) => dispatch(setCurrentDash(dashInfo)),
  updateDashboard: (update) => dispatch(saveDashboard(update)),
});

export default connect(mapState, mapDispatch)(OrgDashboards);
