import { Card, Text } from '@intus-ui';

const Stat = ({ title, value, style }) => {
  return (
    <Card style={{ ...styles.card, ...style }}>
      <div style={styles.container}>
        <div>
          <Text wrapper="div" type="subtitle">
            {title}
          </Text>
        </div>

        <Text wrapper="div" type="display">
          {value}
        </Text>
      </div>
    </Card>
  );
};

const styles = {
  card: {
    backgroundColor: 'white',
    width: 275,
    height: 150,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
};

export default Stat;
