import { omitBy } from 'lodash';

import { useGetCohortSearchTermsQuery } from '@api';
import { useFeatureFlag } from '@util';
import { toSentenceCase } from '@util/utilFunctions';

import { getStringReplacement } from '@util/stringReplacements';
import { useGetSessionUser } from '@util/session';
import { getLabel } from '../../searchTermLabels';
import { filterRowSchemas } from './filterRowConfig';

const useFilter = () => {
  const { data, isLoading, isError } = useGetCohortSearchTermsQuery();

  const isReady = !isLoading && !isError;

  const { organizationId } = useGetSessionUser();

  const { featureIsActive: medicationsIsActive } = useFeatureFlag('medications');

  let filterOptions = {
    incidents: {
      label: 'Incidents',
      // Rule: option should match filterRowSchemas.option
      option: 'incidents',
      options: !isReady
        ? []
        : [...data.incidents].map((incident) => {
            return { value: incident, label: getLabel('incident', incident) };
          }),
      singular: 'incident',
    },
    hospitalizations: {
      label: 'Hospitalizations',
      option: 'hospitalizations',
      options: !isReady
        ? []
        : [...data.hospitalizations].map((hospitalization) => {
            return { value: hospitalization, label: getLabel('hospitalization', hospitalization) };
          }),
      singular: 'hospitalization',
    },
    diagnosis: {
      label: 'Diagnoses - Intus Labels',
      option: 'diagnosis',
      options: !isReady ? [] : [...data.diagnosis].sort(),
      singular: 'diagnosis',
    },
    team: {
      label: getStringReplacement('Team'),
      option: 'team',
      options: !isReady ? [] : [...data.teams].sort(),
    },
    facility: {
      label: getStringReplacement('Center'),
      option: 'facility',
      options: !isReady ? [] : [...data.facilities].sort(),
    },
    age: {
      label: 'Age',
      option: 'age',
    },

    acuityScoreGroup: {
      label: 'Acuity Score Group',
      option: 'acuityScoreGroup',
      options: ['Low', 'Moderate', 'High', 'Highest'],
    },
    enrollmentDuration: {
      label: 'Enrollment Duration',
      option: 'enrollmentDuration',
    },
    livingSituation: {
      label: 'Living Situation',
      option: 'livingSituation',
      options:
        !isReady || !data?.livingSituation
          ? []
          : [...data.livingSituation].map((livingSituation) => ({
              value: livingSituation,
              label: toSentenceCase(livingSituation),
            })),
    },
    gender: {
      label: 'Gender',
      option: 'gender',
      options: !isReady ? [] : [...data.gender],
    },
    // document: {
    //   label: 'Documentation',
    //   option: 'document',
    //   options: ['HRA', 'POLST'],
    // },
  };

  if (medicationsIsActive) {
    filterOptions.medicationCount = {
      label: 'Active Medication Count',
      option: 'medicationCount',
      disableAddButton: true,
    };

    filterOptions.medicationName = {
      label: 'Active Medication Names',
      option: 'medicationName',
    };
  }

  if (organizationId === 200 || process.env.NODE_ENV === 'development') {
    filterOptions.diagnosisCCSR = {
      label: 'Diagnoses - CCSR',
      option: 'diagnosisCCSR',
      options: !isReady ? [] : [...data.diagnosisCCSR].sort(),
      singular: 'diagnosis',
    };
  }

  filterOptions = omitBy(filterOptions, (value) => value.options && value.options.length === 0);

  const getFilterOptions = (option) => {
    return filterOptions[option];
  };

  const operators = ['AND', 'OR'];

  const initialType = undefined;

  const initialFilterGroup = [
    {
      filters: [{ type: initialType, searchTerm: undefined, logic: '=', operator: undefined }],
    },
  ];

  // Smooth scroll when user add user group
  const scrollToDiv = (scrollableDiv) => {
    scrollableDiv.current.scrollTo({
      left: 0,
      top: scrollableDiv.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  const singleFilter = [
    {
      type: initialType,
      searchTerm: undefined,
      logic: '=',
      schema: filterRowSchemas()[initialType],
    },
  ];

  // new filterGroup
  const singleFilterGroup = {
    operator: 'OR',
    conditions: [...singleFilter],
  };

  const newFilterGroup = {
    // initialFilterGroup
    searchCriteria: {
      groupsOperator: 'AND',
      groups: [singleFilterGroup],
    },
  };

  return {
    isReady,
    filterOptions,
    operators,
    initialFilterGroup,
    newFilterGroup,
    singleFilterGroup,
    singleFilter,
    scrollToDiv,
    getFilterOptions,
  };
};

export default useFilter;
