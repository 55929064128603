import './Adherence.scss';

import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Icon, List, Modal, SpinnerOrError, Text } from '@intus-ui';
import { useLazyQuery } from '@api/useQuery';
import {
  IPatientStarsAdherence,
  IPatientStarsMeasureData,
  getSTARSAdherence,
} from '@api/polypharmacy/getSTARSAdherence';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';
import { ButtonBase, MenuItem, Select } from '@mui/material';
import { produce } from 'immer';
import { last } from 'lodash';
import {
  IAllStarsMeasures,
  starsMeasureToName,
  starsMeasuresPossibleYears,
} from 'Polypharmacy/types/STARSMeasures';
import { StarsMeasureDropdown } from 'Polypharmacy/components/StarsMeasureDropdown';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { getPatientMedicationsWithFilters } from '@api/patients/getPatientMedications';
import { textColors } from '@intus-ui/styles/SecondaryColors';
import { NextFillDueColumn } from 'Polypharmacy/components/NextFillDueColumn';
import {
  AdherenceStatusSelect,
  IAllAdherenceStatuses,
} from 'Polypharmacy/components/AdherenceStatusSelect';
import MedicationsModalList from './MedicationsModalList';

type AdherenceProps = {
  filters: IPolypharmacyFilters;
};

type PossibleMeasures = 'all' | IAllStarsMeasures;

const columnConfig = {
  default: {
    columns: ['name', 'measure', 'fillCount', 'percentageDaysCovered', 'fillDueDate', 'seeDetails'],
  },
};

type RowItem = { item: IPatientStarsAdherence };

const format = (onClickSeeDetails: (patientId: number) => void) => {
  return [
    {
      field: 'name',
      name: 'Participant Name',
      flex: '1 1 300px',
      addOns: [{ type: 'sort', element: <SortIcon id="name" /> }],
    },
    {
      field: 'measure',
      name: 'Adherence Measure',
      flex: '0 0 260px',
      className: 'starsAdherence-measureColumn',
      customStyles: {
        marginRight: -20,
      },
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div className="starsAdherence-measureColumn-subRow">
                  {starsMeasureToName[item.measure]}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'fillCount',
      name: '# of Fills',
      flex: '1 1 120px',
      className: 'starsAdherence-measureColumn',
      align: 'end',
      customStyles: { marginRight: -20 },
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div className="starsAdherence-measureColumn-subRow" style={{ textAlign: 'right' }}>
                  {item.fillCount}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'percentageDaysCovered',
      name: 'PDC',
      flex: '1 1 120px',
      className: 'starsAdherence-measureColumn',
      customStyles: { marginRight: -20 },
      align: 'end',
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    textAlign: 'right',
                    color: item.percentageDaysCovered < 0.8 ? textColors.error : undefined,
                  }}
                >
                  {`${parseFloat((item.percentageDaysCovered * 100).toFixed(0))}%`}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'fillDueDate',
      name: 'Next fill due',
      flex: '0 0 190px',
      className: 'starsAdherence-measureColumn',
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div className="starsAdherence-measureColumn-subRow">
                  <NextFillDueColumn fillDueDate={item.fillDueDate} />
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'seeDetails',
      name: '',
      flex: '0 0 150px',
      customStyles: {
        width: '100%',
        height: '100%',
        justifyContent: 'end',
        alignItems: 'end',
      },
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;

        return (
          <ButtonBase
            type="button"
            onClick={() => {
              onClickSeeDetails(patient.id);
            }}
            sx={{
              '&:hover': {
                span: {
                  color: '#052d8f !important',
                },
              },
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '3px',
                justifyContent: 'center',
                color: '#2E62E7',
              }}
            >
              <Text color="link" type="subtitle">
                See Details
              </Text>
              <Icon name="ArrowRight" size="medium" />
            </div>
          </ButtonBase>
        );
      },
    },
  ];
};

type FlatPatient = IPatientStarsMeasureData & {
  id: number;
  name: string;
};

function singleMeasureFormat(onClickSeeDetails: (patientId: number) => void) {
  return [
    {
      field: 'name',
      name: 'Participant Name',
      flex: '1 1 300px',
      addOns: [{ type: 'sort', element: <SortIcon id="name" /> }],
    },
    {
      field: 'measure',
      name: 'Adherence Measure',
      flex: '0 0 260px',
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;

        return starsMeasureToName[patient.measure];
      },
    },
    {
      field: 'fillCount',
      name: '# of Fills',
      flex: '1 1 120px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon id="fillCount" /> }],
    },
    {
      field: 'percentageDaysCovered',
      name: 'PDC',
      flex: '1 1 120px',
      align: 'end',
      customStyles: { marginRight: -20 },
      addOns: [{ type: 'sort', element: <SortIcon id="percentageDaysCovered" /> }],
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;

        return (
          <span
            style={{ color: patient.percentageDaysCovered < 0.8 ? textColors.error : undefined }}
          >
            {parseFloat((patient.percentageDaysCovered * 100).toFixed(0))}%
          </span>
        );
      },
    },
    {
      field: 'fillDueDate',
      name: 'Next fill due',
      flex: '0 0 190px',
      className: 'starsAdherence-measureColumn',
      addOns: [{ type: 'sort', element: <SortIcon id="fillDueDate" /> }],
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            <NextFillDueColumn fillDueDate={patient.fillDueDate} />
          </div>
        );
      },
    },
    {
      field: 'seeDetails',
      name: '',
      flex: '0 0 150px',
      customStyles: {
        width: '100%',
        height: '100%',
        justifyContent: 'end',
        alignItems: 'end',
      },
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        return (
          <ButtonBase
            type="button"
            sx={{
              '&:hover': {
                span: {
                  color: '#052d8f !important',
                },
                svg: {
                  color: '#052d8f !important',
                },
              },
            }}
            onClick={() => {
              onClickSeeDetails(patient.id);
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '3px',
                justifyContent: 'center',
                color: '#2E62E7',
              }}
            >
              <Text color="link" type="subtitle">
                See Details
              </Text>
              <Icon name="ArrowRight" size="medium" />
            </div>
          </ButtonBase>
        );
      },
    },
  ];
}

export const Adherence: FC<AdherenceProps> = ({ filters }) => {
  const [medicationsModalOpen, setMedicationsModalOpen] = useState(false);
  const [selectedMeasure, setSelectedMeasure] = useState<PossibleMeasures>('all');
  const [adherentStatus, setAdherentStatus] = useState<IAllAdherenceStatuses>('All');

  const [selectedYear, setSelectedYear] = useState(() => {
    const currentYear = new Date().getFullYear();
    return starsMeasuresPossibleYears.includes(currentYear)
      ? currentYear
      : last(starsMeasuresPossibleYears)!;
  });

  const { data, loading, error, runQuery } = useLazyQuery(getSTARSAdherence);

  const {
    data: medicationsData,
    loading: medicationsDataLoading,
    error: medicationsDataError,
    runQuery: runGetMedicationsData,
  } = useLazyQuery((patientId) =>
    getPatientMedicationsWithFilters({
      patientId,
      starsMeasure: selectedMeasure,
      starsMeasureYear: selectedYear,
      active: false,
      isRisingStars: false,
    })
  );

  const onClickSeeDetails = useCallback(
    (patientId: number) => {
      runGetMedicationsData(patientId);
      setMedicationsModalOpen(true);
    },
    [runGetMedicationsData]
  );

  const fullListFormat = useMemo(() => {
    return format(onClickSeeDetails);
  }, [onClickSeeDetails]);

  const singleMeasureListFormat = useMemo(() => {
    return singleMeasureFormat(onClickSeeDetails);
  }, [onClickSeeDetails]);

  useEffect(() => {
    runQuery(selectedYear, filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, selectedYear]);

  const filteredPatientAdherence = useMemo(() => {
    if (data == null) return [];

    return produce(data.patientAdherence, (draft) => {
      const filteredPatients: IPatientStarsAdherence[] = [];

      for (const patient of draft) {
        let filteredMeasures = patient.measureData;
        if (selectedMeasure !== 'all') {
          filteredMeasures = patient.measureData.filter((m) => m.measure === selectedMeasure);
        }

        if (adherentStatus === 'Adherent (≥80%)') {
          filteredMeasures = filteredMeasures.filter((m) => m.percentageDaysCovered >= 0.8);
        } else if (adherentStatus === 'Not adherent (<80%)') {
          filteredMeasures = filteredMeasures.filter((m) => m.percentageDaysCovered < 0.8);
        }

        if (filteredMeasures.length > 0) {
          filteredPatients.push({
            ...patient,
            measureData: filteredMeasures,
          });
        }
      }
      return filteredPatients;
    });
  }, [adherentStatus, data, selectedMeasure]);

  const flattenedPatients = useMemo(() => {
    const flatPatients: FlatPatient[] = [];

    for (const patient of filteredPatientAdherence) {
      flatPatients.push({
        ...patient.measureData[0],
        id: patient.id,
        name: patient.name,
      });
    }
    return flatPatients;
  }, [filteredPatientAdherence]);

  if (error) return <SpinnerOrError error="An error occurred loading adherence" />;

  if (loading || !data) return <SpinnerOrError />;

  return (
    <div style={{ paddingTop: 20 }}>
      <Modal
        open={medicationsModalOpen}
        onClose={() => setMedicationsModalOpen(false)}
        type="large"
        style={{ overflow: 'hidden' }}
        modalStyles={{ height: '90%' }}
        header={{ title: 'Medications', centered: true }}
      >
        <MedicationsModalList
          medicationsData={medicationsData}
          loading={medicationsDataLoading}
          error={medicationsDataError}
        />
      </Modal>
      <div style={styles.cardGrid}>
        <AdherenceCard
          title="% of Pts Adherent for Diabetes Medications"
          value={data.diabetesAdherence}
        />
        <AdherenceCard
          title="% of Pts Adherent for Hypertension (RAS Antagonists)"
          value={data.rasAdherence}
        />
        <AdherenceCard
          title="% of Pts Adherent for Cholesterol (Statins)"
          value={data.statinsAdherence}
        />
      </div>
      <div style={{ padding: '10px 0px 10px 0px' }}>
        <Text type="caption" color="caption">
          Disclaimer: We provide insights into individual patient and population-level adherence to
          medications. The calculations are based on similar criteria but should not be considered
          an official representation of STARs Ratings. Exclusions that do not factor into
          calculations include: Hospice Enrollment, Dialysis Coverage Dates
        </Text>
      </div>
      <div style={{ display: 'flex', gap: 15 }}>
        {/* Measure Year selector */}
        {new Date().getFullYear() > 2023 && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text>Measure Year</Text>
            <Select
              label="Select a Year"
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(parseInt(e.target.value.toString()));
              }}
              sx={{ width: 100 }}
            >
              {starsMeasuresPossibleYears.map((year) => {
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}

        {/* Measure selector */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Text>Measure</Text>
          <StarsMeasureDropdown
            value={selectedMeasure}
            onChange={(measure) => setSelectedMeasure(measure)}
          />
        </div>

        {/* Adherence or Not Adherent patient selector */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Text>Adherence Status</Text>
          <AdherenceStatusSelect
            adherentStatus={adherentStatus}
            setAdherentStatus={setAdherentStatus}
          />
        </div>
      </div>
      <div>
        {selectedMeasure === 'all' && (
          <List
            key="allMeasureList"
            className="adherence-list"
            data={filteredPatientAdherence}
            format={fullListFormat}
            columnConfig={columnConfig.default}
            defaultSort={{ field: 'name', direction: 'asc' }}
            listStyle="striped"
          />
        )}
        {selectedMeasure !== 'all' && (
          <List
            key="singleMeasureList"
            className="adherence-list"
            data={flattenedPatients}
            format={singleMeasureListFormat}
            columnConfig={columnConfig.default}
            defaultSort={{ field: 'name', direction: 'asc' }}
            listStyle="striped"
          />
        )}
      </div>
    </div>
  );
};

type AdherenceCardProps = {
  title: string;
  value: number;
};

const AdherenceCard: FC<AdherenceCardProps> = ({ title, value }) => {
  return (
    <div style={styles.adherenceCard}>
      <Text type="subtitle">{title}</Text>
      <Text type="display">{(value * 100).toFixed(1)}%</Text>
    </div>
  );
};

const styles: Record<string, CSSProperties> = {
  cardGrid: {
    display: 'flex',
    gap: 10,
    justifyContent: 'space-between',
    height: 171,
    margin: '0px 40px',
  },
  adherenceCard: {
    flex: '1 1 100px',
    maxWidth: 320,
    gap: 10,
    border: '1px solid black',
    borderRadius: 10,
    padding: '10px 15px 15px 15px',
    textAlign: 'center',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
