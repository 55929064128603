/**
 * Builds the query string for a GET request that uses the team/facility/patient groups filters.
 *
 * !!!! IMPORTANT !!!!
 * This query string is encoded, DO NOT CALL THE REGULAR `get` request from our apiMethods.ts code.
 * Use `getWithoutEncoding` if you need a GET request.
 *
 * This can be used like:
 * `/patients?${buildPatientFilterQueryString(selectedFilters)}`
 * or:
 * `/patients?status=ACTIVE&${buildPatientFilterQueryString(selectedFilters)}`
 * or:
 * ```js
 * const urlParams = new URLSearchParams(buildPatientFilterQueryString(selectedFilters));
 *
 * `/patients?${urlParams.toString()}`
 * ```
 *
 */
export function buildPatientFilterQueryString(selectedFilters) {
  if (selectedFilters == null) {
    return '';
  }

  const url = new URLSearchParams({});
  url.append('filters', JSON.stringify(selectedFilters));

  // Decode this, the API re-encodes the query string so we need to avoid double encoding.
  return url.toString();
}
