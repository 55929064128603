import { useEffect } from 'react';
import { Box, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { getEHRBenchmark } from '@api/benchmark/getEHRBenchmark';
import { useLazyQuery } from '@api/useQuery';
import { SpinnerOrError, Text, useParticipantFilters } from '@intus-ui';
import { useSelector } from 'react-redux';
import Quad from 'Dashboard/Quad';
import { BenchmarkBarChart } from 'Dashboard/DashboardTypes/PerformanceBenchmark/BenchmarkBarChart';
import { formatDate } from '@util/dateFunctions';

export const PerformanceBenchmarkDashboard = () => {
  const { selectedFilters } = useParticipantFilters();

  const timeFilter = useSelector((state) => state.filters.timeFilter);

  const { data, loading, error, runQuery } = useLazyQuery(() =>
    getEHRBenchmark(timeFilter.startDate, timeFilter.endDate, selectedFilters)
  );

  useEffect(() => {
    runQuery();
  }, [timeFilter, selectedFilters]);

  if (loading) {
    return <SpinnerOrError />;
  }

  if (error) {
    return <SpinnerOrError error="An error ocurred loading the benchmark data" />;
  }

  // We will only hit this for a single render when the page first loads, then the loading boolean is true
  if (!data) {
    return <SpinnerOrError />;
  }

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <Box sx={styles.card}>
          <Quad
            title={
              <PerformanceBenchmarkQuadTitle
                title="Admissions/Visits per 100MM"
                tooltipContent={
                  <Text>
                    ER visits & inpatient admissions in the selected months, divided by the member
                    months in the time period multiplied by 100
                  </Text>
                }
              />
            }
          >
            <BenchmarkBarChart
              chartTitle="Admissions/Visits per 100MM"
              yLabel="Admits per 100MM"
              barData={[data.erVisits.myOrg, data.inpatientVisits.myOrg]}
              lineData={[data.erVisits.otherOrgs, data.inpatientVisits.otherOrgs]}
            />
          </Quad>
        </Box>

        <Box sx={styles.card}>
          <Quad
            title={
              <PerformanceBenchmarkQuadTitle
                title="Falls per 100MM"
                tooltipContent={
                  <Text>
                    Falls in the selected months, divided by the member months in the time period
                    multiplied by 100
                  </Text>
                }
              />
            }
          >
            <BenchmarkBarChart
              chartTitle="Falls per 100MM"
              yLabel="Falls per 100MM"
              barData={[data.falls.myOrg]}
              lineData={[data.falls.otherOrgs]}
            />
          </Quad>
        </Box>
      </div>

      <div style={styles.row}>
        <Box sx={styles.card}>
          <Quad
            title={
              <PerformanceBenchmarkQuadTitle
                title="Total Inpatient Admission Length of Stay per 100MM"
                tooltipContent={
                  <Text>
                    Total inpatient length of stay for each month between{' '}
                    {formatDate(timeFilter.startDate, "MMMM 'YY")} and{' '}
                    {formatDate(timeFilter.endDate, "MMMM 'YY")}
                  </Text>
                }
              />
            }
          >
            <BenchmarkBarChart
              chartTitle="Total Inpatient Admission Length of Stay per 100MM"
              yLabel="Total LOS per 100MM"
              barData={[data.totalLengthOfStay.myOrg]}
              lineData={[data.totalLengthOfStay.otherOrgs]}
            />
          </Quad>
        </Box>
        <Box sx={styles.card}>
          <Quad
            title={
              <PerformanceBenchmarkQuadTitle
                title="Average Inpatient Admission Length of Stay"
                tooltipContent={
                  <Text>
                    Average inpatient length of stay across inpatient admissions between{' '}
                    {formatDate(timeFilter.startDate, 'MMMM DD, YYYY')} and{' '}
                    {formatDate(timeFilter.endDate, 'MMMM DD, YYYY')}
                  </Text>
                }
              />
            }
          >
            <BenchmarkAverageLengthOfStay ehrName={data.ehrName} data={data.averageLengthOfStay} />
          </Quad>
        </Box>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'auto',
    padding: '10px',
  },
  row: {
    flex: '1 1 50%',
    overflow: 'hidden',
    display: 'flex',
  },
  card: {
    flex: '1 1 50%',
    width: '50%',
    maxWidth: '50%',
    padding: '10px',
  },
  quadTitle: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
  },
};

const BenchmarkAverageLengthOfStay = ({ ehrName, data }) => {
  return (
    <div style={losStyles.container}>
      <div style={{ ...losStyles.square, borderRight: '1px solid #dee2e6' }}>
        <div>
          <Text type="title">My Organization</Text>
          <br />
          <Text type="title">Average LOS</Text>
        </div>
        <Text type="display">{data.myOrg.value}</Text>
        <Text>Days per Hospitalization</Text>
      </div>

      <div style={losStyles.square}>
        <div>
          <Text type="title">Intus Community using {ehrName}</Text>
          <br />
          <Text type="title">Average LOS</Text>
        </div>
        <Text type="display">{data.otherOrgs.value}</Text>
        <Text>Days per Hospitalization</Text>
      </div>
    </div>
  );
};

const losStyles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  square: {
    margin: '0px 0px 20px 0px',
    flex: '1 1 50%',
    padding: '0 10px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: 5,
  },
};

const PerformanceBenchmarkQuadTitle = ({ title, tooltipContent }) => {
  return (
    <div style={styles.quadTitle}>
      <span>{title}</span>

      <Tooltip arrow style={{ maxWidth: 400 }} placement="top" title={tooltipContent}>
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
            // Negative margins since the SVG has some padding that can't easily be changed.
            marginTop: -1,
            cursor: 'pointer',
          }}
        >
          <InfoIcon variant="success" className="info-icon cursor-pointer m-0" />
        </span>
      </Tooltip>
    </div>
  );
};
