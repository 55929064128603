import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getModalContent } from '@global-state/redux/homepageSlice';

import CensusAnalytics from 'Homes/HomePage/ModalContent/CensusAnalytics';

import '@intus-ui/styles/Utilities.scss';
import { useParticipantFilters } from '@intus-ui';

export const CensusAnalyticsDashboard = () => {
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.filters.startDate);
  const endDate = useSelector((state) => state.filters.endDate);

  const { selectedFilters } = useParticipantFilters();

  useEffect(() => {
    dispatch(getModalContent('census', startDate, endDate, selectedFilters));
  }, [startDate, endDate, selectedFilters]);

  return <CensusAnalytics isDashboard />;
};
