import { getPatientAdmits } from '@api';
import { useQuery } from '@api/useQuery';
import { List, SpinnerOrError, Text } from '@intus-ui';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { filter, includes } from 'lodash';
import { createFormat } from './list/format';
import { columnConfig } from './list/columnConfig';
import { AdmitTypeFilter } from './AdmitTypeFilter';

const AdmitsList = ({ admitsData }) => {
  const [filters, setFilters] = useState(null);
  const [filteredData, setFilteredData] = useState(admitsData);

  useEffect(() => {
    if (!filters) {
      setFilteredData(admitsData);
    } else if (filters.length === 0) {
      setFilteredData(admitsData);
    } else {
      setFilteredData(
        filter(admitsData, (admit) => {
          return includes(filters, admit.type);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters)]);

  return (
    <List
      data={filteredData}
      format={createFormat({
        typeFilter: <AdmitTypeFilter data={admitsData} filters={filters} setFilters={setFilters} />,
      })}
      columnConfig={columnConfig.default}
      listStyle="striped"
      emptyListMessage="No admissions for this participant."
      defaultSort={{ field: 'admitDate', direction: 'desc' }}
    />
  );
};

const Admits = () => {
  const { patientId } = useParams();

  const {
    data: admitsData,
    loading: admitsDataLoading,
    error: admitsDataError,
  } = useQuery(() => getPatientAdmits(patientId));

  const loadingOrError = admitsDataLoading || admitsDataError;

  return (
    <div style={{ overflow: 'hidden' }}>
      <Text type="title" color="navy">
        Admissions
      </Text>
      {loadingOrError && <SpinnerOrError />}
      {!loadingOrError && <AdmitsList admitsData={admitsData} />}
    </div>
  );
};

export default Admits;
