/* eslint-disable no-underscore-dangle */
import { useRef } from 'react';
import { Scatter } from 'react-chartjs-2';

const ScatterPlot = ({ data, options, plugins, handleChartClick }) => {
  if (!data) return null;
  const chartRef = useRef(null);

  const getDatapointFromEvent = (elements) => {
    if (!elements || !elements.length) return null;
    const datasetIndex = elements[0]._datasetIndex;
    const pointIndex = elements[0]?._index;
    return data.datasets[datasetIndex].data[pointIndex];
  };

  return (
    <Scatter
      ref={chartRef}
      getElementAtEvent={(elements, event) =>
        handleChartClick(elements, event, getDatapointFromEvent(elements))
      }
      data={data}
      options={options}
      plugins={plugins}
    />
  );
};

ScatterPlot.defaultProps = {
  plugins: [],
  data: { datasets: [{ data: [] }] },
  options: {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: true,
            display: true,
            lineWidth: 1,
            color: ['black'],
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            display: true,
            drawOnChartArea: false,
            lineWidth: 1,
            color: ['black'],
          },
        },
      ],
    },
  },
};

export default ScatterPlot;
