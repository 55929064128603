import { GeneralButton } from '@intus-ui';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import './ActionCardActions.css';

export default function ActionCardActions({
  complete,
  onEditClick,
  onCompleteClick,
  editing,
  clearForm,
}) {
  return (
    <div className="controls">
      <GeneralButton
        name={complete ? 'Undo Complete' : 'Complete'}
        onClick={onCompleteClick}
        secondary={complete}
        style={{ width: '100%' }}
      />
      {!complete && (
        <GeneralButton
          name={editing ? 'Cancel' : 'Edit'}
          onClick={editing ? clearForm : onEditClick}
          style={{ width: '100%', marginTop: '1rem' }}
          secondary
        >
          {editing ? <ClearIcon /> : <EditIcon />}
        </GeneralButton>
      )}
      {/* <button
        disabled={complete}
        className="action-button"
        onClick={onCompleteClick}
      >
        <div>
          <i className="bi bi bi-check2-square" />
        </div>
        <div style={{ flexGrow: 1 }}>Complete</div>
      </button>
      {!complete && inMeeting && (
        <button className="action-button" onClick={onEditClick}>
          <div>
            <i className="bi bi-pencil-square" />
          </div>
          <div style={{ flexGrow: 1 }}>Edit</div>
        </button>
      )} */}
    </div>
  );
}
