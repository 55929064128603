const ActionItemsApi = {
  tagTypes: ['Meeting'],
  endpoints: (builder) => ({
    getActionItemsByPatientId: builder.query({
      query: (patientId) => ({
        url: `action-items?patientId=${patientId}`,
        method: 'GET',
      }),
    }),

    addActionItem: builder.mutation({
      query: (data) => ({
        url: 'action-items',
        method: 'POST',
        body: data,
      }),
    }),

    updateActionItem: builder.mutation({
      query: (data) => ({
        url: `action-items/${data.id}`,
        method: 'PUT',
        body: data,
      }),
    }),

    deleteActionItem: builder.mutation({
      query: (actionItemId) => ({
        url: `action-items?actionItemId=${actionItemId}`,
        method: 'DELETE',
      }),
    }),
  }),
};

export default ActionItemsApi;
