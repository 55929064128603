import PropTypes from 'prop-types';

import { getFeatureFlag } from '@config';

export const USER_ACCESS_CAREGIVER = 'CAREGIVER';
export const USER_ACCESS_ADMIN = 'ADMIN';
export const USER_ACCESS_HEALTH_SYS_ADMIN = 'HEALTHSYSTEMADMIN';
export const USER_ACCESS_GOD = 'GOD';
export const USER_ACCESS_TRACKING_ADMIN = 'TRACKINGADMIN';
export const USER_ACCESS_PIPELINE_GOD = 'PIPELINEGOD';

export const userAccessPropType = PropTypes.oneOf([
  USER_ACCESS_CAREGIVER,
  USER_ACCESS_ADMIN,
  USER_ACCESS_HEALTH_SYS_ADMIN,
  USER_ACCESS_GOD,
  USER_ACCESS_TRACKING_ADMIN,
]);

export const UserProfileSettingPropType = PropTypes.shape({
  team: PropTypes.string,
  facility: PropTypes.string,
  homePageBoxes: PropTypes.instanceOf(Array),
  defaultRecentUpdatesFilter: PropTypes.instanceOf(Array),
});

export const SUBSCRIPTION_ACUITY = 'ACUITY';
export const SUBSCRIPTION_DASHBOARD = 'DASHBOARD';
export const SUBSCRIPTION_COHORTS = 'COHORTS';
export const SUBSCRIPTION_TRUCHART = 'TRUCHART';
export const SUBSCRIPTION_COGNIFY = 'COGNIFY';
export const SUBSCRIPTION_RTZ = 'RTZ';
export const EHR_PROVIDES_NO_FINANCIALS = 'EHR_PROVIDES_NO_FINANCIALS';

export const userSubscriptionPropType = PropTypes.oneOf([
  SUBSCRIPTION_ACUITY,
  SUBSCRIPTION_DASHBOARD,
  SUBSCRIPTION_COHORTS,
  SUBSCRIPTION_TRUCHART,
  SUBSCRIPTION_COGNIFY,
  SUBSCRIPTION_RTZ,
  EHR_PROVIDES_NO_FINANCIALS,
]);

export const EHR_COGNIFY = 'COGNIFY';
export const EHR_RTZ = 'RTZ';
export const EHR_TRUCHART = 'TRUCHART';

export const NAV_BAR_ITEM_NAME = {
  GENERAL: 'general',
  HOMEPAGE: 'homepage',
  HOMEPAGE_CARDS: 'cardSelections',
  HOMEPAGE_RECENTS: 'recentEventSelections',
  TUTORIALS: 'tutorials',
  TUTORIALS_USECASES: 'useCases',
  TUTORIALS_VIDEOS: 'videos',
};

export const newMenuMap = [
  { name: NAV_BAR_ITEM_NAME.GENERAL, type: 'item', displayTitle: 'General' },
  // {
  //   name: NAV_BAR_ITEM_NAME.TUTORIALS,
  //   type: 'category',
  //   displayTitle: 'Tutorials',
  //   children: [
  //     {
  //       name: NAV_BAR_ITEM_NAME.TUTORIALS_USECASES,
  //       type: 'item',
  //       displayTitle: 'Use Case Guides',
  //     },
  //   ],
  // },
];
const { featureIsActive } = getFeatureFlag('new-welcome-and-home');

export const menuMap = featureIsActive
  ? newMenuMap
  : [
      { name: NAV_BAR_ITEM_NAME.GENERAL, type: 'item', displayTitle: 'General' },
      {
        name: NAV_BAR_ITEM_NAME.HOMEPAGE,
        type: 'category',
        displayTitle: 'Homepage',
        children: [
          { name: NAV_BAR_ITEM_NAME.HOMEPAGE_CARDS, type: 'item', displayTitle: 'Card Selections' },
          {
            name: NAV_BAR_ITEM_NAME.HOMEPAGE_RECENTS,
            type: 'item',
            displayTitle: 'Recent Events',
          },
        ],
      },
      // {
      //   name: NAV_BAR_ITEM_NAME.TUTORIALS,
      //   type: 'category',
      //   displayTitle: 'Tutorials',
      //   children: [
      //     {
      //       name: NAV_BAR_ITEM_NAME.TUTORIALS_USECASES,
      //       type: 'item',
      //       displayTitle: 'Use Case Guides',
      //     },
      //   ],
      // },
    ];

export const UserProfilePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  access: userAccessPropType.isRequired,
  organizationId: PropTypes.number.isRequired,
  setting: UserProfileSettingPropType,
});

const userSettingsPropType = PropTypes.shape({
  profile: UserProfilePropType,
  subscriptions: PropTypes.arrayOf(userSubscriptionPropType.isRequired),
});

export default userSettingsPropType;
