import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { Form, Col } from 'react-bootstrap';
import { Button, Text } from '@intus-ui';
import BoxOnBlue from '@intus-ui/components/BoxOnBlue';
import FormikFormControl from '@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';
import { validateToken, getUserInfo, updateUser } from 'Admin/resetPassword/resetPasswordSlice';

const schema = Yup.object().shape({
  password: Yup.string().required('Required').min(9, 'Password must be at least 9 characters long'),
  passwordConfirmation: Yup.mixed()
    .required('Required')
    .oneOf([Yup.ref('password'), null], "Passwords don't match"),
});

const PasswordReset = ({
  doAuthenticateToken,
  token,
  doGetUserLocalData,
  doUpdateUser,
  history,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [tokenText, setTokenText] = useState('Verifying Token...');
  const [localData, setLocalData] = useState({});
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    doAuthenticateToken({ passToken: token })
      .then((res) => {
        if (res) {
          setLoaded(true);
          setEditable(true);
        } else {
          setTokenText('Invalid Token. Please contact your IT administrator for a new one.');
        }
      })
      .then(() => doGetUserLocalData())
      .then(setLocalData);
  }, [doAuthenticateToken, token, doGetUserLocalData]);

  return (
    <BoxOnBlue logo>
      {!loaded ? (
        <p className="text-center">{tokenText}</p>
      ) : (
        <Formik
          initialValues={{}}
          onSubmit={(values, { setErrors, setTouched, setSubmitting, setStatus }) => {
            const includedFields = {
              password: values.password,
              mandatoryPasswordChange: false,
              id: localData.id,
            };
            setErrors({});
            setTouched({});
            doUpdateUser(includedFields);
            setSubmitting(false);
            setStatus({ success: 'Successfully Reset Password. Redirecting...' });
            setEditable(false);
            setTimeout(() => history.push('/'), 1500);
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, errors, touched, isSubmitting, status }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <FormikFormControl
                  as={Col}
                  md="12"
                  label="New password"
                  placeholder="New password"
                  type="password"
                  name="password"
                  disabled={!editable}
                />
              </Form.Row>
              <Form.Row>
                <FormikFormControl
                  as={Col}
                  md="12"
                  label="Confirm new password"
                  placeholder="Confirm new password"
                  type="password"
                  name="passwordConfirmation"
                  disabled={!editable}
                />
              </Form.Row>
              {status ? <p className="text-success font-weight-bold">{status.success}</p> : null}
              <Form.Row>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      width: '100%',
                    }}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      disabled={
                        Object.values(errors).length > 0 ||
                        Object.values(touched).length === 0 ||
                        isSubmitting
                      }
                    >
                      Confirm and log in
                    </Button>
                  </div>
                </Col>
              </Form.Row>
            </Form>
          )}
        </Formik>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 30,
          margin: '50px 10px 10px 10px',
          alignItems: 'center',
        }}
      >
        <Text type="caption">Need help?</Text>
        <Text
          wrapper="a"
          href="https://intuscare.com/demo"
          target="_blank"
          type="subtitle"
          color="link"
        >
          Contact Us.
        </Text>
      </div>
      <div className="d-flex justify-content-center align-self-center w-100 pt-1">
        <Text>Please contact your IT administrator to create a new account.</Text>
      </div>
    </BoxOnBlue>
  );
};

PasswordReset.propTypes = {
  doAuthenticateToken: PropTypes.func.isRequired,
  doGetUserLocalData: PropTypes.func.isRequired,
  doUpdateUser: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const mapDispatch = (dispatch) => ({
  doAuthenticateToken: (body) => dispatch(validateToken(body)),
  doGetUserLocalData: () => dispatch(getUserInfo()),
  doUpdateUser: (body) => dispatch(updateUser(body)),
});

export default withRouter(connect(null, mapDispatch)(PasswordReset));
