import {
  addDate,
  dateDifference,
  formatDate,
  isSameDate,
  newDate,
  newDateInFormat,
} from '@util/dateFunctions';
import { TrialMetricChartLegend } from 'Initiatives/MetricChart/Legend/TrialMetricChartLegend';
import { useTrialMetricChartTooltip } from 'Initiatives/MetricChart/Tooltip/TrialMetricChartTooltip';
import { getCohortLineChartColorValue } from 'Initiatives/MetricChart/cohortLineChartColors';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

export const TrialMetricChart = ({ trial, metric, hideLegend, onClickChart }) => {
  // This should never happen, but bail out if we get no metric data.
  if (metric.cohorts == null || metric.cohorts.length === 0) {
    return null;
  }

  // add data to this return
  const graphData = useMemo(() => {
    return generateGraphDataForCohorts(metric.cohorts, trial);
  }, [metric.cohorts]);

  const { tooltipComponent, chartOptions } = useGenerateLineGraphOptions(
    trial,
    metric,
    metric.yAxisText,
    onClickChart
  );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <Line data={graphData} options={chartOptions} responsive plugins={[drawMetricLines]} />
      {!hideLegend && <TrialMetricChartLegend trial={trial} metric={metric} />}
      {tooltipComponent}
    </div>
  );
};

/**
 * Translate API response into a list of labels and datasets to use in the chart.
 * @param {object} metricResults - The trial metric results, looks like this `{ '03/22': { value: 12.34, format: "DOUBLE" }, '04/22': { value: 5.5, format: "DOUBLE" } }`
 */
const translateMetricResultsToChartData = (metricResults, cohort) => {
  // A Date array for the axis, the chart options below handle formatting the dates to strings.
  const labels = [];
  // The raw data like [12.45, 0.12]
  const data = [];

  for (const monthAndYearString of Object.keys(metricResults)) {
    // We get back a date string in the format of `MM/YY` like `03/22`
    // Turn that into a Date object and then format it into the format of `Mar '22` to display on the X axis.
    const month = newDateInFormat(monthAndYearString, 'MM/YY');
    labels.push(month.toDate());

    data.push(metricResults[monthAndYearString].value);
  }

  return { labels, data, cohort, metricResults };
};

/**
 * Transform raw dataset into a form the charting library expects
 * @param {Object} metricCohorts - Array of cohorts objects containing metrics results data
 * @returns {Chart.ChartData}
 */
const generateGraphDataForCohorts = (metricCohorts, trial) => {
  const chartData = metricCohorts.map((c) => {
    return translateMetricResultsToChartData(c.results, c);
  });

  /** @type {Chart.ChartDataSets[]} */
  const datasets = chartData.map((obj) => {
    const color = getCohortLineChartColorValue(obj.cohort.color);
    /** @type {Chart.ChartDataSets} */
    const dataset = {
      label: obj.cohort.name,
      data: obj.data,
      fill: false,
      pointBackgroundColor: Object.values(obj.metricResults).map((r) =>
        r.isPartialData ? 'white' : color
      ),
      backgroundColor: color,
      borderColor: color,
      lineTension: 0,
      cohort: obj.cohort,
      metricResults: obj.metricResults,
      trial,
    };

    return dataset;
  });

  return {
    labels: chartData[0].labels,
    datasets,
  };
};

/**
 * Set up common chart options
 *
 * @param {String} metricName - Metric name
 * @param {String} yLabel - Y-axis name
 *
 * @returns {{ chartOptions: Chart.ChartOptions }}
 */
const useGenerateLineGraphOptions = (trial, metric, yLabel, onClickChart) => {
  const metricName = metric.name;

  const { tooltipComponent, setTooltipModel } = useTrialMetricChartTooltip(trial, metric);

  const chartOptions = useMemo(() => {
    /** @type {Chart.ChartOptions} */
    const options = {
      padding: 5,
      // We render a custom legend.
      legend: {
        display: false,
      },
      onClick: (_e, activeElements) => {
        if (onClickChart == null) {
          return;
        }
        // eslint-disable-next-line no-underscore-dangle
        const index = activeElements[0]?._index;
        if (index >= 0) {
          const { results } = metric.cohorts[0];
          const monthAndYearString = Object.keys(results)[index];
          onClickChart(monthAndYearString);
        }
      },
      // Controls what points are shown as highlighted when mousing over the chart.
      // This must match the tooltip options below.
      hover: {
        // Intersect false and mode nearest means we always show the closest point to the mouse.
        // This means that the tooltip/hover will always be visible when the mouse is over the chart.
        intersect: false,
        mode: 'nearest',
        onHover(e) {
          // Change the cursor to a pointer if the mouse is over a dot.
          const point = this.getElementAtEvent(e);
          if (point.length) {
            e.target.style.cursor = 'pointer';
          } else {
            e.target.style.cursor = 'default';
          }
        },
      },
      // We're on an old version of chart.js, here's the docs on configuring tooltips.
      // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
      tooltips: {
        mode: 'nearest',
        intersect: false,
        // Disable the on-canvas tooltip
        enabled: false,
        custom(tooltipModel) {
          // eslint-disable-next-line no-underscore-dangle
          setTooltipModel(this._chart, tooltipModel);
        },
      },
      // Disable the lines between points. We use a custom plugin to handle dashed and solid lines.
      showLines: false,
      // Turn off the animation since it doesn't work with the custom lines.
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            ticks: {
              // Only show ticks for points in the data.
              // Without this chart.js renders multiple ticks for each month if the chart range is a few months.
              source: 'labels',
              // If the chart is over a long time range, then start skipping months in the x-axis ticks.
              autoSkip: true,
              maxTicksLimit: 48,
              maxRotation: 60,
              minRotation: 0,
              fontSize: 12,
              padding: 8,
              callback: (value, index, ticks) => {
                const date = ticks[index].value;
                // If we're in the current month, display MTD on the chart.
                if (isSameDate(newDate(), date, 'month')) {
                  return 'MTD';
                }
                return formatDate(date, "MMM 'YY");
              },
            },
            gridLines: {
              display: true,
              // Draws the line right above the x-axis
              drawBorder: true,
              // Do not display a line going down passed the bottom of the axis to the date label.
              drawTicks: false,
            },
            scaleLabel: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              // The suggestedMax here is only used if all data points are below 5
              // We do this to prevent the graph from looking bad for small numbers.
              suggestedMax: 5,
              suggestedMin: 0,
              maxTicksLimit: 8,
              fontSize: 12,
              precision: 0,
            },
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: yLabel,
              fontSize: 12,
              fontStyle: 'bold',
            },
          },
        ],
      },
    };

    return options;
  }, [metricName, yLabel]);

  return {
    tooltipComponent,
    chartOptions,
  };
};

/**
 * Draws the lines on the chart and handles dashed versus dotted lines.
 * A cohort has a dashed line if the data we're showing is outside the start/end date for the initiative.
 * If the start/end date occurs in the middle of a month we draw a line that is dashed for only part of teh month.
 * @type {Chart.PluginServiceRegistrationOptions}
 */
const drawMetricLines = {
  id: 'drawMetricLines',
  beforeDatasetsDraw(chart) {
    const {
      config: {
        data: { datasets },
      },
    } = chart;

    datasets.forEach((dataset) => {
      const { cohort, metricResults } = dataset;

      const keys = Object.keys(metricResults);

      // Loop through all the months in the dataset and draw a line for each month, except for the last month.
      for (let i = 0; i < keys.length - 1; ++i) {
        const monthString = keys[i];

        const month = newDateInFormat(monthString, 'MM/YY').toDate();

        const nextMonth = addDate(month, 1, 'months').toDate();

        const result = metricResults[monthString];

        const nextResult = metricResults[keys[i + 1]];

        let shouldDrawMixedDottedAndSolidLine =
          result.isWithinInitiative !== nextResult.isWithinInitiative;

        if (isSameDate(nextMonth, cohort.startDate, 'day')) {
          shouldDrawMixedDottedAndSolidLine = false;
        }

        // If one month is in the initiative and the next is not or vice versa, we need to draw a line that is partly dashed and partly solid.
        if (shouldDrawMixedDottedAndSolidLine) {
          // If this point is in the initiative, it means we went beyond the end date. Draw a dotted line on the left.
          // If this point is not within the initiative, it means are before the start date. Draw a dotted line on the right.
          let dottedLineSide = 'left';
          if (result.isWithinInitiative) {
            dottedLineSide = 'right';
          }

          let midPointX = newDate(
            dottedLineSide === 'left' ? cohort.startDate : cohort.endDate
          ).toDate();
          // Add 1 day otherwise the line looks too far to the left ¯\_(ツ)_/¯
          midPointX = addDate(midPointX, 1, 'days').toDate();

          // To calculate the Y midpoint, we need to calculate how far along in the month the X midpoint is.
          //
          // E.x. if the date we start drawing the dotted line is the 15th in a 30 day month,
          // we're 50% of the way through the month.
          //
          // If the date we start drawing the dotted line is the 10th in a 30 day month,
          // we're 1/3rd of the way through the month.
          const daysDifference = dateDifference(midPointX, month, 'days');

          const daysInMonth = newDate(month).daysInMonth();

          const percentThroughMonth = daysDifference / daysInMonth;

          const midPointY = result.value + (nextResult.value - result.value) * percentThroughMonth;

          drawLine(
            chart,
            {
              x: month,
              y: result.value,
            },
            {
              x: midPointX,
              y: midPointY,
            },
            {
              color: dataset.backgroundColor,
              type: dottedLineSide === 'left' ? 'dotted' : 'solid',
            }
          );

          drawLine(
            chart,
            {
              x: midPointX,
              y: midPointY,
            },
            {
              x: addDate(month, 1, 'months').toDate(),
              y: nextResult.value,
            },
            {
              color: dataset.backgroundColor,
              type: dottedLineSide === 'right' ? 'dotted' : 'solid',
            }
          );
        } else {
          drawLine(
            chart,
            {
              x: month,
              y: result.value,
            },
            {
              x: addDate(month, 1, 'months').toDate(),
              y: nextResult.value,
            },
            {
              color: dataset.backgroundColor,
              type: result.isWithinInitiative ? 'solid' : 'dotted',
            }
          );
        }
      }
    });
  },
};

/**
 * @typedef {{ x: Date, y: number }} LineInfo
 *
 * @typedef {{ type: 'dotted' | 'solid', color: string  }} LineOptions
 */

/**
 * @param {Chart} chart
 * @param {LineInfo} lineStart
 * @param {LineInfo} lineEnd
 * @param {LineOptions} lineOptions
 */
function drawLine(chart, lineStart, lineEnd, lineOptions) {
  const { ctx } = chart;

  const x1 = getXPositionForDate(chart, lineStart.x);
  const x2 = getXPositionForDate(chart, lineEnd.x);

  const y1 = getYPositionForNumber(chart, lineStart.y);
  const y2 = getYPositionForNumber(chart, lineEnd.y);

  if (x1 == null || x2 == null || y1 == null || y2 == null) {
    return;
  }

  ctx.save();
  ctx.beginPath();
  if (lineOptions.type === 'dotted') {
    // Make this a dotted line.
    ctx.setLineDash([5]);
  }
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  if (lineOptions.type === 'dotted') {
    ctx.lineWidth = 1;
  } else {
    ctx.lineWidth = 2.5;
  }

  ctx.strokeStyle = lineOptions.color;
  ctx.stroke();
  ctx.restore();
}

function getXPositionForDate(chart, dateToDrawLine) {
  const { scales } = chart;

  const xAxis = scales['x-axis-0'];

  const x = xAxis.getPixelForValue(dateToDrawLine.getTime());

  return x;
}

function getYPositionForNumber(chart, num) {
  const { scales } = chart;

  const yAxis = scales['y-axis-0'];

  const x = yAxis.getPixelForValue(num);

  return x;
}
