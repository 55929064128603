import './style.scss';

const Checkbox = ({ checked, onChange, disabled, ...props }) => {
  const onChangeExt = (e) => onChange(e.target.checked);
  return (
    <div className="checkbox-wrapper">
      <input
        className={checked ? 'intusui-checkbox-checked' : ''}
        type="checkbox"
        checked={checked}
        onChange={onChangeExt}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default Checkbox;
