/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  withRouter,
  Switch,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { useFeatureFlag } from '@util';
import { dateDifference, toUTC, newDate, formatDate } from '@util/dateFunctions';
import { stringToClassname } from '@util/utilFunctions';
import { useGetSessionUser } from '@util/session';

import { getTrackingEvent } from '@api/api';
import { getPatient, getPatientIncidents } from '@global-state/redux/patientDetailSlice';
import { getPatientAcuityInfo } from '@global-state/redux/acuitySlice';
import { getRecentHospitalizations } from '@global-state/redux/hospitalizationsSlice';
import { setRiskFilter } from '@global-state/redux/patientListSlice';
import { getPatientCarePlanInfo } from '@global-state/redux/carePlanSlice';
import {
  setPage,
  setCurrPatient,
  getPptRecentStats,
  setPatientList,
} from '@global-state/redux/profileSlice';

import { EHR_COGNIFY, SUBSCRIPTION_ACUITY } from 'Settings/userSettingsPropType';

import Select, { components } from 'react-select';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { Button, TabButton, Icon } from '@intus-ui';
import ProtectedRoute from '@router/ProtectedRoute';
import ProfileCurrentAcuities from 'Participants/acuity/ProfileCurrentAcuities';
import RiskTrendline from 'Participants/acuity/riskTrendLine/RiskTrendline';
import Hospitalizations from 'Dashboard/hospitalizations/RecentHospitalizations';
import ChangesTimelineView from 'Participants/Timeline';
import FallAnalysis from 'Participants/Falls';
import { RiskTrendline as RiskTrendlineV2 } from 'Participants/RiskTrendline';
import Admits from 'Participants/Admits/Admits';
import { Medications } from 'Participants/Medications/Medications';
import ActionItems from '../../Meetings/ActionItems';
import profileSubjectPropType from './profileSubjectPropType';
import ProfileCurrentCarePlan from '../CarePlan/ProfileCurrentCarePlan';

import '@intus-ui/styles/Utilities.scss';
import '@intus-ui/styles/Profile.scss';

import { MeetingContext } from '../../Meetings/context/MeetingContext';

const DropdownIndicator = (props) => {
  const menu = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <div className="flex-row align-items-center">
        {menu.selectProps.menuIsOpen ? (
          <KeyboardArrowUpOutlinedIcon className="base-white align-top" />
        ) : (
          <KeyboardArrowDownOutlinedIcon className="base-white d-flex" />
        )}
      </div>
    </components.DropdownIndicator>
  );
};

// eslint-disable-next-line react/prop-types
const ValueContainer = ({ children, state: _state, ...props }) => {
  const menu = props;
  return (
    components.ValueContainer && (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.ValueContainer {...props}>
        {children && menu.selectProps.menuIsOpen ? (
          <Icon name="search" color="#4d7ef8" />
        ) : (
          <Icon name="person" color="#4d7ef8" />
        )}
        <div className="pl-1">{children}</div>
      </components.ValueContainer>
    )
  );
};

const Profile = ({
  subject,
  onBack,
  doSetPage,
  page,
  patientList,
  currPatient,
  history,
  doSetCurrPatient,
  doGetPatient,
  doGetPatientAcuityInfo,
  doGetPatientCarePlanInfo,
  doGetPatientHospitalizations,
  doSetRiskFilter,
  doGetPatientIncidents,
  doGetPatientStats,
  doSetPatientList,
  statTime,
}) => {
  const { meeting, nextAgendaItem, previousAgendaItem, setAgendaItem } = useContext(MeetingContext);

  // Formats data from the subject.
  const acuityLevels = [...subject.acuityLevels];
  acuityLevels.sort((a, b) => dateDifference(b.createdAt, a.createdAt));

  const profileInfo = {
    name: `${subject.firstName} ${subject.lastName}`,
  };
  const { organization } = useGetSessionUser();
  const { subscriptions } = organization;
  const isCognifySubscriber = subscriptions.includes(EHR_COGNIFY);

  // Configures the pages shown in the profile.
  let pages = [];

  const { featureIsActive: meetingFeatureIsActive } = useFeatureFlag('meeting-feature');
  const { featureIsActive: newRiskTrendlineIsActive } = useFeatureFlag('new-risk-trendline');
  const { featureIsActive: fallAnalysisIsActive } = useFeatureFlag('falls-analytics-tab');
  // using polypharmacy feature flag since this is only being release to scan
  const { featureIsActive: polypharmacyIsActive } = useFeatureFlag('polypharmacy');
  // const { featureIsActive: warningSignalsIsActive } = useFeatureFlag('warning-signals');

  const { featureIsActive: admitsIsActive } = useFeatureFlag('admits');

  const { url } = useRouteMatch();
  const { patientId, tabName } = useParams();

  const defaultPages = [
    {
      name: organization.id === 200 ? 'Summary' : 'Current Acuity',
      page: <ProfileCurrentAcuities />,
      url: `/patient/${patientId}/current-acuity`,
    },
    {
      name: 'Risk Trendline',
      page: newRiskTrendlineIsActive ? <RiskTrendlineV2 /> : <RiskTrendline />,
      url: `/patient/${patientId}/risk-trendline`,
    },
  ];
  if (fallAnalysisIsActive) {
    defaultPages.push({
      name: 'Falls',
      page: <FallAnalysis />,
      url: `/patient/${patientId}/falls`,
    });
  }

  const { href } = window.location;
  const isDevEnv =
    href.includes('localhost') || href.includes('https://intuswebsite-develop.azurewebsites' || href.includes('https://demo.intus.care'));

  let enableCarePlans = isCognifySubscriber;
  if (organization.id === 23) enableCarePlans = isDevEnv;
  // [BL] DISABLE CARE PLANS FOR SCAN DUE TO DATA INTEGRITY ISSUES
  if (organization.id === 200) enableCarePlans = false;

  if (enableCarePlans)
    defaultPages.push({
      name: 'Care Plan',
      page: <ProfileCurrentCarePlan />,
      url: `/patient/${patientId}/care-plan`,
    });

  if (admitsIsActive) {
    defaultPages.push({
      name: 'Admissions',
      page: <Admits />,
      url: `/patient/${patientId}/admits`,
    });
  } else {
    defaultPages.push({
      name: 'Admits',
      page: <Hospitalizations />,
      url: `/patient/${patientId}/admits`,
    });
  }

  if (polypharmacyIsActive) {
    defaultPages.push({
      name: 'Medications',
      page: <Medications />,
      url: `/patient/${patientId}/medications`,
    });
  }
  // if (warningSignalsIsActive)
  //   defaultPages.push({
  //     name: 'Timeline',
  //     page: <ChangesTimelineView />,
  //     url: `/patient/${patientId}/timeline`,
  //   });

  if (meetingFeatureIsActive)
    defaultPages.push({
      name: 'Action Items',
      page: <ActionItems patientId={subject.id} />,
      url: `/patient/${patientId}/action-items`,
    });

  const activeAdmitsPage = admitsIsActive ? Admits : Hospitalizations;
  const setPages = () => {
    pages = [];
    if (subscriptions.includes(SUBSCRIPTION_ACUITY)) {
      pages = defaultPages;
    }
  };
  setPages();

  useEffect(() => {
    let targetTab;
    // SET DEFAULT TAB TO NAVIGATE TO IF THE TARGET IS NOT FOUND
    let targetName = pages[0].name;
    let targetUrl = pages[0].url;

    // CHECK FOR PAGE NAME SET IN REDUX VIA setPage()
    if (page) {
      targetTab = pages.find((p) => p.name === page);
      // CHECK THE REQUESTED URL FOR DEEP LINKS
    } else if (!page) {
      targetTab = pages.find((p) => p.url === url);
    }

    // IF WE FOUND A TAB OBJECT, USE IT
    if (targetTab) {
      targetName = targetTab.name;
      targetUrl = targetTab.url;
    }
    // ONLY UPDATE REDUX IF IT ISN'T SET OR NEEDS TO BE UPDATED
    if (!page || page !== targetName) doSetPage(targetName);
    history.replace(targetUrl);
  }, [url, page]);

  const currentPage = pages.find((p) => p.url === url) || pages[0];
  const [searchVal, setSearchVal] = useState(
    patientList ? patientList[currPatient || 0] : { name: '', id: '-1' }
  );

  const clickDirection = (num) => {
    const newIndex = currPatient + num;

    // CHANGE MEETING AGENDA ITEM
    if (meeting) {
      if (num > 0) nextAgendaItem();
      else previousAgendaItem();
    }

    const { id } = patientList[newIndex];
    doSetCurrPatient(newIndex);
    doGetPatient(id);
    doGetPatientAcuityInfo(id);
    doGetPatientCarePlanInfo(id);
    doGetPatientHospitalizations(id);
    doGetPatientIncidents(id);
    doGetPatientStats(id, statTime);
    getTrackingEvent({
      time: formatDate(toUTC(newDate())),
      page: `Patient ${id} details`,
      action: `Patient ${id} is being viewed`,
    });
    setPages();
    setSearchVal(id);
    history.replace(`/patient/${id}/${tabName}`);
  };

  return (
    <div id="focusView" style={styles.focusView}>
      <div id="subMenuBar" style={styles.subMenuBar}>
        <div style={styles.alignCenter}>
          {!meeting && history.length > 1 && (
            <Button
              onClick={() => {
                doSetPatientList(undefined);
                // for some reason the state of this is remaining when we click on a patient
                // and then click back so we are hard resetting it here
                doSetRiskFilter('All');
                onBack();
              }}
            >
              <Icon name="caret-left" />
              Back
            </Button>
          )}
          <h4 className="font-weight-bold text-secondary px-3">{profileInfo.name}</h4>
        </div>
        <div style={styles.alignCenter}>
          {patientList &&
            (currPatient !== 0 ? (
              <Button
                textOnly
                onClick={() => {
                  clickDirection(-1);
                }}
              >
                <Icon name="caret-left" />
              </Button>
            ) : null)}
          {patientList ? (
            <Select
              components={{
                ValueContainer,
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option}
              isOptionDisabled={(option) => option.name === searchVal.name}
              isOptionSelected={(option) => option.name === searchVal.name}
              noOptionsMessage={() => 'No Results'}
              value={searchVal}
              placeholder={searchVal}
              options={patientList}
              styles={selectStyles}
              onChange={(patient) => {
                // TODO SET CURRENT AGENDA ITEM
                const newIndex = patientList.indexOf(patient);
                const { id } = patientList[newIndex];
                setAgendaItem({ index: newIndex, item: id });
                doSetCurrPatient(newIndex);
                doGetPatient(id);
                doGetPatientAcuityInfo(id);
                doGetPatientCarePlanInfo(id);
                doGetPatientHospitalizations(id);
                doGetPatientStats(id, statTime);
                doGetPatientIncidents(id);
                getTrackingEvent({
                  time: formatDate(toUTC(newDate())),
                  page: `Patient ${id} details`,
                  action: `Patient ${id} is being viewed`,
                });
                setPages();
                setSearchVal(patient);
                history.replace(`/patient/${id}/${tabName}`);
              }}
            />
          ) : null}
          {patientList &&
            (currPatient !== patientList.length - 1 ? (
              <Button
                textOnly
                onClick={() => {
                  clickDirection(1);
                }}
              >
                <Icon name="caret-right" />
              </Button>
            ) : null)}
        </div>
      </div>
      <div id="tabMenuContainer" style={styles.tabContainer}>
        <div id="tabMenu" style={styles.menu}>
          {pages.map((p, index) => {
            return (
              <span
                key={p.name}
                style={styles.menuItem}
                id={`ppt-focus-${stringToClassname(p.name)}`}
              >
                {index !== 0 ? <span className="text-muted px-3">|</span> : null}
                <TabButton
                  name={p.name}
                  active={p === currentPage}
                  hoverText={
                    p === currentPage ? `Currently in ${p.name} tab` : `Select ${p.name} tab`
                  }
                  onClick={() => {
                    history.replace(p.url);
                    doSetPage(p.name);
                    getTrackingEvent({
                      time: formatDate(toUTC(newDate())),
                      page: 'Participants',
                      action: `Clicked ${p.name} tab in Participants page`,
                    });
                  }}
                />
              </span>
            );
          })}
        </div>
        <div id="tabContainer" style={styles.tabContentContainer}>
          <Router>
            <Switch>
              <ProtectedRoute
                path={['/patient/:patientId/:tabName', '/patient/:patientId']}
                component={
                  currentPage.url === `/patient/${patientId}/current-acuity`
                    ? ProfileCurrentAcuities
                    : currentPage.url === `/patient/${patientId}/risk-trendline`
                    ? newRiskTrendlineIsActive
                      ? RiskTrendlineV2
                      : RiskTrendline
                    : currentPage.url === `/patient/${patientId}/care-plan`
                    ? ProfileCurrentCarePlan
                    : currentPage.url === `/patient/${patientId}/falls`
                    ? FallAnalysis
                    : currentPage.url === `/patient/${patientId}/timeline`
                    ? ChangesTimelineView
                    : currentPage.url === `/patient/${patientId}/medications`
                    ? Medications
                    : activeAdmitsPage
                }
              />
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  );
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    maxWidth: 240,
    borderRadius: 10,
    cursor: 'pointer',
    height: 40,
    minHeight: 40,
    '&:hover': {
      backgroundColor: '#deebff', // 'rgba(222, 235, 255, 0.9)'
    },
    color: provided.menuIsOpen ? '#97999b' : 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(0, 123, 255,.1)' : 'white',
    color: state.isSelected ? '#97999b' : 'black',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#cce5ff', // '#cad8fd'
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    height: 40,
    minHeight: 40,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 10,
    backgroundColor: '#4d7ef8',
    maxWidth: 40,
    minWidth: 40,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: 38,
    minHeight: 38,
  }),
};

const styles = {
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  focusView: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#F6F7FA',
    padding: '0px 20px 20px 20px',
    gap: 15,
  },
  subMenuBar: {
    display: 'flex',
    paddingTop: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 1rem 3rem rgba(0, 0, 0, .175)',
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
    gap: 10,
  },
  tabContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0',
    minHeight: 0,
    overflowY: 'auto',
    padding: '0 35px 30px 35px',
  },
  menu: { display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 5 },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

Profile.propTypes = {
  subject: profileSubjectPropType.isRequired,
  onBack: PropTypes.func.isRequired,
  page: PropTypes.string,
  doSetPage: PropTypes.func.isRequired,
  patientList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.instanceOf(Object),
  }),
  currPatient: PropTypes.number,
  history: PropTypes.instanceOf(Object).isRequired,
  doSetCurrPatient: PropTypes.func.isRequired,
  doGetPatient: PropTypes.func.isRequired,
  doGetPatientAcuityInfo: PropTypes.func.isRequired,
  doGetPatientCarePlanInfo: PropTypes.func.isRequired,
  doGetPatientHospitalizations: PropTypes.func.isRequired,
  doSetRiskFilter: PropTypes.func.isRequired,
  doGetPatientIncidents: PropTypes.func.isRequired,
  doGetPatientStats: PropTypes.func.isRequired,
  doSetPatientList: PropTypes.func.isRequired,
  statTime: PropTypes.string,
};

Profile.defaultProps = {
  page: undefined,
  location: '',
  patientList: undefined,
  currPatient: 0,
  statTime: undefined,
};

const mapState = (state) => ({
  page: state.profile.page,
  patientList: state.profile.patientList,
  currPatient: state.profile.currPatient,
  statTime: state.profile.statTime,
});

const mapDispatch = (dispatch) => ({
  doSetPage: (name) => dispatch(setPage(name)),
  doSetCurrPatient: (index) => dispatch(setCurrPatient(index)),
  doGetPatient: (id) => dispatch(getPatient(id)),
  doGetPatientAcuityInfo: (id) => dispatch(getPatientAcuityInfo(id)),
  doGetPatientCarePlanInfo: (id) => dispatch(getPatientCarePlanInfo(id)),
  doGetPatientHospitalizations: (id) => dispatch(getRecentHospitalizations(id)),
  doSetRiskFilter: (filter) => dispatch(setRiskFilter(filter)),
  doGetPatientIncidents: (id) => dispatch(getPatientIncidents(id)),
  doGetPatientStats: (id, statTime) => dispatch(getPptRecentStats(id, statTime)),
  doSetPatientList: (item) => dispatch(setPatientList(item)),
});

export default withRouter(connect(mapState, mapDispatch)(Profile));
