/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import riskBreakup from '@util/riskGrouping';

import { textColors } from '@intus-ui/styles/SecondaryColors';
import { getBadge, matchAcuityPoint } from './badges';
import { BADGE_OFFSET } from './Context';

/**
 * Redraws yAxis and adds labels for Acuity and Events
 */
export const removeYAxisLabelsAboveLimit = {
  id: 'yAxisLimit',
  beforeDatasetsDraw(chart, args, options) {
    const {
      ctx,
      scales,
      chartArea: { left, bottom },
    } = chart;
    const { maxAcuity } = options;

    const yValue = scales['y-axis-1'];
    const yAxisLimit = yValue.getPixelForValue(maxAcuity);

    ctx.save();
    ctx.beginPath();

    // DRAW Y-AXIS
    ctx.moveTo(left, yAxisLimit);
    ctx.lineTo(left, yValue.bottom);
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'black';
    ctx.stroke();

    // DRAW Y-AXIS LABELS
    ctx.font = '15px Inter, Arial, sans-serif';
    ctx.fillStyle = textColors.body;
    ctx.textAlign = 'center';

    // MOVE CTX TO DRAW LABELS
    ctx.translate(left - 40, bottom);
    ctx.rotate(Math.PI * -0.5);

    ctx.fillText('Acuity Score', (bottom - yAxisLimit) * 0.5, 0);
    ctx.fillText('Events', bottom - yAxisLimit * 0.5, 0);

    ctx.restore();
  },
};

/**
 * Draws dotted lines for events
 */
export const drawDottedLines = {
  id: 'drawDottedLines',
  beforeDatasetsDraw(chart, args, options) {
    const {
      ctx,
      scales,
      chartArea: { left, right },
      config: {
        data: { datasets },
      },
    } = chart;
    const { hoveredPoint, selectedPoint } = options;
    const xAxis = scales['x-axis-1'];
    const yAxis = scales['y-axis-1'];

    ctx.save();
    ctx.beginPath();

    const activePoints = [];
    if (hoveredPoint) activePoints.push(hoveredPoint.x);
    if (selectedPoint) activePoints.push(selectedPoint.x);

    datasets.forEach((dataset) => {
      if (dataset.lineValue) {
        // DRAW EVENT DATASET HORIZONTAL LINE
        const xPosition = yAxis.getPixelForValue(dataset.lineValue);
        ctx.beginPath();
        ctx.setLineDash([5]);
        ctx.moveTo(left, xPosition);
        ctx.lineTo(right, xPosition);
        ctx.lineWidth = 0.4;
        ctx.strokeStyle = '#9D9D9D';
        ctx.stroke();

        // DRAW EVENT BADGE VERTICAL LINES
        dataset.data.forEach((event) => {
          const yPosition = xAxis.getPixelForValue(event.x);

          // DRAW HOVER / SELECTED VERTICAL LINES
          ctx.beginPath();
          if (activePoints.includes(event.x)) {
            ctx.moveTo(yPosition, yAxis.bottom);
            ctx.lineTo(yPosition, yAxis.getPixelForValue(dataset.lineValue));
            ctx.lineWidth = 0.8;
            ctx.strokeStyle = 'black';
          } else {
            // STANDARD VERTICAL LINES
            ctx.moveTo(yPosition, yAxis.bottom);
            ctx.lineTo(yPosition, yAxis.getPixelForValue(dataset.lineValue));
            ctx.lineWidth = 0.4;
            ctx.strokeStyle = '#9D9D9D';
          }
          ctx.stroke();
        });
      }
    });

    ctx.stroke();

    ctx.restore();
  },
};

/**
 * Colors the background of the chart area.
 * Colors correspond to risk grouping used in the rest of the app.
 * Add in labels corresponding to acuity groups
 */
export const backgroundChartArea = {
  id: 'backgroundChartArea',
  beforeDatasetsDraw(chart, args, options) {
    const {
      ctx,
      chartArea,
      scales,
      config: {
        data: { datasets },
      },
    } = chart;
    const { maxAcuity } = options;
    const { bottom, left, right } = chartArea;
    const yValue = scales['y-axis-1'];

    const maxYPixelValue = yValue.getPixelForValue(maxAcuity);
    const lowRiskPixelValue = yValue.getPixelForValue(riskBreakup.MODERATE.lowerLimit - 1);
    const moderateRiskPixelValue = yValue.getPixelForValue(riskBreakup.HIGH.lowerLimit - 1);
    const highRiskPixelValue = yValue.getPixelForValue(riskBreakup.HIGHEST.lowerLimit - 1);

    if (!chartArea) {
      return null;
    }

    const canvas = ctx.canvas.getContext('2d');
    canvas.save();

    // ACUITY LABELS
    canvas.font = 'bold 10px Inter, Arial, sans-serif';
    canvas.textAlign = 'right';

    const textOffset = right - 5;

    // LOW ACUITY
    canvas.fillStyle = riskBreakup.LOW.mutedColor;
    canvas.fillRect(left, lowRiskPixelValue, right - left, bottom - lowRiskPixelValue);
    canvas.fillStyle = '#9D9D9D';
    canvas.fillText('Low', textOffset, bottom - 10);

    // MODERATE ACUITY
    canvas.fillStyle = riskBreakup.MODERATE.mutedColor;
    canvas.fillRect(
      left,
      moderateRiskPixelValue,
      right - left,
      lowRiskPixelValue - moderateRiskPixelValue
    );
    canvas.fillStyle = '#9D9D9D';
    canvas.fillText('Moderate', textOffset, lowRiskPixelValue - 10);

    // HIGH ACUITY
    canvas.fillStyle = riskBreakup.HIGH.mutedColor;
    canvas.fillRect(
      left,
      highRiskPixelValue,
      right - left,
      moderateRiskPixelValue - highRiskPixelValue
    );
    canvas.fillStyle = '#9D9D9D';
    canvas.fillText('High', textOffset, moderateRiskPixelValue - 10);

    // HIGHEST ACUITY
    canvas.fillStyle = riskBreakup.HIGHEST.mutedColor;
    canvas.fillRect(left, maxYPixelValue, right - left, highRiskPixelValue - maxYPixelValue);
    canvas.fillStyle = '#9D9D9D';
    canvas.fillText('Highest', textOffset, highRiskPixelValue - 10);

    // EVENT LABELS
    datasets.forEach((dataset) => {
      if (dataset.lineValue) {
        canvas.fillText(dataset.label, textOffset, yValue.getPixelForValue(dataset.lineValue) - 14);
      }
    });

    canvas.restore();
  },
};

/**
 * Draws acuity dots and event badges
 */
const drawBadges = (chart, x, isActive) => {
  const {
    config: {
      data: { datasets },
    },
  } = chart;
  const xValue = chart.scales['x-axis-1'].getPixelForValue(x);
  const yAxis = chart.scales['y-axis-1'];

  // DRAW ACUITY DOTS
  const index = datasets.findIndex((d) => d.pointType === 'acuityScore');
  const acuity = datasets[index].data.find((o) => o.x === x);

  if (acuity) {
    const acuityIcon = matchAcuityPoint(
      acuity.y,
      {
        MODERATE: yAxis.getPixelForValue(riskBreakup.MODERATE.lowerLimit),
        HIGH: yAxis.getPixelForValue(riskBreakup.HIGH.lowerLimit),
        HIGHEST: yAxis.getPixelForValue(riskBreakup.HIGHEST.lowerLimit),
      },
      isActive
    );

    chart.ctx.drawImage(acuityIcon, xValue - 8, yAxis.getPixelForValue(acuity.y) - 8);
  }

  // DRAW EVENTS
  datasets
    .filter((dataset) => dataset.eventType)
    .forEach((dataset) => {
      for (let i = 0; i < dataset.data.length; i++) {
        Object.entries(dataset._meta).forEach((entry) => {
          const point = dataset._meta[entry[0]].data[i];
          if (point._model.x === xValue) {
            const { label } = dataset.data[point._index];
            const badge = getBadge(label, isActive ? 'active' : 'hover');
            const xPosition = xValue - badge.width * 0.5;
            const yPosition = point._model.y - badge.height * 0.5;
            chart.ctx.drawImage(badge, xPosition, yPosition);
          }
        });
      }
    });
};

// ADJUST Y VALUE FOR EVENTS OF THE SAME TYPE ON THE SAME DAY
export const formatBadges = {
  id: 'formatBadges',
  afterUpdate(chart) {
    const { datasets } = chart.config.data;
    datasets
      .filter((dataset) => dataset.eventType)
      .forEach((dataset) => {
        for (let i = 1; i < dataset.data.length; i++) {
          Object.entries(dataset._meta).forEach((entry) => {
            const point = dataset._meta[entry[0]].data[i]._model;
            const previousPoint = dataset._meta[entry[0]].data[i - 1]._model;
            if (point.x === previousPoint.x) {
              point.y = previousPoint.y + BADGE_OFFSET;
              point.controlPointNextY = previousPoint.y + BADGE_OFFSET;
              point.controlPointPreviousY = previousPoint.y + BADGE_OFFSET;
            }
          });
        }
      });
  },
  // DRAW HOVER STATE BADGES
  afterDraw(chart, args, options) {
    const { hoveredPoint, selectedPoint } = options;
    const hoveredDate = hoveredPoint?.date;
    const selectedDate = selectedPoint?.date;
    if (hoveredDate && hoveredDate !== selectedDate) drawBadges(chart, hoveredDate, false);
    if (selectedDate) drawBadges(chart, selectedDate, true);
  },
};
