import { PillMultiselect } from '@intus-ui/components/PillMultiselect/PillMutliselect';
import { uniq } from 'lodash';

export const AdmitTypeFilter = ({ data, filters, setFilters }) => {
  if (!data) return null;
  const uniqueTypes = uniq(data.map((admit) => admit.type));
  if (uniqueTypes.length === 0) return null;

  const inpatientAdmissions = uniqueTypes.filter((type) => type.includes('Inpatient Admissions'));
  const erVisits = uniqueTypes.filter((type) => type.includes('ER Visits'));
  const other = uniqueTypes.includes('Other Admission');
  const categories = [];
  if (erVisits.length) categories.push({ category: 'ER Visits (All)', subcategories: erVisits });
  if (inpatientAdmissions.length)
    categories.push({ category: 'Inpatient Admissions (All)', subcategories: inpatientAdmissions });
  if (other) categories.push({ category: 'Other Admission', subcategories: null });
  return (
    <PillMultiselect
      selectedItems={filters || uniqueTypes}
      allowSelectAll
      categoriesWithSubcategories={categories}
      onChange={(items) => {
        if (items.length === uniqueTypes.length) {
          setFilters(null);
        } else {
          setFilters(items);
        }
      }}
      items={uniq(data.map((admit) => admit.type))}
    />
  );
};
