/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { dateDifference, subtractDate } from '@util/dateFunctions';

import { textColors } from '@intus-ui/styles/SecondaryColors';

import { PROPORTION_VALUE } from './Context';

/**
 * Options specifically formatted for chartjs
 */
export const chartOptions = ({
  maxAcuity,
  earliestDate,
  hoveredPoint,
  selectedPoint,
  setHoveredPoint,
  dateFilter,
}) => {
  // Slightly relax date range, so that the first month shown is just after the y axis (more readable and corresponds with design)
  const amendedStart = dateFilter.start
    ? subtractDate(dateFilter.start, 1, 'day')
    : subtractDate(earliestDate, 1, 'day');

  const startDate = amendedStart.toDate();
  const endDate = dateFilter.end.toDate();
  const monthsCovered = dateDifference(endDate, startDate, 'month');

  const options = {
    onHover: (event, elements) => {
      if (elements.length) {
        const datasetIndex = elements[0]._datasetIndex;

        // IF HOVERED OVER THE ACUITY CHANGES, DO NOTHING
        if (datasetIndex === 0) return;

        const index = elements[0]._index;
        const point = elements[0]._chart.config.data.datasets[datasetIndex].data[index];

        // IGNORE HIDDEN POINTS
        if (point.eventType === 'acuityScore' && !point.visible) {
          event.target.style.cursor = 'default';
          setHoveredPoint();
        } else {
          event.target.style.cursor = 'pointer';
          setHoveredPoint(point);
        }
      } else {
        event.target.style.cursor = 'default';
        if (hoveredPoint) setHoveredPoint();
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    plugins: {
      yAxisLimit: {
        maxAcuity,
      },
      drawDottedLines: {
        hoveredPoint,
        selectedPoint,
      },
      backgroundChartArea: {
        maxAcuity,
      },
      formatBadges: {
        hoveredPoint,
        selectedPoint,
      },
      highlightActiveBadge: {
        hoveredPoint,
      },
    },
    tooltips: {
      backgroundColor: 'white',
      yAlign: 'bottom',
      caretPadding: 15,
      xAlign: 'center',
      bodyFontFamily: "'Inter', 'Arial', sans-serif",
      caretSize: 8,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      callbacks: {
        label() {
          return [
            'This spot marks an update to the',
            'Acuity Risk Model, which is a result',
            'of our algorithm learning. Some',
            'change in score may occur around',
            'this point. Model changes are rare,',
            'but important, as they allow us to',
            'better serve you and your IDT.',
            'Thank you!',
          ];
        },
        labelTextColor() {
          return '#404040';
        },
      },
      filter(tooltipItem) {
        const { datasetIndex } = tooltipItem;
        return datasetIndex === 0;
      },
      enabled: true,
    },
    showLine: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: true,
            display: true,
            drawOnChartArea: false,
            lineWidth: 1,
            color: ['black'],
          },
          type: 'time',
          parsing: false,
          time: {
            unit: 'month',
          },
          ticks: {
            maxRotation: 0,
            min: amendedStart.toDate(),
            max: endDate,
            callback: (value) => {
              // SHOW ALL FOR A YEAR OR LESS
              if (monthsCovered <= 12) return value;

              // TRIM TO ONLY SHOW SOME LABELS FOR ALL TIME
              const skipCount = Math.floor(monthsCovered / 12);
              const monthsSinceStart = dateDifference(value, startDate, 'months');
              if (monthsSinceStart % skipCount === 0) return value;
              return undefined;
            },
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            display: true,
            drawOnChartArea: false,
            lineWidth: 1,
            color: ['black'],
          },
          scaleLabel: {
            display: true,
            labelString: '',
            fontSize: 15,
            fontColor: textColors.body,
            fontFamily: 'Inter, Arial, sans-serif',
          },
          ticks: {
            beginAtZero: true,
            fontStyle: 'bold',
            max: maxAcuity * PROPORTION_VALUE,
            callback: (value) => {
              if (value > maxAcuity) return undefined;
              return value;
            },
          },
        },
      ],
    },
  };
  return options;
};
