import { getWithoutEncoding } from '@api/apiMethods';
import { buildPatientFilterQueryString } from '@api/patients/buildPatientFilterQueryString';
import { formatDate } from '@util/dateFunctions';

export function getEHRBenchmark(startDate, endDate, filters) {
  const patientFilters = buildPatientFilterQueryString(filters);

  const urlParams = new URLSearchParams(patientFilters);
  urlParams.set('startDate', formatDate(startDate, 'YYYY-MM-DD'));
  urlParams.set('endDate', formatDate(endDate, 'YYYY-MM-DD'));

  return getWithoutEncoding(`benchmark?${urlParams.toString()}`);
}
