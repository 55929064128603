import SortIcon from '../../List/SortIcon';
import StatusDropdown from '../filters/StatusDropdown';

/**
 * A list of all possible fields with their corresponding names, styles, and addOns
 * columnConfig selects specific fields from this to display

 */
export const format = [
  {
    field: 'name',
    name: 'User Name',
    flex: 3,
    bg: 'lightpink',
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
  },
  {
    field: 'statusNode',
    name: 'Status',
    flex: 3,
    bg: 'lightyellow',
    align: 'end',
    addOns: [{ type: 'sort', element: <StatusDropdown key="statusDropdown" /> }],
  },
  {
    field: 'access',
    name: 'Access',
    flex: 3,
    bg: 'lightgreen',
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="access" id="access" /> }],
  },
  {
    field: 'password',
    name: 'Reset Password',
    flex: 3,
    bg: 'lightblue',
    align: 'center',
  },
  {
    field: 'settings',
    name: 'Settings',
    flex: 3,
    bg: 'green',
    align: 'center',
  },
];
