import { useEffect } from 'react';
import { useLazyQuery } from '@api/useQuery';
import { getPolypharmacySummary } from '@api';
import { SpinnerOrError } from '@intus-ui';
import { PieChart } from './components';

import styles from './styles';

const Summary = ({ filters }) => {
  const { data, error, loading, runQuery } = useLazyQuery(() => getPolypharmacySummary(filters));

  useEffect(() => {
    runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  if (loading || error || !data)
    return <SpinnerOrError loading={loading} error={error && 'An error occured'} />;

  const chartContainer = {
    display: 'flex',
    gap: 30,
    margin: 10,
    justifyContent: 'center',
    width: '100%',
  };

  return (
    <div style={{ ...styles.grid, padding: 10 }}>
      <div style={chartContainer}>
        <PieChart title="Top 10 Filled Meds By Volume" data={data?.top10FilledMedsByVolume} />
        <PieChart title="Polypharmacy by Age Breakdown" data={data?.tenPlusMedsByAge} />{' '}
      </div>
      <div style={chartContainer}>
        <PieChart title="Polypharmacy by Gender" data={data?.tenPlusMedsByGender} />
        <PieChart title="Unique Med Count by Pt Breakdown" data={data?.uniqueMedCount} />{' '}
      </div>
    </div>
  );
};

export default Summary;
