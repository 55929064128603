import React, { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import * as userTracking from '@util/userTracking';

import { toUTC, newDate, formatDate } from '@util/dateFunctions';

import { getTrackingEvent } from '@api/api';
import { setCurrentDash } from '@global-state/redux/dashboardSlice';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Icon } from '@intus-ui';
import { CUSTOM_DASH } from 'Dashboard/dashDataPropTypes/currentDashPropType';
import {
  createDashboard,
  saveDashboard,
  selectAllCustomDashboards,
  selectCustomDashNames,
} from 'Dashboard/normalizedDashRedux/customDashboardSlice';
import { useHistory } from 'react-router-dom';
import DashMenuContext from './DashMenuContext';
import ButtonToForm from './ButtonToForm';
import ExpandableTitle from './ExpandableTitle';

const CustomDashboards = ({
  currentDash,
  doSetCurrentDash,
  doCreateDashboard,
  expanded,
  toggleEditMode,
  sectionCheckboxes,
  // updateDashboard,
}) => {
  const { searchVal, closeMenu } = useContext(DashMenuContext);
  const allDashboards = useSelector(selectAllCustomDashboards);
  const dashboardsFiltered = (allDashboards || []).filter(({ name }) =>
    name?.toLowerCase().includes(searchVal.toLowerCase())
  );
  const history = useHistory();

  const dashNames = useSelector(selectCustomDashNames);
  const schema = Yup.object().shape({
    input: Yup.mixed()
      .default('')
      .notOneOf([...dashNames], 'No duplicate names allowed'),
  });

  const newDash = (newDashName) => {
    doCreateDashboard({ name: newDashName });
    history.push('/dashboard');
  };

  // const editDashName = (id, newDashName) => {
  //   updateDashboard({ id, changes: { name: newDashName } });
  // };

  // Function that takes a dashName as input and returns the associated indicators
  const getDashIndicators = (name) => {
    const dash = Object.values(sectionCheckboxes).find((obj) => obj.dashName === name);
    let indicatorList;
    if (dash) {
      indicatorList = dash.indicators.map((obj) => obj.displayTitle);
    }
    return indicatorList;
  };

  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
      <ExpandableTitle
        title="Custom Dashboards"
        menuType="custom"
        icon={<Icon name="dashboard" className="mx-2" />}
        listLength={dashboardsFiltered?.length}
      />
      {expanded && (
        <div className="col-12 w-100">
          <div className="row-12">
            <ButtonToForm
              buttonTitle="New Custom Dashboard"
              buttonIcon={<AddRoundedIcon />}
              validationSchema={schema}
              className="text-white pl-4"
              onSubmit={(newDashName) => {
                newDash(newDashName);
                getTrackingEvent({
                  time: formatDate(toUTC(newDate())),
                  page: 'Custom Dashboard in Hamburgermenu',
                  action: `New Custom Dashboard ${newDashName} created`,
                });
                closeMenu();
                toggleEditMode(true);
              }}
            />
          </div>
          <div className="row-12">
            {dashboardsFiltered.map(({ id, name }) => (
              <button
                key={id}
                className={` m-0 text-left w-100 py-2 pl-4 border-0 outline-none dash-menu-option ${
                  currentDash === id
                    ? 'font-weight-bold bg-white text-secondary dash-menu-option-focus'
                    : 'text-white'
                }`}
                type="button"
                onClick={() => {
                  doSetCurrentDash({ id, type: CUSTOM_DASH });
                  history.push('/dashboard');
                  userTracking.logEvent(`Dashboards: Viewed Custom dashboard`, { name, id });
                  getTrackingEvent({
                    time: formatDate(toUTC(newDate())),
                    page: 'Custom Dashboards',
                    action: `${name} Custom Dashboard Loaded`,
                    dashIndicators: getDashIndicators(name),
                  });
                  closeMenu();
                }}
              >
                {name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

CustomDashboards.propTypes = {
  currentDash: PropTypes.number.isRequired,
  doSetCurrentDash: PropTypes.func.isRequired,
  // updateDashboard: PropTypes.func.isRequired,
  doCreateDashboard: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  sectionCheckboxes: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapState = (state) => ({
  currentDash: state.dashboard.currentDash.id,
  expanded: state.dashboard.dashMenuExpanded.custom,
  sectionCheckboxes: state.spreadsheetModal.sectionCheckboxes,
});

const mapDispatch = (dispatch) => ({
  doSetCurrentDash: (dashInfo) => dispatch(setCurrentDash(dashInfo)),
  updateDashboard: (update) => dispatch(saveDashboard(update)),
  doCreateDashboard: (body) => dispatch(createDashboard(body)),
});

export default connect(mapState, mapDispatch)(CustomDashboards);
