import { isPlainObject } from 'lodash';
import React, { createContext, useState } from 'react';

export const MeetingContext = createContext({});

const MeetingProvider = ({ children }) => {
  // MEETING OBJECT
  const [meeting, setMeeting] = useState();

  // CURRENT MEETING AGENDA ITEM STATE FOR TIMER
  const [currentAgendaItem, setCurrentAgendaItem] = useState({});
  // {
  //   patientId: meeting.agendaItems[0],
  //   index: 0,
  // }

  // MEETING TIMER STATE: INIT, RUNNING, PAUSED, STOPPED
  const [runState, setRunState] = useState('INIT');

  // TIMER METADATA STATE
  const [timerState, setTimerState] = useState({});

  const [warning, setWarning] = useState(false);
  const [overtime, setOvertime] = useState(false);

  const setAgendaItem = (index) => {
    if (isPlainObject(index)) setCurrentAgendaItem(index);
    else setCurrentAgendaItem({ index, item: meeting?.agendaItems[index] });
  };

  const nextAgendaItem = () => {
    // TODO ADD VALIDATION
    setAgendaItem(currentAgendaItem.index + 1);
    // const index = currentAgendaItem.index + 1;
    // setCurrentAgendaItem({ index, item: meeting?.agendaItems[index] });
  };

  const previousAgendaItem = () => {
    // TODO ADD VALIDATION
    setAgendaItem(currentAgendaItem.index - 1);
    // const index = currentAgendaItem.index - 1;
    // setCurrentAgendaItem({ index, patientId: meeting?.agendaItems[index] });
  };

  return (
    <MeetingContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        meeting,
        setMeeting,
        currentAgendaItem,
        setCurrentAgendaItem,
        runState,
        setRunState,
        timerState,
        setTimerState,
        warning,
        setWarning,
        overtime,
        setOvertime,
        setAgendaItem,
        nextAgendaItem,
        previousAgendaItem,
      }}
    >
      {children}
    </MeetingContext.Provider>
  );
};

export default MeetingProvider;
