import React, { useState, useEffect, useContext, createRef } from 'react';
import { FormCheck } from 'react-bootstrap';
import { getActionItems, createActionItem, updateActionItem, deleteActionItem } from '@api';

import { Text } from '@intus-ui/index';
import ActionItemCard from './ActionItems/ActionItemCard';
import ActionItemForm from './ActionItems/ActionItemForm';

import { MeetingContext } from './context/MeetingContext';

import './ActionItems/ActionItemsTab.css';

const ActionItems = ({ patientId }) => {
  const { meeting } = useContext(MeetingContext);

  const [actionItems, setActionItems] = useState();
  const [editing, setEditing] = useState(false);
  const [indexEditing, setIndexEditing] = useState(-1);
  const [description, setDescription] = useState('');
  const [note, setNote] = useState('');
  const [showComplete, setShowComplete] = useState(false);

  const checkRef = createRef();

  useEffect(() => {
    getItems();
  }, [patientId]);

  const getItems = async () => {
    const response = await getActionItems(patientId);
    const existing = await response.json();
    setActionItems(existing);
  };

  const createItem = async (newDescription, newNote) => {
    if (newDescription !== '') {
      const item = {
        newDescription,
        newNote,
        complete: false,
        patientId,
        // TODO FIX
        meetingId: 1,
      };

      const response = await createActionItem(item);
      const newItem = await response.json();

      // changed this from item to newItem to get id stored
      setActionItems([newItem, ...actionItems]);
    } else {
      // eslint-disable-next-line no-alert
      alert('Description and Notes fields must not be blank');
    }
  };

  const editItem = async (index, editDescription, editNote) => {
    const response = await updateActionItem({ ...actionItems[index], editNote, editDescription });
    const modifiedItem = await response.json();
    setActionItems([
      ...actionItems.slice(0, index),
      modifiedItem,
      ...actionItems.slice(index + 1, actionItems.length),
    ]);
  };

  const deleteItem = async (index) => {
    await deleteActionItem(actionItems[index].id);
    setActionItems([
      ...actionItems.slice(0, index),
      ...actionItems.slice(index + 1, actionItems.length),
    ]);
  };

  const onEditClick = (index) => {
    setDescription(actionItems[index].description);
    setNote(actionItems[index].note);
    setEditing(true);
    setIndexEditing(index);
  };

  const onCompleteClick = async (index) => {
    const response = await updateActionItem({
      ...actionItems[index],
      complete: !actionItems[index].complete,
      completedAt: actionItems[index].complete ? null : Date.now(),
    });
    const modifiedItem = await response.json();
    setActionItems([
      ...actionItems.slice(0, index),
      modifiedItem,
      ...actionItems.slice(index + 1, actionItems.length),
    ]);
    // setActionItems([
    //   ...actionItems.slice(0, index),
    //   {...actionItems[index], complete: !actionItems[index].complete},
    //   ...actionItems.slice(index + 1, actionItems.length),
    // ]);
  };

  const clearForm = () => {
    setEditing(false);
    setIndexEditing(-1);
    setDescription('');
    setNote('');
  };
  const hasNoActiveActionItems =
    actionItems?.filter((actionItem) => !actionItem.complete).length === 0;
  return (
    <div
      style={{
        display: 'flex',
        height: '90%',
        margin: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          margin: 10,
          flex: 3,
          overflowY: 'auto',
        }}
      >
        {actionItems && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text type="headline" wrapper="div">
              Active Actions
            </Text>
            <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'row-reverse' }}>
              <FormCheck
                id="completed-switch"
                type="switch"
                label="Show Completed Action Items"
                onChange={(e) => {
                  setShowComplete(e.target.checked);
                  checkRef.current.blur();
                }}
                checked={showComplete}
                ref={checkRef}
              />
            </div>
          </div>
        )}
        <div
          style={{
            disaply: 'flex',
            flexDirection: 'column',
            flex: 2,
            // overflowY: 'auto',
            margin: 5,
          }}
        >
          <div>
            {hasNoActiveActionItems ? (
              <Text type="body">No active action items for participant.</Text>
            ) : (
              <div
                style={{
                  flex: 1,
                }}
              >
                {actionItems?.map(
                  (actionItem, index) =>
                    (!actionItem.complete || actionItem.complete === false) && (
                      <ActionItemCard
                        index={index}
                        description={actionItem.description}
                        complete={actionItem.complete}
                        note={actionItem.note}
                        onEditClick={onEditClick}
                        onCompleteClick={onCompleteClick}
                        editing={index === indexEditing}
                        clearForm={clearForm}
                        createdAt={actionItem.createdAt}
                        completedAt={actionItem.completedAt}
                      />
                    )
                )}{' '}
              </div>
            )}
          </div>
        </div>
        {showComplete && (
          <div>
            <div>
              {actionItems && (
                <Text type="headline" wrapper="div">
                  Completed Actions
                </Text>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 2,
                // overflowY: 'auto',
                margin: 5,
              }}
            >
              <div>
                {actionItems?.filter((actionItem) => actionItem.complete).length === 0 ? (
                  <Text type="body">No completed action items for participant.</Text>
                ) : (
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    {actionItems?.map(
                      (actionItem, index) =>
                        actionItem.complete && (
                          <ActionItemCard
                            index={index}
                            description={actionItem.description}
                            complete={actionItem.complete}
                            note={actionItem.note}
                            onEditClick={onEditClick}
                            onCompleteClick={onCompleteClick}
                            editing={index === indexEditing}
                            clearForm={clearForm}
                            createdAt={actionItem.createdAt}
                            completedAt={actionItem.completedAt}
                          />
                        )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {(editing || meeting) && (
        <div style={{ flex: 1, margin: 10 }}>
          <ActionItemForm
            createActionItem={createItem}
            editActionItem={editItem}
            description={description}
            setDescription={setDescription}
            note={note}
            setNote={setNote}
            editing={editing}
            setEditing={setEditing}
            indexEditing={indexEditing}
            setIndexEditing={setIndexEditing}
            deleteActionItem={deleteItem}
          />
        </div>
      )}
    </div>
  );
};

export default ActionItems;
