import { useState, useEffect } from 'react';

/**
 * Custom Hook for delaying a mount/unmount on a component.
 * This is useful for components that could use an animation while mounting/unmounting.
 * @param {Number} mountTime milliseconds
 * @param {Number} unmountTime milliseconds
 */
// eslint-disable-next-line default-param-last
const useDelayedMountUnmount = (mountTime = 1000, unmountTime) => {
  const [state, setState] = useState('unmounted');

  const show = () => {
    if (state === 'unmounting') return;
    setState('mounting');
  };
  const hide = () => {
    if (state === 'mounting') return;
    setState('unmounting');
  };

  useEffect(() => {
    let timeoutId;
    if (state === 'unmounting')
      timeoutId = setTimeout(() => setState('unmounted'), unmountTime || mountTime);
    else if (state === 'mounting') timeoutId = setTimeout(() => setState('mounted'), mountTime);

    return () => clearTimeout(timeoutId);
  }, [state, mountTime, unmountTime]);

  return [state, show, hide];
};

export default useDelayedMountUnmount;
