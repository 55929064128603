import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSessionUser } from '@util/session';

import { indicatorsReset } from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';

import currentDashPropType, { CUSTOM_DASH } from 'Dashboard/dashDataPropTypes/currentDashPropType';
import { USER_ACCESS_ADMIN } from 'Settings/userSettingsPropType';

import TrashIcon from 'assets/Icons/Trash';
import EditIcon from 'assets/Icons/Edit';

const EditDeleteButtons = ({
  editMode,
  resetIndicators,
  currentDashboard,
  toggleEditMode,
  leaderboard,
  showDeleteDashModal,
  expanded,
}) => {
  const isAdmin = getSessionUser().access === USER_ACCESS_ADMIN;
  return (
    <div
      style={{ top: '1rem', left: 'calc(32.5px - 1rem)', zIndex: 2 }}
      className="d-flex flex-column justify-content-center align-items-center position-absolute"
    >
      {(currentDashboard.type === CUSTOM_DASH || isAdmin) && (
        <>
          <SideMenuButton
            onClick={() => {
              if (editMode) resetIndicators();
              toggleEditMode();
            }}
            variant="secondary"
            disabled={leaderboard}
            title="Toggle Edit Dashboard"
          >
            <EditIcon expanded={expanded} />
          </SideMenuButton>
          <SideMenuButton
            title="Delete Dashboard"
            onClick={showDeleteDashModal}
            variant="danger"
            disabled={!editMode || leaderboard}
          >
            <TrashIcon expanded={false} />
          </SideMenuButton>
        </>
      )}
    </div>
  );
};

EditDeleteButtons.propTypes = {
  editMode: PropTypes.bool.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  leaderboard: PropTypes.bool.isRequired,
  showDeleteDashModal: PropTypes.func.isRequired,
  resetIndicators: PropTypes.func.isRequired,
  currentDashboard: currentDashPropType.isRequired,
  expanded: PropTypes.bool.isRequired,
};

const mapState = (state) => ({
  currentDashboard: state.dashboard.currentDash,
});

const mapDispatch = (dispatch) => ({
  resetIndicators: () => dispatch(indicatorsReset()),
});

export default connect(mapState, mapDispatch)(EditDeleteButtons);

export const SideMenuButton = ({ children, onClick, disabled, variant, title }) => {
  const opacity = disabled ? 0 : 1;
  return (
    <button
      className={`${
        !disabled && variant ? `text-${variant}` : ''
      } bg-transparent border-0 hover-light-blue rounded outline-none smoother`}
      disabled={disabled}
      onClick={onClick}
      type="button"
      style={{ padding: '2px', marginBottom: '.5rem', opacity }}
      title={title}
    >
      {children}
    </button>
  );
};

SideMenuButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SideMenuButton.defaultProps = {
  children: null,
  disabled: false,
  variant: undefined,
};
