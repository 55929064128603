import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useSubscriptions } from '@util';
import { toUTC, newDate, formatDate } from '@util/dateFunctions';

import { getTrackingEvent } from '@api/api';
import * as userTracking from '@util/userTracking';

import { Icon } from '@intus-ui';
import { premiumDashboards } from 'Dashboard/premiumDashNames';
import { getFeatureFlag } from '@config';
import DashMenuContext from './DashMenuContext';
import ExpandableTitle from './ExpandableTitle';

const PremiumDashboards = () => {
  const history = useHistory();

  const currentDashId = useSelector((state) => state.dashboard.currentDash.id);
  const expanded = useSelector((state) => state.dashboard.dashMenuExpanded.premium);

  const subscriptions = useSubscriptions();
  const [shouldNotIncludeFinancials, setShouldNotIncludeFinancials] = useState(
    subscriptions?.includes('EHR_PROVIDES_NO_FINANCIALS')
  );
  useEffect(() => {
    setShouldNotIncludeFinancials(subscriptions?.includes('EHR_PROVIDES_NO_FINANCIALS'));
  }, [subscriptions]);

  const { searchVal, closeMenu } = useContext(DashMenuContext);
  const filteredDashboards = premiumDashboards.filter((dash) => {
    if (shouldNotIncludeFinancials && dash.name === 'Financial') {
      return false;
    }

    // For dashboards behind feature flags, make sure the user has the flag enables.
    if (dash.featureFlag != null) {
      const feature = getFeatureFlag(dash.featureFlag);

      if (!feature || !feature.featureIsActive) {
        return false;
      }
    }

    return dash.name.toLowerCase().includes(searchVal.toLowerCase());
  });

  return (
    <div className="w-100 h-100 d-flex flex-column text-white overflow-hidden">
      <ExpandableTitle
        title="Analytics Dashboards"
        menuType="premium"
        icon={<Icon name="chart" className="mx-2" />}
        listLength={filteredDashboards?.length}
      />
      <div className="col-12">
        {expanded &&
          filteredDashboards.map((dash) => (
            <button
              key={dash.id}
              className={` m-0 text-left w-100 py-2 pl-4 border-0 outline-none dash-menu-option ${
                currentDashId === dash.id
                  ? 'font-weight-bold bg-white text-secondary dash-menu-option-focus'
                  : 'text-white'
              }`}
              type="button"
              onClick={() => {
                const { route } = dash;
                history.push(route);
                userTracking.logEvent(`Dashboards: Viewed Premium dashboard`, {
                  name: dash.name,
                  id: dash.id,
                });
                getTrackingEvent({
                  time: formatDate(toUTC(newDate())),
                  page: dash.name,
                  action: `${dash.name} Dashboard Loaded`,
                });
                closeMenu();
              }}
            >
              {dash.name}
            </button>
          ))}
      </div>
    </div>
  );
};

export default PremiumDashboards;
