/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { arrayEquals, toSentenceCase, searchFilter, useFeatureFlag } from '@util';
import { useGetSessionUser } from '@util/session';

import { useGetActionCohortsQuery, useGetCohortsQuery } from '@api';

import { Container, Row, Col, Form, FormControl } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SimpleTooltip from '@intus-ui/components/SimpleTooltip';
import FormikFormControl from '@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';
import { Button } from '@intus-ui';
import TreeList from '@intus-ui/components/lists/TreeList/TreeList';
import {
  indicatorsReset,
  selectDashIndicatorIds,
} from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';
import {
  saveDashboard,
  selectDashNameById,
  selectCustomDashNames,
} from 'Dashboard/normalizedDashRedux/customDashboardSlice';
import currentDashPropType, { ORG_DASH } from 'Dashboard/dashDataPropTypes/currentDashPropType';
import { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import DraggableListItem from './DraggableListItem';
import DragContext from './DragContext';
import { ACTION_COHORT_ID, SHARED_COHORT_ID, PERSONAL_COHORT_ID } from './cohortsCustomDashIds';

const sortByStrProperty = (list, property) => {
  list.sort((a, b) => {
    if (a[property].toLowerCase() > b[property].toLowerCase()) return 1;
    if (a[property].toLowerCase() < b[property].toLowerCase()) return -1;
    return 0;
  });
};

const nonCamelCaseFolders = {
  'ER Admissions': 'ER Admissions',
  nonElectiveInpatient: 'Non-Elective Inpatient',
};

const formatFolderName = (name) => nonCamelCaseFolders[name] || toSentenceCase(name);

// Formatting cohorts to match custom indicators data format
const formatCohortToFolder = (cohort) => {
  const participantList = cohort.result.participantIds;
  let cohortDashboardItem;
  if (cohort) {
    let cohortSubType;
    let cohortId;
    if (cohort.shared === undefined) {
      cohortSubType = 'action';
      cohortId = cohort.id + ACTION_COHORT_ID;
    } else if (cohort.shared === true) {
      cohortSubType = 'shared';
      cohortId = cohort.id + SHARED_COHORT_ID;
    } else if (cohort.shared === false) {
      cohortSubType = 'personal';
      cohortId = cohort.id + PERSONAL_COHORT_ID;
    }
    cohortDashboardItem = {
      dashboardType: 'cohort',
      description: cohort.description,
      displayTitle: cohort.name,
      subType: cohortSubType,
      participants: participantList,
      isMM: undefined,
      id: cohortId,
      fetchInfo: {
        id: cohortId,
        dashboardType: cohortSubType,
        description: cohort.description,
      },
      value: participantList.length, // if this is undefined the cohort will show up as 'loading' when dragged onto the dashboard
    };
  }
  return cohortDashboardItem;
};

const DraggableList = ({
  resetIndicators,
  currDash,
  updateDashboard,
  initialIndicators,
  filters,
}) => {
  const { toggleEditMode, editMode } = useContext(DragContext);
  const { featureIsActive } = useFeatureFlag('cohorts-in-custom-dash');
  const { patientFilters } = filters;
  const { access } = useGetSessionUser();
  const allIndicators = useSelector((state) => state.dashboard.allIndicators);
  const isHealthSystemAdmin = access === USER_ACCESS_HEALTH_SYS_ADMIN;

  if (featureIsActive) {
    const {
      data: actionCohorts = [],
      // isError,
      // isLoading,
      // refetch,
    } = useGetActionCohortsQuery({ filters: patientFilters });
    const {
      data: personalCohorts = [],
      // isError,
      // isLoading,
      // refetch,
    } = useGetCohortsQuery({ filters: patientFilters });
    const {
      data: sharedCohorts = [],
      // isError,
      // isLoading,
      // refetch,
    } = useGetCohortsQuery({ shared: true, filters: patientFilters });

    // formatting personal cohorts
    const formattedPersonalCohorts = personalCohorts.map((cohort) => formatCohortToFolder(cohort));
    // formatting shared cohorts
    const formattedSharedCohorts = sharedCohorts.map((cohort) => formatCohortToFolder(cohort));
    // formatting action cohorts
    const formattedActionCohorts = actionCohorts.map((cohort) => formatCohortToFolder(cohort));
    if (
      featureIsActive &&
      formattedActionCohorts &&
      formattedPersonalCohorts &&
      formattedSharedCohorts &&
      !isHealthSystemAdmin
    ) {
      allIndicators.cohorts.Action = formattedActionCohorts; // change action back to lowercase for style
      allIndicators.cohorts.Personal = formattedPersonalCohorts;
      allIndicators.cohorts.Shared = formattedSharedCohorts;
    }
  }

  const dashNames = useSelector(selectCustomDashNames);
  const currDashName = useSelector(selectDashNameById(currDash.id));
  const activeIndicatorIds = useSelector(selectDashIndicatorIds);
  let noChangesMade = true;
  if (
    !arrayEquals(
      initialIndicators.map(({ id }) => id),
      activeIndicatorIds
    )
  ) {
    noChangesMade = false;
  }

  const schema = Yup.object().shape({
    newDashName: Yup.mixed().default('').notOneOf(dashNames, 'No duplicate names allowed'),
  });

  const buildFolders = (nodes) =>
    Object.entries(nodes || {})
      .map(([key, value]) => {
        const formattedKey = formatFolderName(key);
        if (Array.isArray(value)) {
          const folderNodes = value
            .map(({ fetchInfo, displayTitle, description, id, isMM, participants }) => ({
              id,
              displayTitle,
              description,
              isMM,
              fetchInfo,
              participantIds: participants,
              value: participants?.length,
            }))
            .filter(({ id }) => !activeIndicatorIds.includes(id));
          sortByStrProperty(folderNodes, 'displayTitle');
          return { id: formattedKey, title: formattedKey, nodes: folderNodes };
        }
        const recursiveBuild = buildFolders(value);
        sortByStrProperty(recursiveBuild, 'title');
        return { id: formattedKey, title: formattedKey, nodes: recursiveBuild };
      })
      .filter((item) => !!item.nodes.length);

  // Adding cohorts to all indicators list

  const folders = buildFolders(allIndicators);
  sortByStrProperty(folders, 'title');

  const allIndicatorsFlat = [
    ...allIndicators.census,
    ...allIndicators.clinical,
    ...allIndicators.financial,
    ...allIndicators.demographics,
    ...allIndicators.incidents.Burns,
    ...allIndicators.incidents.Falls,
    ...allIndicators.incidents.Infections,
    ...allIndicators.incidents['Medicine Errors'],
    ...allIndicators.hospitalizations['ER Visits'],
    ...allIndicators.hospitalizations['ER/Inpatient Combined'],
    ...allIndicators.hospitalizations['Inpatient Admissions'],
  ];

  if (featureIsActive) {
    const cohortsIndicatorsFlat = [
      ...allIndicators.cohorts.Shared,
      ...allIndicators.cohorts.Action,
      ...allIndicators.cohorts.Personal,
    ];
    allIndicatorsFlat.push(...cohortsIndicatorsFlat);
  }

  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredIndicators, setFilteredIndicators] = useState(allIndicatorsFlat);

  const opacity = editMode ? 1 : 0;

  const handleSearch = (e) => {
    const keyword = e ? e.target.value : searchKeyword;
    const filterResults = searchFilter(keyword, allIndicatorsFlat, 'displayTitle').filter(
      ({ id }) => !activeIndicatorIds.includes(id)
    );
    setFilteredIndicators(filterResults);
    setSearchKeyword(keyword);
  };

  useEffect(() => {
    handleSearch();
  }, [activeIndicatorIds]);

  return (
    <Container className="m-0 p-0 w-100 h-100">
      <Row className="m-0 w-100 h-100">
        <Col className="w-100 h-100 smoother" style={{ opacity, zIndex: 1 }}>
          <div className="w-100 h-100 bg-white rounded-xlg box-shadow-on-gray d-flex flex-column overflow-hidden">
            <Row className="m-0 pt-3 px-3 text-secondary font-weight-bold">
              <div className="border-bottom border-dark w-100">
                Dashboard Items
                <SimpleTooltip
                  className="d-inline-block"
                  content="Drag items into your custom dashboard."
                >
                  <InfoIcon variant="success" className="info-icon cursor-pointer m-0" />
                </SimpleTooltip>
              </div>
            </Row>
            <Row className="m-0 d-flex flex-grow-1 px-2 overflow-scroll py-2">
              <FormControl
                className="rounded-xlg"
                style={{ margin: 5 }}
                aria-label="Searchbar for indicator list"
                placeholder="Search item"
                value={searchKeyword}
                onChange={handleSearch}
              />
              {searchKeyword === '' ? (
                <TreeList nodes={folders} labelNode={(item) => <DraggableListItem item={item} />} />
              ) : (
                <div className="d-flex flex-column w-100 h-100">
                  {filteredIndicators.map((item) => (
                    <DraggableListItem key={item.id} item={item} />
                  ))}
                </div>
              )}{' '}
            </Row>
            <Row
              className="d-flex flex-shrink-1 flex-row justify-content-center border-top border-light"
              style={{ boxShadow: '0px 0 1em rgba(0, 0, 0, 0.15)', zIndex: 4 }}
            >
              <Formik
                className="m-0"
                validationSchema={schema}
                initialValues={{ newDashName: '' }}
                onSubmit={(values, { resetForm }) => {
                  const update = {
                    id: currDash.id,
                    changes: {
                      indicators: activeIndicatorIds,
                      name: values.newDashName.length ? values.newDashName : currDashName,
                    },
                    orgDash: currDash.type === ORG_DASH,
                  };
                  updateDashboard(update);
                  toggleEditMode();
                  resetForm();
                }}
              >
                {({ errors, values, resetForm, handleSubmit, isSubmitting }) => (
                  <div className="d-flex justify-content-center align-items-center w-100 h-100 pt-2">
                    <Form onSubmit={handleSubmit}>
                      <FormikFormControl
                        placeholder={currDashName}
                        as="span"
                        name="newDashName"
                        className="border-0"
                        label="Edit Dashboard Name"
                      />
                      <div
                        className="d-flex w-100 h-100 flex-row justify-content-center align-items-center"
                        style={{ gap: 10, padding: '10px 0' }}
                      >
                        <Button
                          secondary
                          onClick={() => {
                            toggleEditMode();
                            resetIndicators();
                            resetForm();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          busy={isSubmitting}
                          disabled={
                            (noChangesMade && !values.newDashName.length) ||
                            !!Object.keys(errors).length
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

DraggableList.propTypes = {
  currDash: currentDashPropType.isRequired,
  resetIndicators: PropTypes.func.isRequired,
  updateDashboard: PropTypes.func.isRequired,
  initialIndicators: PropTypes.arrayOf(PropTypes.object),
};

DraggableList.defaultProps = {
  initialIndicators: [],
};

const mapState = (state) => ({
  currDash: state.dashboard.currentDash,
  initialIndicators: state.customDashIndicators.initialIndicators,
});

const mapDispatch = (dispatch) => ({
  resetIndicators: () => dispatch(indicatorsReset()),
  updateDashboard: (update) => dispatch(saveDashboard(update)),
});

export default connect(mapState, mapDispatch)(DraggableList);
