/* eslint-disable prefer-arrow-callback */
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { setEditingCaregiver, saveChanges } from '@global-state/redux/caregiverListSlice';

import { Modal, Form, Col } from 'react-bootstrap';
import { Button, Icon } from '@intus-ui';
import FormikFormControl from '@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';
import caregiverPropType from './caregiverListCaregiverPropType';

const schema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  access: Yup.string().required('Required'),
});

const CaregiverSettingsModal = ({ show, hide, add, passwordReset, selectedCaregiver }) =>
  selectedCaregiver && !passwordReset ? (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Icon name="gear" className="mr-3" />
          Edit {`${selectedCaregiver.firstName} ${selectedCaregiver.lastName}'s`} Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            id: selectedCaregiver.id,
            email: selectedCaregiver.email,
            access: selectedCaregiver.access,
            status: selectedCaregiver.status,
          }}
          onSubmit={(values) => {
            // The modal will be closed once add succeeds, so setSubmitting isn't needed.
            add(values);
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, errors, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <FormikFormControl as={Col} label="Email" type="text" name="email" />
              </Form.Row>
              <Form.Row>
                <FormikFormControl as={Col} md="6" label="Access" controlAs="select" name="access">
                  <option value="ADMIN"> ADMIN </option>
                  <option value="CAREGIVER"> USER </option>
                </FormikFormControl>
                <FormikFormControl as={Col} md="6" label="Status" controlAs="select" name="status">
                  <option value="ACTIVE"> ACTIVE </option>
                  <option value="INACTIVE"> INACTIVE </option>
                </FormikFormControl>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Button
                    type="submit"
                    fluid
                    busy={isSubmitting}
                    disabled={Object.values(errors).length > 0}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  ) : null;

CaregiverSettingsModal.propTypes = {
  passwordReset: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  selectedCaregiver: caregiverPropType,
};

CaregiverSettingsModal.defaultProps = {
  selectedCaregiver: undefined,
};

const mapState = (state) => ({
  show: state.caregiverList.editingCaregiver,
  selectedCaregiver: state.caregiverList.selectedCaregiver,
  passwordReset: state.caregiverList.passwordReset,
});

const mapDispatch = (dispatch) => ({
  hide: () => dispatch(setEditingCaregiver(false)),
  add: (values) => dispatch(saveChanges(values, "'s settings have been successfully updated.")),
});

export default connect(mapState, mapDispatch)(CaregiverSettingsModal);
