import Input from '@intus-ui/components/Input';
import { Multiselect } from '@intus-ui/components/Multiselect/Multiselect';
import { useParticipantFilters } from '@intus-ui/components/filters/ParticipantFiltersContext';
import { PatientGroupFilterList } from '@intus-ui/components/filters/patientGroups/PatientGroupFilterList';
import { getTextWidthInPixels } from '@util/getTextWidthInPixels';
import { useGetSessionUser } from '@util/session';
import { getStringReplacement } from '@util/stringReplacements';
import { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import { useMemo } from 'react';

/**
 * Displays the filters for team, facility, patient groups, and an organization dropdown if a user is a health system admin.
 *
 * @param {{ disabled?: boolean }} props
 */
export const TeamFacilityFilter = (props) => {
  const { disabled, isCohortsPage } = props;

  const user = useGetSessionUser();

  const isHealthSysAdmin = user.access === USER_ACCESS_HEALTH_SYS_ADMIN;

  return (
    <div style={styles.container}>
      {!isHealthSysAdmin && (
        <>
          <TeamFilter {...props} />
          <FacilityFilter {...props} />
          {!isCohortsPage && <CohortFilter {...props} />}
          <PatientGroupFilterList disabled={disabled} />
        </>
      )}
      {isHealthSysAdmin && <OrgFilter disabled={disabled} />}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    // Wrap the filters if we have too many.
    flexWrap: 'wrap',
    padding: '0px 10px 0px 0px',
    gap: 10,
  },
};

const TeamFilter = ({ disabled }) => {
  const { selectedFilters, teams, setTeams } = useParticipantFilters();

  function onChange(selectedTeams) {
    setTeams(selectedTeams);
  }

  if (teams == null || teams.length === 0) {
    return null;
  }

  return (
    <Multiselect
      id="Team"
      label={getStringReplacement('Team')}
      allowSelectAll
      items={teams}
      // selectedFilters.teams being null means all teams are selected
      // empty array means the user unchecked all the teams.
      selectedItems={selectedFilters.teams || teams}
      onChange={(value) => onChange(value)}
      disabled={disabled}
    />
  );
};

const CohortFilter = ({ disabled }) => {
  const { selectedFilters, cohorts, setSelectedCohorts } = useParticipantFilters();

  function onChange(selectedCohortsName) {
    const newlySelectedCohorts = cohorts.filter((cohort) => {
      return selectedCohortsName.includes(cohort.name);
    });
    setSelectedCohorts(newlySelectedCohorts);
  }

  if (cohorts == null || cohorts.length === 0) {
    return null;
  }

  function getCohortsNames(cohorts) {
    return cohorts.map((cohort) => cohort.name);
  }

  const cohortsNames = ['All', ...getCohortsNames(cohorts)];
  return (
    <Input
      fluid
      id="Cohort"
      style={{
        minWidth: 200,
        cursor: 'pointer',
      }}
      name="Cohort Filters"
      type="select"
      options={cohortsNames}
      value={getCohortsNames(selectedFilters.cohorts)[0] || 'All'}
      placeholder="Select Cohort"
      onChange={(value) => onChange(value)}
      rightIcon="caret-down"
      disabled={disabled}
      innerLabel="Cohort:"
    />
  );
};

const FacilityFilter = ({ disabled }) => {
  const { selectedFilters, facilities, setFacilities } = useParticipantFilters();

  function onChange(selectedFacilities) {
    setFacilities(selectedFacilities);
  }

  if (facilities == null || facilities.length === 0) {
    return null;
  }

  return (
    <Multiselect
      id="Facility"
      label={getStringReplacement('Center')}
      allowSelectAll
      items={facilities}
      // selectedFilters.facilities being null means all facilities are selected
      // empty array means the user unchecked all the facilities.
      selectedItems={selectedFilters.facilities || facilities}
      onChange={(value) => onChange(value)}
      disabled={disabled}
    />
  );
};

const OrgFilter = ({ disabled }) => {
  const { selectedFilters, healthSystemAdminOrganizations, setSelectedOrg } =
    useParticipantFilters();

  const orgMap = useMemo(() => {
    const map = { All: 'All' };

    healthSystemAdminOrganizations.forEach(({ id, name }) => {
      map[id] = name;
    });

    return map;
  }, [healthSystemAdminOrganizations]);

  // Change the organization objects to use label as a field instead of name so
  // they can be used in the dropdown.
  const orgsForDropdown = useMemo(() => {
    return [
      'All',
      ...healthSystemAdminOrganizations.map((org) => {
        return { id: org.id, label: org.name };
      }),
    ];
  }, [healthSystemAdminOrganizations]);

  const selectedOrgText = orgMap[selectedFilters.organization] || 'All';

  // Calculate the width of the dropdown based on the current text.
  const width = useMemo(() => {
    const textWidth =
      getTextWidthInPixels(`Org: ${selectedOrgText}`, {
        fontSize: 15,
        fontWeight: 'bold',
      }) + 55;

    if (textWidth < 200) {
      return 180;
    }

    return textWidth;
  }, [selectedOrgText]);

  return (
    <Input
      id="OrgSelector"
      type="select"
      rightIcon="caret-down"
      innerLabel="Org:"
      name="OrgSelector"
      options={orgsForDropdown}
      value={selectedOrgText}
      onChange={(value) => setSelectedOrg(value)}
      style={{
        ...dropdownStyles.input,
        width,
        maxWidth: width,
      }}
      disabled={disabled}
    />
  );
};

const dropdownStyles = {
  input: {
    cursor: 'pointer',
    textAlign: 'left',
  },
};
