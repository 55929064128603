/* eslint-disable jsx-a11y/label-has-associated-control */
import { connect } from 'react-redux';
import { Formik, FieldArray, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { setAddingUser, addUser } from '@global-state/redux/caregiverListSlice';

import { Modal, Form, Col } from 'react-bootstrap';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, ButtonGroup } from '@intus-ui';
import { getSessionUser } from '@util/session';
import caregiverlistCaregiverPropType from './caregiverListCaregiverPropType';

const defaultUser = {
  firstName: '',
  lastName: '',
  email: '',
  access: 'CAREGIVER',
  phoneNumber: '',
};

const initialValues = {
  users: [defaultUser],
};

const AddUserModal = ({ show, hide, add, hiddenDueToError, caregivers }) => {
  const user = getSessionUser();

  const onAddAnotherUser = (values, setValues) => {
    const valuesCopy = { ...values };
    valuesCopy.users.push(defaultUser);
    setValues(valuesCopy);
  };

  const onRemoveUser = (values, setValues, index) => {
    const valuesCopy = { ...values };
    valuesCopy.users.splice(index, 1);
    setValues(valuesCopy);
  };

  const existingEmails = caregivers.map(({ email }) => email);
  const schema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string()
          .email('Email must be valid')
          .notOneOf(existingEmails, 'A user with this email already exists')
          .required('Required'),
        access: Yup.string().typeError('Required').required('Required'),
        phoneNumber: Yup.number()
          .default(1)
          .typeError('Must be a phone number')
          .test(
            'len',
            'Phone numbers must be either 10 or 11 characters long',
            (val) =>
              val &&
              (val.toString().length === 10 ||
                val.toString().length === 11 ||
                val.toString().length === 1)
          ),
      })
    ),
  });

  if (hiddenDueToError || !show) {
    return null;
  }

  const validateEmailField = (value, users) => {
    let errorMessage;
    const newEmails = users.map(({ email }) => email);
    const isDuplicate = newEmails.filter((email) => email === value).length > 1;
    if (isDuplicate) {
      errorMessage = 'Duplicate email address';
    }
    return errorMessage;
  };

  return (
    <Modal dialogClassName="min-w-70" scrollable show onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Users</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingBottom: 0 }}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            add(values);
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, errors, touched, isSubmitting, values, setValues }) => (
            <Form onSubmit={handleSubmit}>
              <FieldArray name="users">
                {() =>
                  values.users.map((user, index) => {
                    const userErrors = (errors.users?.length && errors.users[index]) || {};
                    const userTouched = (touched.users?.length && touched.users[index]) || {};
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`add-user-row-${index}`}>
                        <Form.Row>
                          <div className="form-group col-4">
                            <label className="form-label"> First Name * </label>
                            <Field
                              name={`users.${index}.firstName`}
                              type="text"
                              className={`form-control${
                                userErrors.firstName && userTouched.firstName ? ' is-invalid' : ''
                              }`}
                            />
                            <ErrorMessage
                              name={`users.${index}.firstName`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-4">
                            <label className="form-label"> Last Name * </label>
                            <Field
                              name={`users.${index}.lastName`}
                              type="text"
                              className={`form-control${
                                userErrors.lastName && userTouched.lastName ? ' is-invalid' : ''
                              }`}
                            />
                            <ErrorMessage
                              name={`users.${index}.lastName`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-3">
                            <label className="form-label"> Access * </label>
                            <Field
                              name={`users.${index}.access`}
                              component="select"
                              className={`form-control${
                                userErrors.access && userTouched.access ? ' is-invalid' : ''
                              }`}
                            >
                              <option value="ADMIN">Admin</option>
                              <option value="CAREGIVER">User</option>
                            </Field>
                            <ErrorMessage
                              name={`users.${index}.access`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {values.users.length > 1 && (
                            <Col>
                              <Button
                                text="Remove User"
                                name={`users.${index}`}
                                onClick={() => onRemoveUser(values, setValues, index)}
                                variant="link"
                                className="font-weight-bold text-danger mt-0 p-0 float-right"
                              >
                                <CloseRoundedIcon />
                              </Button>
                            </Col>
                          )}
                        </Form.Row>
                        <Form.Row>
                          <div className="form-group col-8">
                            <label className="form-label"> Email * </label>
                            <Field
                              validate={(value) => validateEmailField(value, values.users)}
                              name={`users.${index}.email`}
                              type="text"
                              className={`form-control${
                                userErrors.email && userTouched.email ? ' is-invalid' : ''
                              }`}
                            />
                            <ErrorMessage
                              name={`users.${index}.email`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-4">
                            <label className="form-label"> Phone Number </label>
                            <Field
                              name={`users.${index}.phoneNumber`}
                              type="tel"
                              className={`form-control${
                                userErrors.phoneNumber && userTouched.phoneNumber
                                  ? ' is-invalid'
                                  : ''
                              }`}
                            />
                            <ErrorMessage
                              name={`users.${index}.phoneNumber`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Form.Row>
                        <hr />
                      </div>
                    );
                  })
                }
              </FieldArray>
              <Form.Row>
                <Col>
                  <Button onClick={() => onAddAnotherUser(values, setValues)}>
                    Add Another User
                  </Button>
                </Col>
              </Form.Row>
              {!user.isUsingSSO && (
                <Form.Row style={{ marginBottom: 20 }}>
                  A password reset link will be sent to the user&apos;s emails. Please note that
                  this link will only be valid for 24 hours.
                </Form.Row>
              )}
              <Form.Row>
                <Col>
                  <ButtonGroup>
                    <Button
                      type="submit"
                      busy={isSubmitting}
                      disabled={
                        Object.values(errors).length > 0 || Object.values(touched).length === 0
                      }
                    >
                      Create User
                    </Button>
                  </ButtonGroup>
                </Col>
              </Form.Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

AddUserModal.propTypes = {
  add: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  hiddenDueToError: PropTypes.bool.isRequired,
  caregivers: PropTypes.arrayOf(caregiverlistCaregiverPropType.isRequired),
};

AddUserModal.defaultProps = {
  caregivers: [],
};

const mapState = (state) => ({
  show: state.caregiverList.addingUser,
  hiddenDueToError: !!state.notification.notificationBody,
  caregivers: state.caregiverList.caregivers,
});

const mapDispatch = (dispatch) => ({
  hide: () => dispatch(setAddingUser(false)),
  add: (values) => dispatch(addUser(values)),
});

export default connect(mapState, mapDispatch)(AddUserModal);
