import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from '@global-state';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import WelcomeBox from 'Welcome/WelcomeBox';
import ModalButtons from 'Welcome/ModalButtons';
import { setPage } from '@global-state/redux/welcomeSlice';
import { updateUserSettings } from '@global-state/redux/userSettingsSlice';
import { stringToClassname } from '@util/utilFunctions';

const allOptions = [
  {
    name: 'Individual Participant Focus',
    description: 'Stay updated on daily to weekly changes in participant health.',
    settingsId: 'participant', // do not change, aligns with backend settings
  },
  {
    name: 'Population Health Trends',
    description: 'Get metrics and trendlines to review population health.',
    settingsId: 'trend', // do not change, aligns with backend settings
  },
];

const OptionWithSubtitle = ({ name, description, settingsId, setSelected }) => {
  return (
    <label htmlFor={settingsId} id={`viewSelect-${stringToClassname(name)}`}>
      <div
        style={{ width: '35vw', height: '90px' }}
        className="d-flex cursor-pointer flex-row align-items-center justify-content-center selection-button form-check-label py-1 box-shadow-on-gray rounded-xl border-0 text-left m-2"
      >
        <input
          name="segment-selector"
          type="radio"
          value={name}
          style={{ width: '35px', height: '90px' }}
          className="mx-4 cursor-pointer"
          id={settingsId}
          onChange={() => setSelected(settingsId)}
        />
        <div className="d-inline-flex flex-column w-100 cursor-pointer">
          <h5 className="font-weight-bold">{name}</h5>
          <p>{description}</p>
        </div>
      </div>
    </label>
  );
};

const PreferencesOptions = () => {
  const [disabled, setIsDisabled] = useState(true); // for modal buttons
  const [selected, setSelected] = useState(''); // selecting options
  const dispatch = useDispatch();
  const page = useSelector((state) => state.welcome.page);
  const profile = useSelector((state) => state.userSettings.profile);
  if (!profile) {
    return <SpinnerOrError error="Could not load the page. Please refresh and try again. " />;
  }
  const { setting } = profile;

  useEffect(() => {
    if (selected !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selected]);

  const updateSettings = (selection) => {
    const updatedSetting = { ...setting, segmentationView: selection };
    const updatedProfile = { ...profile, settings: updatedSetting };
    dispatch(updateUserSettings(updatedProfile));
  };

  const handleSubmit = (selection) => {
    if (!selection) {
      updateSettings('trend'); // default selection
    } else {
      updateSettings(selection);
    }
    dispatch(setPage(page + 1));
  };

  return (
    <Row className="d-flex flex-row justify-content-center">
      {allOptions.map((option) => (
        <OptionWithSubtitle
          name={option.name}
          description={option.description}
          settingsId={option.settingsId}
          key={option.name}
          setSelected={setSelected}
        />
      ))}
      <ModalButtons
        onClick={() => handleSubmit(selected)}
        disabled={disabled}
        padding=" px-4 mt-4"
      />
    </Row>
  );
};

const WelcomeBoxHeader = () => {
  return (
    <>
      <h2 className="text-secondary font-weight-bold p-3">Preferences</h2>
      <h4 className="w-70 p-3">What information is most useful to your workday?</h4>
    </>
  );
};

const MonthlyDailyPreferences = () => {
  return (
    <WelcomeBox minWidth="w-60" minHeight="h-60">
      <div className="d-flex flex-column align-items-center justify-content-between pt-4 h-100 w-100">
        <WelcomeBoxHeader />
        <PreferencesOptions />
      </div>
    </WelcomeBox>
  );
};

export default MonthlyDailyPreferences;
