import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setAddingUser } from '@global-state/redux/caregiverListSlice';

import { Button, Icon, List, Text } from '@intus-ui';

import { getSessionUser } from '@util/session';
import { defaultColumnConfig, usingSSOColumnConfig } from './list/columnConfigs';
import { format } from './list/format';
import { formatUsers } from './helpers';

const UserList = ({ data }) => {
  const [filteredData, setFilteredData] = useState(formatUsers(data));
  const dispatch = useDispatch();

  const user = getSessionUser();

  useEffect(() => {
    setFilteredData(formatUsers(data));
  }, [data]);

  const addUserButton = (
    <Button onClick={() => dispatch(setAddingUser(true))}>
      <Icon name="add" size="medium" />
      <Text color="white" type="subtitle" style={{ padding: '0px 10px 0px 0px' }}>
        Create User
      </Text>
    </Button>
  );
  return (
    <List
      data={filteredData}
      searchable
      format={format}
      columnConfig={
        user.isUsingSSO === true ? usingSSOColumnConfig.default : defaultColumnConfig.default
      }
      topRightElements={addUserButton}
      emptyListMessage="There are no users in this list. Add a user using the button above."
      showColumnHeaders
    />
  );
};

export default UserList;
