import React from 'react';
import PropTypes from 'prop-types';

import { Line } from 'react-chartjs-2';
import { SpinnerOrError } from '@intus-ui';

// defaults.global.defaultFontFamily = 'Muli';

class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      data,
      options,
      legend,
      title,
      xLabel,
      yLabel,
      aspectRatio,
      responsive,
      legendPosition,
      noPadding,
      maxXTicks,
      maxYTicks,
      loading,
    } = this.props;
    if (!data) return null;
    /*
    Finding the maximum point from all the data to find a suitable
    suggestedMax value for the y-axis.
    */
    const allData = data.datasets.map((dataset) => dataset.data);
    const allMaxData = allData.map((dataset) => Math.max(...dataset));
    const maxDataPoint = Math.max(...allMaxData);
    const paddedMax = maxDataPoint + Math.min(30, maxDataPoint / 5);

    // Default options settings
    const intusOptions = {
      title: {
        display: true,
        text: title,
        position: 'top',
        align: 'center',
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: maxXTicks || data.label?.length,
              fontSize: 12,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
            scaleLabel: {
              display: true,
              labelString: xLabel,
              fontSize: 12,
              fontStyle: 'bold',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              suggestedMax: paddedMax,
              fontSize: 12,
              precision: 1,
              maxTicksLimit: maxYTicks,
            },
            gridLines: {
              drawBorder: false,
            },
            scaleLabel: {
              display: true,
              labelString: yLabel,
              fontSize: 12,
              fontStyle: 'bold',
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, chartData) => {
            let label = chartData.datasets[tooltipItem.datasetIndex].label || '';

            if (label) label += ': ';
            label += Math.round(tooltipItem.yLabel * 100) / 100;
            return label;
          },
        },
      },
      maintainAspectRatio: aspectRatio,
      responsive,
      elements: { point: { hitRadius: 100 } },
      padding: 5,
    };

    // Default legend settings
    const intusLegend = {
      position: legendPosition,
      align: 'center',
      padding: 0,
      title: {
        padding: 0,
      },
      labels: {
        padding: 8,
        boxWidth: 10,
      },
    };

    return (
      <div className={`w-100 h-100 ${noPadding ? '' : 'mb-4 pb-3'}`}>
        {loading ? (
          <SpinnerOrError />
        ) : (
          <Line
            legend={legend !== undefined ? legend : intusLegend}
            data={data}
            options={options !== undefined ? options : intusOptions}
          />
        )}
      </div>
    );
  }
}

/*
Check react-chartjs-2 and Charts.js documentation for a full description
of all data, options, and legend settings. These are the PropTypes for some
basic settings that might be needed.
*/
LineChart.propTypes = {
  // Prop types for data, contains the information for axis labelling and data content
  data: PropTypes.shape({
    label: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.shape),
    fill: PropTypes.bool,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    lineTension: PropTypes.number,
  }),
  // Prop types for options, which formats the appearance of the chart.
  options: PropTypes.shape({
    title: PropTypes.instanceOf(Object),
    scales: PropTypes.shape({
      xAxes: PropTypes.arrayOf(
        PropTypes.shape({
          gridLines: PropTypes.shape({
            color: PropTypes.string,
          }),
          ticks: PropTypes.shape({
            display: PropTypes.bool,
            autoSkip: PropTypes.bool,
            maxTicksLimit: PropTypes.number,
            fontSize: PropTypes.number,
          }),
        })
      ),
      yAxes: PropTypes.arrayOf(
        PropTypes.shape({
          gridLines: PropTypes.shape({
            color: PropTypes.string,
          }),
          ticks: PropTypes.shape({
            display: PropTypes.bool,
            autoSkip: PropTypes.bool,
            maxTicksLimit: PropTypes.number,
            fontSize: PropTypes.number,
          }),
        })
      ),
    }),
    maintainAspectRatio: PropTypes.bool,
  }),
  // Prop types for formatting the legend of the chart
  legend: PropTypes.shape({
    display: PropTypes.bool,
    position: PropTypes.string,
    labels: PropTypes.instanceOf(Object),
  }),
  aspectRatio: PropTypes.bool,
  legendPosition: PropTypes.string,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  title: PropTypes.string,
  responsive: PropTypes.bool,
  noPadding: PropTypes.bool,
  maxXTicks: PropTypes.number,
  maxYTicks: PropTypes.number,
};

LineChart.defaultProps = {
  legendPosition: 'chartArea',
  data: undefined,
  options: undefined,
  legend: undefined,
  aspectRatio: false,
  xLabel: '',
  yLabel: '',
  title: '',
  responsive: true,
  noPadding: false,
  maxXTicks: undefined,
  maxYTicks: 8,
};

export default LineChart;
