/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getLogger } from '@util/logger';
import riskBreakup from '@util/riskGrouping';
import { toSentenceCase } from '@util/utilFunctions';
import { formatDate, subtractDate, isSameOrAfterDate, newDate } from '@util/dateFunctions';

import { getAcuityLevelChanges } from '@api/api';

import { EHR_TRUCHART, EHR_RTZ } from 'Settings/userSettingsPropType';

import { Badge, Dropdown, Row, OverlayTrigger } from 'react-bootstrap';
import { ResponsiveLine } from '@nivo/line';
import { TooltipWrapper } from '@nivo/tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import NatureRoundedIcon from '@mui/icons-material/NatureRounded';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import HomeIcon from '@mui/icons-material/Home';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NoteIcon from '@mui/icons-material/Note';
import { Icon } from '@intus-ui';
import CustomDropdownToggle from '@intus-ui/components/CustomDropdownToggle';
import DropdownTitleAboveValue from 'Dashboard/DropdownTitleAboveValue';
import IncidentsIcon from 'assets/Icons/Incidents';
import { getSessionUser } from '@util/session';
import RiskTrendLinePopup from './RiskTrendLinePopup';

import '@intus-ui/styles/Profile.scss';

const log = getLogger('RiskLineGraph');

const modelUpdateTooltip = (
  <div>
    This spot marks an update to the Acuity Risk Model, which is a result of our algorithm learning.
    Some change in score may occur around this point. Model changes are rare, but important, as they
    allow us to better serve you and your IDT. Thank you.
  </div>
);
const acuitySeriesIdNames = [
  'low',
  'moderate',
  'high',
  'highest',
  'low-re',
  'moderate-re',
  'high-re',
  'highest-re',
  'low-final',
  'moderate-final',
  'high-final',
  'highest-final',
];

const symbolGenerator = (letter) => (
  <svg x="-10" y="-10" width={letter === 'LTC' || letter === 'INP' ? '40' : '30'} height="25">
    {letter}
    <rect rx="5" ry="5" width="30" height="25" fill={letter === 'vms' ? '#fff' : 'gray'} />
    {letter === 'vms' ? (
      <InfoIcon variant="success" className="info-icon" />
    ) : (
      <text
        fill="white"
        x="50%"
        y="60%"
        fontWeight="bold"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {letter}
      </text>
    )}
  </svg>
);

const idToSymbol = (id, badge) => {
  if (id === 'burn')
    return (
      <Badge className="tooltip-text my-1 mx-2" variant="muted">
        Burn
      </Badge>
    );
  if (id === 'infection')
    return (
      <Badge className="tooltip-text my-1 mx-2" variant="muted">
        Infection
      </Badge>
    );
  if (id === 'mederror')
    return (
      <Badge className="tooltip-text my-1 mx-2" variant="muted">
        Med Error
      </Badge>
    );
  if (id === 'fall')
    return (
      <Badge className="tooltip-text my-1 mx-2" variant="muted">
        Fall
      </Badge>
    );
  if (id === 'general')
    return (
      <Badge className="tooltip-text my-1 mx-2" variant="muted">
        General
      </Badge>
    );
  if (id === 'ER')
    return (
      <Badge className="tooltip-text my-1 mx-2" variant="muted">
        ER
      </Badge>
    );
  if (id === 'LTC')
    return (
      <Badge className="tooltip-text my-1 mx-2" variant="muted">
        LTC
      </Badge>
    );
  if (id === 'INP')
    return (
      <Badge className="tooltip-text my-1 mx-2" variant="muted">
        INP
      </Badge>
    );
  return (
    <Badge className="tooltip-text my-1 mx-2" variant={badge.color}>
      {badge.text} Risk
    </Badge>
  );
};

const hospitalizationsTooltip = ({ lengthOfStay, principalDx, dischargeStatusDesc }) => (
  <div>
    <text className="pl-2 text-black ">
      <strong>Length of Stay: </strong>
      {`${lengthOfStay} days`}
      <br />
    </text>
    <text className="pl-2 text-black text-black keep-on-one-line">
      <strong>Principal DX: </strong>
      {principalDx}
      <br />
    </text>
    <div className="pl-2 text-black">
      <strong>Discharge Status: </strong>
      {dischargeStatusDesc}
    </div>
  </div>
);

const incidentTooltip = ({
  dateTimeOccurred,
  location,
  severity,
  burnDegree,
  infectionType,
  subType,
  activityAtTimeOfFall,
}) => (
  <div className="py-2">
    <div
      style={{ fontSize: '.8em' }}
      className="pb-2 pl-4 d-flex justify-content-start align-items-center keep-on-one-line"
    >
      <CalendarTodayIcon fontSize="small" className="text-muted" />
      <div className="pl-2">
        {formatDate(
          dateTimeOccurred,
          getSessionUser().organization.subscriptions.includes(EHR_RTZ)
            ? 'MM/DD/YYYY'
            : 'MM/DD/YY, h:mm A'
        )}
      </div>
    </div>
    {!infectionType && (
      <div
        style={{ fontSize: '.8em' }}
        className="pb-2 pl-4 d-flex justify-content-start align-items-center keep-on-one-line"
      >
        <HomeIcon fontSize="small" className="text-muted" />
        <div className="pl-2">{location}</div>
      </div>
    )}
    {(burnDegree || infectionType || subType || severity) && (
      <div
        style={{ fontSize: '.8em' }}
        className="pb-2 pl-4 d-flex justify-content-start align-items-center keep-on-one-line"
      >
        <LocalHospitalIcon fontSize="small" className="text-muted" />
        <div className="pl-2">{burnDegree || infectionType || subType || severity}</div>
      </div>
    )}
    {activityAtTimeOfFall && (
      <div>
        <div
          style={{ fontSize: '.8em' }}
          className="pb-2 pl-4 d-flex justify-content-start align-items-center"
        >
          <NoteIcon fontSize="small" className="text-muted" />
          <div className="pl-2 text-dark">{activityAtTimeOfFall}</div>
        </div>
      </div>
    )}
  </div>
);

const acuityTooltip = (hoverChanges, point) => (
  <>
    {Object.values(hoverChanges).flat().length ? (
      <>
        <div className="pt-2 pb-1">
          <span className="font-weight-bold font-italic text-muted keep-on-one-line">
            Changes Since Last Assessment:
          </span>
        </div>
        {Object.entries(hoverChanges).map(([subscore, conditionArray]) => (
          <div
            key={`${subscore}-${point.data.index}`}
            className="pl-3 d-flex justify-content-start align-items-start flex-column"
          >
            <span className="font-weight-bold keep-on-one-line">{subscore}</span>
            {conditionArray.map((condition) => {
              const changeNode = condition.includes('?') ? (
                <del className="pl-2">{condition.slice(1)}</del>
              ) : (
                <div className="pl-2">{condition.slice(1)}</div>
              );
              return (
                <div className="d-flex justify-content-start align-items-center" key={condition}>
                  <Icon name="bullet" className="text-muted" />
                  {changeNode}
                </div>
              );
            })}
          </div>
        ))}
      </>
    ) : (
      <div>
        <span>No new tracked conditions since last assessment.</span>
      </div>
    )}
  </>
);

const CustomSymbol = (props) => {
  const { size, borderWidth, borderColor, datum } = props;
  if (borderColor === 'rgba(0,0,0,0)') return datum.icon;
  if (borderColor === 'rgba(0,0,0,.01)') return symbolGenerator('B');
  if (borderColor === 'rgba(0,0,0,.02)') return symbolGenerator('I');
  if (borderColor === 'rgba(0,0,0,.03)') return symbolGenerator('M');
  if (borderColor === 'rgba(0,0,0,.04)') return symbolGenerator('F');
  if (borderColor === 'rgba(0,0,0,.05)') return symbolGenerator('G');
  if (borderColor === 'rgba(0,0,0,0.06)') return symbolGenerator('vms');

  return (
    <g>
      <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
    </g>
  );
};

const RiskLineGraph = ({
  data,
  maxScore,
  showIncidents,
  toggleIncidents,
  admitsFilter,
  setAdmitsFilter,
  changeType,
  type,
  acuityPoint,
  changePoint,
}) => {
  const patient = useSelector((state) => state.patientDetail.patient);
  const [acuityLevelChanges, setAcuityLevelChanges] = useState([]);
  // fetch acuityLevelChanges
  useEffect(() => {
    const ac = new AbortController();
    let { aborted } = ac.signal;
    getAcuityLevelChanges(patient.id, 1)
      .then(async (result) => {
        if (result.ok) {
          const json = await result.json();
          const levelChanges = json.map((assessment) => ({
            ...assessment.intusLabels,
          }));
          aborted = ac.signal.aborted;
          if (aborted === false) {
            setAcuityLevelChanges(levelChanges);
          }
        }
      })
      .catch((error) => {
        log.error(error);
      });
    return () => {
      ac.abort();
      setAcuityLevelChanges([]);
    };
  }, [patient.id]);

  const [timeFilter, setTimeFilter] = useState('Past 6 Months');
  const acuityLevels = [...patient.acuityLevels];
  const [show, setShow] = useState(false);

  if (!data.length) return null;
  const onHoverChanges = (index) => {
    if (!acuityLevelChanges.length) return [];
    const currChanges = {};
    // acuityLevelChanges is an array with the most recent first while index is oldest first
    const correctIndex = acuityLevelChanges.length - 1 - index;
    Object.entries(acuityLevelChanges[correctIndex]).forEach(([subscore, conditionArray]) => {
      const changes = [];
      conditionArray.forEach((condition) => {
        if (condition.includes('!') || condition.includes('?')) changes.push(condition);
      });
      changes.sort();
      if (changes.length) currChanges[toSentenceCase(subscore)] = changes;
    });
    return currChanges;
  };

  const displayPointInfo = (point) => {
    if (point.serieId === 'hospitalizations') return hospitalizationsTooltip(point.data);
    if (['burn', 'infection', 'mederror', 'fall', 'general'].includes(point.serieId))
      return incidentTooltip(point.data);
    if (point.serieId === 'vertical-marker') return modelUpdateTooltip;
    return acuityTooltip(onHoverChanges(point.data.index), point);
  };

  const handleClick = (isShown) => {
    setShow(isShown);
  };

  const tooltipEle = ({ point }) => {
    const badge = {
      text: riskBreakup.INSUFF.name,
      color: riskBreakup.INSUFF.badgeColor,
    };
    let badgeColor = '#b3c6f4';
    if (Number(point.data.y) < riskBreakup.MODERATE.lowerLimit) {
      badgeColor = riskBreakup.LOW.color;
    }
    if (
      Number(point.data.y) >= riskBreakup.MODERATE.lowerLimit &&
      Number(point.data.y) < riskBreakup.HIGH.lowerLimit
    ) {
      badgeColor = riskBreakup.MODERATE.color;
    }
    if (
      Number(point.data.y) >= riskBreakup.HIGH.lowerLimit &&
      Number(point.data.y) < riskBreakup.HIGHEST.lowerLimit
    ) {
      badgeColor = riskBreakup.HIGH.color;
    }
    if (Number(point.data.y) >= riskBreakup.HIGHEST.lowerLimit) {
      badgeColor = riskBreakup.HIGHEST.color;
    }

    let icon = <div />;
    let anchor = 'left';
    let position = [0, 75];
    if (point.serieId === 'hospitalizations') {
      anchor = 'top';
      position = [0, 0];
      icon = point.data.icon;
    }
    if (['burn', 'infection', 'mederror', 'fall', 'general'].includes(point.serieId)) {
      icon = idToSymbol(point.serieId, badge);
      anchor = 'bottom';
      position = [0, 20];
    }
    if (point.serieId === 'vertical-marker') {
      icon = symbolGenerator('vms');
      position = [0, 20];
      anchor = 'bottom';
    }

    return (
      <TooltipWrapper anchor={anchor} position={position}>
        {!acuitySeriesIdNames.includes(point.serieId) && (
          <div
            style={{
              width: 'responsive',
            }}
          >
            <div className="card pl-2 pt-2 pb-2 pr-3 mx-4 non-clickable box-shadow-on-gray rounded-lg bg-white d-flex">
              <div className="d-flex justify-content-between align-items-center">
                <span className="font-weight-bold keep-on-one-line mr-4">{`${formatDate(
                  point.data.x,
                  'MMMM DD, YYYY'
                )}`}</span>
                {icon}
              </div>
              <hr className="m-0" />
              {displayPointInfo(point)}
            </div>
          </div>
        )}
        {acuitySeriesIdNames.includes(point.serieId) && (
          <div
            className="px-3 py-2 rounded-lg box-shadow-on-gray"
            style={{
              backgroundColor: 'rgba(255,255,255,.9)',
              top: Number(`${point.data.y === 0 ? 25 : -point.y}`),
              borderWidth: '5px',
              borderStyle: 'solid',
              borderColor: `${badgeColor}`,
              borderRadius: '10px',
            }}
          >
            <div className="d-flex align-items-center">
              <RadioButtonUncheckedIcon
                style={{ color: badgeColor }}
                className="pr-2"
                fontSize="large"
              />
              <h5 style={{ color: badgeColor }} className="font-weight-bold pr-2">
                {point.data.y}
              </h5>
              <span>{`${formatDate(point.data.x, 'MMMM DD, YYYY')}`}</span>
            </div>
            <hr className="m-0" />
            {displayPointInfo(point)}
          </div>
        )}
      </TooltipWrapper>
    );
  };

  const utilMap = {
    'Past 6 Months': {
      filterTitle: 'every 1 month',
      axisBottomFormat: "%b '%y",
      months: 6,
    },
    'Past Year': {
      filterTitle: 'every 2 month',
      axisBottomFormat: "%b '%y",
      months: 12,
    },
    'All Time': {
      filterTitle: 'every 1 year',
      axisBottomFormat: '%Y',
      months: undefined,
    },
  };

  const timeDropdown = (
    <Dropdown onSelect={(eventKey) => setTimeFilter(eventKey)}>
      <Dropdown.Toggle as={CustomDropdownToggle} id="riskTrendLine-time-dropdown">
        <DropdownTitleAboveValue
          value={timeFilter}
          icon={<DateRangeIcon fontSize="small" className="" />}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="Past 6 Months">Past 6 Months</Dropdown.Item>
        <Dropdown.Item eventKey="Past Year">Past Year</Dropdown.Item>
        <Dropdown.Item eventKey="All Time">All Time</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
  const {
    organization: { subscriptions },
  } = getSessionUser();
  const isTruChartSubscriber = subscriptions.includes(EHR_TRUCHART);
  const admitsDropdown = (
    <Dropdown onSelect={(eventKey) => setAdmitsFilter(eventKey)}>
      <Dropdown.Toggle as={CustomDropdownToggle} id="riskTrendLine-time-dropdown">
        <DropdownTitleAboveValue
          title="Admit Type"
          value={admitsFilter}
          icon={<NatureRoundedIcon fontSize="small" className="" />}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="All">All</Dropdown.Item>
        <Dropdown.Item eventKey="None">None</Dropdown.Item>
        <Dropdown.Item eventKey="Emergency Room">Emergency Room</Dropdown.Item>
        <Dropdown.Item eventKey="Inpatient">Inpatient</Dropdown.Item>
        {isTruChartSubscriber && (
          <>
            <Dropdown.Item eventKey="SNF">SNF</Dropdown.Item>
            <Dropdown.Item eventKey="ALF">ALF</Dropdown.Item>
          </>
        )}
        <Dropdown.Item eventKey="LTC">LTC</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const filterData = () => {
    const fitleredData = [];
    data.forEach((item) => {
      const filteredItem = {};
      filteredItem.id = item.id;
      filteredItem.data = item.data.filter((point) => {
        if (utilMap[timeFilter].months) {
          return (
            // filters a point out if it is not in the past utilMap[timeFilter].months months
            isSameOrAfterDate(
              point.x,
              subtractDate(newDate(), utilMap[timeFilter].months, 'month'),
              'month'
            )
          );
        }
        return point;
      });

      fitleredData.push(filteredItem);
    });
    return fitleredData;
  };

  CustomSymbol.propTypes = {
    size: PropTypes.number.isRequired,
    borderWidth: PropTypes.number.isRequired,
    borderColor: PropTypes.string.isRequired,
    datum: PropTypes.instanceOf(Object).isRequired,
  };

  const openPopup = (point) => {
    const pointInfo = point;

    if (acuitySeriesIdNames.includes(pointInfo.serieId)) {
      handleClick(true);
      switch (pointInfo.serieId) {
        case undefined:
          changeType('undefined');
          changePoint('');
          break;
        default:
          changeType('acuity');
          changePoint(pointInfo);
      }
    }
  };

  return (
    <div className="w-100 h-100 d-flex flex-column">
      <Row className="m-0 pt-3 d-flex flex-shrink-1 justify-content-between align-items-center">
        <h2 className="text-secondary">Risk Trendline</h2>
        <div className="d-flex justify-content-center align-items-center">
          <button
            onClick={toggleIncidents}
            type="button"
            className={`${
              showIncidents ? 'icon-toggle-selected' : ''
            } text-secondary bg-transparent border-0 icon-toggle hover-light-blue outline-none rounded`}
            style={{ marginRight: '0px', padding: '5px' }}
            title={`${showIncidents ? 'Hide' : 'Show'} Incidents`}
          >
            <IncidentsIcon toggleState={showIncidents} />
          </button>
          <div className="ml-3">{admitsDropdown}</div>
          <div className="ml-3">{timeDropdown}</div>
        </div>
      </Row>
      <OverlayTrigger
        show={show}
        container={this}
        placement="right"
        target="popover-basic"
        // eslint-disable-next-line max-len
        overlay={
          <RiskTrendLinePopup
            point={acuityPoint}
            type={type}
            acuityLevelChanges={acuityLevelChanges}
            acuityLevels={acuityLevels}
            handleClick={handleClick}
          />
        }
        rootClose
        style={{ cursor: 'pointer' }}
      >
        <Row className="flex-grow-1">
          <ResponsiveLine
            theme={{
              fontFamily: 'Inter',
              axis: {
                legend: {
                  text: {
                    fontWeight: 'bold',
                    fontSize: '1em',
                  },
                },
              },
              labels: {
                text: {
                  fontSize: '1.2em',
                },
              },
            }}
            className="linechart"
            data={filterData()}
            margin={{
              top: 50,
              right: 110,
              bottom: 90,
              left: 90,
            }}
            xScale={{
              type: 'time',
              useUTC: false,
              format: '%Y-%m-%d',
              precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: 'linear',
              min: 0,
              max: maxScore,
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: '%b %Y',
              tickValues: 5,
              legend: 'Assessment Date',
              legendOffset: 50,
              legendPosition: 'middle',
            }}
            axisLeft={{
              legend: 'Acuity Score',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            colors={[
              'rgba(0,0,0,0.06)', // vertical-marker
              'rgba(0,0,0,.01)', // burn
              'rgba(0,0,0,.04)', // fall
              'rgba(0,0,0,.05)', // general
              'rgba(0,0,0,.02)', // infection
              'rgba(0,0,0,.03)', // med error
              'rgba(0,0,0,0)', // hospitalizations
              riskBreakup.LOW.color,
              riskBreakup.MODERATE.color,
              riskBreakup.HIGH.color,
              riskBreakup.HIGHEST.color,
              riskBreakup.LOW.color,
              riskBreakup.MODERATE.color,
              riskBreakup.HIGH.color,
              riskBreakup.HIGHEST.color,
              riskBreakup.LOW.color,
              riskBreakup.MODERATE.color,
              riskBreakup.HIGH.color,
              riskBreakup.HIGHEST.color,
            ]}
            pointSize={12}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointSymbol={CustomSymbol}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh
            enableArea
            areaOpacity={0.1}
            lineWidth={3}
            crosshairType="cross"
            enableGridX={false}
            tooltip={tooltipEle}
            onClick={openPopup}
            markers={[
              {
                axis: 'y',
                value: riskBreakup.HIGHEST.lowerLimit,
                lineStyle: {
                  stroke: riskBreakup.HIGHEST.color,
                  strokeWidth: 1.2,
                },
                legendPosition: 'right',
              },
              {
                axis: 'y',
                value: riskBreakup.HIGH.lowerLimit,
                lineStyle: {
                  stroke: riskBreakup.HIGH.color,
                  strokeWidth: 1.2,
                },
              },
              {
                axis: 'y',
                value: riskBreakup.MODERATE.lowerLimit,
                lineStyle: {
                  stroke: riskBreakup.MODERATE.color,
                  strokeWidth: 1.2,
                },
              },
              {
                axis: 'y',
                value: riskBreakup.LOW.lowerLimit,
                lineStyle: {
                  stroke: riskBreakup.LOW.color,
                  strokeWidth: 1.2,
                },
              },
              {
                axis: 'y',
                value: (riskBreakup.HIGHEST.lowerLimit + maxScore) / 2,
                lineStyle: {
                  stroke: riskBreakup.HIGHEST.color,
                  strokeWidth: 0,
                },
                legendPosition: 'right',
                legend: riskBreakup.HIGHEST.name,
              },
              {
                axis: 'y',
                value: (riskBreakup.HIGH.lowerLimit + riskBreakup.HIGHEST.lowerLimit) / 2,
                lineStyle: { stroke: riskBreakup.HIGH.color, strokeWidth: 0 },
                legendPosition: 'right',
                legend: riskBreakup.HIGH.name,
              },
              {
                axis: 'y',
                value: (riskBreakup.MODERATE.lowerLimit + riskBreakup.HIGH.lowerLimit) / 2,
                lineStyle: {
                  stroke: riskBreakup.MODERATE.color,
                  strokeWidth: 0,
                },
                legendPosition: 'right',
                legend: riskBreakup.MODERATE.name,
              },
              {
                axis: 'y',
                value: (riskBreakup.LOW.lowerLimit + riskBreakup.MODERATE.lowerLimit) / 2,
                lineStyle: { stroke: riskBreakup.LOW.color, strokeWidth: 0 },
                legendPosition: 'right',
                legend: riskBreakup.LOW.name,
              },
            ]}
          />
        </Row>
      </OverlayTrigger>
    </div>
  );
};

RiskLineGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxScore: PropTypes.number.isRequired,
  showIncidents: PropTypes.bool.isRequired,
  toggleIncidents: PropTypes.func.isRequired,
  admitsFilter: PropTypes.string.isRequired,
  setAdmitsFilter: PropTypes.func.isRequired,
  changeType: PropTypes.func.isRequired,
  acuityPoint: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired,
  changePoint: PropTypes.func.isRequired,
};

export default RiskLineGraph;
