import { getSessionUser } from '@util/session';
import { environment } from '@config/constants';

const isTester = (featureName) => {
  const user = getSessionUser();
  if (user) {
    const { flags } = user;
    return flags?.includes(featureName);
  }
  return false;
};

const activateFeatureFlag = (featureName, featureEnv) => {
  const { href } = window.location;
  // TODO: Set process.env.NODE_ENV for azure dev and bluenova builds
  const isDevEnvironment =
    process.env.NODE_ENV === 'development' ||
    href.includes('https://pvw-intuscare-frontend') || // bluenova build
    href.includes('https://intuswebsite-intuswebsite-demo.azurewebsites') || // azure demo build
    href.includes('https://intuswebsite-develop.azurewebsites'); // azure dev build

  if (featureEnv === 'development' && isDevEnvironment) return true;

  // add an env option to trigger flag check regardless of environment
  if (featureEnv === environment.checkFlag) return isTester(featureName);

  if (featureEnv === environment.staging || featureEnv === environment.production) {
    if (isDevEnvironment) {
      return true;
    }
    return isTester(featureName);
  }

  return process.env.NODE_ENV === featureEnv;
};

export default activateFeatureFlag;
