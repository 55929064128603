import { post } from '@api/apiMethods';
import { IPatientFirstFillStatus } from '@api/polypharmacy/getRisingStars';
import { IAllStarsMeasures } from 'Polypharmacy/types/STARSMeasures';

type FirstFillStatusParams = {
  patientId: number;
  measure: IAllStarsMeasures;
  measureYear: number;
  status: IPatientFirstFillStatus['status'] | null;
};

export function updateFirstFillStatus(params: FirstFillStatusParams) {
  return post(`polypharmacy/upsert-first-fill-status`, params);
}
