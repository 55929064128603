import React from 'react';
import PropTypes from 'prop-types';
import '@intus-ui/styles/FilteredList.scss';
import '@intus-ui/styles/Utilities.scss';
import { shadesOfBlue } from '@intus-ui/styles/SecondaryColors';
import HoverBg from './HoverBg';

// Icon
const EditIcon = ({ lineColor, expanded }) => {
  const editIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.77428 14.9004L18.2584 3.41605C19.0405 2.63399 20.3084 2.63398 21.0905 3.41603C21.8725 4.19808 21.8725 5.46602 21.0905 6.24807L9.60632 17.7324L5.87106 18.6356L6.77428 14.9004Z"
        stroke={lineColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3136 4.57666H3.49982C2.39525 4.57666 1.49982 5.47209 1.49982 6.57666V21.0002C1.49982 22.1048 2.39524 23.0002 3.49981 23.0002H17.9234C19.0279 23.0002 19.9234 22.1048 19.9234 21.0002V12.1864"
        stroke={lineColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const expandedIcon = (
    <>
      {editIcon}
      <p className="text-secondary text-icon font-weight-bold pl-1">Edit</p>
    </>
  );

  return <HoverBg>{expanded ? expandedIcon : editIcon}</HoverBg>;
};

EditIcon.propTypes = {
  lineColor: PropTypes.string,
  expanded: PropTypes.bool,
};

EditIcon.defaultProps = {
  lineColor: shadesOfBlue.intusNavy,
  expanded: true,
};

export default EditIcon;
