import { GeneralButton } from '@intus-ui';
import { Edit } from '@intus-ui/icons/index';
import { useContext } from 'react';
import { connect } from 'react-redux';

import { setPatientList } from '@global-state/redux/profileSlice';

import { MeetingContext } from './context/MeetingContext';

const EndMeetingButton = ({ doSetPatientList }) => {
  const { setMeeting, setRunState } = useContext(MeetingContext);

  const onClick = () => {
    setRunState('STOPPED');
    setMeeting();
    doSetPatientList();
  };

  return (
    <GeneralButton name="End Meeting" hoverText="End Meeting" onClick={onClick} secondary>
      <Edit name="edit" />
    </GeneralButton>
  );
};

const mapDispatch = (dispatch) => ({
  doSetPatientList: (list) => dispatch(setPatientList(list)),
});

export default connect(null, mapDispatch)(EndMeetingButton);
