import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Form } from 'react-bootstrap';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SubmitButton from '@intus-ui/components/SubmitButton';
import useEventListener from '@intus-ui/components/useEventListener';

const ButtonToForm = ({
  buttonTitle,
  buttonIcon,
  inputPlaceholder,
  validationSchema,
  onSubmit,
  buttonOnClick,
  className,
  iconTitle,
}) => {
  const [isButton, setIsButton] = useState(true);
  const [input, setInput] = useState('');

  const form = useRef();

  // this will convert the input field back to a button if
  // a click outside this component is detected
  const handleOutsideClick = (e) => {
    if (!form?.current?.contains(e.target)) setIsButton(true);
  };
  useEventListener('mousedown', handleOutsideClick);

  const setToInput = () => {
    setIsButton(false);
    setInput('');
  };
  if (isButton) {
    return (
      <div
        className={`"m-0 p-0 w-100 outline-none border-0 d-flex flex-row-reverse justify-content-between align-items-center parent-hover ${
          !buttonOnClick ? 'bg-primary search-dashboards' : className
        }`}
      >
        {buttonTitle.includes('New') ? (
          <button
            type="button"
            className={`pl-2 py-2 m-0 border-0 outline-none text-left text-white ${
              buttonOnClick ? 'hover-primary child-opacity' : ''
            }`}
            onClick={setToInput}
            style={{ backgroundColor: 'inherit' }}
            title={iconTitle}
          >
            {buttonIcon}
          </button>
        ) : (
          <button
            type="button"
            className={`py-2 d-flex flex-grow-1 m-0 border-0 outline-none text-left p-0 overflow-hidden ${className}`}
            onClick={buttonOnClick || setToInput}
            style={{ backgroundColor: 'inherit', fontWeight: 'inherit' }}
            title={buttonTitle}
          >
            {buttonTitle.length <= 20 ? buttonTitle : `${buttonTitle.slice(0, 20)}...`}
          </button>
        )}
        {!buttonTitle.includes('New') ? (
          <button
            type="button"
            className={`pl-3 py-2 m-0 border-0 d-flex-flex-shrink-1 outline-none text-white text-right ${
              buttonOnClick ? 'hover-primary child-opacity' : ''
            }`}
            onClick={setToInput}
            style={{ backgroundColor: 'inherit' }}
            title={iconTitle}
          >
            {buttonIcon}
          </button>
        ) : (
          <button
            type="button"
            className={`py-2 pr-5 d-flex flex-grow-1 m-0 border-0 outline-none text-left p-0 overflow-hidden ${className}`}
            onClick={buttonOnClick || setToInput}
            style={{ backgroundColor: 'inherit', fontWeight: 'inherit' }}
            title={buttonTitle}
          >
            {buttonTitle.length <= 20 ? buttonTitle : `${buttonTitle.slice(0, 20)}...`}
          </button>
        )}
      </div>
    );
  }

  return (
    <Formik
      initialValues={{ input: '' }}
      className="w-100 m-0"
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values.input);
        resetForm();
        setIsButton(true);
      }}
    >
      {({ errors, values, resetForm, handleSubmit, isSubmitting, setFieldValue }) => (
        <Form onSubmit={handleSubmit} className="w-100">
          <div
            ref={form}
            className="w-100 bg-secondary text-white py-2 search-dashboards d-flex justify-content-between align-items-center"
          >
            {/* <div className="w-100 ml-3"> */}
            <input
              // In this case, you have to click a button for the input field to render
              // in place and autofocus so it is not really an accessibility issue.
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              type="text"
              placeholder={inputPlaceholder}
              className="d-flex flex-grow-1 bg-transparent text-white border-0 outline-none p-0 ml-3 w-80"
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
                setFieldValue('input', e.target.value);
              }}
              maxLength={50}
            />
            <div className="d-flex justify-content-center align-items-center w-20 mr-1">
              <button
                aria-label="Cancel"
                type="button"
                className={`bg-transparent m-0 border-0 text-white p-0 hover-primary ${
                  isSubmitting ? 'disabled-muted' : ''
                }`}
                onClick={() => {
                  resetForm();
                  setIsButton(true);
                  setInput('');
                }}
                disabled={isSubmitting}
              >
                <CloseRoundedIcon />
              </button>
              <SubmitButton
                defaultButton
                hideTextOnSubmit
                className={`bg-transparent m-0 border-0 rounded-0-important text-white p-0 hover-primary ${
                  !isSubmitting ? 'disabled-muted' : ''
                }`}
                text={<CheckRoundedIcon />}
                isSubmitting={isSubmitting}
                formNotComplete={!!Object.keys(errors).length || !values.input.length}
              />
              {/* </div> */}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ButtonToForm.propTypes = {
  buttonTitle: PropTypes.node,
  buttonIcon: PropTypes.node,
  inputPlaceholder: PropTypes.string,
  validationSchema: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func.isRequired,
  buttonOnClick: PropTypes.func,
  className: PropTypes.string,
  iconTitle: PropTypes.string,
};

ButtonToForm.defaultProps = {
  buttonTitle: null,
  buttonIcon: null,
  inputPlaceholder: '',
  validationSchema: undefined,
  buttonOnClick: undefined,
  className: '',
  iconTitle: undefined,
};

export default ButtonToForm;
