import { post } from '@api/apiMethods';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';
import { TagProps } from '@intus-ui/components/Tags/Tag';
import { IAllStarsMeasures } from 'Polypharmacy/types/STARSMeasures';

export type IRisingStarsData = {
  patientAdherence: IPatientRisingStarsData[];
};

export type IPatientRisingStarsData = {
  id: number;
  name: string;
  risingStarsData: IRisingStarMeasureData[];
};

export type IRisingStarMeasureData = {
  measure: IAllStarsMeasures;
  fillCount: number;
  fillDate: string;
  percentageDaysCovered: number;
  fillDueDate: string | null;
  status: IPatientFirstFillStatus | null;
};

export const allPatientFirstFillStatus = ['discussed', 'to_discuss', 'de_prescribed'] as const;

export type IPatientFirstFillStatus = {
  status: (typeof allPatientFirstFillStatus)[number];
};

export const firstFillStatusToDescription: Record<IPatientFirstFillStatus['status'], string> = {
  discussed: 'Discussed',
  de_prescribed: 'De-prescribed',
  to_discuss: 'To discuss',
};

export const firstFillStatusToColor: Record<IPatientFirstFillStatus['status'], TagProps['color']> =
  {
    discussed: 'green',
    de_prescribed: 'maroon',
    to_discuss: 'blue',
  };

export function getRisingStars(
  filters: IPolypharmacyFilters,
  patientIds?: number[],
  ignoreSingleFillRequirement?: boolean
) {
  return post<IRisingStarsData>(`polypharmacy/rising-stars`, {
    filters,
    patientIds,
    ignoreSingleFillRequirement,
  });
}
