import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formatDate } from '@util/dateFunctions';

import { getModalPatientList } from '@global-state/redux/dashboardSlice';

import { HospitalizationsModalPropType } from 'Homes/modalPropTypes/hospitalizationsModalPropType';

import { Container, Row, Col } from 'react-bootstrap';
import { SpinnerOrError } from '@intus-ui';
import { defaultChartColors } from '@intus-ui/styles/SecondaryColors';
import LineChart from '@intus-ui/components/graphs/LineChart';
import BarChart from '@intus-ui/components/graphs/BarChart';
import Quad from 'Dashboard/Quad';
import TextData from 'Dashboard/QuadContent/TextData';
import BigTitle from 'Dashboard/QuadContent/BigTitle';
import PercentageRow from '../PercentageRow';
import PercentageCol from '../PercentageCol';

import '@intus-ui/styles/Utilities.scss';

class HospitalizationAnalytics extends React.Component {
  componentDidMount() {}

  isActive(route) {
    const { location } = this.props;
    return location.pathname.startsWith(route);
  }

  render() {
    const { error, subcards, getPatientList, isDashboard } = this.props;
    let inpatientAdmissionsData;
    let erAdmissionsData;
    let admissionsGraphOptions;
    let admissionsGraphData;
    let totalLOSData;
    const totalLOS = [];
    const totalLOSLabels = [];

    if (subcards !== undefined && subcards.inpatientAdmissionsPer100MMTrendline !== undefined) {
      // All data formatting for Admissions line chart
      inpatientAdmissionsData = subcards?.inpatientAdmissionsPer100MMTrendline;
      erAdmissionsData = subcards?.erVisitsPer100MMTrendline;
      const admissionsChartLabels = [];
      const inpatientAdmissionsChartData = [];
      const erAdmissionsChartData = [];
      inpatientAdmissionsData.map((dataPoint) =>
        admissionsChartLabels.push(formatDate(dataPoint.month, 'MMM YYYY'))
      );
      inpatientAdmissionsData.map((dataPoint) =>
        inpatientAdmissionsChartData.push(dataPoint.value)
      );
      erAdmissionsData.map((dataPoint) => erAdmissionsChartData.push(dataPoint.value));

      admissionsGraphData = {
        labels: admissionsChartLabels,
        datasets: [
          {
            label: 'Inpatient Admissions per 100MM',
            data: inpatientAdmissionsChartData,
            fill: false,
            backgroundColor: defaultChartColors.lightLilac,
            borderColor: defaultChartColors.lightLilac,
            lineTension: 0,
          },
          {
            label: 'ER Visits per 100MM',
            data: erAdmissionsChartData,
            fill: false,
            backgroundColor: defaultChartColors.deepBlue,
            borderColor: defaultChartColors.deepBlue,
            lineTension: 0,
          },
        ],
      };

      totalLOSData = subcards?.totalInpatientLengthOfStayTrendLine;
      totalLOSData.map((dataPoint) => totalLOSLabels.push(formatDate(dataPoint.month, 'MMM YYYY')));
      totalLOSData.map((dataPoint) => totalLOS.push(dataPoint.value));

      /*
      Finding the maximum point from all the data to find a suitable
      suggestedMax value for the y-axis. This is to prevent the line
      chart from overlapping with the legend.
      */
      const allData = admissionsGraphData.datasets.map((dataset) => dataset.data);
      const allMaxData = allData.map((dataset) => Math.max(...dataset));
      const maxDataPoint = Math.max(...allMaxData) + 5;

      admissionsGraphOptions = {
        elements: { point: { hitRadius: 100 } },
        padding: 15,
        legend: {
          align: 'center',
        },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 4,
                fontSize: 12,
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'Time',
                fontSize: 12,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                /*
                Suggested Max can be calculated based on the maximum data point.
                Calculated above.
                Check LineChart.jsx component for details.
                */
                suggestedMax: maxDataPoint,
                maxTicksLimit: 8,
                fontSize: 12,
                precision: 0,
              },
              gridLines: {
                drawBorder: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'Amount',
                fontSize: 12,
                fontStyle: 'bold',
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) label += ': ';
              label += Math.round(tooltipItem.yLabel * 100) / 100;
              return label;
            },
          },
        },
      };
    }

    if (
      error ||
      subcards === undefined ||
      subcards.inpatientAdmissionsPer100MMTrendline === undefined
    ) {
      return <SpinnerOrError error={error} />;
    }
    return (
      <Container
        fluid
        className="bg-light m-0 h-100 w-100 d-flex flex-column overflow-hidden font-weight-normal pt-2 pb-3"
      >
        <div className="w-100 h-100 d-flex flex-column">
          {/* First row of popup with ER + Inpatient information and Inpatient Readmission */}
          <PercentageRow isDashboard={isDashboard}>
            <PercentageCol isDashboard={isDashboard}>
              <Quad
                title="ER and Inpatient (Past 30 Days)"
                onClick={
                  subcards?.inpatientAdmissions.value > 0 || subcards.erVisits.value > 0
                    ? () => {
                        getPatientList(
                          [
                            {
                              patientList: {
                                patientList: subcards.erVisits.ids,
                              },
                              listTitle: 'ER Visits',
                            },
                            {
                              patientList: {
                                patientList: subcards.inpatientAdmissions.ids,
                              },
                              listTitle: 'Inpatient Admissions',
                            },
                          ],
                          'erAdmissions',
                          'ER and Inpatient (Past 30 Days)',
                          this.isActive('/home') ? 'home' : 'dashboard'
                        );
                      }
                    : null
                }
                padding="p-3"
                scrollable
              >
                <Row className="w-100 h-100 pb-4">
                  <Col
                    lg={6}
                    className="d-flex flex-column justify-content-center align-items-center border-right h-100"
                  >
                    <div className="d-flex flex-column">
                      <BigTitle title="ER Visits" secondary className="font-weight-bold" />
                      <TextData
                        data={
                          subcards ? (
                            subcards.erVisits.value.toString()
                          ) : (
                            <SpinnerOrError error={error} />
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    className="d-flex flex-column justify-content-center align-items-center h-100"
                  >
                    <div className="d-flex flex-column">
                      <BigTitle
                        title="Inpatient Admissions"
                        secondary
                        className="font-weight-bold"
                      />
                      <TextData
                        data={
                          subcards ? (
                            subcards.inpatientAdmissions.value.toString()
                          ) : (
                            <SpinnerOrError error={error} />
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Quad>
            </PercentageCol>
            <PercentageCol isDashboard={isDashboard}>
              <Quad
                title="Inpatient Readmission (Past 30 Days)"
                onClick={
                  subcards?.inpatientReadmissionsPast30Days.value > 0
                    ? () => {
                        getPatientList(
                          [
                            {
                              patientList: {
                                patientList: subcards.inpatientReadmissionsPast30Days.ids,
                              },
                              listTitle: 'Inpatient Readmissions',
                            },
                          ],
                          'inpatientReadmissions',
                          'Inpatient Readmissions',
                          this.isActive('/home') ? 'home' : 'dashboard'
                        );
                      }
                    : null
                }
              >
                <Row className="w-100 h-100 justify-content-center">
                  <Col
                    lg={6}
                    className="h-100 d-flex flex-column justify-content-center align-items-center"
                  >
                    <TextData
                      subtext={{ text: 'Readmissions', vertical: true }}
                      data={
                        subcards ? (
                          subcards.inpatientReadmissionsPast30Days.value.toString()
                        ) : (
                          <SpinnerOrError error={error} />
                        )
                      }
                    />
                  </Col>
                </Row>
              </Quad>
            </PercentageCol>
          </PercentageRow>
          {/* Second row of popup with Total Length of Inpatient Stay and Admissions/Visits */}
          <PercentageRow isDashboard={isDashboard}>
            <PercentageCol isDashboard={false} isHalf>
              <Quad
                scrollable
                title="Total Length of Inpatient Stay"
                padding="justify-content-center pb-2 pt-1"
              >
                <div className="h-100 w-70">
                  <BarChart
                    xData={totalLOSLabels}
                    yData={totalLOS}
                    yLabel="Total Number of Days"
                    xAxisLabel="Month"
                    yAxisLabel="Total Days"
                    legendDisplay
                    bgColors={[defaultChartColors.tealBlue]}
                  />
                </div>
              </Quad>
            </PercentageCol>
            <PercentageCol isDashboard={false} isHalf>
              <Quad scrollable title="Admissions/Visits" padding="justify-content-center pb-2 pt-1">
                {/* Line graph for admissions/visits  */}
                <div className="h-100 w-70">
                  <LineChart
                    data={admissionsGraphData}
                    options={admissionsGraphOptions}
                    responsive={false}
                    legendPosition="top"
                  />
                </div>
              </Quad>
            </PercentageCol>
          </PercentageRow>
        </div>
      </Container>
    );
  }
}

HospitalizationAnalytics.propTypes = {
  subcards: HospitalizationsModalPropType,
  getPatientList: PropTypes.func.isRequired,
  error: PropTypes.string,
  isDashboard: PropTypes.bool,
  location: PropTypes.instanceOf(Object).isRequired,
};

HospitalizationAnalytics.defaultProps = {
  subcards: undefined,
  error: undefined,
  isDashboard: undefined,
};

const mapState = (state) => ({
  subcards: state.homepage.modalContent,
  error: state.homepage.homeError,
});

const mapDispatch = (dispatch) => ({
  getPatientList: (list, item, modalTitle, backLoc) =>
    dispatch(getModalPatientList(list, item, modalTitle, backLoc)),
});

export default withRouter(connect(mapState, mapDispatch)(HospitalizationAnalytics));
