import React from 'react';

import { dateDifference, newDate, formatDate } from '@util/dateFunctions';
import { pluralize, toSentenceCase } from '@util/utilFunctions';
import { getStringReplacement } from '@util/stringReplacements';

import { Text, Divider, layout } from '@intus-ui';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';

import StatBar from '../StatBar';

const ProfileInfos = ({ title, value }) => (
  <div style={styles.profileInfos}>
    <Text type="caption-bold">{title}</Text>
    <Text type="caption">{value}</Text>
  </div>
);

const ProfileStatsSummary = ({ acuityLevels, patient, statsTimeFilter, recentStats }) => {
  const enrollmentDuration = `(${pluralize(
    'yr',
    dateDifference(patient.disenrollmentDate || newDate(), patient.enrollmentDate, 'years'),
    true
  )} ${pluralize(
    'month',
    dateDifference(patient.disenrollmentDate || newDate(), patient.enrollmentDate, 'months') % 12,
    true
  )})`;

  return (
    <div style={styles.leftSideMenu}>
      <div style={styles.profileInfosBox}>
        <Text type="caption-bold" color="navy">
          Profile Info:
        </Text>
        <ProfileInfos
          title="Age:"
          value={`${dateDifference(newDate(), patient.dateOfBirth, 'years')}`}
        />
        <ProfileInfos
          title="Living Situation:"
          value={toSentenceCase(
            (acuityLevels[0] || { livingSituation: 'unknown' }).livingSituation
          )}
        />
        <ProfileInfos title={`${getStringReplacement('Team')}:`} value={patient.team} />
        <ProfileInfos title={`${getStringReplacement('Center')}:`} value={patient.facility} />
        <ProfileInfos
          title="Enrolled:"
          value={`${formatDate(patient.enrollmentDate, 'MM/DD/YYYY')} ${enrollmentDuration} `}
        />

        {patient.disenrollmentDate && (
          <ProfileInfos
            title=" Disenrolled:"
            value={`${formatDate(patient.disenrollmentDate, 'MM/DD/YYYY')}`}
          />
        )}
      </div>
      <Divider color="disabled" />
      <div style={styles.profileInfosBox}>
        <div style={styles.profileInfosBoxHeader}>
          <Text type="caption-bold" color="navy">
            Recent Stats:
          </Text>
          {statsTimeFilter}
        </div>
        {recentStats ? (
          <div style={styles.recentStats}>
            <StatBar
              statData={{
                name: 'Inpatient Admissions',
                count: recentStats['Inpatient admissions'].value,
              }}
              linkTo="Risk Trendline"
            />
            <StatBar
              statData={{
                name: 'Total Length of Stay',
                count: recentStats['Total inpatient length of stay'].value,
              }}
              linkTo="Risk Trendline"
            />
            <StatBar
              statData={{
                name: 'ER Visits',
                count: recentStats['ER Admissions'].value,
              }}
              linkTo="Risk Trendline"
            />
            <StatBar
              statData={{
                name: 'Falls',
                count: recentStats.Falls.value,
              }}
              linkTo="Falls"
            />
          </div>
        ) : (
          <div style={{ ...layout.centeredContentContainer, padding: 20 }}>
            <CenteredSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  leftSideMenu: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    borderRadius: 20,
    boxShadow: '0px 0.4px 0.4rem 0.06rem rgba(0, 0, 0, 0.2)',
    padding: 10,
    gap: 10,
  },
  profileInfosBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    gap: 5,
  },
  profileInfosBoxHeader: {
    ...layout.rowContainer,
    alignItems: 'center',
    justifyContent: 'space-between',
    overflowY: 'visible',
  },
  profileInfos: {
    display: 'flex',
    gap: 5,
  },
  recentStats: {},
};

export default ProfileStatsSummary;
