export const environment = {
  development: 'development',
  staging: 'staging',
  test: 'test',
  production: 'production',
  checkFlag: 'checkFlag',
};

export const testGroup = { beta: 'beta', 'A/B': 'A/B', all: 'all' }; // Testers
/**
 * whitelist.localStorage is a list of localStorage keys that won't be
 * deleted by window.localStorage.clear()
 */
export const whitelist = { localStorage: ['surveys'] };
