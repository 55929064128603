/* eslint-disable no-nested-ternary */
import { useState, useRef, useEffect } from 'react';

import { searchFilter } from '@util/utilFunctions';

import { textColors, input } from '@intus-ui/styles/SecondaryColors';
import { useHover } from '@intus-ui/hooks';
import DropdownList from '@intus-ui/components/Dropdown/DropdownList';
import DropdownItem from '@intus-ui/components/Dropdown/DropdownItem';
import DateRangeDropdownList from '@intus-ui/components/DateRange/DropdownList';
import { monthsOptions } from '@intus-ui/components/DateRange/config';
import { isObject } from 'lodash';
import { PopperPortal } from '@intus-ui/components/Popper/PortalPopper';
import Icon from '../Icon';
import { fonts } from '../../fonts';
import Text from '../Text';
import './input.css';
import '@intus-ui/components/Dropdown/style.scss';

const Input = (props) => {
  const {
    id,
    suggestionLabel,
    searchable, // input text  or selected searchable
    type, //  text || select || textarea
    leftIcon,
    rightIcon,
    name,
    options,
    innerLabel,
    onChange,
    fluid,
    value,
    range,
    placeholder,
    style,
    disabled,
    color,
    size,
    children,
    suggestions,
    onApplyRange,
    onCancelRange,
    noMatchLabel = '',
    withCustomDateRanges,
    dateRangeDirection,
    isCustomDropdownList,
    error,
    ...rest
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDateRange, setShowDateRange] = useState(false);
  const [hasSuggestion, setSuggestion] = useState(false);
  const [hoverRef, isHovered] = useHover();
  const dropDownRef = useRef(null);
  const inputRef = useRef(null);

  const isSelect = type === 'select';
  const isCustomDropdown = !!children;

  const textAreaStyles = {
    default: {
      color: disabled ? textColors.disabled : textColors[color],
      ...fonts[size],
      ...style,
      minHeight: 90,
      borderRadius: '5px',
      gap: 10,
      padding: '10px 15px',
      border: '1px solid #9D9D9D',
      resize: 'none',
      boxShadow: 'none',
      backgroundColor:
        !disabled && isHovered ? input.hover : disabled ? textColors.disabled : input.default,
    },
  };

  useEffect(() => {
    setSearchKeyword(value);
    if (isCustomDropdownList && isCollapsed) {
      setIsCollapsed(!isCollapsed);
    }
  }, [value]);

  useEffect(() => {
    setFilteredOptions(withCustomDateRanges ? [...range, monthsOptions.customRange] : options);
  }, [options, range]);

  useEffect(() => {
    if (searchable) {
      setFilteredOptions(filteredOptions);
    }
  }, [filteredOptions]);

  useEffect(() => {
    if (!isCollapsed) {
      setShowDateRange(false);
    }
  }, [isCollapsed]);

  const onClickOutside = () => {
    setIsCollapsed(false);
  };

  const handleOnFocus = () => {
    if (searchable) {
      setFilteredOptions(options);
      setIsCollapsed(true);
    }
  };

  const handleOnChange = (e) => {
    const keyword = e.target.value;

    if (searchable) {
      setIsCollapsed(true);
      const searchObjectProp = options[0]?.label && 'label';
      const filterResults = searchFilter(keyword, options, searchObjectProp);
      const match = filterResults.find((el) => {
        const currentMatch = el.label ? el.label : el;
        return currentMatch.toLowerCase() === keyword.toLowerCase();
      });
      setFilteredOptions(filterResults);
      setSearchKeyword(match?.label || match);
      onChange(match);

      //  suggestionLabel controls if the user input appears as an option in the dropdown
      // if there is no label to put in parentheses, we set it to ''
      if (suggestions && !suggestionLabel && match === undefined) {
        filterResults.unshift(`${keyword}`);
        setSuggestion(true);
      } else {
        setSuggestion(false);
      }
    } else {
      onChange(keyword);
      setSearchKeyword(keyword);
    }
  };

  const handleTriggerDropdown = () => {
    if (disabled) return;
    if (searchable) {
      inputRef.current.focus();
    }
    setIsCollapsed(!isCollapsed);
  };

  const width = style?.width || 200;

  if (type === 'textarea') {
    return (
      <div>
        <textarea
          className="textarea"
          placeholder={placeholder}
          style={textAreaStyles.default}
          onChange={onChange}
          value={value}
          ref={hoverRef}
          {...rest}
        />
      </div>
    );
  }

  return (
    <div
      id={`${id}-container` || name}
      name={name}
      ref={dropDownRef}
      style={{
        width: fluid ? 'max-content' : width,
        inlineSize: fluid && 'max-content',
        blockSize: fluid && 'max-content',
        whiteSpace: fluid && 'nowrap',
        position: 'relative',
        ...style,
      }}
      {...rest}
    >
      <div
        ref={hoverRef}
        style={{
          borderRadius: '5px',
          border: '1px solid',
          borderColor: isCollapsed
            ? textColors.link
            : disabled
            ? '#C8C8C8'
            : error
            ? '#CD3B3B'
            : textColors.caption,
          display: 'flex',
          alignItems: 'center',
          backgroundColor:
            !disabled && isHovered ? input.hover : disabled ? textColors.disabled : input.default,
          height: '35px',
          cursor: 'pointer',
          padding: '5px',
        }}
      >
        {leftIcon && (
          <div
            onClick={handleTriggerDropdown}
            onKeyDown={handleTriggerDropdown}
            role="none"
            style={{ padding: '0 10px' }}
            disabled={disabled}
          >
            <Icon
              name={leftIcon}
              alt={leftIcon}
              style={{
                color: disabled ? '#C8C8C8' : isCollapsed ? input.active : input.collapsed,
              }}
            />
          </div>
        )}
        {isSelect ? (
          <div
            style={{
              flex: 5,
              display: 'flex',
              padding: !leftIcon && !innerLabel ? '8.5px 0px 8.5px 8.5px' : 0,
              height: '100%',
              alignItems: 'center',
              width: leftIcon || rightIcon ? '75%' : '100%',
            }}
            onClick={handleTriggerDropdown}
            onKeyDown={handleTriggerDropdown}
            role="none"
            disabled={disabled}
          >
            {innerLabel && (
              <div
                style={{
                  display: 'flex',
                  gap: 5,
                  width: '100%',
                  padding: '8.5px 0px 8.5px 8.5px',
                }}
              >
                <Text
                  type="subtitle"
                  onClick={handleTriggerDropdown}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 'fit-content',
                    maxWidth: '40%',
                  }}
                >
                  {innerLabel}
                </Text>
                <Text
                  type="body"
                  onClick={handleTriggerDropdown}
                  color={disabled ? 'disabled' : 'body'}
                  style={{
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {isObject(value) ? value.label : value}
                </Text>
              </div>
            )}

            {!innerLabel && value && (
              <Text
                type="body"
                style={{
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                onClick={handleTriggerDropdown}
              >
                {value}
              </Text>
            )}

            {!innerLabel && !value && (
              <Text type="body" color="caption" onClick={handleTriggerDropdown}>
                {placeholder}
              </Text>
            )}
          </div>
        ) : (
          <input
            id={id}
            className="inputComponent"
            ref={inputRef}
            style={
              type === 'text'
                ? {
                    margin: 10,
                    height: '100%',
                    width: leftIcon ? '75%' : '100%',
                    border: 'none',
                    outlineWidth: 0,
                    borderRadius: '5px',
                    backgroundColor: disabled
                      ? textColors.disabled
                      : isHovered
                      ? input.hover
                      : input.default,
                  }
                : {
                    width: leftIcon && '75%',
                    flex: !leftIcon && 1,
                    height: '100%',
                    border: 'none',
                    outlineWidth: 0,
                    borderRadius: '5px',
                    backgroundColor: disabled
                      ? textColors.disabled
                      : isHovered
                      ? input.hover
                      : input.default,
                  }
            }
            placeholder={placeholder}
            type="text"
            value={searchKeyword}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            disabled={disabled}
            autoComplete="off"
            {...rest}
          />
        )}
        {error && (
          <div style={{ padding: '0 10px' }}>
            {' '}
            <Icon name="Alert" color="#CD3B3B" />{' '}
          </div>
        )}
        {rightIcon && (
          <div
            onClick={handleTriggerDropdown}
            onKeyDown={handleTriggerDropdown}
            role="none"
            style={{ padding: '0 10px' }}
            disabled={disabled}
          >
            <Icon
              alt={rightIcon}
              name={rightIcon}
              style={{
                color: disabled ? '#C8C8C8' : isCollapsed ? input.active : input.collapsed,
              }}
            />
          </div>
        )}
      </div>
      {isCollapsed && withCustomDateRanges && (
        <PopperPortal anchorRef={dropDownRef} onClickOutside={onClickOutside}>
          <DateRangeDropdownList
            styles={{ width: fluid ? '100%' : width }}
            showDateRange={showDateRange}
            onChange={onChange}
            onApplyRange={onApplyRange}
            onCancelRange={onCancelRange}
            setShowDateRange={setShowDateRange}
            filteredOptions={filteredOptions}
            hasSuggestion={hasSuggestion}
            suggestionLabel={suggestionLabel}
            noMatchLabel={noMatchLabel}
            setIsCollapsed={setIsCollapsed}
            dateRangeDirection={dateRangeDirection}
            value={value}
          />
        </PopperPortal>
      )}

      {isCollapsed && !isCustomDropdown && !withCustomDateRanges && (
        <PopperPortal anchorRef={dropDownRef} onClickOutside={onClickOutside}>
          <DropdownList styles={{ width: fluid ? '100%' : width, overflowY: 'auto' }}>
            {filteredOptions.length === 0 ? (
              <DropdownItem
                empty
                hasSuggestion={hasSuggestion}
                suggestionLabel={suggestionLabel}
                noMatchLabel={noMatchLabel}
              />
            ) : (
              filteredOptions.map((option, i) => {
                const key = i;

                return (
                  <DropdownItem
                    key={key}
                    index={i}
                    suggestionLabel=""
                    option={option}
                    onSelect={onChange}
                    setIsCollapsed={setIsCollapsed}
                  />
                );
              })
            )}
          </DropdownList>
        </PopperPortal>
      )}

      {isCollapsed && isCustomDropdown && !isCustomDropdownList && (
        <PopperPortal anchorRef={dropDownRef} onClickOutside={onClickOutside}>
          <DropdownList styles={{ width }}>{children}</DropdownList>
        </PopperPortal>
      )}
      {isCollapsed && isCustomDropdown && isCustomDropdownList && (
        <PopperPortal anchorRef={dropDownRef} onClickOutside={onClickOutside}>
          {children}
        </PopperPortal>
      )}
    </div>
  );
};

export default Input;
