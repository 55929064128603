import { useEffect, useState, useContext } from 'react';
import { Text } from '@intus-ui/index';
import { MeetingContext } from './context/MeetingContext';

const Timer = ({
  runState, // string: RUNNING, STOPPED, PAUSED, or INIT
  lapMessage, // string: Message to be displayed alongside lap timer
  totalMessage, // string: Message to be displayed alongside total timer
  meetingDuration, // number: Overall length of the meeting
  currentAgendaItem, // any: Agenda item currently being discussed
  numAgendaItems, // number: Total number of agenda items
  warningThreshold, // number: % as a decimal of when to warn on low time (0.9, 0.55, etc)
  onLowTime, // fucntion: Callback function fired when low time warning starts being displayed
  onOverTime, // function: Callback function fired when over time for a given agenda item
  showTotalTimer, // bool: Whether or not to show the overall timer
  showLapTimer, // bool: Whether or not to show the overall timer
  setTimerState, // function (React hook): to access state of timer inside of parent component
}) => {
  const [agendaItemMap, setAgendaItemMap] = useState({});
  const [currentDurationCounter, setCurrentDurationCounter] = useState(
    agendaItemMap[currentAgendaItem.id] != null ? agendaItemMap[currentAgendaItem.id] : 0
  );
  const [overallDurationCounter, setOverallDurationCounter] = useState(0);

  const secDuration = meetingDuration * 60;
  const secPerItem = Math.floor(secDuration / numAgendaItems);

  // const showWarning =
  //   (warningThreshold
  //     ? currentDurationCounter + warningThreshold * secPerItem > secPerItem
  //     : currentDurationCounter + 0.1 * secPerItem > secPerItem) &&
  //   currentDurationCounter <= secPerItem;

  const lapMinutes = Math.floor(currentDurationCounter / 60);
  const lapSeconds = currentDurationCounter % 60;

  const totalMinutes = Math.floor(overallDurationCounter / 60);
  const totalSeconds = overallDurationCounter % 60;

  const { warning, setWarning, overtime, setOvertime } = useContext(MeetingContext);

  useEffect(() => {
    if (agendaItemMap[currentAgendaItem.item] == null) {
      setAgendaItemMap({
        ...agendaItemMap,
        [currentAgendaItem.item]: 0,
      });
      setCurrentDurationCounter(0);
      setWarning(false);
      setOvertime(false);
    } else {
      setCurrentDurationCounter(agendaItemMap[currentAgendaItem.item]);
      if (
        warningThreshold
          ? Math.floor(agendaItemMap[currentAgendaItem.item] + warningThreshold * secPerItem) >=
            secPerItem
          : Math.floor(agendaItemMap[currentAgendaItem.item] + 0.1 * secPerItem) >= secPerItem
      ) {
        setWarning(true);
      } else {
        setWarning(false);
      }

      if (agendaItemMap[currentAgendaItem.item] >= secPerItem) {
        setOvertime(true);
      } else {
        setOvertime(false);
      }
    }
    const interval = setInterval(() => {
      if (runState === 'RUNNING') {
        setTimerState({
          ...agendaItemMap,
          [currentAgendaItem.item]: agendaItemMap[currentAgendaItem.item] + 1,
        });

        setAgendaItemMap({
          ...agendaItemMap,
          [currentAgendaItem.item]: agendaItemMap[currentAgendaItem.item] + 1,
        });

        if (
          warningThreshold
            ? Math.floor(currentDurationCounter + warningThreshold * secPerItem) + 1 === secPerItem
            : Math.floor(currentDurationCounter + 0.1 * secPerItem) + 1 === secPerItem
        ) {
          setWarning(true);
          onLowTime();
        } else if (currentDurationCounter + 1 === secPerItem) {
          setOvertime(true);
          onOverTime();
        }

        setOverallDurationCounter(overallDurationCounter + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [runState, currentAgendaItem, warningThreshold, agendaItemMap]);

  return (
    <>
      <div
        style={{
          // backgroundColor: '#2c62e7',
          // padding: '1rem',
          // color: 'white',
          display: 'flex',
          margin: 'auto',
          // flexGrow: 1,
          // alignItems: 'center',
          // justifyContent: 'center',
          // fontFamily: "'Inter', sans-serif",
          // gap: '20px',
        }}
      >
        <div style={{ fontWeight: '500', display: 'flex' }}>
          {showLapTimer && (
            <div style={{ margin: '0 20px' }}>
              <Text type="subtitle" style={{ color: 'white', marginRight: '1.5rem' }}>
                {lapMessage}
              </Text>
              <Text style={{ color: 'white' }} type="title">
                {`${lapMinutes}:${lapSeconds < 10 ? 0 : ''}${lapSeconds}`}
              </Text>
            </div>
          )}
          {showTotalTimer && (
            <div style={{ margin: '0 20px' }}>
              <Text type="subtitle" style={{ color: 'white', marginRight: '1.5rem' }}>
                {totalMessage}
              </Text>
              <Text style={{ color: 'white' }} type="title">
                {`${totalMinutes}:${totalSeconds < 10 ? 0 : ''}${totalSeconds}`}
              </Text>
            </div>
          )}
        </div>
      </div>
      {warning && !overtime && !(overallDurationCounter > secDuration) && (
        <span style={{ color: 'white', margin: '0 20px' }}>Time almost up!</span>
      )}
      {(overtime || overallDurationCounter > secDuration) && (
        <span style={{ color: 'white', margin: '0 20px' }}>Over time!</span>
      )}
    </>
  );
};

export default Timer;
