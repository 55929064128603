import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '@intus-ui/styles/Profile.scss';
import '@intus-ui/styles/Utilities.scss';
import riskBreakup from '@util/riskGrouping';
import { formatDate, newDate } from '@util/dateFunctions';
import RiskLineGraph from './RiskLineGraph';

const admitGenerator = (str, stackPosition) => {
  if (!str) return null;
  const yVal = -20 * (stackPosition || 0) - 10;
  return (
    <svg x="-10" y={yVal} width={(str.length + 1) * 10} height="20">
      {str}
      <rect rx="5" ry="5" width={(str.length + 1) * 10} height="20" fill="gray" />
      <text
        fill="white"
        x="50%"
        y="60%"
        fontWeight="bold"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {str}
      </text>
    </svg>
  );
};

class AcuityGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showIncidents: true,
      admitsFilter: 'All',
      type: '',
      acuityPoint: {},
    };
  }

  render() {
    const changeType = (newType) => {
      this.setState({ type: newType });
    };
    const changePoint = (newPoint) => {
      this.setState({ acuityPoint: newPoint });
    };
    const { acuityHist, hosps, incidentsByCategory } = this.props;
    const { showIncidents, admitsFilter, type, acuityPoint } = this.state;
    const filteredHist = [];
    const uniqueDates = [];
    let maxScore = riskBreakup.HIGH.lowerLimit;
    if (acuityHist) {
      acuityHist.forEach((acuity) => {
        if (acuity.acuityScore > maxScore) {
          maxScore = acuity.acuityScore;
        }
        if (!uniqueDates.includes(formatDate(acuity.createdAt, 'YYYY-MM-DD'))) {
          uniqueDates.push(formatDate(acuity.createdAt, 'YYYY-MM-DD'));
          filteredHist.push(acuity);
        }
      });
    }
    maxScore = Math.max(maxScore * 1.2, 80);
    const filteredAcuityHist = filteredHist.reverse();

    const incidents = [];
    Object.keys(incidentsByCategory)
      .sort()
      .forEach((categoryName) => {
        const categoryObj = {
          id: categoryName.toLowerCase(),
          data: (showIncidents ? incidentsByCategory[categoryName] : []).map((incident) => ({
            x: formatDate(incident.dateTimeOccurred, 'YYYY-MM-DD'),
            y: maxScore,
            ...incident,
          })),
        };
        incidents.push(categoryObj);
      });
    const toggleIncidents = () => {
      this.setState((state) => ({ ...state, showIncidents: !state.showIncidents }));
    };

    const filterByAdmitType = () => {
      let filtered = [];
      if (admitsFilter === 'None') filtered = [];
      else if (admitsFilter === 'All') filtered = hosps;
      else if (admitsFilter === 'Emergency Room')
        filtered = hosps.filter(({ iconText }) => iconText === 'ER');
      else if (admitsFilter === 'Inpatient')
        filtered = hosps.filter(({ iconText }) => iconText === 'INP');
      else if (admitsFilter === 'SNF')
        filtered = hosps.filter(({ iconText }) => iconText === 'SNF');
      else if (admitsFilter === 'ALF')
        filtered = hosps.filter(({ iconText }) => iconText === 'ALF');
      else filtered = hosps.filter(({ iconText }) => iconText === 'LTC');

      // we cannot do this in RiskLineGraph because the graph renders before we
      // get a chance to conditionally create this badge
      const sameDateMap = {};
      const filteredWithIcon = filtered.map((hosp) => {
        const date = formatDate(hosp.startDate, 'YYYY-MM-DD');
        if (sameDateMap[date] === undefined) sameDateMap[date] = -1;
        sameDateMap[date] += 1;
        return { ...hosp, icon: admitGenerator(hosp.iconText, sameDateMap[date]) };
      });
      return filteredWithIcon;
    };
    const setAdmitsFilter = (filter) => {
      this.setState({ admitsFilter: filter });
    };

    return (
      <Row className="w-100 h-100 pt-2 m-0 overflow-scroll bg-white px-3">
        <Col lg={12} className="height-90 pb-1 px-5">
          {acuityHist && hosps ? (
            <RiskLineGraph
              acuityPoint={acuityPoint}
              changePoint={changePoint}
              type={type}
              changeType={changeType}
              showIncidents={showIncidents}
              toggleIncidents={toggleIncidents}
              admitsFilter={admitsFilter}
              setAdmitsFilter={setAdmitsFilter}
              maxScore={maxScore}
              data={[
                {
                  id: 'vertical-marker',
                  data: [
                    { x: formatDate(newDate('2021-01-17'), 'YYYY-MM-DD'), y: maxScore },
                    { x: formatDate(newDate('2021-02-14'), 'YYYY-MM-DD'), y: maxScore },
                  ],
                },
                ...incidents,
                {
                  id: 'hospitalizations',
                  data: filterByAdmitType().map((hosp) => ({
                    x: formatDate(hosp.startDate, 'YYYY-MM-DD'),
                    y: 0,
                    hospIndex: hosp.hospIndex,
                    iconText: hosp.iconText,
                    icon: hosp.icon,
                    lengthOfStay: hosp.lengthOfStay,
                    principalDx: hosp.formattedPrincipalDx,
                    dischargeStatusDesc: hosp.dischargeStatusDesc,
                  })),
                },
                {
                  id: 'low',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (acuity.acuityScore < riskBreakup.MODERATE.lowerLimit) {
                      return {
                        x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                        y: acuity.acuityScore,
                        index: i,
                      };
                    }
                    if (i < filteredAcuityHist.length - 1) {
                      if (filteredAcuityHist[i + 1].acuityScore < riskBreakup.MODERATE.lowerLimit) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'moderate',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (
                      acuity.acuityScore >= riskBreakup.MODERATE.lowerLimit &&
                      acuity.acuityScore < riskBreakup.HIGH.lowerLimit
                    ) {
                      return {
                        x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                        y: acuity.acuityScore,
                        index: i,
                      };
                    }
                    if (i < filteredAcuityHist.length - 1) {
                      if (
                        filteredAcuityHist[i + 1].acuityScore >= riskBreakup.MODERATE.lowerLimit &&
                        filteredAcuityHist[i + 1].acuityScore < riskBreakup.HIGH.lowerLimit
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'high',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (
                      acuity.acuityScore >= riskBreakup.HIGH.lowerLimit &&
                      acuity.acuityScore < riskBreakup.HIGHEST.lowerLimit
                    ) {
                      return {
                        x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                        y: acuity.acuityScore,
                        index: i,
                      };
                    }
                    if (i < filteredAcuityHist.length - 1) {
                      if (
                        filteredAcuityHist[i + 1].acuityScore >= riskBreakup.HIGH.lowerLimit &&
                        filteredAcuityHist[i + 1].acuityScore < riskBreakup.HIGHEST.lowerLimit
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'highest',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (acuity.acuityScore >= riskBreakup.HIGHEST.lowerLimit) {
                      return {
                        x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                        y: acuity.acuityScore,
                        index: i,
                      };
                    }
                    if (i < filteredAcuityHist.length - 1) {
                      if (filteredAcuityHist[i + 1].acuityScore >= riskBreakup.HIGHEST.lowerLimit) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                // to ensure that the point color is correct because nivo renders in order
                {
                  id: 'low-re',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (i < filteredAcuityHist.length - 1) {
                      if (
                        filteredAcuityHist[i + 1].acuityScore >= riskBreakup.MODERATE.lowerLimit &&
                        acuity.acuityScore < riskBreakup.MODERATE.lowerLimit
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'moderate-re',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (i < filteredAcuityHist.length - 1) {
                      if (
                        (filteredAcuityHist[i + 1].acuityScore < riskBreakup.MODERATE.lowerLimit ||
                          filteredAcuityHist[i + 1].acuityScore >= riskBreakup.HIGH.lowerLimit) &&
                        acuity.acuityScore >= riskBreakup.MODERATE.lowerLimit &&
                        acuity.acuityScore < riskBreakup.HIGH.lowerLimit
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'high-re',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (i < filteredAcuityHist.length - 1) {
                      if (
                        (filteredAcuityHist[i + 1].acuityScore < riskBreakup.HIGH.lowerLimit ||
                          filteredAcuityHist[i + 1].acuityScore >=
                            riskBreakup.HIGHEST.lowerLimit) &&
                        acuity.acuityScore >= riskBreakup.HIGH.lowerLimit &&
                        acuity.acuityScore < riskBreakup.HIGHEST.lowerLimit
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'highest-re',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (i < filteredAcuityHist.length - 1) {
                      if (
                        filteredAcuityHist[i + 1].acuityScore < riskBreakup.HIGHEST.lowerLimit &&
                        acuity.acuityScore >= riskBreakup.HIGHEST.lowerLimit
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'low-final',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (i < filteredAcuityHist.length - 1 && i > 0) {
                      // if the scores around you are higher, and you are in low section
                      // or if you're in moderate section but the next one is in low section,
                      // and you+2 is in mod section
                      if (
                        filteredAcuityHist[i + 1].acuityScore >= riskBreakup.MODERATE.lowerLimit &&
                        filteredAcuityHist[i - 1].acuityScore >= riskBreakup.MODERATE.lowerLimit &&
                        acuity.acuityScore < riskBreakup.MODERATE.lowerLimit
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                      if (i < filteredAcuityHist.length - 2) {
                        if (
                          filteredAcuityHist[i + 1].acuityScore < riskBreakup.MODERATE.lowerLimit &&
                          acuity.acuityScore > riskBreakup.MODERATE.lowerLimit &&
                          filteredAcuityHist[i + 2].acuityScore >= riskBreakup.MODERATE.lowerLimit
                        ) {
                          return {
                            x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                            y: acuity.acuityScore,
                            index: i,
                          };
                        }
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'moderate-final',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (i < filteredAcuityHist.length - 1 && i > 0) {
                      // if you are in moderate section AND
                      // if the scores around you are higher OR
                      // if the score after you is lower
                      if (
                        acuity.acuityScore < riskBreakup.HIGH.lowerLimit &&
                        acuity.acuityScore >= riskBreakup.MODERATE.lowerLimit &&
                        ((filteredAcuityHist[i + 1].acuityScore >= riskBreakup.HIGH.lowerLimit &&
                          filteredAcuityHist[i - 1].acuityScore >= riskBreakup.HIGH.lowerLimit) ||
                          filteredAcuityHist[i + 1].acuityScore < riskBreakup.MODERATE.lowerLimit)
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                      // or if you're in higher section but the next one is in mod section,
                      // and you+2 is in higher section
                      if (i < filteredAcuityHist.length - 2) {
                        if (
                          filteredAcuityHist[i + 1].acuityScore < riskBreakup.HIGH.lowerLimit &&
                          filteredAcuityHist[i + 1].acuityScore >=
                            riskBreakup.MODERATE.lowerLimit &&
                          filteredAcuityHist[i + 2].acuityScore >= riskBreakup.HIGH.lowerLimit &&
                          acuity.acuityScore >= riskBreakup.HIGH.lowerLimit
                        ) {
                          return {
                            x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                            y: acuity.acuityScore,
                            index: i,
                          };
                        }
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'high-final',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (i < filteredAcuityHist.length - 1 && i > 0) {
                      // if you are in high section AND
                      // if the scores around you are highest OR
                      // if the score after you is moderate or lower
                      if (
                        acuity.acuityScore < riskBreakup.HIGHEST.lowerLimit &&
                        acuity.acuityScore >= riskBreakup.HIGH.lowerLimit &&
                        ((filteredAcuityHist[i + 1].acuityScore >= riskBreakup.HIGHEST.lowerLimit &&
                          filteredAcuityHist[i - 1].acuityScore >=
                            riskBreakup.HIGHEST.lowerLimit) ||
                          filteredAcuityHist[i + 1].acuityScore < riskBreakup.HIGH.lowerLimit)
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                      // or if you're in higher section but the next one is in high section,
                      // and you+2 is in highest section
                      if (i < filteredAcuityHist.length - 2) {
                        if (
                          filteredAcuityHist[i + 1].acuityScore < riskBreakup.HIGHEST.lowerLimit &&
                          filteredAcuityHist[i + 1].acuityScore >= riskBreakup.HIGH.lowerLimit &&
                          filteredAcuityHist[i + 2].acuityScore >= riskBreakup.HIGHEST.lowerLimit &&
                          acuity.acuityScore >= riskBreakup.HIGHEST.lowerLimit
                        ) {
                          return {
                            x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                            y: acuity.acuityScore,
                            index: i,
                          };
                        }
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
                {
                  id: 'highest-final',
                  data: filteredAcuityHist.map((acuity, i) => {
                    if (i < filteredAcuityHist.length - 1) {
                      // if you are in highest section AND
                      // if the score after you is high or lower
                      if (
                        acuity.acuityScore >= riskBreakup.HIGHEST.lowerLimit &&
                        filteredAcuityHist[i + 1].acuityScore < riskBreakup.HIGHEST.lowerLimit
                      ) {
                        return {
                          x: formatDate(acuity.createdAt, 'YYYY-MM-DD'),
                          y: acuity.acuityScore,
                          index: i,
                        };
                      }
                    }
                    return { x: formatDate(acuity.createdAt, 'YYYY-MM-DD'), y: null };
                  }),
                },
              ]}
            />
          ) : null}
        </Col>
      </Row>
    );
  }
}

AcuityGraph.propTypes = {
  acuityHist: PropTypes.arrayOf(PropTypes.object),
  hosps: PropTypes.arrayOf(PropTypes.object),
  incidentsByCategory: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
};

AcuityGraph.defaultProps = {
  acuityHist: [],
  hosps: [],
  incidentsByCategory: {},
};

const mapState = (state) => ({
  acuityHist: state.acuity.acuityInfo,
  hosps: state.hospitalizations.recentHosps,
  incidentsByCategory: state.patientDetail.incidentsByCategory,
});

export default connect(mapState)(AcuityGraph);
