import { useContext, useEffect } from 'react';

import { log } from '@util/logger';

import { MeetingContext } from './context/MeetingContext';

import Timer from './Timer';

const WARNING_THRESHOLD = 0.5;

const MeetingTimer = () => {
  const { meeting, runState, setTimerState, currentAgendaItem } = useContext(MeetingContext);

  useEffect(() => {
    log.info('currentAgendaItem', currentAgendaItem);
  }, [currentAgendaItem]);

  return (
    <Timer
      runState={runState}
      lapMessage="Time on Participant"
      totalMessage="Total Meeting Time"
      meetingDuration={meeting.runningTimeSeconds / 60}
      currentAgendaItem={currentAgendaItem}
      numAgendaItems={meeting.agendaItems.length}
      warningThreshold={WARNING_THRESHOLD}
      onLowTime={() => log.info('low time callback')}
      onOverTime={() => log.info('over time callback')}
      showTotalTimer
      showLapTimer
      setTimerState={setTimerState}
    />
  );
};
export default MeetingTimer;
