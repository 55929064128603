import { useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import * as userTracking from '@util/userTracking';
import { toUTC, newDate, formatDate } from '@util/dateFunctions';

import { getTrackingEvent } from '@api/api';
import { setCurrentDash } from '@global-state/redux/dashboardSlice';
import {
  createDashboard,
  saveDashboard,
  selectAllCustomDashboards,
  selectCustomDashNames,
} from 'Dashboard/normalizedDashRedux/customDashboardSlice';

import { Col, Nav } from 'react-bootstrap';
import { Icon, SpinnerOrError } from '@intus-ui';
import DashMenuContext from 'Dashboard/DashboardNavigation/DashMenuContext';
import ButtonToForm from 'Dashboard/DashboardNavigation/ButtonToForm';
import { CUSTOM_DASH } from 'Dashboard/dashDataPropTypes/currentDashPropType';
import Quad from 'Dashboard/Quad';

import '@intus-ui/styles/Home.scss';
import '@intus-ui/styles/Utilities.scss';
import { useFeatureFlag } from '@util';
import { getAllCustomDashboards } from '@api/dashboard';
import { useQuery } from '@api/useQuery';

const CustomListPreview = ({ doSetCurrentDash, doCreateDashboard, history, error }) => {
  const { searchVal, closeMenu } = useContext(DashMenuContext);
  const allDashboards = useSelector(selectAllCustomDashboards);
  const dashboardsFiltered = (allDashboards || []).filter(({ name }) =>
    name?.toLowerCase().includes(searchVal.toLowerCase())
  );

  const dashNames = useSelector(selectCustomDashNames);
  const schema = Yup.object().shape({
    input: Yup.mixed()
      .default('')
      .notOneOf([...dashNames], 'No duplicate names allowed'),
  });

  const { featureIsActive: customDashboardsV2IsActive } = useFeatureFlag('custom-dashboard-v2');

  const { data: customDashboardsV2List } = useQuery(() => getAllCustomDashboards());

  const newDash = (newDashName) => {
    doCreateDashboard({ name: newDashName });
  };
  if (error) {
    return (
      <Quad title="Custom Dashboards" padding="py-0 px-3" scrollable>
        <SpinnerOrError error={error} />
      </Quad>
    );
  }
  return (
    <Quad title="Custom Dashboards" padding="py-0 px-3" scrollable>
      <Col className="h-100 w-100 pb-2 px-0">
        <div className="pt-1 px-2 m-0 w-100 align-items-start">
          {!customDashboardsV2IsActive ? (
            <>
              <ButtonToForm
                className="text-white"
                buttonTitle="Add Dash"
                buttonIcon={<Icon name="add" size="small" />}
                validationSchema={schema}
                onSubmit={(newDashName) => {
                  newDash(newDashName);
                  getTrackingEvent({
                    time: formatDate(toUTC(newDate())),
                    page: 'Custom Dashboard in Hamburgermenu',
                    action: `New Custom Dashboard created`,
                  });
                  history.replace({
                    pathname: '/dashboard',
                    state: { editMode: true },
                  });
                  closeMenu();
                }}
              />
              <div className="px-2 m-0 w-100 h-100">
                {dashboardsFiltered ? (
                  dashboardsFiltered.map(({ id, name }, i) => (
                    <Nav.Link
                      key={`${id}-${name}`}
                      as={Link}
                      className="list-nav-link"
                      onClick={() => {
                        getTrackingEvent({
                          time: formatDate(toUTC(newDate())),
                          page: 'Home Page',
                          action: 'Home page loaded',
                        });
                        doSetCurrentDash({ id, type: CUSTOM_DASH });
                        userTracking.logEvent(`Dashboard: Opened dashboard`, {
                          dashboardId: id,
                          dashboardName: name,
                          isShared: false,
                          from: 'Home',
                        });
                      }}
                      to={customDashboardsV2IsActive ? `/customdashboards/${id}` : '/dashboard'}
                    >
                      <div
                        className={`px-1 py-2 w-100 d-flex flex-row align-items-center justify-content-start ${
                          i !== dashboardsFiltered.length - 1 ? 'border-bottom' : 'pb-4'
                        }`}
                      >
                        <Icon name="dashboard" size="medium" className="mx-2" />
                        {name}
                      </div>
                    </Nav.Link>
                  ))
                ) : (
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <h5 className="text-muted">No custom dashboards. Start by creating one.</h5>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="px-2 m-0 w-100 h-100">
              {customDashboardsV2List?.customDashboards?.length > 0 ? (
                customDashboardsV2List.customDashboards?.map(({ id, name }, i) => (
                  <Nav.Link
                    key={`${id}-${name}`}
                    as={Link}
                    className="list-nav-link"
                    onClick={() => {
                      getTrackingEvent({
                        time: formatDate(toUTC(newDate())),
                        page: 'Home Page',
                        action: 'Home page loaded',
                      });
                      userTracking.logEvent(`Dashboard: Opened dashboard`, {
                        dashboardId: id,
                        dashboardName: name,
                        isShared: false,
                        from: 'Home',
                      });
                    }}
                    to={`/customdashboards/${id}`}
                  >
                    <div
                      className={`px-1 py-2 w-100 d-flex flex-row align-items-center justify-content-start ${
                        i !== customDashboardsV2List.customDashboards.length - 1
                          ? 'border-bottom'
                          : 'pb-4'
                      }`}
                    >
                      <Icon name="dashboard" size="medium" className="mx-2" />
                      {name}
                    </div>
                  </Nav.Link>
                ))
              ) : (
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <h5 className="text-muted">No custom dashboards. Start by creating one.</h5>
                </div>
              )}
            </div>
          )}
        </div>
      </Col>
    </Quad>
  );
};

CustomListPreview.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  doSetCurrentDash: PropTypes.func.isRequired,
  doCreateDashboard: PropTypes.func.isRequired,
  error: PropTypes.string,
};

CustomListPreview.defaultProps = {
  error: '',
};

const mapState = (state) => ({
  currentDash: state.dashboard.currentDash.id,
  error: state.dashboard.dashError,
  sectionCheckboxes: state.spreadsheetModal.sectionCheckboxes,
});

const mapDispatch = (dispatch) => ({
  doSetCurrentDash: (dashInfo) => dispatch(setCurrentDash(dashInfo)),
  updateDashboard: (update) => dispatch(saveDashboard(update)),
  doCreateDashboard: (body) => dispatch(createDashboard(body)),
});

export default withRouter(connect(mapState, mapDispatch)(CustomListPreview));
