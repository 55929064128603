import { Icon, RiskBadge, Text } from '@intus-ui';
import { shadesOfBlue, textColors } from '@intus-ui/styles/SecondaryColors';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import riskBreakup from '@util/riskGrouping';
import { formatDate } from '@util/dateFunctions';
import { toSentenceCase, getAgeFromDateOfBirthString } from '@util';
import SortIcon from '@intus-ui/components/List/SortIcon';
import RiskBreakupDropdown from '@intus-ui/components/ParticipantList/filters/RiskBreakupDropdown';

/**
 * Participants must be formatted this way for the list
 * NOTE: property order actually matters! (this is why 'pinned' is first)
 * @param {*an unformatted list of participants from the api} participants
 * @returns a formatted list of participants
 */
export const formatParticipants = (participants) => {
  const filteredList = participants?.filter(
    (patient) =>
      (patient?.firstName && patient?.lastName && patient?.id) || (patient?.name && patient?.id)
  );

  const formattedList = filteredList?.map((patient) => {
    return {
      ...patient,
      pinnedIcon:
        patient?.pinned !== undefined && patient?.pinned ? (
          <Icon name="PinSolid" color={`${shadesOfBlue.intusNavy}`} />
        ) : (
          <Icon name="Pin" color={`${shadesOfBlue.intusNavy}`} />
        ),
      pinned: patient?.pinned,
      id: patient?.id,
      instanceCount: patient?.instanceCount || patient?.count,
      age: getAgeFromDateOfBirthString(patient?.dateOfBirth) || '-',
      livingSituation:
        patient?.acuityLevels && patient.acuityLevels[0].livingSituation
          ? toSentenceCase(patient.acuityLevels[0].livingSituation)
          : 'Unknown',
      acuity:
        patient?.acuityScore ??
        (patient?.acuityLevels ? patient.acuityLevels[0]?.acuityScore : undefined),
      riskLevel:
        patient?.riskLevel ??
        patient?.riskGroup ??
        patient?.riskBadge?.props?.riskGroup ??
        (patient?.acuityLevels ? patient?.acuityLevels[0]?.riskGroup : undefined),
      scoreGroup:
        patient?.scoreGroup ??
        patient?.riskBadge ??
        riskBadge(
          patient?.riskGroup ??
            (patient?.acuityLevels ? patient?.acuityLevels[0]?.riskGroup : riskBreakup.INSUFF.enum),
          !!patient?.disenrollmentDate
        ),
      disenrollDate: patient?.disenrollmentDate
        ? formatDate(patient?.disenrollmentDate, 'MM/DD/YYYY')
        : 'Invalid Date',
      disenrollReason: patient?.disenrollmentReason?.includes('Deceased')
        ? removeFirstWord(patient?.disenrollmentReason)
        : patient?.disenrollmentReason || 'Unknown',
      cohorts: patient?.cohorts,
      admissions: patient?.admissions,
      inpatientAdmissions: patient?.inpatientAdmissions,
      falls: patient?.falls,
      totalLOS: patient?.totalLOS,
      infections: patient?.infections,
      medicineErrors: patient?.medicineErrors,
      emergencyroomReoccurrences: patient?.emergencyroomReoccurrences,
      inpatientReoccurrences: patient?.inpatientReoccurrences,
      majorHarmOrDeathFallIncindents: patient?.majorHarmOrDeathFallIncindents,
      name: patient?.name ? `${patient?.name}` : `${patient?.firstName} ${patient?.lastName}`,
      fallCount: patient?.fallCount,
      erVisitCount: patient?.erVisitCount,
      inpatientAdmissionsCount: patient?.inpatientAdmissionsCount,
    };
  });

  return sortFormattedList(formattedList);
};

const removeFirstWord = (str) => {
  const indexOfSpace = str.indexOf(' ');
  if (indexOfSpace === -1) {
    return '';
  }
  return str.substring(indexOfSpace + 1);
};

const riskBadge = (riskGroup, isDisenrolled) => {
  return (
    <RiskBadge
      riskGroup={riskGroup}
      isDisenrolled={isDisenrolled}
      style={{ height: '25px', width: '90px' }}
    />
  );
};

const sortFormattedList = (formattedList) => {
  // sorts disenrolled patients to the bottom of the list
  return formattedList.sort((a, b) => {
    if (a.disenrollDate === 'Invalid Date') return -1;
    if (b.disenrollDate === 'Invalid Date') return 1;
    return new Date(a.disenrollDate) - new Date(b.disenrollDate);
  });
};

/**
 * Takes in a columnConfg object and returns an array of custom columns
 * @param {Object} columnConfig - columnConfig to create custom columns for
 */
export const createCustomColumns = (columnConfig) => {
  const customColumns = [];
  for (const column of columnConfig) {
    const clientColumn = {
      ...column,
      addOns: [],
    };
    customColumns.push(clientColumn);
    for (const addOn of column.addOns ?? []) {
      if (addOn.type === 'sort') {
        clientColumn.addOns.push({
          type: 'sort',
          element: <SortIcon key={column.field} id={column.field} />,
        });
      } else {
        throw new Error(`Unknown addOn type: ${addOn.type}`);
      }
    }
  }

  renderRiskGroup(customColumns);
  renderAcuityScoreChangeColumn(customColumns);

  return customColumns;
};

function renderRiskGroup(customColumns) {
  // Special case the risk group column.
  // This displays the risk badge (a colored tag with text like Highest in it.)
  // It also allows filtering the list by risk group.
  const riskGroupColumnIndex = customColumns.findIndex((c) => c.field === 'riskGroup');

  customColumns[riskGroupColumnIndex] = {
    align: 'center',
    flex: '0 0 155px',
    render: (row) => {
      const patient = row.item;

      let { riskGroup } = patient;

      if (riskGroup == null) {
        riskGroup = 'insufficient';
      }

      return (
        <RiskBadge
          riskGroup={riskGroup}
          isDisenrolled={false}
          style={{ height: '25px', width: '90px' }}
        />
      );
    },
    ...customColumns[riskGroupColumnIndex],
    addOns: [{ type: 'filter', element: <RiskBreakupDropdown key="riskBreakdownDropdown" /> }],
  };
}

function renderAcuityScoreChangeColumn(customColumns) {
  const acuityScoreChangeColumnIndex = customColumns.findIndex(
    (c) => c.field === 'acuityScoreChange'
  );

  customColumns[acuityScoreChangeColumnIndex] = {
    align: 'flex-end',
    flex: '0 0 150px',
    render: (row) => {
      const patient = row.item;

      const { acuityScoreChange } = patient;

      const textColor = acuityScoreChange > 0 ? 'error' : 'link';

      return (
        // Negative margin to try and line this up with the sort icon, list styles don't work great for this.
        <div style={{ display: 'flex', alignItems: 'center', gap: 2, marginRight: -5 }}>
          <Text style={{ marginTop: 3, color: textColors[textColor] }}>
            {Math.abs(acuityScoreChange)}
          </Text>
          {acuityScoreChange > 0 ? (
            <ArrowUpwardIcon style={{ fontSize: '1.7em', color: textColors[textColor] }} />
          ) : (
            <ArrowDownwardIcon style={{ fontSize: '1.7em', color: textColors[textColor] }} />
          )}
        </div>
      );
    },
    ...customColumns[acuityScoreChangeColumnIndex],
    addOns: [
      { type: 'filter', element: <SortIcon key="acuityScoreChange" id="acuityScoreChange" /> },
    ],
  };
}
