import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

import {
  getSingleIndicator,
  indicatorsUpdated,
  selectDashIndicatorIds,
} from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';

import draggableTypes from '@util/draggableTypes';

import { useParticipantFilters } from '@intus-ui';
import DragContext from './DragContext';

const { DASH_LIST_ITEM, DRAGGABLE_QUAD } = draggableTypes;

const QuadDropTarget = ({ dropTargetIndex }) => {
  const dispatch = useDispatch();
  const { selectedFilters } = useParticipantFilters();

  const timeFilter = useSelector((state) => state.filters.timeFilter);

  const activeItemIds = useSelector(selectDashIndicatorIds);
  const { editMode } = useContext(DragContext);
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: [DASH_LIST_ITEM, DRAGGABLE_QUAD],
    canDrop: (item) => {
      let droppable = true;
      const activeDropItems = activeItemIds.filter((activeId) => activeId === item.id);
      if (activeDropItems.length) droppable = false;
      return editMode && (droppable || item.type === DRAGGABLE_QUAD);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item) => {
      if (item.type === DRAGGABLE_QUAD) {
        const targetId = activeItemIds[dropTargetIndex];
        const updatedIndicators = [
          { id: targetId, changes: { position: item.index } },
          { id: item.id, changes: { position: dropTargetIndex } },
        ];
        dispatch(indicatorsUpdated(updatedIndicators));
      }
      if (item.type === DASH_LIST_ITEM) {
        const itemCopy = { ...item };
        delete itemCopy.type;
        itemCopy.position = dropTargetIndex;
        const currentActiveItem = activeItemIds[dropTargetIndex];
        // if currentActiveItem is not undefined, we will replace it with the new item
        dispatch(
          getSingleIndicator(
            itemCopy,
            {
              patientFilters: selectedFilters,
              timeFilter,
            },
            currentActiveItem
          )
        );
      }
      return item;
    },
  });

  let border = 'none';
  let backgroundColor = 'white';
  if (canDrop && !isOver) {
    border = '.12em dashed rgba(5, 45, 143, .5)';
    backgroundColor = 'rgba(5, 45, 143, .1)';
  } else if (canDrop && isOver) {
    border = '.12em dashed rgba(40, 167, 69, .8)';
    backgroundColor = 'rgba(40, 167, 69, .2)';
  }

  return (
    <div
      className="w-100 h-100 rounded-xlg position-absolute d-flex justify-content-center align-items-center px-2 py-3 smoother"
      style={{
        border,
        backgroundColor,
        top: 0,
        left: 0,
        zIndex: 2,
      }}
      ref={dropRef}
    >
      {!canDrop && isOver && <div className="text-danger text-center">No Duplicates Allowed</div>}
    </div>
  );
};

QuadDropTarget.propTypes = {
  dropTargetIndex: PropTypes.number.isRequired,
};

export default QuadDropTarget;
