import React, { useContext } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import customDashItemPropType from 'Dashboard/customDashItemPropType';

import {
  getSingleIndicator,
  MAX_INDICATORS,
  selectDashIndicatorsTotal,
} from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';

import draggableTypes from '@util/draggableTypes';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useParticipantFilters } from '@intus-ui';
import DragContext from './DragContext';

import '@intus-ui/styles/ListItem.scss';

const DraggableListItem = ({ item }) => {
  const dispatch = useDispatch();

  const timeFilter = useSelector((state) => state.filters.timeFilter);

  const { setCurrentDrag, editMode } = useContext(DragContext);
  const { selectedFilters } = useParticipantFilters();

  const position = useSelector(selectDashIndicatorsTotal);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: draggableTypes.DASH_LIST_ITEM, ...item },
    canDrag: () => editMode,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    begin: () =>
      setCurrentDrag({
        title: item.title,
        type: draggableTypes.DASH_LIST_ITEM,
      }),
    end: () => setCurrentDrag(undefined),
  });

  const opacity = isDragging ? 0.3 : 1;

  const quickAdd = () => {
    const filters = {
      patientFilters: selectedFilters,
      timeFilter,
    };
    const itemCopy = item;
    itemCopy.position = position;
    dispatch(getSingleIndicator(itemCopy, filters));
  };

  return (
    <div
      className="w-100 px-2 py-3 my-1 bg-white list-item box-shadow-cards rounded-lg d-flex justify-content-between align-items-center"
      // the transform: translate gets rid of the undesired
      //  white background revealed by the rounded corners
      style={{ cursor: 'move', opacity, transform: 'translate(0,0)', blockSize: 'fit-content' }}
      ref={dragRef}
    >
      <div>{item.displayTitle}</div>
      <button
        type="button"
        title="Quick Add"
        className="outline-none border-0 rounded bg-transparent hover-light-blue smoother"
        style={{ padding: '2px' }}
        onClick={quickAdd}
        disabled={position === MAX_INDICATORS}
      >
        <AddRoundedIcon />
      </button>
    </div>
  );
};

DraggableListItem.propTypes = {
  item: customDashItemPropType.isRequired,
};

export default DraggableListItem;
