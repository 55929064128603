import React, { CSSProperties } from 'react';
import { Tag } from '@intus-ui';
import { formatDate, newDate } from '@util/dateFunctions';

type NextFillDueColumnProps = {
  fillDueDate: string | null;
  hideText?: boolean;
  style?: CSSProperties;
};

type FillDueDateStatus = 'overdue' | 'dueIn7Days' | 'okay';

export const NextFillDueColumn = (props: NextFillDueColumnProps) => {
  const { fillDueDate, hideText = false, style = {} } = props;

  if (!fillDueDate) return <span>&nbsp;</span>;

  let fillStatus: FillDueDateStatus = 'okay';

  const fillDueDateDayjs = newDate(fillDueDate);

  if (fillDueDateDayjs.isBefore(newDate())) {
    fillStatus = 'overdue';
  } else if (fillDueDateDayjs.isBefore(newDate().add(7, 'day'))) {
    fillStatus = 'dueIn7Days';
  }

  return (
    <div style={{ paddingLeft: 10, display: 'flex', gap: 10, ...style }}>
      {!hideText && <div style={{ width: 65 }}>{formatDate(fillDueDate, 'MM/DD/YY')}</div>}
      {fillStatus === 'overdue' && (
        <Tag style={styles.tag} color="orange">
          Past due
        </Tag>
      )}
      {fillStatus === 'dueIn7Days' && (
        <Tag style={styles.tag} color="purple">
          Due in ≤7 days
        </Tag>
      )}
    </div>
  );
};

const styles: Record<string, CSSProperties> = {
  tag: {
    width: 'auto',
    whiteSpace: 'nowrap',
  },
};
