import { getWithoutEncoding } from '@api/apiMethods';

export const getPolypharmacySummary = (filters) => {
  const urlParams = new URLSearchParams({ filters: JSON.stringify(filters) });

  return getWithoutEncoding(`polypharmacy/summary?${urlParams.toString()}`);
};
export const getPolypharmacyPatients = (filters, moreThan10ActiveMeds = false) => {
  const urlParams = new URLSearchParams({
    filters: JSON.stringify(filters),
    moreThan10ActiveMeds: moreThan10ActiveMeds.toString(),
  });

  return getWithoutEncoding(`polypharmacy/patients?${urlParams.toString()}`);
};
export const getPolypharmacyTopPrescribed = (filters) => {
  const urlParams = new URLSearchParams({ filters: JSON.stringify(filters) });

  return getWithoutEncoding(`polypharmacy/top-prescribed?${urlParams.toString()}`);
};
