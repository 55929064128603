import { useState } from 'react';

import Text from '../Text';

const Tab = ({ name, isActive, onClick, isFirst }) => (
  <div
    style={{
      ...styles.tab,
      backgroundColor: isActive ? '#FFFFFF' : '#EFF0F3',
      borderBottom: isActive ? 'none' : '1px solid #9D9D9D',
      marginLeft: !isFirst && -1,
      paddingBottom: isActive && '1px',
      cursor: isActive ? 'inherit' : 'pointer',
    }}
    onKeyPress={onClick}
    role="none"
    onClick={onClick}
  >
    <Text type="subtitle" color="navy">
      {name}
    </Text>
  </div>
);

const SidebarwithTabs = ({ items, defaultTabIndex, contentStyles = {} }) => {
  const [selected, setSelected] = useState(defaultTabIndex || 0);

  return (
    <div id="sidebarWithTabsContainer" style={styles.container}>
      <div id="sidebarWithTabsTabs" style={styles.tabsContainer}>
        {items.map(({ tab }, index) => {
          const key = `tab-${index}`;
          const isActive = selected === index;
          const isFirst = index === 0;
          return (
            <Tab
              key={key}
              name={tab}
              onClick={() => setSelected(index)}
              isActive={isActive}
              isFirst={isFirst}
            />
          );
        })}
      </div>
      <div id="sidebarWithTabsContentContainer" style={{ ...styles.tabsContentContainer }}>
        <div id="sidebarWithTabsContent" style={{ ...styles.tabsContent, ...contentStyles }}>
          {items[selected].content}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    overflowY: 'auto',
  },
  tabsContainer: { display: 'flex' },
  tab: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: '35px',
    borderRadius: '10px 10px 0px 0px',
    border: '1px solid #9D9D9D',
    cursor: 'pointer',
  },
  tabsContentContainer: {
    flex: 1,
    display: 'flex',
    border: '1px solid #9D9D9D',
    borderRadius: ' 0px 0px 10px 10px',
    borderTop: 'none',
    gap: '10px',
    overflowY: 'auto',
  },
  tabsContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: ' 0px 0px 10px 10px',
    borderTop: 'none',
    padding: '20px 15px 15px 15px',
    gap: '10px',
    overflowY: 'auto',
  },
};

export default SidebarwithTabs;
