import '@intus-ui/styles/Palette.scss';

// name: the displayed name for users for each risk group
// enum: the name each risk group is labeled in the backend
//    (the field name in the data objects received)
// color: main color for each risk group
//    Ex. used for dot icons, the line on line graph, badge color, etc.
// badgeColor: the bootstrap label color assigned to each risk group
// stroke: color of the outline of badge or dot
//    Note: rn this really only applies to 'All', bc the design for that is no fill, but an outline
// lowerLimit: the lower cutoff for the risk group (min value for each label)

const riskBreakup = {
  HIGHEST: {
    name: 'Highest',
    abbrev: ' H',
    enum: 'HIGHEST',
    color: 'rgb(204, 98, 98)',
    mutedColor: 'rgb(204, 98, 98, 0.16)',
    badgeColor: 'danger',
    stroke: 'rgba(0, 0, 0, 0)',
    lowerLimit: 75,
  },
  HIGH: {
    name: 'High',
    abbrev: ' h',
    enum: 'HIGH',
    color: 'rgb(234, 197, 5)',
    mutedColor: 'rgb(234, 197, 5, 0.16)',
    badgeColor: 'warning',
    stroke: 'rgba(0, 0, 0, 0)',
    lowerLimit: 51,
  },
  MODERATE: {
    name: 'Moderate',
    abbrev: ' M',
    enum: 'MODERATE',
    color: 'rgb(25, 148, 187)',
    mutedColor: 'rgb(25, 148, 187, 0.16)',
    badgeColor: 'primary',
    stroke: 'rgba(0, 0, 0, 0)',
    lowerLimit: 31,
  },
  LOW: {
    name: 'Low',
    abbrev: ' L',
    enum: 'LOW',
    color: 'rgb(148, 200, 249)',
    mutedColor: 'rgb(148, 200, 249, 0.16)',
    badgeColor: 'info',
    stroke: 'rgba(0, 0, 0, 0)',
    lowerLimit: 0,
  },
  INSUFF: {
    name: 'Insufficient Data',
    abbrev: ' -',
    enum: 'INSUFF',
    color: 'black',
    badgeColor: 'muted',
    stroke: 'rgba(0, 0, 0, 0)',
    lowerLimit: null,
  },
  ALL: {
    name: 'All',
    enum: 'ALL',
    abbrev: ' A',
    color: 'rgba(0, 0, 0, 0)',
    badgeColor: 'muted',
    stroke: '#97999b',
    lowerLimit: null,
  },
};

export default riskBreakup;
