import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Button } from 'react-bootstrap';
import TreeListNode from './TreeListNode';

/*
 *
 * THE SHOWFOLDERSTRUCTURE PROP IS NOT FULLY SUPPORTED YET
 *
 */

const TreeList = ({ nodes, showFolderStructure, labelNode }) => {
  const [folderStructure, setFolderStructure] = useState([{ id: 'root', title: 'Root' }]);
  const [selectedOptions, setSelectedOptions] = useState({});
  // const [visibleNodes, setVisibleNodes] = useState(nodes);

  const lastIndex = folderStructure.length - 1;

  const createFolderStructure = (id, title, index) => {
    const arrow = index !== lastIndex ? <ChevronRightRoundedIcon fontSize="default" /> : null;
    const buttonOrTitle =
      index !== lastIndex ? (
        <Button
          className="m-0 p-0 border-0"
          variant="link"
          onClick={() => {
            setFolderStructure((old) => {
              const newFolderStructure = [...old].splice(0, index + 1);
              // setVisibleNodes(() => {
              //   let newVisible = [...nodes];
              //   if (newFolderStructure.length > 1) {
              //     // get to the index we are at now:
              //     newFolderStructure.forEach((folder) => {
              //       newVisible.forEach((node) => {
              //         if (node.id === folder.id) {
              //           newVisible = node.nodes;
              //         }
              //       });
              //     });
              //   }
              //   return newVisible;
              // });
              // ONLY WORKS IF FOLDER STRUCTURE IS ENABLED
              setSelectedOptions(() => {
                const newSelected = {};
                newFolderStructure.forEach((folder, i) => {
                  if (i !== 0) {
                    newSelected[folder.id] = {};
                  }
                });
                return newSelected;
              });

              return newFolderStructure;
            });
          }}
        >
          {title}
        </Button>
      ) : (
        <div>{title}</div>
      );
    return (
      <div key={`folder-structure-${id}-${title}`} className="d-flex">
        {buttonOrTitle}
        {arrow}
      </div>
    );
  };

  return (
    <div className="w-100 h-100 d-flex flex-column">
      {showFolderStructure ? (
        <div className="w-100 d-flex flex-shrink-1 p-3 justify-content-start align-items-center flex-wrap">
          {folderStructure.map(({ id, title }, index) => createFolderStructure(id, title, index))}
        </div>
      ) : null}
      <div className="d-flex flex-grow-1 w-100">
        <TreeListNode
          nodes={nodes}
          selectedOptions={selectedOptions}
          onChange={(newOptions) => setSelectedOptions(newOptions)}
          folderStructure={folderStructure}
          labelNode={labelNode}
          setFolderStructure={(newFolderStructure) => {
            // setVisibleNodes((oldVisible) => {
            //   let newVisible = [...nodes];
            //   if (newFolderStructure.length > 1) {
            //     const folder = newFolderStructure[newFolderStructure.length - 1];
            //     oldVisible.forEach((node) => {
            //       if (node.id === folder.id) {
            //         newVisible = node.nodes;
            //       }
            //     });
            //   }
            //   return newVisible;
            // });

            setFolderStructure(newFolderStructure);
          }}
        />
      </div>
    </div>
  );
};

TreeList.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  showFolderStructure: PropTypes.bool,
  labelNode: PropTypes.func,
};

TreeList.defaultProps = {
  showFolderStructure: false,
  labelNode: undefined,
};

export default TreeList;
