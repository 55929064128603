import React from 'react';
import PropTypes from 'prop-types';
import '@intus-ui/styles/FilteredList.scss';
import '@intus-ui/styles/Utilities.scss';
import { shadesOfBlue } from '@intus-ui/styles/SecondaryColors';
import HoverBg from './HoverBg';

// Icon
const Trash = ({ lineColor, expanded }) => {
  const trashIcon = (
    <svg width="24" height="24" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" y1="4" x2="19" y2="4" stroke={lineColor} strokeWidth="2" strokeLinecap="round" />
      <line x1="6" y1="18" x2="6" y2="8" stroke={lineColor} strokeWidth="2" strokeLinecap="round" />
      <line
        x1="10"
        y1="18"
        x2="10"
        y2="8"
        stroke={lineColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="14"
        y1="18"
        x2="14"
        y2="8"
        stroke={lineColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2 4V20C2 21.1046 2.89543 22 4 22L16 22C17.1046 22 18 21.1046 18 20V4"
        stroke={lineColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 5V3C14 1.89543 13.1046 1 12 1H8C6.89543 1 6 1.89543 6 3V5"
        stroke={lineColor}
        strokeWidth="2"
      />
    </svg>
  );
  const expandedIcon = (
    <>
      {trashIcon}
      <p className="text-secondary text-icon font-weight-bold px-1">Trash</p>
    </>
  );

  return <HoverBg>{expanded ? expandedIcon : trashIcon}</HoverBg>;
};

Trash.propTypes = {
  lineColor: PropTypes.string,
  expanded: PropTypes.bool,
};

Trash.defaultProps = {
  lineColor: shadesOfBlue.intusNavy,
  expanded: true,
};

export default Trash;
