import { get, getWithoutEncoding, post, put } from '@api/apiMethods';
import { buildPatientFilterQueryString } from '@api/patients/buildPatientFilterQueryString';
import { formatDate } from '@util/dateFunctions';

/* *************** */
/* *** DASHBOARD QUERIES *** */
/* *************** */

type IDashboard = {
  id: number;
  name: string;
  isShared: boolean;
  canUserEdit: boolean;
  metrics: IDashboardMetric[];
};

type IDashboardMetric = {
  id: number;
  metricId: string;
  metricConfig: Record<string, unknown>;
  order: number;
  name: string;
  component: string;
};

export const getDashboardById = (dashboardId: string | number) =>
  get<IDashboard>(`custom-dashboard/${dashboardId}`);

type IDashboardForListView = {
  id: number;
  name: string;
  isShared: boolean;
  sharedByUser: boolean;
  lastEditedDate: string;
  metricCategories: {
    color: string;
    name: string;
  }[];
};
export const getAllCustomDashboards = () => get<IDashboardForListView[]>('custom-dashboard');

type ISaveDashboard = {
  id?: number | null;
  name: string;
  isShared: boolean;
  metrics: ISaveDashboardMetric[];
  timeFilter: string | null;
};

type ISaveDashboardMetric = {
  id?: number | null;
  metricId: string;
  metricConfig: Record<string, unknown>;
};
export const saveCustomDashboardById = (id: string | number, body: ISaveDashboard) =>
  put<IDashboard>(`custom-dashboard/${id != null ? id : ''}`, body);

type IMetricCategory = {
  category: string;
  color: string;
  metrics: {
    id: number;
    name: string;
    component: string;
  }[];
};

export const getAllMetricsCategories = () =>
  get<IMetricCategory>('custom-dashboard/metricsCategory');

/* *************** */
/* *** Metric QUERIES ***  */
/* *************** */

type IMetric = {
  id: number;
  name: string;
  component: string;
  data: Record<string, unknown>;
  color: string;
  modal: {
    component: string;
  } | null;
};

export const getMetricById = (
  metricId: string,
  startDate: string | Date,
  endDate: string | Date,
  metricConfig: Record<string, unknown>,
  filters: Record<string, unknown>
) => {
  const filterString = buildPatientFilterQueryString(filters);

  const urlSearchParams = new URLSearchParams(filterString);
  urlSearchParams.set('startDate', formatDate(startDate, 'YYYY-MM-DD'));
  urlSearchParams.set('endDate', formatDate(endDate, 'YYYY-MM-DD'));

  const defaultMetricConfig = {};
  urlSearchParams.set('metricConfig', JSON.stringify(metricConfig || defaultMetricConfig));

  return getWithoutEncoding<IMetric>(
    `custom-dashboard/metric/${metricId}?${urlSearchParams.toString()}`
  );
};

type IMetricModal = {
  component: string;
  color: string;
  data: Record<string, unknown>;
};

export const getMetricModalById = (
  metricId: string,
  startDate: string | Date,
  endDate: string | Date,
  metricConfig: Record<string, unknown>,
  filters: Record<string, unknown>
) => {
  const filterString = buildPatientFilterQueryString(filters);

  const urlSearchParams = new URLSearchParams(filterString);
  urlSearchParams.set('startDate', formatDate(startDate, 'YYYY-MM-DD'));
  urlSearchParams.set('endDate', formatDate(endDate, 'YYYY-MM-DD'));

  const defaultMetricConfig = {};
  urlSearchParams.set('metricConfig', JSON.stringify(metricConfig || defaultMetricConfig));

  return getWithoutEncoding<IMetricModal>(
    `custom-dashboard/modal/${metricId}?${urlSearchParams.toString()}`
  );
};

type IMetricConfig = Record<string, unknown>;

export const getMetricConfigById = (metricId: string) =>
  get<IMetricConfig>(`custom-dashboard/metric/${metricId}/config`);

/** Used in the breakdown (team/facility comparison) screen, returns one stat value for each team and facility. */
type IStatMetricCompare = {
  id: number;
  title: string;
  breakdown: {
    teams: IStatMetricCompareGroup[];
    facilities: IStatMetricCompareGroup[];
  };
};

type IStatMetricCompareGroup = { column: string; value: number | string };

export const getDashboardCompare = (
  dashboardId: number | string,
  startDate: string | Date,
  endDate: string | Date
) => {
  return get<IStatMetricCompare[]>(
    `custom-dashboard/compare/${dashboardId}?startDate=${formatDate(
      startDate,
      'YYYY-MM-DD'
    )}&endDate=${formatDate(endDate, 'YYYY-MM-DD')}`
  );
};

/** Downloads the xlsx file for a single metric. */
export const postDownloadMetric = (
  metricId: string,
  startDate: string | Date,
  endDate: string | Date,
  metricConfig: Record<string, unknown>,
  filters: Record<string, unknown>
) => {
  return post(`custom-dashboard/download/metric`, {
    metricId,
    startDate: formatDate(startDate, 'YYYY-MM-DD'),
    endDate: formatDate(endDate, 'YYYY-MM-DD'),
    metricConfig,
    filters,
  });
};

/** Downloads the xlsx file for a dashboard. */
export const postDownloadDashboard = (
  dashboardId: number,
  startDate: string | Date,
  endDate: string | Date,
  filters: Record<string, unknown>
) => {
  return post(`custom-dashboard/download/dashboard`, {
    dashboardId,
    startDate: formatDate(startDate, 'YYYY-MM-DD'),
    endDate: formatDate(endDate, 'YYYY-MM-DD'),
    filters,
  });
};
