/* eslint-disable react/no-array-index-key */
import { Text } from '@intus-ui';
import { useReactChartTooltip } from '@intus-ui/components/graphs/useReactChartTooltip';
import { formatDate, isSameDate, newDate } from '@util/dateFunctions';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

/**
 * @typedef {{ color: string, label: string; data: { month: string, value: number }[]}} BenchmarkChartData
 */

/**
 *
 * @param {{  yLabel: string; barData: BenchmarkChartData, lineData: BenchmarkChartData }} props
 * @returns
 */
export const BenchmarkBarChart = ({ chartTitle, yLabel, barData, lineData }) => {
  const myOrgData = useMemo(() => {
    /** @type {BenchmarkChartData[]} */
    const myBarData = Array.isArray(barData) ? barData : [barData];

    const datasets = myBarData.map((metric) => {
      const { label, data, color } = metric;

      /** @type {Chart.ChartDataSets} */
      const dataset = {
        // Display the other org lines on top of the bars.
        order: 2,
        label,
        data: data.map((d) => d.value),
        fill: false,
        backgroundColor: color,
        borderColor: color,
        lineTension: 0,
      };

      return dataset;
    });

    return datasets;
  }, [barData]);

  const otherOrgData = useMemo(() => {
    /** @type {BenchmarkChartData[]} */
    const myLineData = Array.isArray(lineData) ? lineData : [lineData];

    const datasets = myLineData.map((metric) => {
      const { label, data, color } = metric;

      /** @type {Chart.ChartDataSets} */
      const dataset = {
        order: 1,
        label,
        data: data.map((d) => d.value),
        fill: false,
        backgroundColor: color,
        borderColor: color,
        lineTension: 0,
        type: 'line',
      };

      return dataset;
    });

    return datasets;
  }, [lineData]);

  const { tooltipOptions, tooltipComponent } = useReactChartTooltip((dataIndex) => (
    <BenchmarkTooltip
      dataIndex={dataIndex}
      barData={barData}
      lineData={lineData}
      chartTitle={chartTitle}
    />
  ));

  return (
    <div style={{ width: '100%', height: '100%', paddingTop: 6, paddingBottom: 6 }}>
      {tooltipComponent}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div style={{ flex: '1 1 100%', maxHeight: '90%', overflow: 'hidden' }}>
          <Bar
            data={{
              datasets: [...myOrgData, ...otherOrgData],
              labels: barData[0].data.map((d) => newDate(d.month).toDate()),
            }}
            options={{
              animation: false,
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              tooltips: tooltipOptions,
              scales: {
                xAxes: [
                  {
                    offset: true,
                    type: 'time',
                    ticks: {
                      // Only show ticks for points in the data.
                      // Without this chart.js renders multiple ticks for each month if the chart range is a few months.
                      source: 'labels',
                      // If the chart is over a long time range, then start skipping months in the x-axis ticks.
                      autoSkip: true,
                      maxTicksLimit: 48,
                      maxRotation: 60,
                      minRotation: 0,
                      fontSize: 12,
                      padding: 8,
                      callback: (value, index, ticks) => {
                        const date = ticks[index].value;
                        // If we're in the current month, display MTD on the chart.
                        if (isSameDate(newDate(), date, 'month')) {
                          return 'MTD';
                        }
                        return formatDate(date, "MMM 'YY");
                      },
                    },
                    gridLines: {
                      display: true,
                      // Draws the line right above the x-axis
                      drawBorder: true,
                      // Do not display a line going down passed the bottom of the axis to the date label.
                      drawTicks: false,
                    },
                    scaleLabel: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      // The suggestedMax here is only used if all data points are below 5
                      // We do this to prevent the graph from looking bad for small numbers.
                      suggestedMax: 5,
                      suggestedMin: 0,
                      maxTicksLimit: 8,
                      fontSize: 12,
                      precision: 0,
                    },
                    gridLines: {
                      display: false,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: yLabel,
                      fontSize: 12,
                      fontStyle: 'bold',
                    },
                  },
                ],
              },
            }}
          />
        </div>

        <div style={{ flex: '0 0 auto' }}>
          <BenchmarkChartLegend barData={barData} lineData={lineData} />
        </div>
      </div>
    </div>
  );
};

const BenchmarkTooltip = ({ chartTitle, barData, lineData, dataIndex }) => {
  const myBarData = Array.isArray(barData) ? barData : [barData];
  const myLineData = Array.isArray(lineData) ? lineData : [lineData];

  const { month } = myBarData[0].data[dataIndex];

  const dateText = !isSameDate(newDate(), month, 'month')
    ? formatDate(month, "MMM 'YY")
    : `Data so far in ${formatDate(month, "MMM 'YY")} (MTD)`;

  return (
    <div>
      <div>
        <Text type="caption-bold">{dateText}</Text>
      </div>
      <div>
        <Text type="caption">{chartTitle}</Text>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 6, paddingTop: 5 }}>
        {myBarData.map((data, index) => {
          return (
            <BenchmarkTooltipLegend key={`barData-${index}`} data={data} dataIndex={dataIndex} />
          );
        })}

        {myLineData.map((data, index) => {
          return (
            <BenchmarkTooltipLegend
              key={`lineData-${index}`}
              showDot
              data={data}
              dataIndex={dataIndex}
            />
          );
        })}
      </div>
    </div>
  );
};

const BenchmarkTooltipLegend = ({ data, dataIndex, showDot }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: data.color,
          width: 12,
          height: 12,
          borderRadius: showDot ? 1000 : 0,
        }}
      />
      <Text type="caption-bold" style={{ paddingLeft: 4 }}>
        {data.label}
      </Text>
      <Text type="caption" style={{ paddingLeft: 4 }}>
        {data.data[dataIndex].value}
      </Text>
    </div>
  );
};

export const BenchmarkChartLegend = ({ barData, lineData }) => {
  const myBarData = Array.isArray(barData) ? barData : [barData];
  const myLineData = Array.isArray(lineData) ? lineData : [lineData];

  return (
    <div style={legendStyles.container}>
      {myBarData.map((data, index) => {
        return (
          <div style={legendStyles.item}>
            <BenchmarkLegendBox key={`barData-${index}`} data={data} />{' '}
          </div>
        );
      })}

      {myLineData.map((data, index) => {
        return (
          <div style={legendStyles.item}>
            <BenchmarkLegendBox key={`lineData-${index}`} showDot data={data} />{' '}
          </div>
        );
      })}
    </div>
  );
};

const legendStyles = {
  container: {
    paddingLeft: 55,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 24,
    rowGap: 4,
  },
  item: {
    flex: '1 1 40%',
  },
};

const BenchmarkLegendBox = ({ data, showDot }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: data.color,
          borderRadius: showDot ? 1000 : 0,
          width: 12,
          height: 12,
          marginRight: 6,
        }}
      />
      <Text type="caption-bold" style={{ paddingRight: 4 }}>
        {data.label}
      </Text>
    </div>
  );
};
