import { useState, useEffect } from 'react';

import { useListContext } from './Context';

const SortArrowAsc = ({ name }) => {
  return (
    <div id={name}>
      <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11" r="9" strokeWidth="2" />
        <path
          d="M5.31494 14.1905C4.99756 13.8709 4.99756 13.3527 5.31493 13.0331L10.5022 7.80954C10.6547 7.65607 10.8614 7.56984 11.0769 7.56984C11.2924 7.56984 11.4992 7.65607 11.6516 7.80954L16.8389 13.0331C17.1562 13.3527 17.1562 13.8709 16.8389 14.1905C16.5215 14.5101 16.0069 14.5101 15.6896 14.1905L11.0769 9.54557L6.46425 14.1905C6.14688 14.5101 5.63231 14.5101 5.31494 14.1905Z"
          fill="#2E62E7"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#2E62E7"
        />
      </svg>
    </div>
  );
};

const SortArrowDesc = ({ name }) => {
  return (
    <div id={name}>
      <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11" r="9" strokeWidth="2" />
        <path
          d="M15.9233 8.9375L11.1027 13.7247L6.28203 8.9375"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#2E62E7"
          fill="none"
        />
      </svg>
    </div>
  );
};

const SortArrowNone = ({ name }) => {
  return (
    <div id={name}>
      <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11" r="9" strokeWidth="2" />
        <line
          x1="6.0769"
          y1="10.8462"
          x2="15.9231"
          y2="10.8462"
          stroke="#2E62E7"
          strokeWidth="2"
          strokeLinecap="round"
          fill="#2E62E7"
        />
      </svg>
    </div>
  );
};

const SortIcon = ({ id }) => {
  const { sorting, setSorting } = useListContext();
  const [name, setName] = useState(`sort-arrow-${sorting?.direction}`);

  // changing direction of sorting based on previous state of the icon
  const handleSetSorting = (e) => {
    const currSorting = e.currentTarget.id;
    const field = currSorting.substring(0, currSorting.indexOf('-')); // getting field from sorting variable
    const prevDirection = currSorting.substring(currSorting.indexOf('-') + 1); // getting direction from sorting variable
    if (prevDirection === 'none') setSorting({ field, direction: 'desc' });
    if (prevDirection === 'desc') setSorting({ field, direction: 'asc' });
    if (prevDirection === 'asc') setSorting({ field, direction: 'none' });
  };

  useEffect(() => {
    setName('sort-arrow-none');
    if (id === sorting.field) {
      if (sorting?.direction === 'asc') {
        setName('sort-arrow-asc');
      } else if (sorting?.direction === 'desc') {
        setName('sort-arrow-desc');
      }
    }
  }, [sorting, id]);

  return (
    <div
      className="sort-icon"
      id={`${`${id}-${sorting?.direction}`}`}
      onClick={(e) => handleSetSorting(e)}
      style={{ margin: 0, maxWidth: '14px' }}
      role="none"
    >
      {name === 'sort-arrow-none' && <SortArrowNone name={name} />}
      {name === 'sort-arrow-asc' && <SortArrowAsc name={name} />}
      {name === 'sort-arrow-desc' && <SortArrowDesc name={name} />}
    </div>
  );
};

export const SortIconNoNeutral = ({ id }) => {
  const { sorting, setSorting } = useListContext();
  const [name, setName] = useState(`sort-arrow-${sorting?.direction}`);

  // changing direction of sorting based on previous state of the icon
  const handleSetSorting = (e) => {
    const currSorting = e.currentTarget.id;
    const field = currSorting.substring(0, currSorting.indexOf('-')); // getting field from sorting variable
    const prevDirection = currSorting.substring(currSorting.indexOf('-') + 1); // getting direction from sorting variable
    if (prevDirection === 'desc') setSorting({ field, direction: 'asc' });
    if (prevDirection === 'asc') setSorting({ field, direction: 'desc' });
  };

  useEffect(() => {
    setName('sort-arrow-none');
    if (id === sorting.field) {
      if (sorting?.direction === 'asc') {
        setName('sort-arrow-asc');
      } else if (sorting?.direction === 'desc') {
        setName('sort-arrow-desc');
      }
    }
  }, [sorting, id]);

  return (
    <div
      className="sort-icon"
      id={`${`${id}-${sorting?.direction}`}`}
      onClick={(e) => handleSetSorting(e)}
      style={{ margin: 0, maxWidth: '14px' }}
      role="none"
    >
      {name === 'sort-arrow-none' && <SortArrowNone name={name} />}
      {name === 'sort-arrow-asc' && <SortArrowAsc name={name} />}
      {name === 'sort-arrow-desc' && <SortArrowDesc name={name} />}
    </div>
  );
};

export default SortIcon;
