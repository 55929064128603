import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

// defaults.global.defaultFontFamily = 'Muli' was giving me an undefined error

class DonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { info, label, options, colors, expand } = this.props;
    if (!info) return null;

    const intusData = {
      labels: label,
      datasets: [
        {
          label: 'Current Acuity Score Breakdown',
          backgroundColor: colors,
          data: info,
        },
      ],
    };

    // Default options
    const intusOptions = {
      radius: '90%',
      // borderRadius: 4,
      cutout: '40%',
      responsive: false,
      maintainAspectRatio: true,
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          position: 'bottom',
          rtl: false,
        },
      },
    };

    const emptyData = {
      labels: [],
      datasets: [
        {
          label: 'No Information Available',
          backgroundColor: ['gray'],
          data: [1],
        },
      ],
    };

    const isEmptyFunction = (data) => {
      let isEmpty = true;
      Object.entries(data).forEach((value) => {
        if (value[1] !== 0) {
          isEmpty = false;
        }
      });
      return isEmpty;
    };

    return (
      <div className={expand ? 'w-100 h-100' : ''}>
        {!isEmptyFunction(info) ? (
          <div className="w-100 h-100 mb-4">
            <Doughnut data={intusData} options={options || intusOptions} />
          </div>
        ) : (
          <div className="w-100 h-100 mb-4">
            <h6 className="text-dark text-center align-self-start w-100 d-flex flex-column justify-content-center">
              No Information Available
            </h6>
            <Doughnut data={emptyData} options={options || intusOptions} />
          </div>
        )}
      </div>
    );
  }
}

DonutChart.propTypes = {
  info: PropTypes.arrayOf(PropTypes.number),
  colors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.shape({
    radius: PropTypes.string,
    borderRadius: PropTypes.number,
    cutout: PropTypes.string,
    responsive: PropTypes.bool,
    maintainAspectRatio: PropTypes.bool,
    plugins: PropTypes.shape({
      legend: PropTypes.shape({
        display: PropTypes.bool,
      }),
      datalabels: PropTypes.shape({
        labels: PropTypes.shape({
          title: PropTypes.shape({
            formatter: PropTypes.func,
            align: PropTypes.string,
            anchor: PropTypes.string,
            offset: PropTypes.number,
            font: PropTypes.shape({
              size: PropTypes.number,
            }),
            color: PropTypes.string,
            // color: (value, context) => value.dataset.backgroundColor[value.dataIndex],
          }),
          value: PropTypes.shape({
            font: PropTypes.shape({
              size: PropTypes.string,
            }),
            color: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
  expand: PropTypes.bool,
};

DonutChart.defaultProps = {
  info: undefined,
  label: undefined,
  colors: undefined,
  options: undefined,
  expand: false,
};

export default DonutChart;
