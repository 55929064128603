import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { USER_ACCESS_ADMIN, USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import { useGetSessionUser } from '@util/session';
import PremiumDashboards from './PremiumDashboards';
import CustomDashboards from './CustomDashboards';
import DashMenuContext from './DashMenuContext';
import OrgDashboards from './OrgDashboards';

const DashboardMenu = ({ closeMenu, toggleEditMode }) => {
  const [searchVal, setSearchVal] = useState('');

  const {
    access,
    organization: { customDashboards },
  } = useGetSessionUser();
  const isAdmin = access === USER_ACCESS_ADMIN;
  const orgDashboards = customDashboards;
  const isHealthSysAdmin = access === USER_ACCESS_HEALTH_SYS_ADMIN;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DashMenuContext.Provider value={{ searchVal, closeMenu }}>
      <div
        className="px-3 pb-3 d-flex flex-column align-items-stretch"
        style={{ boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)', zIndex: 5 }}
      >
        <input
          type="text"
          className="bg-secondary p-3 border-0 text-white outline-none rounded search-dashboards"
          placeholder="Search Dashboards"
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
        />
      </div>
      <div
        className="d-flex flex-grow-1 flex-column pl-2 justify-content-start align-items-stretch pt-2"
        style={{ overflowY: 'scroll' }}
      >
        <div>
          <PremiumDashboards />
        </div>
        <div>
          <CustomDashboards toggleEditMode={toggleEditMode} />
        </div>
        {!!(isAdmin || orgDashboards.length) && !isHealthSysAdmin && (
          <div>
            <OrgDashboards toggleEditMode={toggleEditMode} />
          </div>
        )}
      </div>
    </DashMenuContext.Provider>
  );
};

DashboardMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  // orgItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleEditMode: PropTypes.func.isRequired,
};

// eslint-disable-next-line no-unused-vars
const mapState = () => ({
  // orgItems: state.spreadsheetModal.orgItems,
});

export default connect(mapState)(DashboardMenu);
