import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getSessionUser } from '@util/session';

import {
  fetchLeaderboardItems,
  fetchLeaderboardMonthlyComparison,
  setLeaderboardType,
} from '@global-state/redux/leaderboardSlice';
import { selectDashIndicatorsAll } from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';
import { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';

import { Table, Button } from 'react-bootstrap';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import Centered from '@intus-ui/components/Centered';
import { useParticipantFilters } from '@intus-ui';

const TeamLeaderboard = ({ showLeaderboard, toggleLeaderboard }) => {
  const dispatch = useDispatch();

  const timeFilter = useSelector((state) => state.filters.timeFilter);
  const teamRows = useSelector((state) => state.leaderboard.teamRows);
  const facilityRows = useSelector((state) => state.leaderboard.facilityRows);
  const orgRows = useSelector((state) => state.leaderboard.orgRows);
  const monthRows = useSelector((state) => state.leaderboard.monthRows);
  const leaderboardStatus = useSelector((state) => state.leaderboard.leaderboardStatus);
  const leaderboardType = useSelector((state) => state.leaderboard.leaderboardType) ?? 'loading';

  const {
    teams,
    facilities,
    selectedFilters,
    healthSystemAdminOrganizations: organizations,
  } = useParticipantFilters();

  const orgMap = { All: 'All' };

  organizations.forEach(({ id, name }) => {
    orgMap[id] = name;
  });
  const orgIds = organizations.map(({ id }) => id);
  const isHealthSysAdmin = getSessionUser().access === USER_ACCESS_HEALTH_SYS_ADMIN;
  let array = teams;
  if (isHealthSysAdmin) {
    array = orgIds;
  } else if (!teams.length) {
    array = facilities;
  }

  const [leaderboardArray, setLeaderboardArray] = useState(array);
  const activeDashItems = useSelector(selectDashIndicatorsAll);

  const setLeaderboardTypeHelper = (newType) => {
    if (newType !== leaderboardType) {
      if (newType === 'teams') setLeaderboardArray(teams);
      else if (newType === 'facilities') setLeaderboardArray(facilities);
      else if (newType === 'organizations') setLeaderboardArray(orgIds);
      dispatch(setLeaderboardType(newType));
    }
  };

  useEffect(() => {
    if (isHealthSysAdmin) {
      dispatch(setLeaderboardType('organizations'));
    } else if (teams.length) {
      dispatch(setLeaderboardType('teams'));
    } else if (facilities.length) {
      dispatch(setLeaderboardType('facilities'));
    }
  }, []);

  useEffect(() => {
    const fetchData = () => {
      if (leaderboardType === 'months') {
        dispatch(fetchLeaderboardMonthlyComparison(activeDashItems, selectedFilters));
      } else {
        dispatch(
          fetchLeaderboardItems(
            activeDashItems,
            leaderboardType,
            leaderboardArray,
            timeFilter.startDate,
            timeFilter.endDate
          )
        );
      }
    };
    if (showLeaderboard) fetchData();
  }, [
    activeDashItems,
    showLeaderboard,
    isHealthSysAdmin,
    timeFilter.startDate,
    timeFilter.endDate,
    leaderboardType,
    leaderboardArray,
    selectedFilters,
  ]);

  const typeToRows = {
    teams: teamRows,
    facilities: facilityRows,
    organizations: orgRows,
    months: monthRows,
  };

  const rows = typeToRows[leaderboardType];

  if (leaderboardStatus === 'error')
    return (
      <Centered className="w-100 h-100">
        Error loading statistics. Please refresh and try again.
      </Centered>
    );

  if (rows === undefined || leaderboardType === 'loading')
    return <CenteredSpinner className="w-100 h-100" />;

  let titles;
  if (rows[0]) {
    titles = Object.keys(rows[0].results);
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center bg-white box-shadow-on-gray rounded-xlg w-100 h-100 p-3 position-relative">
      <button
        type="button"
        title="Hide Breakdown"
        className="position-absolute outline-none border-0 bg-transparent text-dark hover-light-blue d-flex justify-content-center align-items-center rounded"
        style={{ top: '1rem', right: '1rem', padding: '2px' }}
        onClick={() => toggleLeaderboard(false)}
      >
        <CloseRoundedIcon />
      </button>
      <div>
        {teams !== undefined && teams.length !== 0 && !isHealthSysAdmin && (
          <Button
            className={`acuity-nav ${leaderboardType === 'teams' ? 'acuity-nav-focus' : ''}`}
            variant="link"
            title="Team"
            onClick={() => {
              setLeaderboardTypeHelper('teams');
            }}
          >
            Team
          </Button>
        )}
        {facilities !== undefined && facilities.length !== 0 && !isHealthSysAdmin && (
          <Button
            className={`acuity-nav ${leaderboardType === 'facilities' ? 'acuity-nav-focus' : ''}`}
            variant="link"
            title="Facility"
            onClick={() => {
              setLeaderboardTypeHelper('facilities');
            }}
          >
            Facility
          </Button>
        )}
        {!isHealthSysAdmin && (
          <Button
            className={`acuity-nav ${leaderboardType === 'months' ? 'acuity-nav-focus' : ''}`}
            variant="link"
            title="Monthly"
            onClick={() => {
              setLeaderboardTypeHelper('months');
            }}
          >
            Monthly
          </Button>
        )}
        {organizations !== undefined && organizations.length !== 0 && isHealthSysAdmin && (
          <Button
            className={`acuity-nav ${
              leaderboardType === 'organizations' ? 'acuity-nav-focus' : ''
            }`}
            variant="link"
            title="Organization"
            onClick={() => {
              setLeaderboardTypeHelper('organizations');
            }}
          >
            Organization
          </Button>
        )}
      </div>
      {titles !== undefined && (titles || []).length !== 0 && (
        <div className="tableFixHead m-0 d-flex flex-grow-1 w-100">
          <Table borderless hover striped responsive>
            <thead>
              <tr>
                <th>Dashboard Items</th>
                {[...titles].map((item) => (
                  <th key={item}>{leaderboardType === 'organizations' ? orgMap[item] : item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map(({ displayTitle, results }) => (
                <tr key={`${displayTitle}`}>
                  <td className="py-3">{displayTitle}</td>
                  {[...titles].map((item) => {
                    let val = results[item].value;
                    const { format } = results[item];
                    if (val !== 'N/A') {
                      if (format === 'PERCENTAGE') val = `${val.toFixed(1)}%`;
                      else if (format === 'MONEY') val = `$${val}`;
                      else if (format === 'DOUBLE') val = val.toFixed(1);
                      else if (format === 'INT') val = val.toFixed(1);
                    }
                    return (
                      <td style={{ verticalAlign: 'middle' }} key={item}>
                        <div className="d-flex justify-content-start align-items-center h-100">
                          {val}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

TeamLeaderboard.propTypes = {
  showLeaderboard: PropTypes.bool.isRequired,
  toggleLeaderboard: PropTypes.func.isRequired,
};

export default TeamLeaderboard;
