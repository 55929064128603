import { useState } from 'react';
import { useParams } from 'react-router';
import layouts from '@intus-ui/layouts';
import { Card, TeamFacilityFilter, useParticipantFilters } from '@intus-ui';
import { Adherence } from 'Polypharmacy/Adherence';
import { AdherenceRisingStars } from 'Polypharmacy/AdherenceRisingStars';
import { TabMenu } from './components';
import { INITIAL_FILTERS } from './components/Filters';

import Summary from './Summary';
import Patients from './Patients';
import TopPrescribed from './TopPrescribed';
import { MedClass } from './MedClass';

const Content = ({ filters }) => {
  const { tab } = useParams();
  const { selectedFilters } = useParticipantFilters();
  const allFilters = { ...filters, globalFilters: selectedFilters };

  return (
    <div style={{ ...layouts.container, gap: 0 }}>
      <TabMenu />
      <div style={{ ...layouts.container, gap: 30, padding: 0 }}>
        {tab === 'summary' && <Summary filters={allFilters} />}
        {tab === 'patients' && <Patients filters={allFilters} />}
        {tab === 'top-prescribed' && <TopPrescribed filters={allFilters} />}
        {tab === 'adherence' && <Adherence filters={allFilters} />}
        {tab === 'first-fills' && <AdherenceRisingStars filters={allFilters} />}
        {tab === 'med-class' && <MedClass filters={allFilters} />}
      </div>
    </div>
  );
};

const Polypharmacy = () => {
  const [filters] = useState(INITIAL_FILTERS);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flex: 1,
        padding: 10,
        backgroundColor: '#F6F7FA',
      }}
    >
      <TeamFacilityFilter />
      <div
        style={{
          height: '100%',
          ...layouts.rowContainer,
          padding: 10,
          gap: 10,
          backgroundColor: '#F6F7FA',
        }}
      >
        {/* <Card
          dropshadow
          style={{
            display: 'flex',
            overflowY: 'auto',
            overflowX: 'hidden',
            backgroundColor: 'white',
          }}
          contentStyle={{ padding: 10 }}
        >
          <Filters filters={filters} setFilters={setFilters} />
        </Card> */}
        <Card
          dropshadow
          style={{
            ...layouts.container,
            padding: 10,
            gap: 20,
            margin: '0 20px',
            flex: 4,
            overflowX: 'hidden',
            backgroundColor: 'white',
          }}
          contentStyle={layouts.container}
        >
          <Content filters={filters} />
        </Card>
      </div>
    </div>
  );
};

export default Polypharmacy;
