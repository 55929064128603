import React from 'react';
import PropTypes from 'prop-types';
import Quad from 'Dashboard/Quad';
import DonutChart from '@intus-ui/components/graphs/DonutChart';
import { defaultChartColors } from '@intus-ui/styles/SecondaryColors';
import { SpinnerOrError } from '@intus-ui';
import { censusModalPropType } from 'Homes/modalPropTypes/censusModalPropType';

const Disenrolls = ({ subcards, error }) => {
  const getDeathDisenrolls = () => {
    const pastYear = subcards.monthlyEnrollmentChange;
    const half = Math.floor(subcards.monthlyEnrollmentChange.length / 2);
    const pastHalfYear = pastYear.slice(half);
    let deaths = 0;
    let disenrolls = 0;
    Object.entries(pastHalfYear).forEach(([, val]) => {
      deaths += val.deaths;
      disenrolls += val.disenrolled;
    });
    const voluntary = disenrolls - deaths;
    return { 'Due to death': deaths, 'Voluntary Leave': voluntary };
  };

  let content = (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <SpinnerOrError error={error} />
    </div>
  );
  if (subcards) {
    let censusDisenrollsData;
    let censusDisenrollsGraphOptions;
    const censusDisenrollsChartColors = [
      defaultChartColors.skyBlue,
      defaultChartColors.lightLilac,
      defaultChartColors.tealBlue,
      defaultChartColors.deepBlue,
    ];
    const censusDisenrollsChartLabels = [];
    const censusDisenrollsChartData = [];
    if (subcards.totalParticipantsTrendline !== undefined) {
      censusDisenrollsData = getDeathDisenrolls();
      Object.entries(censusDisenrollsData).forEach(([key, val]) => {
        censusDisenrollsChartLabels.push(key);
        censusDisenrollsChartData.push(val);
      });

      censusDisenrollsGraphOptions = {
        radius: '90%',
        borderRadius: 4,
        cutout: '40%',
      };
    }

    content = (
      <DonutChart
        expand
        options={censusDisenrollsGraphOptions}
        info={censusDisenrollsChartData}
        label={censusDisenrollsChartLabels}
        colors={censusDisenrollsChartColors}
      />
    );
  }
  return (
    <Quad scrollable padding="pb-3" title="# Disenrolls due to Death (Past 6 Months)">
      {content}
    </Quad>
  );
};

Disenrolls.propTypes = {
  subcards: censusModalPropType,
  error: PropTypes.string,
};

Disenrolls.defaultProps = {
  subcards: undefined,
  error: undefined,
};

export default Disenrolls;
