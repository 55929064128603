import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from '@intus-ui';

import useDelayedMountUnmount from '@intus-ui/components/useDelayedMountUnmount';
import HamburgerMenu from '@intus-ui/components/HamburgerMenu';
import DashboardMenu from './DashboardMenu';

import '@intus-ui/styles/HamburgerMenu.scss';

const DashboardHamburgerMenu = ({
  hamburgerOpen,
  onOpen,
  onClose,
  toggleEditMode,
  disabled = false,
}) => {
  const [state, show, hide] = useDelayedMountUnmount(250);

  const openMenu = () => {
    if (!disabled) {
      show();
    }
    onOpen();
  };
  const closeMenu = () => {
    if (!disabled) {
      hide();
    }
    onClose();
  };

  return (
    <>
      <Button onClick={() => openMenu()} hoverText="All Dashboards" textOnly compact>
        <Icon name="HamburgerMenu" color="navy" hoverColor="#052d8f" />
      </Button>
      {state !== 'unmounted' && (
        <>
          <HamburgerMenu
            isOpen={hamburgerOpen}
            onClose={closeMenu}
            className={state === 'unmounting' ? 'close-hamburger-menu' : ''}
          >
            <DashboardMenu closeMenu={closeMenu} toggleEditMode={toggleEditMode} />
          </HamburgerMenu>
          {/* overlays the rest of the page content when the menu is open */}
          <div
            className={`position-absolute w-100 h-100 show-overlay ${
              state === 'unmounting' ? 'hide-overlay' : ''
            }`}
            style={{
              zIndex: 4,
              top: 0,
              left: 0,
              backgroundColor: 'black',
            }}
          />
        </>
      )}
    </>
  );
};

DashboardHamburgerMenu.propTypes = {
  hamburgerOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DashboardHamburgerMenu.defaultProps = {
  disabled: false,
};

export default DashboardHamburgerMenu;
