import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { setDashMenuExpanded } from '@global-state/redux/dashboardSlice';
import '@intus-ui/styles/ExpandableTitle.scss';

const ExpandableTitle = ({ title, menuType, icon, dashMenuExpanded, toggleExpanded }) => {
  const expanded = dashMenuExpanded[menuType];
  return (
    <button
      type="button"
      className={`w-100 d-flex justify-content-between align-items-center mb-2 p-0 outline-none border-0 bg-transparent dash-menu-title ${
        expanded ? 'dash-menu-title-expanded' : 'dash-menu-title'
      }`}
      title={`${expanded ? 'Collapse' : 'Expand'} ${title}`}
      onClick={() => toggleExpanded(menuType, !expanded)}
    >
      <div
        className="w-100 d-flex justify-content-start align-items-center py-2"
        style={{ color: 'inherit' }}
      >
        {icon}
        <h3 className="font-weight-bold text-left">{title}</h3>
      </div>
      <ExpandMore className={`mr-3 ${expanded ? 'open-dropdown' : ''}`} />
    </button>
  );
};

ExpandableTitle.propTypes = {
  title: PropTypes.string.isRequired,
  menuType: PropTypes.string.isRequired,
  icon: PropTypes.node,
  dashMenuExpanded: PropTypes.objectOf(PropTypes.bool).isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};

ExpandableTitle.defaultProps = {
  icon: null,
};

const mapState = (state) => ({
  dashMenuExpanded: state.dashboard.dashMenuExpanded,
});

const mapDispatch = (dispatch) => ({
  toggleExpanded: (menu, val) => dispatch(setDashMenuExpanded({ menu, val })),
});

export default connect(mapState, mapDispatch)(ExpandableTitle);
