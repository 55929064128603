/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getLogger } from '@util/logger';
import { asyncForEach } from '@util/utilFunctions';
import { getSessionUser } from '@util/session';

import {
  getUsersByOrgAPI,
  postBulkUsersAPI,
  getUserByIdAPI,
  postResetPassword,
  fetchUserByEmail,
  updateUserAPI,
} from '@api/api';
import { setShowNotification } from './notificationSlice';

const log = getLogger('caregiverListSlice');

const caregiverListSlice = createSlice({
  name: 'subscription',
  initialState: {
    caregivers: undefined,
    error: undefined,
    addingUser: false,
    addUserError: undefined,
    editingCaregiver: false,
    selectedCaregiver: undefined,
    passwordReset: false,
    successModal: undefined,
    noOfUsersCreated: undefined,
  },
  reducers: {
    // The payload should be a list of caregiverListCaregiverPropType.
    setCaregivers(state, action) {
      state.caregivers = action.payload;
    },

    // This allows the caregiver list to display an error.
    setError(state, action) {
      state.error = action.payload;
    },

    // This is triggered together with the retry button.
    clearCaregiversAndErrors(state) {
      state.caregivers = undefined;
      state.error = undefined;
    },

    // This shows or hides the add user modal.
    setAddingUser(state, action) {
      state.addingUser = action.payload;
    },

    // This adds the user to the state.
    addUserToState(state, action) {
      state.caregivers.push(action.payload);
    },

    setAddUserError(state, { payload }) {
      state.addUserError = payload;
    },

    setEditingCaregiver(state, { payload }) {
      state.editingCaregiver = payload;
    },

    setSelectedCaregiver(state, action) {
      state.selectedCaregiver = action.payload;
    },

    clearSelectedCaregiver(state) {
      state.selectedCaregiver = undefined;
    },

    setPasswordReset(state, { payload }) {
      state.passwordReset = payload;
    },

    setSuccess(state, { payload }) {
      state.successModal = payload;
    },
    setnoOfUsersCreated(state, { payload }) {
      state.noOfUsersCreated = payload;
    },
    clearnoOfUsersCreated(state) {
      state.noOfUsersCreated = undefined;
    },
  },
});

export default caregiverListSlice.reducer;
export const {
  setCaregivers,
  setError,
  clearCaregiversAndErrors,
  addPatientToState,
  setAddingUser,
  addUserToState,
  setAddUserError,
  setEditingCaregiver,
  setSelectedCaregiver,
  clearSelectedCaregiver,
  setPasswordReset,
  setSuccess,
  setnoOfUsersCreated,
  clearnoOfUsersCreated,
} = caregiverListSlice.actions;

export const passwordReset = (caregiverID) => async (dispatch) => {
  dispatch(clearSelectedCaregiver());
  dispatch(setPasswordReset(true));
  const errorMessage = `There was a problem fetching caregiver ${caregiverID}.`;
  try {
    const result = await getUserByIdAPI(caregiverID);
    if (result.ok) {
      const { id, firstName, lastName, phoneNumber, email, password, access } = await result.json();
      dispatch(
        setSelectedCaregiver({
          id,
          firstName,
          lastName,
          phoneNumber,
          email,
          password,
          access,
        })
      );
    } else if (result.status === 404) {
      dispatch(setError(`Caregiver ${caregiverID} does not exist.`));
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
    dispatch(setError(errorMessage));
  }
};

export const sendPasswordReset =
  (caregiverID, successText, noOfUsers, isNewAccount) => async (dispatch) => {
    try {
      const body = {
        id: caregiverID,
        isNewAccount,
      };
      const result = await postResetPassword(body);
      if (result.ok) {
        dispatch(setPasswordReset(false));
        dispatch(setnoOfUsersCreated(noOfUsers));
        if (noOfUsers > 1) {
          dispatch(setSuccess('Users have been successfully created'));
        } else {
          dispatch(setSuccess(successText));
        }
      } else {
        dispatch(setPasswordReset(false));
        throw new Error('An unspecified error occurred while sending password reset email.');
      }
    } catch (e) {
      log.error(e);
      dispatch(setShowNotification(e.message));
    }
  };

export const editSelectedCaregiver = (caregiverID) => async (dispatch) => {
  dispatch(clearSelectedCaregiver());
  dispatch(setEditingCaregiver(true));
  const errorMessage = `There was a problem fetching caregiver ${caregiverID}.`;
  try {
    const result = await getUserByIdAPI(caregiverID);
    if (result.ok) {
      const { id, firstName, lastName, phoneNumber, email, password, access, status } =
        await result.json();
      dispatch(
        setSelectedCaregiver({
          id,
          firstName,
          lastName,
          phoneNumber,
          email,
          password,
          access,
          status,
        })
      );
    } else if (result.status === 404) {
      dispatch(setError(`Caregiver ${caregiverID} does not exist.`));
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
    dispatch(setError(errorMessage));
  }
};

export const getCaregivers = () => async (dispatch) => {
  dispatch(clearCaregiversAndErrors());
  const user = getSessionUser();
  const errorMessage = 'There was a problem fetching the caregivers for this organization.';
  try {
    const result = await getUsersByOrgAPI(user.organizationId);
    if (result.ok) {
      const json = await result.json();
      dispatch(
        setCaregivers(
          json.map((data) => ({
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            access: data.access,
            status: data.status,
            email: data.email,
          }))
        )
      );
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
    dispatch(setError(errorMessage));
  }
};

export const saveChanges = (selectedCaregiver, successText) => async (dispatch) => {
  try {
    const body = {
      email: selectedCaregiver.email,
      access: selectedCaregiver.access,
      status: selectedCaregiver.status,
    };
    const result = await updateUserAPI(body, selectedCaregiver.id);
    if (result.ok) {
      dispatch(setEditingCaregiver(false));
      dispatch(setSuccess(successText));
      dispatch(getCaregivers());
    } else {
      throw new Error('An unspecified error occurred when saving these changes.');
    }
  } catch (e) {
    log.error(e);
    dispatch(setShowNotification(e.message));
  }
};

export const updateUserPassword = (selectedCaregiver) => async (dispatch) => {
  try {
    const body = {
      password: selectedCaregiver.password,
      mandatoryPasswordChange: false,
    };
    const result = await updateUserAPI(body, selectedCaregiver.id);
    if (result.ok) {
      dispatch(setPasswordReset(false));
      dispatch(setSuccess("'s password has been successfully updated."));
      dispatch(getCaregivers());
    } else {
      dispatch(setPasswordReset(false));
      throw new Error(
        `Password reset email was not sent successfully. Unable to change password for ${selectedCaregiver.firstName} ${selectedCaregiver.lastName}.`
      );
    }
  } catch (e) {
    log.error(e);
    dispatch(setShowNotification(e.message));
  }
};

export const addUser = (users) => async (dispatch) => {
  try {
    const orgId = getSessionUser().organization.id;
    const userArr = users.users.map((user) => ({
      ...user,
      password: '123456789',
      organizationId: orgId,
    }));
    const result = await postBulkUsersAPI(userArr);
    if (result.ok) {
      const resultId = await result.json();
      const callback = (_item, i) => {
        const userId = resultId[i].id;
        dispatch(
          addUserToState({
            id: userId,
            firstName: users.users[i].firstName,
            lastName: users.users[i].lastName,
            phoneNumber: users.users[i].phoneNumber,
            access: users.users[i].access,
            status: 'ACTIVE',
          })
        );
        dispatch(setAddingUser(false));
        fetchUserByEmail(users.users[i].email.toLowerCase()).then((user) => {
          dispatch(setSelectedCaregiver(user[0]));
          if (user !== undefined) {
            dispatch(
              sendPasswordReset(user[0].id, ' has been successfully added.', resultId.length, true)
            );
          }
        });
      };
      asyncForEach(resultId, callback).then(() => {
        dispatch(getCaregivers());
      });
    } else if (result.status === 409) {
      throw new Error('A user with this email already exists. Try a different email.');
    } else {
      throw new Error('An unspecified error occurred when adding a user.');
    }
  } catch (e) {
    log.error(e);
    dispatch(setShowNotification(e.message));
  }
};
