import React, { useContext, useState } from 'react';
import './ActionItemForm.css';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { Modal } from 'react-bootstrap';
import { GeneralButton, Input, Text } from '../../@intus-ui';
import { MeetingContext } from '../context/MeetingContext';

export default function ActionItemForm({
  createActionItem,
  editActionItem,
  description,
  setDescription,
  note,
  setNote,
  editing,
  setEditing,
  indexEditing,
  setIndexEditing,
  deleteActionItem,
}) {
  const { meeting } = useContext(MeetingContext);

  const [showModal, setShowModal] = useState(false);

  const clearForm = () => {
    setEditing(false);
    setIndexEditing(-1);
    setDescription('');
    setNote('');
  };

  const onSubmit = (e) => {
    if (editing) {
      if (!meeting) {
        setShowModal(true);
      } else {
        editActionItem(indexEditing, description, note);
        clearForm();
      }
    } else {
      createActionItem(description, note);
      clearForm();
    }
    submitCallback(e);
  };

  const submitCallback = (e) => {
    e.preventDefault();
    document.activeElement.blur();
  };

  return (
    <div
      style={{
        borderRadius: '10px',
        padding: '1rem',
      }}
    >
      <Modal show={!!showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Text type="headline">Confirm Changes</Text>
        </Modal.Header>
        <Text style={{ paddingLeft: '2rem', marginBottom: '1rem' }}>
          Are you sure you would like to modify this action item?
        </Text>
        <Modal.Footer>
          <GeneralButton name="Cancel" secondary onClick={() => setShowModal(false)} />
          <GeneralButton
            name="Confirm"
            onClick={() => {
              editActionItem(indexEditing, description, note);
              clearForm();
            }}
          />
        </Modal.Footer>
      </Modal>
      <form onSubmit={onSubmit}>
        <span className="label" style={{ fontWeight: '600' }}>
          Action Item Description
        </span>
        <Input
          type="textarea"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <span className="label" style={{ fontWeight: '600' }}>
          Action Item Note
        </span>

        <Input type="textarea" value={note} onChange={(e) => setNote(e.target.value)} />
        <div style={{ width: '100%' }}>
          {editing ? (
            <div className="editing-buttons">
              <GeneralButton
                name="Delete"
                onClick={() => {
                  deleteActionItem(indexEditing);
                  clearForm();
                }}
                secondary
              >
                <DeleteIcon />
              </GeneralButton>
              <GeneralButton name="Save" type="submit">
                <CheckIcon />
              </GeneralButton>
            </div>
          ) : (
            <GeneralButton name="Add Action Item" type="submit" style={{ margin: 'auto' }} />
          )}
        </div>
      </form>
    </div>
  );
}
