import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getStringReplacement } from '@util/stringReplacements';
import { getPrevMonths, toSentenceCase } from '@util/utilFunctions';

import { FallsModalPropType } from 'Homes/modalPropTypes/fallsModalPropType';

import DonutChart from '@intus-ui/components/graphs/DonutChart';
import { defaultChartColors } from '@intus-ui/styles/SecondaryColors';
import { getModalPatientList } from '@global-state/redux/dashboardSlice';
import { SpinnerOrError } from '@intus-ui';
import LineChart from '@intus-ui/components/graphs/LineChart';
import Quad from 'Dashboard/Quad';
import TextData from 'Dashboard/QuadContent/TextData';
import BigTitle from 'Dashboard/QuadContent/BigTitle';
import FrequentFallersParticipantList from 'Dashboard/Falls/FrequentFallersParticipantList';
import PercentageRow from '../PercentageRow';
import PercentageCol from '../PercentageCol';

import '@intus-ui/styles/Utilities.scss';

class FallsAnalytics extends React.Component {
  isActive(route) {
    const { location } = this.props;
    return location.pathname.startsWith(route);
  }

  render() {
    const { error, subcards, getPatientList, isDashboard } = this.props;
    let fallsSeverityData;
    let fallsSeverityChartOptions;
    let paddedMaxLineChart;
    // Taken from style guide in figma
    const fallsSeverityColors = [
      defaultChartColors.skyBlue,
      defaultChartColors.tealBlue,
      defaultChartColors.lightLilac,
      defaultChartColors.deepBlue,
    ];
    const fallsSeverityLabels = [];
    const fallsSeverityChartData = [];
    if (subcards !== undefined && subcards.fallsBySeverityPieChartData !== undefined) {
      fallsSeverityData = subcards.fallsBySeverityPieChartData;

      Object.entries(fallsSeverityData).forEach(([key, val]) => {
        fallsSeverityLabels.push(toSentenceCase(key));
        fallsSeverityChartData.push(val);
      });
      fallsSeverityChartOptions = {
        radius: '80%',
        borderRadius: 4,
        cutout: '40%',
        responsive: false,
        maintainAspectRatio: true,
        legend: {
          position: 'right',
        },
      };
      /*
      Finding the maximum point from all the data to find a suitable
      suggestedMax value for the y-axis.
      */
      const maxDataPoint = Math.max(...subcards.fallsPer100MMTrendLine);
      paddedMaxLineChart = maxDataPoint + maxDataPoint / 5;
    }

    const fallsLineOptions = {
      elements: { point: { hitRadius: 100 } },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 4,
              fontSize: 12,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
            scaleLabel: {
              display: true,
              labelString: 'Time (Months)',
              fontSize: 12,
              fontStyle: 'bold',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              maxTicksLimit: 8,
              fontSize: 12,
              precision: 0,
              suggestedMax: paddedMaxLineChart,
            },
            gridLines: {
              drawBorder: false,
            },
            scaleLabel: {
              display: true,
              labelString: 'Falls (per 100MM)',
              fontSize: 12,
              fontStyle: 'bold',
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) label += ': ';
            label += Math.round(tooltipItem.yLabel * 100) / 100;
            return label;
          },
        },
      },
    };
    if (error || subcards === undefined || subcards.fallsBySeverityPieChartData === undefined) {
      return <SpinnerOrError error={error} />;
    }
    return (
      <Container
        fluid
        className="bg-light m-0 h-100 w-100 d-flex flex-column overflow-hidden font-weight-normal pt-2 pb-3"
      >
        <div className="w-100 h-100 d-flex flex-column">
          <PercentageRow isDashboard={isDashboard}>
            <PercentageCol isHalf isDashboard={isDashboard}>
              <Quad
                scrollable
                title={`${getStringReplacement('Participant')} Falls Breakdown (Past 30 days)`}
                onClick={
                  subcards?.fallsBreakdown.participantsWithMultipleFalls.value > 0 ||
                  subcards?.fallsBreakdown.participantsWithFall.value > 0
                    ? () => {
                        getPatientList(
                          [
                            {
                              patientList: {
                                patientList:
                                  subcards.fallsBreakdown.participantsWithMultipleFalls.ids,
                              },
                              listTitle: `${getStringReplacement('Participant', {
                                plural: true,
                              })} with Multiple Falls`,
                            },
                            {
                              patientList: {
                                patientList: subcards.fallsBreakdown.participantsWithFall.ids,
                              },
                              listTitle: `All ${getStringReplacement('Participant', {
                                plural: true,
                              })} with Falls`,
                            },
                          ],
                          'pptsWFalls',
                          `${getStringReplacement('Participant', {
                            plural: true,
                          })} with Falls (Past 30 days)`,
                          this.isActive('/home') ? 'home' : 'dashboard'
                        );
                      }
                    : null
                }
                padding="py-4 pl-5"
              >
                <Row className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                  <Col
                    lg={6}
                    className="h-100 d-flex flex-column justify-content-center align-items-center border-right"
                  >
                    <BigTitle title="Ppts with Multiple Falls" secondary />
                    {subcards?.fallsBreakdown ? (
                      <TextData
                        data={subcards.fallsBreakdown.participantsWithMultipleFalls.value}
                        subtext={{
                          text: getStringReplacement('Participant', { lower: true, plural: true }),
                          vertical: true,
                        }}
                      />
                    ) : (
                      <SpinnerOrError error={error} />
                    )}
                  </Col>
                  <Col
                    lg={6}
                    className="h-100 d-flex flex-column justify-content-center align-items-center pr-5"
                  >
                    <BigTitle title="All Ppts with Falls" secondary />
                    <TextData
                      data={
                        subcards?.fallsBreakdown ? (
                          subcards.fallsBreakdown.participantsWithFall.value
                        ) : (
                          <SpinnerOrError error={error} />
                        )
                      }
                      subtext={{
                        text: getStringReplacement('Participant', { lower: true, plural: true }),
                        vertical: true,
                      }}
                    />
                  </Col>
                </Row>
              </Quad>
            </PercentageCol>
            <PercentageCol isHalf isDashboard={isDashboard}>
              <Quad
                title="Falls per 100MM"
                scrollable
                padding="py-1 my-0 px-0 justify-content-center"
              >
                <div className="h-100 w-75">
                  {subcards ? (
                    <LineChart
                      data={{
                        labels: getPrevMonths(subcards.fallsPer100MMTrendLine.length - 1),
                        datasets: [
                          {
                            label: 'Falls per 100MM',
                            data: [...subcards.fallsPer100MMTrendLine],
                            fill: false,
                            borderColor: 'rgb(75, 192, 192)',
                            tension: 0.1,
                          },
                        ],
                      }}
                      options={fallsLineOptions}
                      aspectRatio={false}
                      responsive
                      legendPosition="top"
                    />
                  ) : (
                    <SpinnerOrError error={error} />
                  )}
                </div>
              </Quad>
            </PercentageCol>
          </PercentageRow>
          <PercentageRow isDashboard={isDashboard}>
            <PercentageCol isDashboard={isDashboard}>
              <Quad title="Frequent Fallers (Past 30 Days)" padding="px-0 py-0">
                {subcards ? (
                  <FrequentFallersParticipantList data={subcards.frequentFallers} />
                ) : (
                  <SpinnerOrError />
                )}
              </Quad>
            </PercentageCol>
            <PercentageCol isDashboard={isDashboard}>
              <Quad
                title="Falls w/ non-minor Injury (Past 30 days)"
                onClick={
                  subcards?.participantsWithMajorInjuryFall.value > 0 ||
                  subcards?.participantsWithModerateInjuryFall.value > 0
                    ? () => {
                        getPatientList(
                          [
                            {
                              patientList: {
                                patientList: subcards.participantsWithMajorInjuryFall.ids,
                              },
                              listTitle: 'Injury with Major Harm',
                            },
                            {
                              patientList: {
                                patientList: subcards.participantsWithModerateInjuryFall.ids,
                              },
                              listTitle: 'Injury with Moderate Harm',
                            },
                          ],
                          'pptsWInjFalls',
                          `${getStringReplacement('Participant', {
                            plural: true,
                          })} with Injured Falls (Past 30 days)`,
                          this.isActive('/home') ? 'home' : 'dashboard'
                        );
                      }
                    : null
                }
                padding="px-3 pb-5 pt-4 "
              >
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                  {subcards ? (
                    <TextData
                      data={
                        subcards.participantsWithMajorInjuryFall.ids.length +
                        subcards.participantsWithModerateInjuryFall.ids.length
                      }
                      subtext={{ text: 'falls', vertical: true }}
                    />
                  ) : (
                    <SpinnerOrError error={error} />
                  )}
                </div>
              </Quad>
            </PercentageCol>
            <PercentageCol isDashboard={isDashboard}>
              <Quad title="Falls by Severity (Past 30 days)" scrollable>
                <div className="w-100 h-100 d-flex justify-content-center align-items-center pt-5 px-5">
                  <DonutChart
                    options={fallsSeverityChartOptions}
                    info={fallsSeverityChartData}
                    label={fallsSeverityLabels}
                    colors={fallsSeverityColors}
                  />
                </div>
              </Quad>
            </PercentageCol>
          </PercentageRow>
        </div>
      </Container>
    );
  }
}

FallsAnalytics.propTypes = {
  subcards: FallsModalPropType,
  getPatientList: PropTypes.func.isRequired,
  error: PropTypes.string,
  isDashboard: PropTypes.bool,
  location: PropTypes.instanceOf(Object).isRequired,
};

FallsAnalytics.defaultProps = {
  subcards: undefined,
  error: undefined,
  isDashboard: undefined,
};

const mapState = (state) => ({
  subcards: state.homepage.modalContent,
});

const mapDispatch = (dispatch) => ({
  getPatientList: (list, item, modalTitle, backLoc) =>
    dispatch(getModalPatientList(list, item, modalTitle, backLoc)),
});

export default withRouter(connect(mapState, mapDispatch)(FallsAnalytics));
