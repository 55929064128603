import { SearchDropdown, Icon, Input, Text } from '@intus-ui';
import { filterRowSchemas } from '../../filterRowConfig';

const DeleteFilterRowButton = ({ handleDeleteFilter }) => (
  <div style={styles.deleteFilterRowButton}>
    <Icon
      name="Remove"
      color="#9D9D9D"
      height="13px"
      width="13px"
      hoverColor="#052d8f"
      onClick={handleDeleteFilter}
    />
  </div>
);

const FilterSearchDropdown = ({
  filterOptions,
  type,
  searchTerm,
  handleChangeSearchTerm,
  isSingleFilterRow,
  isSecond,
  operator,
  operators,
  handleChangeOperator,
  isFirst,
  displayRange,
  filter,
  parentIndex,
  handleDeleteFilter,
}) => {
  const componentSchema = filterRowSchemas()[type]?.components?.FilterSearchDropdown;
  return (
    <div id="secondFilterRow" style={styles.secondFilterRow}>
      {!isSingleFilterRow && (
        <div id="secondFilterRowSpacer" style={styles.filterRowSpacer}>
          {isSecond && (
            <Input
              id="operator"
              name="operator"
              type="select"
              rightIcon="caret-down"
              options={operators.reverse()}
              value={operator}
              onChange={handleChangeOperator}
              style={{ width: 90 }}
            />
          )}
          {!isFirst && !isSecond && (
            <div style={{ paddingLeft: 5 }}>
              <Text type="body">{operator}</Text>
            </div>
          )}
        </div>
      )}
      <SearchDropdown
        fluid
        id="dropdown-Type"
        style={{
          marginRight: 10,
          minWidth: 300,
          cursor: 'pointer',
        }}
        searchable
        dataType={type}
        name="Type"
        options={filterOptions?.[type]?.options}
        value={searchTerm}
        noMatchLabel="No matches"
        placeholder={componentSchema?.placeholder}
        onChange={handleChangeSearchTerm}
        rightIcon="caret-down"
        leftIcon="search"
      />
      {!displayRange && filter.groups[parentIndex].conditions.length > 1 && (
        <DeleteFilterRowButton handleDeleteFilter={handleDeleteFilter} />
      )}
    </div>
  );
};

const styles = {
  secondFilterRow: {
    display: 'flex',
  },
  filterRowSpacer: {
    width: 100,
  },
  deleteFilterRowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default FilterSearchDropdown;
