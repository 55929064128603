export const alertColors = { danger: '#cc6262', warning: '#e4c20f', success: '#7ace46' };

export const riskColors = {
  low: 'rgb(148, 200, 249)',
  moderate: 'rgb(25, 148, 187)',
  high: 'rgb(234, 197, 5)',
  highest: 'rgb(204, 98, 98)',
};

export const riskColorsMuted = {
  low: 'rgb(148, 200, 249, 0.16)',
  moderate: 'rgb(25, 148, 187, 0.16)',
  high: 'rgb(234, 197, 5, 0.16)',
  highest: 'rgb(204, 98, 98, 0.16)',
};

export const shadesOfBlue = {
  intusNavy: '#052d8f',
  hoverBlue: '#4D7EF8',
  hoverBlue16: 'rgba(77, 126, 248, .16)',
  hoverBlue32: 'rgba(77, 126, 248, .32)',
  intusNavy40: 'rgba(5, 45, 143, 0.4)',
  intusNavy60: 'rgba(5, 45, 143, 0.6)',
};

export const defaultChartColors = {
  skyBlue: '#4DBAF8',
  tealBlue: '#60D1D9',
  lightLilac: '#B9A1EB',
  deepBlue: '#2A3CDD',
  mediumLilac: '#6E7BEF',
  fadedSkyBlue: '#479ECE',
  fadedTealBlue: '#8BC8CC',
};

export const initiativeChartColors = {
  blue: '#007BC7',
  orange: '#D4420C',
  purple: '#8C54A6',
  green: '#3D823A',
  pink: '#DB2489',
  maroon: '#8F1717',
  teal: '#088191',
  gray: '#282828',
};

export const cohortChangesColors = {
  disabled: '#C8C8C8',
  red: '#CC6262',
  blue: '#2E62E7',
};

export const textColors = {
  black: '#000000',
  white: '#FFFFFF',
  body: '#404040',
  caption: '#9D9D9D',
  navy: shadesOfBlue.intusNavy,
  link: '#2E62E7',
  error: '#CD3B3B',
  disabled: '#F6F7FA',
  success: '#4DC3CA',
  gray50: '#282828',
  countBlue: cohortChangesColors.blue,
  countRed: cohortChangesColors.red,
  countDisabled: cohortChangesColors.disabled,
  danger: alertColors.danger,
};

export const tagColors = {
  ...initiativeChartColors,
  white: '#BACEFF',
  white50: 'white',
  blue50: 'rgba(137, 196, 233, 0.5)',
  orange50: 'rgba(231, 180, 162, 0.5)',
  purple50: 'rgba(184, 156, 197, 0.5)',
  green50: 'rgba(159, 193, 157, 0.5)',
  pink50: 'rgba(239, 180, 201, 0.5)',
  maroon50: 'rgba(194, 146, 146, 0.5)',
  teal50: 'rgba(150, 177, 178, 0.5)',
  gray50: 'rgba(200, 200, 200, 1)',
  highlight50: 'rgba(186, 206, 255, 1)',
};

export const quadColors = { disabled: '#C8C8C8', active: '#2E62E7' };
export const quadBackgroundColors = { default: '#FFFFFF', secondary: '#E4ECFF', grey: '#F6F7FA' };
export const containerColors = { default: '#FFFFFF' };
export const input = {
  boxShadow: 'rgba(0, 0, 0, 0.2)',
  default: '#FFFFFF',
  hover: '#e3eafe',
  collapsed: '#9D9D9D',
  disabled: '#EFF0F3',
  active: '#2E62E7',
};
export default riskColors;
