import { post } from '@api/apiMethods';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';
import { IAllStarsMeasures } from 'Polypharmacy/types/STARSMeasures';

export type IStarsAdherenceResponse = {
  diabetesAdherence: number;
  rasAdherence: number;
  statinsAdherence: number;
  patientAdherence: IPatientStarsAdherence[];
};

export type IPatientStarsAdherence = {
  id: number;
  name: string;
  measureData: IPatientStarsMeasureData[];
};

export type IPatientStarsMeasureData = {
  fillCount: number;
  measure: IAllStarsMeasures;
  percentageDaysCovered: number;
  fillDueDate: string | null;
};

export function getSTARSAdherence(
  measureYear: number,
  filters: IPolypharmacyFilters,
  patientIds?: number[]
) {
  return post<IStarsAdherenceResponse>(`polypharmacy/adherence`, {
    measureYear,
    filters,
    patientIds,
  });
}
