import { useState, useEffect, useContext } from 'react';
import { Row, Col, Modal, Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import { useGetActiveParticipantsQuery, useAddMeetingMutation } from '@api';
import { setPatientList } from '@global-state/redux/profileSlice';

import PropTypes from 'prop-types';

import { RiskBadge } from '@intus-ui';
import SettingsBox from 'Settings/SettingsBox';
import { log } from '@util/logger';
import { MeetingContext } from './context/MeetingContext';
import ParticipantList from './ParticipantList';

import '@intus-ui/styles/Utilities.scss';
import '@intus-ui/styles/ListItem.scss';

const tableFormat = [
  {
    valueField: 'name',
    nodeField: 'name',
    md: 6,
    className: 'small pl-0 ml-0',
    sorting: 'lexographical',
    heading: 'Name',
  },
  {
    valueField: 'riskGroupNode',
    nodeField: 'riskGroupNode',
    md: 4,
    sorting: 'none',
    className: 'pl-3',
    heading: 'Score Group',
  },
  {
    valueField: 'acuityScoreValue',
    nodeField: 'acuityScoreNode',
    md: 2,
    className: 'text-secondary pl-5',
    value: true,
    sorting: 'numerical',
    heading: 'Acuity',
  },
];

const DEFAULT_NAME = `Alpha Meeting | ${new Date().toDateString()}`;
const DEFAULT_DURATION = 60;

const CreateMeetingModal = ({ hide, showModal, setMeeting, doSetPatientList }) => {
  const { setRunState, setAgendaItem } = useContext(MeetingContext);
  const [addMeeting] = useAddMeetingMutation();

  const [name, setName] = useState(DEFAULT_NAME);
  const [meetingDuration, setMeetingDuration] = useState(DEFAULT_DURATION);
  const [agendaItems, setAgendaItems] = useState([]);

  const riskBadge = (riskGroup) => {
    return <RiskBadge riskGroup={riskGroup} />;
  };

  const { data: participants = [] } = useGetActiveParticipantsQuery();

  const inAgenda = participants
    .map((patient) => ({
      id: patient.id,
      name: patient.name,
      acuityScoreNode: patient.acuity?.score || 0,
      acuityScoreValue: patient.acuity?.score || 0,
      riskGroupNode:
        riskBadge(patient.acuity?.riskGroup) || riskBadge(patient.riskBreakup.INSUFF.enum),
    }))
    .filter((patient) => agendaItems?.includes(patient.id));

  const notInAgenda = participants
    .map((patient) => ({
      id: patient.id,
      name: patient.name,
      acuityScoreNode: patient.acuity?.score || 0,
      acuityScoreValue: patient.acuity?.score || 0,
      riskGroupNode:
        riskBadge(patient.acuity?.riskGroup) || riskBadge(patient.riskBreakup.INSUFF.enum),
    }))
    .filter((patient) => !agendaItems?.includes(patient.id));

  useEffect(() => {
    inAgenda.filter((patient) => agendaItems?.includes(patient.id));
    notInAgenda.filter((patient) => !agendaItems?.includes(patient.id));
  }, [agendaItems, inAgenda, notInAgenda]);

  const handleDurationChange = ({ target }) => {
    const duration = parseInt(target.value, 10);
    if (Number.isInteger) setMeetingDuration(duration);
  };

  const handleRowClick = ({ id }) => {
    const items = [...agendaItems];
    if (!items.includes(id)) items.push(id);
    else items.splice(items.indexOf(id), 1);

    setAgendaItems(items);
  };

  const handleReset = () => {
    setName(DEFAULT_NAME);
    setMeetingDuration(DEFAULT_DURATION);
    setAgendaItems([]);
  };

  const handleSubmit = async () => {
    try {
      const meeting = {
        name,
        runningTimeSeconds: meetingDuration * 60,
        agendaItems,
      };
      const runningMeeting = await addMeeting(meeting).unwrap();
      // RESET THE MODAL FORMS
      handleReset();
      // SET MEETING STATE IN CONTEXT
      setMeeting(runningMeeting);
      // SET AGENDA ITEMS IN PATIENTS LIST SLICE (FOR PPT DETAIL VIEW NAVIGATION)
      doSetPatientList(
        inAgenda.map((patient) => ({
          id: patient.id,
          name: patient.name,
        }))
      );
      // SET CURRENT AGENDA ITEM IN CONTEXT
      setAgendaItem({ index: 0, item: inAgenda[0].id });
      // SET MEETING TIMER STATE IN CONTEXT
      setRunState('RUNNING');
    } catch (err) {
      log.error('error creating meeting', err);
    }
  };

  return (
    <Modal
      dialogClassName="home-modal min-w-90 overflow-hidden rounded-lg"
      show={!!showModal}
      onHide={() => {
        hide(false);
        handleReset();
      }}
    >
      <Modal.Header className="py-0 pl-4 pb-0 m-0 pt-4" closeButton>
        <Modal.Title className="text-align-left text-secondary bg-light m-0 pl-3 pb-2 font-weight-normal">
          Set Meeting Duration and Agenda Items
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-2 ml-4 mr-4 pr-2 pt-0 pb-0">
        <Container fluid className="bg-light m-0 font-weight-normal overflow-hidden p-3">
          <h3 className="text-secondary font-weight-normal">
            Select Target Meeting Duration (in minutes)
          </h3>
          <Row className="pt-3 pb-4">
            <input
              type="number"
              value={meetingDuration}
              onChange={handleDurationChange}
              className="rounded-xlg form-control w-25 ml-3"
            />
          </Row>

          <h3 className="text-secondary font-weight-normal">
            Select Participants in Meeting Agenda
          </h3>
          <Row className="py-3">
            <Col lg={6}>
              <ParticipantList
                title="In Agenda"
                items={inAgenda}
                format={tableFormat}
                onClick={handleRowClick}
                onReset={handleReset}
                defaultSorting={{ valueField: 'acuityScoreValue', value: false }}
                emptyText="You have not added any participants to the meeting agenda, add by selecting from the panel on the right."
              />
            </Col>
            <Col lg={6}>
              <ParticipantList
                title="Not In Agenda"
                items={notInAgenda}
                format={tableFormat}
                onClick={handleRowClick}
                defaultSorting={{ valueField: 'acuityScoreValue', value: false }}
                search={{
                  valueField: 'name',
                  name: 'Participant Name',
                }}
                emptyText="No participants found matching your search."
              />
            </Col>
          </Row>

          <SettingsBox.Buttons
            cancel={() => {
              hide(false);
              handleReset();
            }}
            submit={() => {
              hide(false);
              handleSubmit();
            }}
            submitText="Start Meeting"
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
};

CreateMeetingModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch) => ({
  doSetPatientList: (list) => dispatch(setPatientList(list)),
});

export default connect(null, mapDispatch)(CreateMeetingModal);
