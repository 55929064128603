import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import { brightColorsRGB } from '@intus-ui/styles/NivoColors';

const VerticalBar = ({
  yData,
  yLabel,
  xData,
  legendDisplay,
  bgColors,
  xAxisLabel,
  yAxisLabel,
  numBars,
}) => {
  const bgColorsArray = [];
  while (bgColorsArray.length < numBars) {
    bgColorsArray.push(...bgColors);
  }
  const data = {
    labels: xData.slice(0, numBars - 1),
    datasets: [
      {
        label: yLabel,
        data: yData.slice(0, numBars - 1),
        backgroundColor: bgColorsArray,
        borderColor: bgColors,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    maintainAspectRatio: true,
    responsive: true,
    dataset: {
      barPercentage: 1.0,
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: yAxisLabel,
            fontStyle: 'bold',
          },
          ticks: {
            beginAtZero: true,
            precision: 1,
            maxTicksLimit: 8,
          },
          gridLines: {
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          // barPercentage: 1.0,
          scaleLabel: {
            display: true,
            labelString: xAxisLabel,
            fontStyle: 'bold',
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: legendDisplay,
      labels: {
        boxWidth: 10,
      },
    },
    plugins: {
      legend: {
        display: legendDisplay,
        title: {
          display: true,
        },
        labels: {
          usePointStyle: true,
        },
      },
    },
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{xData && yData ? <Bar data={data} options={options} /> : <CenteredSpinner />}</>;
};

VerticalBar.propTypes = {
  legendDisplay: PropTypes.bool,
  bgColors: PropTypes.arrayOf(PropTypes.string),
  yData: PropTypes.arrayOf(PropTypes.any),
  xData: PropTypes.arrayOf(PropTypes.any),
  yLabel: PropTypes.string,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  numBars: PropTypes.number,
};

VerticalBar.defaultProps = {
  legendDisplay: false,
  bgColors: brightColorsRGB,
  xData: undefined,
  yData: undefined,
  yLabel: '',
  xAxisLabel: '',
  yAxisLabel: '',
  numBars: 13,
};

export default VerticalBar;
