import React, { useEffect } from 'react';
import '@intus-ui/styles/Utilities.scss';
import { getModalContent } from '@global-state/redux/homepageSlice';
import FallsAnalytics from 'Homes/HomePage/ModalContent/FallsAnalytics';
import { useDispatch, useSelector } from 'react-redux';
import { useParticipantFilters } from '@intus-ui';

export const FallsAnalyticsDashboard = () => {
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.filters.startDate);
  const endDate = useSelector((state) => state.filters.endDate);

  const { selectedFilters } = useParticipantFilters();

  useEffect(() => {
    dispatch(getModalContent('falls', startDate, endDate, selectedFilters));
  }, [startDate, endDate, selectedFilters]);

  return <FallsAnalytics isDashboard />;
};
