import React, { useEffect } from 'react';
import '@intus-ui/styles/Utilities.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getModalContent } from '@global-state/redux/homepageSlice';
import RiskAcuityAnalytics from 'Homes/HomePage/ModalContent/RiskAcuityAnalytics';
import { useParticipantFilters } from '@intus-ui';

export const RiskAcuityAnalyticsDashboard = () => {
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.filters.startDate);
  const endDate = useSelector((state) => state.filters.endDate);

  const { selectedFilters } = useParticipantFilters();

  useEffect(() => {
    dispatch(getModalContent('riskAcuity', startDate, endDate, selectedFilters));
  }, [startDate, endDate, selectedFilters]);

  return <RiskAcuityAnalytics />;
};
