export default {
  blue: { code: '#007BC7', labels: ['Female', '55-64'] },
  orange: { code: '#D4420C', labels: ['Male', '<55'] },
  purple: { code: '#8C54A6', labels: ['Other'] },
  green: { code: '#3D823A', labels: ['<10 meds'] },
  pink: { code: '#DB2489', labels: ['10+ meds'] },
  maroon: { code: '#8F1717', labels: ['15+ meds'] },
  teal: { code: '#088191', labels: ['20+ meds'] },
  forestGreen: { code: '#046236', labels: ['85+'] },
  indigo: { code: '#5B07D2', labels: ['75-84'] },
  brown: { code: '#7C4925', labels: ['65-74'] },
};
