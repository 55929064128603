import {
  IPatientFirstFillStatus,
  allPatientFirstFillStatus,
  firstFillStatusToColor,
  firstFillStatusToDescription,
} from '@api/polypharmacy/getRisingStars';
import { updateFirstFillStatus } from '@api/polypharmacy/updateFirstFillStatus';
import { useLazyQuery } from '@api/useQuery';
import { Tag, Text } from '@intus-ui';
import { shadesOfBlue } from '@intus-ui/styles/SecondaryColors';
import { ButtonBase, ButtonBaseProps, Menu, MenuItem } from '@mui/material';
import { IAllStarsMeasures } from 'Polypharmacy/types/STARSMeasures';
import React, { FC } from 'react';

type PatientFirstFillStatusProps = {
  patient: {
    id: number;
    measure: IAllStarsMeasures;
    status: IPatientFirstFillStatus | null;
  };
  onChangeStatus: () => void;
};

export const PatientFirstFillStatus: FC<PatientFirstFillStatusProps> = (props) => {
  const { patient, onChangeStatus } = props;
  const { status } = patient;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = anchorEl != null;

  const onOpenMenu: ButtonBaseProps['onClick'] = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { runQuery } = useLazyQuery(updateFirstFillStatus, {
    onSuccess: () => {
      onChangeStatus();
    },
  });

  const updateStatus = (status: string) => {
    setAnchorEl(null);
    runQuery({
      measure: patient.measure,
      measureYear: new Date().getFullYear(),
      patientId: patient.id,
      status: status as IPatientFirstFillStatus['status'],
    });
  };
  const clearStatus = () => {
    setAnchorEl(null);
    runQuery({
      measure: patient.measure,
      measureYear: new Date().getFullYear(),
      patientId: patient.id,
      status: null,
    });
  };

  const text = status == null ? null : firstFillStatusToDescription[status.status];

  const color = status == null ? null : firstFillStatusToColor[status.status];

  let statusDropdownOptions =
    allPatientFirstFillStatus as readonly IPatientFirstFillStatus['status'][];
  statusDropdownOptions = statusDropdownOptions.filter(
    (s) => s !== status?.status
  ) as IPatientFirstFillStatus['status'][];

  return (
    <div>
      {text != null && color != null && (
        <ButtonBase onClick={onOpenMenu}>
          <Tag style={styles.tag} color={color}>
            {text}
          </Tag>
        </ButtonBase>
      )}
      {status == null && (
        <ButtonBase sx={styles.button} onClick={onOpenMenu}>
          <Text type="caption-bold" style={{ color: '#2E62E7' }}>
            Add Status
          </Text>
        </ButtonBase>
      )}
      {isMenuOpen && (
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={updateStatus}>
          {statusDropdownOptions.map((s) => (
            <MenuItem key={s} onClick={() => updateStatus(s)}>
              {firstFillStatusToDescription[s]}
            </MenuItem>
          ))}
          {status != null && (
            <MenuItem key="Clear Status" onClick={clearStatus}>
              Clear Status
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  );
};

const styles = {
  tag: {
    width: 90,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: shadesOfBlue.hoverBlue16,
      color: 'white',
    },
  },
  button: {
    width: 90,
    height: 20,
    whiteSpace: 'nowrap',
    color: '#2E62E7',
    padding: '0px 8px',
    border: '1px solid #2E62E7',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: shadesOfBlue.hoverBlue16,
      color: 'white',
    },
  },
} satisfies InlineCss;
