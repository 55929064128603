import React from 'react';
import PropTypes from 'prop-types';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Button } from 'react-bootstrap';

const TreeListNode = ({
  nodes,
  selectedOptions,
  onChange,
  folderStructure,
  setFolderStructure,
  labelNode,
}) => {
  const toggleSelect = (selectedId, title) => {
    const copy = { ...selectedOptions };
    const folderCopy = [...folderStructure];
    if (copy[selectedId]) {
      delete copy[selectedId];
    } else {
      copy[selectedId] = {};
      folderCopy.push({ id: selectedId, title });
    }
    onChange(copy);
    setFolderStructure(folderCopy);
  };

  const childNodesToggleSelect = (selectedId, subNodes) => {
    const copy = { ...selectedOptions };
    copy[selectedId] = subNodes;
    onChange(copy);
  };

  return (
    <div className="d-flex flex-column w-100">
      {nodes.map((node) => {
        const childNodes = node.nodes || [];
        return (
          <div className="pl-2" key={node.id}>
            <TreeItem
              selected={!!selectedOptions[node.id]}
              node={node}
              onChange={() => {
                toggleSelect(node.id, node.title);
              }}
              isLeaf={!childNodes.length}
              labelNode={labelNode}
            />
            {childNodes.length > 0 && !!selectedOptions[node.id] && (
              <TreeListNode
                nodes={childNodes}
                selectedOptions={selectedOptions[node.id]}
                onChange={(subNodes) => childNodesToggleSelect(node.id, subNodes)}
                labelNode={labelNode}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

TreeListNode.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object),
  selectedOptions: PropTypes.objectOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  folderStructure: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
    })
  ),
  setFolderStructure: PropTypes.func,
  labelNode: PropTypes.func,
};

TreeListNode.defaultProps = {
  nodes: [],
  folderStructure: [],
  setFolderStructure: () => {},
  labelNode: undefined,
};

export default TreeListNode;

export const TreeItem = ({ selected, node, onChange, isLeaf, labelNode }) => {
  const opacity = isLeaf ? 0 : 1;
  const cursor = isLeaf ? 'auto' : 'pointer';
  return (
    <div className="d-flex justify-content-start align-items-start w-100">
      {!isLeaf && (
        <Button
          disabled={isLeaf}
          style={{
            opacity,
            color: 'inherit',
            cursor,
            outline: 'transparent hidden 0px',
          }}
          className="m-0 p-0 border-0 bg-transparent d-flex"
          onClick={() => {
            onChange(!selected);
          }}
        >
          <PlayArrowRoundedIcon className={selected ? 'rotate-90deg' : ''} />
          <div className="text-left">{node.title}</div>
        </Button>
      )}
      {isLeaf && labelNode !== undefined && labelNode(node)}
    </div>
  );
};

TreeItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  node: PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.instanceOf(Object),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isLeaf: PropTypes.bool.isRequired,
  labelNode: PropTypes.func,
};

TreeItem.defaultProps = {
  labelNode: undefined,
};
