import { useEffect, useRef, useState } from 'react';
import * as userTracking from '@util/userTracking';
import { useOutsideClick } from '@intus-ui/hooks';
import { Menu, MenuItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Text from '../Text';

const MenuItemDropdown = ({ title, id, menuItems, options, isHovered }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const location = useLocation();
  const isActive =
    location.pathname.startsWith('/dashboard') || location.pathname.startsWith('/customdashboards');

  const isMenuOpen = anchorEl != null;

  useEffect(() => {
    if (isMenuOpen) {
      userTracking.logEvent(`Top Nav: Clicked Dashboard`, {
        from: location.pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const dropDownRef = useRef(null);
  // Closes dropdown when clicked outside
  useOutsideClick(dropDownRef, () => {
    setAnchorEl(null);
  });

  const MenuItems = menuItems;
  return (
    <div id={id} ref={dropDownRef}>
      <Text
        style={{ padding: '0px 20px', textDecoration: 'none' }}
        id={id}
        color={isMenuOpen || isActive || isHovered ? 'navy' : 'link'}
        type="subtitle"
        onClick={(e) => onOpenMenu(e)}
      >
        {title}
      </Text>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          margin: '20px 0px 0px 0px',
        }}
        slotProps={{
          paper: {
            style: {
              border: 'none',
            },
          },
        }}
      >
        {menuItems && <MenuItems onCloseMenu={onCloseMenu} />}
        {options &&
          options.map((option) => {
            return (
              <MenuItem onClick={option.onClick} key={option.displayName}>
                <Text type="subtitle" color="link">
                  {option.displayName}
                </Text>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default MenuItemDropdown;
