import { createContext } from 'react';

const DragContext = createContext({
  currentDrag: undefined,
  setCurrentDrag: () => {},
  editMode: false,
  toggleEditMode: () => {},
  selectedTeam: 'All',
});

export default DragContext;
