import { getPatientMedicationsWithFilters } from '@api/patients/getPatientMedications';
import { IRisingStarMeasureData, getRisingStars } from '@api/polypharmacy/getRisingStars';
import { useLazyQuery, useQuery } from '@api/useQuery';
import { Card, Icon, Modal, SpinnerOrError, Text } from '@intus-ui';
import { ButtonBase } from '@mui/material';
import { newDate } from '@util/dateFunctions';
import { getStringReplacement } from '@util/stringReplacements';
import MedicationsModalList from 'Polypharmacy/MedicationsModalList';
import { NextFillDueColumn } from 'Polypharmacy/components/NextFillDueColumn';
import { PatientFirstFillStatus } from 'Polypharmacy/components/PatientFirstFillStatus';
import { IAllStarsMeasures, starsMeasureToName } from 'Polypharmacy/types/STARSMeasures';
import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router';

export const PatientStarsAdherence: FC = () => {
  const [medicationsModalOpen, setMedicationsModalOpen] = useState(false);

  const { patientId } = useParams<{ patientId: string }>();

  const patientIdNumeric = parseInt(patientId);

  const { data, loading, error, runQuery } = useQuery(() =>
    getRisingStars(
      {
        // We don't really care about the dates here.
        startDate: newDate().startOf('year').format('YYYY-MM-DD'),
        endDate: newDate().endOf('year').format('YYYY-MM-DDT23:23:59'),
      },
      [patientIdNumeric],
      true
    )
  );

  const {
    data: medicationsData,
    loading: medicationsDataLoading,
    error: medicationsDataError,
    runQuery: runGetMedicationsData,
  } = useLazyQuery(getPatientMedicationsWithFilters);

  const onClickSeeMore = useCallback(
    (measure: IAllStarsMeasures) => {
      runGetMedicationsData({
        patientId: parseInt(patientId),
        starsMeasure: measure,
        starsMeasureYear: new Date().getFullYear(),
        active: false,
        isRisingStars: true,
      });
      setMedicationsModalOpen(true);
    },
    [patientId, runGetMedicationsData]
  );

  if (error) return <SpinnerOrError error="An error ocurred loading the patient's adherence" />;

  if (loading && !data) return <SpinnerOrError />;
  if (!data) return <SpinnerOrError />;

  const patientAdherence = data.patientAdherence[0]?.risingStarsData ?? [];

  return (
    <Card
      style={{
        width: '100%',
        height: '100%',
        padding: '10px 10px',
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
        }}
      >
        <div>
          <Text type="body" color="navy">
            <b>Adherence</b>
          </Text>
        </div>

        {patientAdherence.map((adherence) => (
          <AdherenceCard
            key={adherence.measure}
            patientId={patientIdNumeric}
            adherence={adherence}
            onClickSeeMore={onClickSeeMore}
            onChangeStatus={() => {
              runQuery();
            }}
          />
        ))}

        {patientAdherence.length === 0 && (
          <Text>
            This {getStringReplacement('Participant', { lower: true })} is not a part of any
            adherence measures.
          </Text>
        )}
      </div>

      <Modal
        open={medicationsModalOpen}
        onClose={() => setMedicationsModalOpen(false)}
        type="large"
        style={{ overflow: 'hidden' }}
        modalStyles={{ height: '90%' }}
        header={{ title: 'Medications', centered: true }}
      >
        <MedicationsModalList
          medicationsData={medicationsData}
          loading={medicationsDataLoading}
          error={medicationsDataError}
        />
      </Modal>
    </Card>
  );
};

type AdherenceCardProps = {
  patientId: number;
  adherence: IRisingStarMeasureData;
  onClickSeeMore: (measure: IAllStarsMeasures) => void;
  onChangeStatus: () => void;
};

const AdherenceCard: FC<AdherenceCardProps> = ({
  patientId,
  adherence,
  onClickSeeMore,
  onChangeStatus,
}) => {
  return (
    <Card style={{ width: '100%' }}>
      <div>
        <Text type="subtitle">{starsMeasureToName[adherence.measure]} PDC</Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 5,
            padding: '8px 0px',
          }}
        >
          <Text type="display" style={{ fontSize: '52px' }}>
            {parseFloat((adherence.percentageDaysCovered * 100).toFixed(0))}%
          </Text>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Text>
              {adherence.fillCount} fill{adherence.fillCount > 1 ? 's' : ''}
            </Text>
            {adherence.fillDueDate && (
              <>
                <Text type="caption" style={{ marginRight: -5 }}>
                  Next Fill Due {newDate(adherence.fillDueDate).format('MM/DD/YY')}
                </Text>
                <NextFillDueColumn
                  fillDueDate={adherence.fillDueDate}
                  hideText
                  style={{ padding: 0 }}
                />
              </>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <PatientFirstFillStatus
            patient={{ id: patientId, ...adherence }}
            onChangeStatus={onChangeStatus}
          />
          <ButtonBase onClick={() => onClickSeeMore(adherence.measure)}>
            <Text color="link">See more</Text>
            <Icon name="ArrowRight" size="medium" color="#2E62E7" hoverColor="#2E62E7" />
          </ButtonBase>
        </div>
      </div>
    </Card>
  );
};
