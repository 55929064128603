import { Bar, HorizontalBar } from 'react-chartjs-2';
import { useEffect, useRef, useState } from 'react';
import { isAllZeroOrEmpty } from '@util';
import { useReactChartTooltipV2 } from '@intus-ui/components/graphs/useReactChartTooltipV2';
import { initiativeChartColors } from '@intus-ui/styles/SecondaryColors';
import { formatDate, isValidDate, isSameDate, newDate } from '@util/dateFunctions';
import Text from '@intus-ui/components/Text';
import layouts from '@intus-ui/layouts';
import {
  adjustColorBrightness,
  createDefaultChartConfig,
  generateHighlightColorArrayAtIndex,
} from './chartConfig';

const BarChart = ({
  metricData,
  isHorizontalBarChart = false,
  isTimeAxis = false,
  onClickBar,
  suggestedMaxValue = null,
  inFocusView = false,
}) => {
  const [chartDataConfig, setChartDataConfig] = useState({});

  // The width and height to render the chart, calculated based on the size of the container.
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const containerRef = useRef();

  const onClickBarExt = (e, elements) => {
    if (!onClickBar) return;
    const { _view, _datasetIndex, _index } = elements[0] || [];
    const metricColor = metricData?.color;
    if (elements.length > 0) {
      const { label } = _view;
      const datasetIndex = _datasetIndex;
      const newChartData = { ...chartDataConfig };
      const currentDataset = newChartData.datasets[datasetIndex];
      onClickBar(label);
      currentDataset.backgroundColor = generateHighlightColorArrayAtIndex(
        metricColor,
        currentDataset.data.length,
        _index
      );
      setChartDataConfig(newChartData);
    } else {
      // reset selected metric and background color of selected bar
      const newChartData = createDefaultChartConfig(metricData, metricColor);
      setChartDataConfig(newChartData);
      onClickBar(null);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const rect = container.getBoundingClientRect();
      setWidth(rect.width);
      setHeight(rect.height);
    }
  }, []);

  useEffect(() => {
    if (metricData) {
      const chartConfig = createDefaultChartConfig(metricData, metricData.color);
      setChartDataConfig(chartConfig);
    }
  }, [metricData]);

  const { tooltipOptions, tooltipComponent } = useReactChartTooltipV2(
    (dataIndex) => (
      <BarChartTooltip dataIndex={dataIndex} metricData={metricData} chartData={chartDataConfig} />
    ),
    isHorizontalBarChart
  );
  const barChartAxisLabel = chartDataConfig.datasets && chartDataConfig.datasets[0]?.label;

  // Optional chart options
  /** @type {Chart.ChartOptions} */
  const options = {
    responsive: false,
    onHover(event, element) {
      if (element.length && inFocusView) {
        event.target.style.cursor = 'pointer';
      } else {
        event.target.style.cursor = 'default';
      }
    },
    maintainAspectRatio: false,
    // Turn off the animation since it doesn't work with the custom lines.
    animation: {
      duration: 0,
    },
    legend: false,
    tooltips: {
      ...tooltipOptions,
      mode: 'nearest',
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: isHorizontalBarChart,
            labelString: barChartAxisLabel,
          },
          position: isHorizontalBarChart && 'top',
          ticks: {
            maxTicksLimit: 24,
            suggestedMax: isHorizontalBarChart && suggestedMaxValue ? suggestedMaxValue : undefined,
            suggestedMin: isHorizontalBarChart ? 0 : undefined,
            callback: (value) => {
              if (!isTimeAxis) {
                return value;
              }
              // If we're in the current month, display MTD on the chart.
              if (isSameDate(newDate(), value, 'month')) {
                return `${formatDate(value, "MMM 'YY")} (MTD)`;
              }
              return formatDate(value, "MMM 'YY");
            },
          },
          gridLines: {
            display: isHorizontalBarChart,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: !isHorizontalBarChart,
            labelString: barChartAxisLabel,
          },
          ticks: {
            // The suggestedMax here is only used if all data points are below 5
            // We do this to prevent the graph from looking bad for small numbers.
            suggestedMax: !isHorizontalBarChart && suggestedMaxValue ? suggestedMaxValue : 5,
            suggestedMin: 0,
            maxTicksLimit: 8,
            fontSize: 12,
            precision: 0,
          },
          gridLines: {
            display: !isHorizontalBarChart,
          },
        },
      ],
    },
    onClick: onClickBarExt,
  };

  let ChartComponent;
  const noDataAvailable =
    chartDataConfig.datasets && isAllZeroOrEmpty(chartDataConfig.datasets[0]?.data);

  if (noDataAvailable) {
    ChartComponent = NoDataComponent;
  } else {
    ChartComponent = isHorizontalBarChart ? HorizontalBar : Bar;
  }

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerRef}>
      {/* Do not render the chart until we have a width/height for the parent container */}
      {width && (
        <>
          {tooltipComponent}
          <ChartComponent width={width} height={height} data={chartDataConfig} options={options} />
        </>
      )}
    </div>
  );
};

const NoDataComponent = () => (
  <div style={{ height: '100%', ...layouts.centeredContentContainer }}>
    <Text>There is no data in this time range</Text>
  </div>
);
const BarChartTooltip = ({ dataIndex = 0, chartData = {}, metricData }) => {
  const { labels = [], datasets = [] } = chartData;
  const { label: datasetLabel = 'N/A', data = [] } = datasets[0] || {};

  let barLabel = 'N/A';
  if (isValidDate(labels[dataIndex])) {
    barLabel = formatDate(labels[dataIndex], "MMM 'YY");
  } else {
    barLabel = labels[dataIndex];
  }

  let barValue = 'N/A';
  if (data[dataIndex] != null) barValue = data[dataIndex];
  const metricColor = initiativeChartColors[metricData?.color] || '#fff';
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <Text type="caption-bold">{barLabel}</Text>
      <div style={{ display: 'flex', gap: 5 }}>
        <div
          style={{
            width: '16px',
            height: '16px',
            backgroundColor: adjustColorBrightness(metricColor, 0.5),
            border: `1px solid ${metricColor}`,
          }}
        />
        <Text type="caption-bold">{datasetLabel}:</Text>
        <Text type="caption">{barValue}</Text>
      </div>
    </div>
  );
};

export default BarChart;
