import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { Form, DropdownButton, Dropdown } from 'react-bootstrap';
import { Button } from '@intus-ui';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import { postDashboardXLSX } from '@global-state/redux/dashboardSlice';
import CustomCheckboxes from './CustomCheckboxes';
import OrgCheckboxes from './OrgCheckboxes';

import {
  initializeSpreadsheetModal,
  resetMainCheckboxes,
  toggleMainCheckbox,
} from './spreadsheetModalSlice';
import { selectAllDashboards } from '../normalizedDashRedux/customDashboardSlice';

const monthsMap = {
  'Past 3 Months': 3,
  'Past 6 Months': 6,
  'Past Year': 12,
};

const SpreadsheetModal = ({
  downloadSpreadsheet,
  customDashItemsFlat,
  loadingStatus,
  mainCheckboxes,
  sectionCheckboxes,
  customItems,
  initSpreadsheetModal,
  doToggleMain,
  orgItems,
}) => {
  const [timeFilter, setTimeFilter] = useState('Past 6 Months');
  const allDashboards = useSelector(selectAllDashboards);

  const flatItemsReady = !!Object.keys(customDashItemsFlat || {}).length;
  useEffect(() => {
    if (loadingStatus === 'not loaded' && flatItemsReady) {
      initSpreadsheetModal(customDashItemsFlat, allDashboards);
    }
  }, [loadingStatus, customDashItemsFlat, flatItemsReady, allDashboards, initSpreadsheetModal]);

  const toggleMain = (dashId) => doToggleMain(dashId);

  const initValues = {};
  Object.keys(mainCheckboxes).forEach((id) => {
    initValues[id] = [];
  });

  const timeFilterButton = (
    <span key="spreadsheet-time-filter" style={{ margin: '.8em 1em 1.8em' }}>
      <DropdownButton
        size="sm"
        id="spreadsheet-time-filter-button"
        title={timeFilter}
        variant="outline-primary"
        onClick={(event) =>
          event.target.id === 'spreadsheet-time-filter-button' || event.target.id === ''
            ? null
            : setTimeFilter(event.target.id)
        }
        bsPrefix="m-0 btn btn-outline-primary"
      >
        <Dropdown.Item id="Past 3 Months">Past 3 Months</Dropdown.Item>
        <Dropdown.Item id="Past 6 Months">Past 6 Months</Dropdown.Item>
        <Dropdown.Item id="Past Year">Past Year</Dropdown.Item>
      </DropdownButton>
    </span>
  );

  const getDisplayName = ({ displayTitle }) => displayTitle;

  const filterSectionBoxes = (boxes) => boxes?.indicators;

  let content = <CenteredSpinner className="w-100 h-100" />;
  if (loadingStatus === 'loading') content = <CenteredSpinner className="w-100 h-100" />;

  if (loadingStatus === 'loaded') {
    content = (
      <Formik
        initialValues={{
          ...initValues,
        }}
        onSubmit={(values) => {
          const data = {};
          const submitHelper = (id) => {
            const { dashName } = sectionCheckboxes[id];
            data[dashName] = values[id].map((item) => customDashItemsFlat[item]);
          };
          // excluding main checkboxes and others that aren't checked
          Object.keys(mainCheckboxes).forEach((id) => {
            if (values[id]?.length) {
              submitHelper(id);
            }
          });
          const toSend = { months: monthsMap[timeFilter], data };
          downloadSpreadsheet(toSend);
          // FIXME hideModal IS NOT DEFINED
          // hideModal();
        }}
        className="w-100 h-100"
      >
        {({ values, isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className="w-100 h-100 d-flex flex-column">
            <Form.Row className="d-flex flex-grow-1 justify-content-start align-items-start flex-column px-3">
              <div className="pb-1 text-bold">Select the field items to download</div>
              <h5>Custom Dashboards</h5>
            </Form.Row>
            <CustomCheckboxes
              customItems={customItems}
              mainCheckboxes={mainCheckboxes}
              sectionCheckboxes={sectionCheckboxes}
              values={values}
              toggleMain={toggleMain}
              filterSectionBoxes={filterSectionBoxes}
              getDisplayName={getDisplayName}
            />
            {!!orgItems.length && (
              <>
                <Form.Row className="d-flex flex-grow-1 justify-content-start align-items-start px-3 pt-3 mt-3 border-top">
                  <h5>Shared Dashboards</h5>
                </Form.Row>
                <OrgCheckboxes
                  orgItems={orgItems}
                  mainCheckboxes={mainCheckboxes}
                  sectionCheckboxes={sectionCheckboxes}
                  values={values}
                  toggleMain={toggleMain}
                  filterSectionBoxes={filterSectionBoxes}
                  getDisplayName={getDisplayName}
                />
              </>
            )}
            <Form.Row className="m-0 w-100 d-flex justify-content-center align-items-center flex-grow-1">
              <div className="d-flex justify-content-center align-items-center">
                {timeFilterButton}
                <Button
                  type="submit"
                  busy={isSubmitting}
                  disabled={
                    !Object.values(values) ||
                    !Object.values(values).reduce((acc, curr) => acc.concat(curr)).length
                  }
                >
                  Download Spreadsheet
                </Button>
              </div>
            </Form.Row>
          </Form>
        )}
      </Formik>
    );
  }

  return content;
};

SpreadsheetModal.propTypes = {
  downloadSpreadsheet: PropTypes.func.isRequired,
  customDashItemsFlat: PropTypes.instanceOf(Object),
  loadingStatus: PropTypes.string.isRequired,
  customItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  mainCheckboxes: PropTypes.objectOf(PropTypes.bool).isRequired,
  sectionCheckboxes: PropTypes.objectOf(PropTypes.object).isRequired,
  initSpreadsheetModal: PropTypes.func.isRequired,
  doToggleMain: PropTypes.func.isRequired,
  doResetMain: PropTypes.func.isRequired,
  orgItems: PropTypes.arrayOf(PropTypes.number).isRequired,
};

SpreadsheetModal.defaultProps = {
  customDashItemsFlat: undefined,
};

const mapState = (state) => ({
  customDashItemsFlat: state.customDashIndicators.customDashItemsFlat,
  loadingStatus: state.spreadsheetModal.loadingStatus,
  customItems: state.spreadsheetModal.customItems,
  mainCheckboxes: state.spreadsheetModal.mainCheckboxes,
  sectionCheckboxes: state.spreadsheetModal.sectionCheckboxes,
  orgItems: state.spreadsheetModal.orgItems,
});

const mapDispatch = (dispatch) => ({
  downloadSpreadsheet: (data) => dispatch(postDashboardXLSX(data)),
  initSpreadsheetModal: (flatIndicators, dashboards) =>
    dispatch(initializeSpreadsheetModal(flatIndicators, dashboards)),
  doToggleMain: (id) => dispatch(toggleMainCheckbox(id)),
  doResetMain: () => dispatch(resetMainCheckboxes()),
});

export default connect(mapState, mapDispatch)(SpreadsheetModal);
