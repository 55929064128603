import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import track from 'react-tracking';
// withRouter is needed for the Prompt
import { withRouter, Prompt } from 'react-router-dom';
import currentDashPropType from 'Dashboard/dashDataPropTypes/currentDashPropType';
import { getCustomDashIndicators } from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';
import CustomDashboardContent from './CustomDashboardContent';
import DraggableList from './DraggableList';
import DragContext from './DragContext';
import DeleteDashModal from './DeleteDashModal';
import TeamLeaderboard from './TeamLeaderboard';
import EditDeleteButtons from './EditDeleteButtons';
import SaveDashModal from './SaveDashModal';

class CustomDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.setCurrentDrag = (val) => {
      this.setState({ currentDrag: val });
    };
    this.state = {
      currentDrag: undefined,
      setCurrentDrag: this.setCurrentDrag,
      showDeleteDash: false,
    };
  }

  componentDidMount() {
    const { currentDashboard, doGetCustomDashIndicators, filters } = this.props;
    const filteredVals = [];

    Object.keys(filters).forEach((key) => {
      if (key !== 'patientFilters') {
        filteredVals.push(filters[key]);
      }
    });
    // we basically don't want to fetch if any filters, except for team and facility filters are ''.
    // Once the filters update we will fetch. If we store filters differently in the future
    // (ie in redux or a more global context) we won't need this anymore.
    const readyToFetch = filteredVals.every((val) => Boolean(val));
    if (readyToFetch) doGetCustomDashIndicators(currentDashboard, filters);
  }

  componentDidUpdate(prevProps) {
    const {
      currentDashboard,
      initialIndicators,
      toggleLeaderboard,
      doGetCustomDashIndicators,
      filters,
    } = this.props;
    const {
      timeFilter: { startDate, endDate },
    } = filters;
    const filtersChanged =
      prevProps.filters.patientFilters !== filters.patientFilters ||
      prevProps.filters.timeFilter.startDate !== startDate ||
      prevProps.filters.timeFilter.endDate !== endDate;
    const dashboardChanged = prevProps.currentDashboard.id !== currentDashboard.id;

    if (dashboardChanged) {
      // switch dashboards, update stored indicators
      doGetCustomDashIndicators(currentDashboard, filters); // Also get cohorts here
      // case where we are viewing the leaderboard and create a new dash
      if (prevProps.leaderboard && !initialIndicators.length) {
        // eslint-disable-next-line react/no-did-update-set-state
        toggleLeaderboard(false);
      }
    }

    if (filtersChanged && !prevProps.leaderboard) {
      // case where the filters are changed, but not on the leaderboard because
      // the filters are handled individually in the leaderboard componentent
      doGetCustomDashIndicators(currentDashboard, filters);
    }
  }

  render() {
    const { currentDrag, setCurrentDrag, showDeleteDash } = this.state;
    const {
      editMode,
      toggleEditMode,
      leaderboard,
      toggleLeaderboard,
      initialIndicators,
      hamburgerOpen,
      filters,
    } = this.props;
    const dashContentOpacity = leaderboard ? 0 : 1;
    const leaderboardOpacity = leaderboard ? 1 : 0;
    let draggableListWidth = '0%';
    let customDashWidth = '0%';
    let leaderboardWidth = '0%';
    if (leaderboard) {
      leaderboardWidth = '100%';
      draggableListWidth = '0%';
      customDashWidth = '0%';
    } else if (editMode) {
      leaderboardWidth = '0%';
      customDashWidth = '75%';
      draggableListWidth = '25%';
    } else {
      leaderboardWidth = '0%';
      customDashWidth = '100%';
      draggableListWidth = '0%';
    }

    return (
      <DragContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          editMode,
          toggleEditMode,
          currentDrag,
          setCurrentDrag,
        }}
      >
        {/* Prompts users to save an unsaved custom
        dashboard when navigating to a different page */}
        <Prompt when={editMode} message="Leave this dashboard without saving changes?" />
        {/* Prompts users to save an unsaved custom dashboard when
         navigating to a different dashboard */}
        <SaveDashModal show={editMode && hamburgerOpen} />
        <DeleteDashModal
          show={showDeleteDash}
          hide={() => this.setState({ showDeleteDash: false })}
        />
        <Container
          fluid
          className="h-100 w-100 d-flex flex-column smoother position-relative"
          style={{ paddingLeft: 60, paddingRight: '3em' }}
        >
          <EditDeleteButtons
            editMode={editMode}
            toggleEditMode={toggleEditMode}
            leaderboard={leaderboard}
            expanded={!initialIndicators.length && !editMode}
            showDeleteDashModal={() => this.setState({ showDeleteDash: true })}
          />
          <Row className="m-0 w-100 d-flex flex-grow-1 overflow-hidden pt-2 smoother">
            <div
              style={{ width: draggableListWidth, opacity: dashContentOpacity }}
              className="height-95 d-flex flex-column pt-2 smoother"
            >
              <DraggableList filters={filters} />
            </div>
            <div
              style={{ width: customDashWidth, opacity: dashContentOpacity }}
              className="height-95 d-flex flex-column smoother"
            >
              <div className="w-100 h-100 pt-2" style={{ overflow: 'hidden auto' }}>
                <CustomDashboardContent />
              </div>
            </div>
            <div
              style={{ width: leaderboardWidth, opacity: leaderboardOpacity }}
              className="height-95 d-flex flex-column smoother"
            >
              <div className="px-3 w-100 h-100">
                <TeamLeaderboard
                  showLeaderboard={leaderboard}
                  toggleLeaderboard={toggleLeaderboard}
                />
              </div>
            </div>
          </Row>
        </Container>
      </DragContext.Provider>
    );
  }
}

CustomDashboard.propTypes = {
  currentDashboard: currentDashPropType.isRequired,
  editMode: PropTypes.bool.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  leaderboard: PropTypes.bool.isRequired,
  toggleLeaderboard: PropTypes.func.isRequired,
  doGetCustomDashIndicators: PropTypes.func.isRequired,
  initialIndicators: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.shape({
    timeFilter: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }).isRequired,
  hamburgerOpen: PropTypes.bool.isRequired,
};

const mapState = (state) => ({
  currentDashboard: state.dashboard.currentDash,
  initialIndicators: state.customDashIndicators.initialIndicators,
  hamburgerOpen: state.dashboard.hamburgerOpen,
});

const mapDispatch = (dispatch) => ({
  doGetCustomDashIndicators: (dashId, filters) =>
    dispatch(getCustomDashIndicators(dashId, filters)),
});

export default track({ page: 'customdash' })(
  withRouter(connect(mapState, mapDispatch)(CustomDashboard))
);
