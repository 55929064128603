import features from './features';
import activateFeatureFlag from './utils';

export const featureFlags = features.map((feature) => {
  return {
    ...feature,
  };
});

export const getFeatureFlag = (featureName) => {
  const feature = featureFlags.find((feat) => feat.name === featureName);
  const featureIsActive = activateFeatureFlag(feature.name, feature.environment);

  return { ...feature, featureIsActive };
};
