import { useContext, useState } from 'react';

import { GeneralButton } from '@intus-ui';
import { Edit } from '@intus-ui/icons/index';

import Patient from 'Participants/Patient';
import CreateMeetingModal from './CreateMeetingModal';

import { MeetingContext } from './context/MeetingContext';

const Meetings = () => {
  const { meeting, setMeeting, currentAgendaItem } = useContext(MeetingContext);

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    if (!modalOpen) setModalOpen(true);
  };

  // useEffect(() => {
  //   const { patientId } = currentAgendaItem;
  // }, [currentAgendaItem]);

  return (
    <>
      {!meeting && (
        <>
          <CreateMeetingModal showModal={modalOpen} hide={setModalOpen} setMeeting={setMeeting} />
          <GeneralButton
            name="Start New Meeting"
            hoverText="Start a New Meeting"
            onClick={toggleModal}
            secondary
          >
            <Edit name="edit" />
          </GeneralButton>
        </>
      )}

      {meeting && currentAgendaItem?.item && <Patient patientId={currentAgendaItem.item} />}
    </>
  );
};

export default Meetings;
