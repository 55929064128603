import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { Row } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import customDashItemPropType from 'Dashboard/customDashItemPropType';
import SimpleTooltip from '@intus-ui/components/SimpleTooltip';
import { indicatorDeleted } from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';
import { getModalPatientList, setSelected } from '@global-state/redux/dashboardSlice';
import draggableTypes from '@util/draggableTypes';
import TextData from '../QuadContent/TextData';
import BigTitle from '../QuadContent/BigTitle';
import DragContext from './DragContext';

const { DRAGGABLE_QUAD } = draggableTypes;

// Can i get filters as a prop here?
const DraggableQuad = ({ item, deleteIndicator, doSetSelected, getPatientList }) => {
  const { displayTitle, description, value, id, allowDuplicatePatients } = item;
  const { currentDrag, setCurrentDrag, editMode } = useContext(DragContext);
  let content = <p>loading</p>;

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: DRAGGABLE_QUAD, item },
    canDrag: () => !!displayTitle && editMode,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    begin: () => setCurrentDrag({ title: displayTitle, type: DRAGGABLE_QUAD }),
    end: () => setCurrentDrag(undefined),
  });

  let opacity = 1;
  let zIndex = 3;
  if (currentDrag) {
    zIndex = currentDrag.title === displayTitle ? 3 : 1;
    opacity = isDragging ? 0.7 : 0.2;
  }

  const deleteFromActive = () => deleteIndicator(id);

  const bigTitle = (
    <>
      {displayTitle}
      {description && (
        <SimpleTooltip className="d-inline-block" content={description}>
          <InfoIcon variant="success" className="info-icon cursor-pointer m-0" />
        </SimpleTooltip>
      )}
    </>
  );

  // must check for undefined
  if (value !== undefined) {
    content = (
      <>
        <BigTitle title={bigTitle} />
        <TextData data={value} />
      </>
    );
  }

  if (item.participantIds?.length) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={`clickable hover-light w-100 h-100 position-absolute box-shadow-on-gray 
        rounded-xlg bg-white d-flex flex-column align-items-center flex-shrink-1 smoother`}
        style={{
          cursor: editMode ? 'move' : 'pointer',
          opacity,
          top: 0,
          left: 0,
          transform: editMode ? 'translate(0,0)' : 'none',
          zIndex,
        }}
        ref={dragRef}
        onClick={
          !editMode
            ? () => {
                getPatientList(
                  [
                    {
                      patientList: { patientList: item.participantIds },
                      listTitle: displayTitle,
                    },
                  ],
                  allowDuplicatePatients
                );
                doSetSelected({
                  item: displayTitle,
                  modalTitle: displayTitle,
                  optionalParams: { allowDuplicatePatients },
                });
              }
            : undefined
        }
      >
        <Row className="p-4 m-0 w-100 h-100">
          <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            {content}
          </div>
        </Row>
        {editMode && (
          <CloseIcon
            fontSize="small"
            className="bg-transparent position-absolute cursor-pointer mr-1 mt-1 text-muted-hover-danger"
            style={{
              top: 0,
              right: 0,
              width: '1em',
              height: '1em',
            }}
            onClick={() => deleteFromActive()}
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={`${editMode ? 'hover-light' : ''} 
      w-100 h-100 position-absolute box-shadow-on-gray rounded-xlg bg-white 
      d-flex flex-column align-items-center flex-shrink-1 smoother`}
      style={{
        cursor: editMode ? 'move' : 'auto',
        opacity,
        top: 0,
        left: 0,
        transform: editMode ? 'translate(0,0)' : 'none',
        zIndex,
      }}
      ref={dragRef}
    >
      <Row className="p-4 m-0 w-100 h-100">
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          {content}
        </div>
      </Row>
      {editMode && (
        <CloseIcon
          fontSize="small"
          className="bg-transparent position-absolute cursor-pointer mr-1 mt-1 text-muted-hover-danger"
          style={{
            top: 0,
            right: 0,
            width: '1em',
            height: '1em',
          }}
          onClick={() => deleteFromActive()}
        />
      )}
    </div>
  );
};

DraggableQuad.propTypes = {
  item: customDashItemPropType.isRequired,
  deleteIndicator: PropTypes.func.isRequired,
  doSetSelected: PropTypes.func.isRequired,
  getPatientList: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch) => ({
  deleteIndicator: (id) => dispatch(indicatorDeleted(id)),
  doSetSelected: (val) => dispatch(setSelected(val)),
  getPatientList: (list, allowDuplicatePatients) =>
    dispatch(getModalPatientList(list, null, null, null, allowDuplicatePatients)),
});

export default connect(undefined, mapDispatch)(DraggableQuad);
